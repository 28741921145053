import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as oidcReducer } from "redux-oidc";
import currentPage from "./currentPageReducer";
import managePage from "./managePageReducer";
import manageExcelTemplates from "./manageExcelTemplateReducer";
import manageExcelTemplateDownload from "./manageExcelTemplateDownload";
import manageMenus from "./manageMenusReducer";
import entityRecord from "./entityRecordReducer";
import manageToolbarSelections from "./manageToolbarSelectionsReducer";
import checklist from "./checklistReducer";
import getLoggedInUserReducer from "./getLoggedInUserReducer";


const mainReducer = combineReducers({
  routing: routerReducer,
  oidc: oidcReducer,
  currentPage,
  managePage,
  manageExcelTemplates,
  manageExcelTemplateDownload,
  manageMenus,
  entityRecord,
  manageToolbarSelections,
  checklist,
  getLoggedInUserReducer,
});

export default mainReducer;
