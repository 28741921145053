import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import A_CheckBox from "../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import * as httpCall from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  textAlign: {
    textAlign: "center",
  },

  box: {
    width: "1000px",
    maxHeight: "10px",
  },
  marginBottom: {
    marginBottom: "1.2rem",
  },
  tabPanel: {
    width: "1000px",
    height: "600px",
  },
  tab: {
    width: "1000px",
  },
  ButtonPosition: {
    marginTop: "7rem",
  },
  minWidth: {
    minWidth: 650,
  },
  box: {
    display: "flex",
    justifyContent: "end",
    paddingY: "2.2rem",
  },
  tabBar: {
    paddingTop: "1.4rem",
    paddingBottom: "1.4rem",
    backgroundColor: "#F0F0F0",
  },
  table: {
    minWidth: 700,
    color: "white",
  },
  tableHead: {
    backgroundColor: "black",
    textColor: "white",
  },
  style: {
    color: "white",
  },
  pointer: {
    cursor: "pointer",
  },
}));

function BasicTable(props) {
  const [formData, setFormData] = React.useState([]);
  const [validationSummary, setValidationSummary] = React.useState([]);
  const [questionBank, setQuestionBank] = React.useState([]);
  const [snackBar, setSnackbar] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  let { crmId, pageUrl } = useParams();
  const classes = useStyles();

  React.useEffect(() => {
    let newFormData = [];

    if (props.itemResponse !== "" && props.itemResponse !== undefined) {
      let responseData = props.data;

      for (let i in responseData) {
        let parsedItemResponse = JSON.parse(props.itemResponse);
        for (let k in parsedItemResponse) {
          if (
            responseData[i].id === parsedItemResponse[k].id &&
            props.masterId === parsedItemResponse[k].masterId
          ) {
            responseData[i].comment = parsedItemResponse[k].comment;
            responseData[i].response = parsedItemResponse[k].response;
            responseData[i].isSkipped = parsedItemResponse[k].isSkipped;
            newFormData.push(
              handleSavedData(
                responseData[i].comment,
                responseData[i].response,
                responseData[i].isSkipped,
                responseData[i].id
              )
            );
          }
        }
      }
      setFormData(newFormData);
      setQuestionBank(responseData);
    } else {
      setQuestionBank(props.data);
    }
  }, []);
  const handleValidations = () => {
    let validations = [];

    let isValidated = true;
    formData.map((item) => {
      if (item.isSkipped === true) {
        if (item.comment === "") {
         
         isValidated = false;
          validations.push(item.id);
        }
      }
    });

    setValidationSummary(validations);
    return isValidated;
  };
  const handleResponse = (e, id) => {
    let flag = true;
    for (let i in formData) {
      if (formData[i].id === id) {
        flag = false;
        formData[i].response = [e];
      }
    }
    if (flag) {
      setFormData([
        ...formData,
        {
          id: id,
          masterId: props.masterId,
          response: [e],
          comment: "",
          isSkipped: false,
        },
      ]);
    }
  };

  const handleSkipped = (e, id) => {
    let flag = true;
    for (let i in formData) {
      if (formData[i].id === id) {
        flag = false;
        formData[i].isSkipped = e;
      }
    }
    if (flag) {
      setFormData([
        ...formData,
        {
          id: id,
          masterId: props.masterId,
          isSkipped: e,
          response: [""],
          comment: "",
        },
      ]);
    }
  };

  const handleTextField = (e, id) => {
    let flag = true;
    for (let i in formData) {
      if (formData[i].id === id) {
        flag = false;
        formData[i].comment = e.target.value;
      }
    }
    if (flag) {
      setFormData([
        ...formData,
        {
          id: id,
          masterId: props.masterId,
          comment: e.target.value,
          response: [""],
          isSkipped: false,
        },
      ]);
    }
  };
  const handleSavedData = (comment, response, isSkipped, id) => {
    return {
      id: id,
      masterId: props.masterId,
      comment: comment,
      response: response,
      isSkipped: isSkipped,
    };
  };
  const formClose = () => {
    window.location.reload();
  };
const handleSave = async () => {
    if (handleValidations()) {
      setSaving(true);
      let count = 0;
      let skippedCount = 0;
      let countForm = 0;

      for (let i in formData) {
        if (formData[i].isSkipped === true) {
          skippedCount++;
        }
        if (
          formData[i].isSkipped === false &&
         (formData[i].response[0] === false || formData[i].response[0] === "")
        ) {
          continue;
        }
        countForm++;
      }
      let percent = (countForm * 100) / questionBank.length;
      let roundoff = parseInt(percent);
      let c = 0;
      let parseResponse =props.itemResponse? JSON.parse(props.itemResponse):[];
      for (let i in parseResponse) {
        for (let j in formData) {
          if (
            parseResponse[i].id === formData[j].id &&
            parseResponse[i].masterId === formData[j].masterId
          ) {
            parseResponse[i] = formData[j];
            c++;
          }
        }
      }
      if (c === 0) {
        parseResponse = [...parseResponse, ...formData];
      } else {
        let value = 0;
        for (let i in formData) {
          for (let j in parseResponse) {
            if (
              parseResponse[j].id === formData[i].id &&
              parseResponse[j].masterId === formData[i].masterId
            ) {
              parseResponse[j] = formData[i];
              value++;
            }
          }
          if (value === 0) {
            parseResponse.push(formData[i]);
          }
          value = 0;
        }
      }
      let finalData = parseResponse?JSON.stringify(parseResponse):{};
      let arrayPercent = [];
      let parsePercent = props.percentComplete?JSON.parse(props.percentComplete):[];
      if (parsePercent && parsePercent.length > 0) {
        for (let i in parsePercent) {
          if (parsePercent[i].masterId === props.masterId) {
            count++;
            parsePercent[i] = {
              masterId: props.masterId,
              percent: roundoff,
              skipped: skippedCount,
            };
          }
        }
        if (count === 0) {
          parsePercent.push({
            masterId: props.masterId,
            percent: roundoff,
            skipped: skippedCount,
          });
        }
        arrayPercent = parsePercent;
      } else {
        arrayPercent.push({
          masterId: props.masterId,
          percent: roundoff,
          skipped: skippedCount,
        });
      }

      let percentComplete = JSON.stringify(arrayPercent);

      let payload = {
        id: crmId,
        label: pageUrl,
        properties: {
          checklistResponse: finalData,
          checklistStatistics: percentComplete,
        },
        security: {
          read: {
            groupids: [""],
            users: [""],
          },
          update: {
            groupids: [""],
            users: [""],
          },
          full: {
            groupids: [""],
            users: [""],
          },
          privateRecord: true,
        },
      };
      const response = await httpCall.httpPut(
        `/CRMData/update?pageUrl=${pageUrl}`,
        payload
      );
      props.close();
      formClose();
    }
  };
  React.useEffect(() => {
    if (saving) {
      setSnackbar(true);
    }
  });

  return (
    <>
      {" "}
      <TableContainer component={Paper}>
        <Table className={classes.minWidth} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Response</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>Skip</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questionBank.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.itemDescription}
                </TableCell>
                <TableCell>
                  <A_CheckBox
                    color="primary"
                    checked={
                      row.response &&
                      row.response.length > 0 &&
                      row.response[0] === true
                        ? row.response[0]
                        : false
                    }
                    onChange={(e) => {
                      handleResponse(e, row.id);
                    }}
                  />{" "}
                </TableCell>
                <TableCell>
                  <A_TextField
                    label=""
                    defaultValue={row.comment ? row.comment : ""}
                    onChange={(e) => {
                      handleTextField(e, row.id);
                    }}
                    error={validationSummary.includes(row.id)}
                    helperText={validationSummary.includes(row.id)?
                    "Comment is required":""
                  }
                  />
                </TableCell>
                <TableCell>
                  <A_CheckBox
                    color="primary"
                    checked={row.isSkipped}
                    onChange={(e) => {
                      handleSkipped(e, row.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box className={classes.box}>
          <A_Button
            textAlign={"right"}
            label={"Save"}
            color={"primary"}
            onClick={handleSave}
          ></A_Button>
        </Box>
      </TableContainer>
      <A_Snackbar
        open={snackBar}
        message="Data saved successfully."
        autoHideDuration={10}
        horizontal="center"
      />
    </>
  );
}
const M_ChecklistForm = (props) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.textAlign}>
        <Box className={classes.marginBottom}>{props.data.name}</Box>
        <h6>Note: If you need to skip any items, you must add a comment</h6>
      </Box>
      <BasicTable
        data={props.data.response}
        masterId={props.data.id}
        itemResponse={props.itemResponse}
        close={props.close}
        percentComplete={props.percentComplete}
      />
    </>
  );
};
export default M_ChecklistForm;
