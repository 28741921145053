import React from "react";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
//www.npmjs.com/package/react-summernote
import "bootstrap";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import _default from "atob";

const A_SummerNote = (props) => {
  return (
    <ReactSummernote
      children={props.defaultValue}
      disabled={props.disabled}
      tag="textarea"
      options={{
        height: props.height,
        dialogsInBody: true,
        toolbar: [
          ["font", ["bold", "underline", "italic"]],
          ['fontsize', ['fontsize']],
          ["fontname", ["fontname"]],
          ["color", ["color"]],
          ["para", ["ul", "ol", "paragraph"]],
          ["table", ["table"]],
        ],
      }}
      onChange={props.onChange}
    />
  );
};
export default A_SummerNote;
