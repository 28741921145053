import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history";
import { syncHistoryWithStore, routerMiddleware } from "react-router-redux";
import { createUserManager, loadUser } from "redux-oidc";
import createSagaMiddleware from "redux-saga";
import throttle from "lodash/throttle";

import mainReducer from "./Reducers/mainReducer";
import userManager from "../utils/oidc/userManager";
import { loadState, saveState } from "../utils/localState/localState";
import * as currentPageSagas from "./sagas/currentPageSagas";
import * as managePageSagas from "./sagas/managePageSagas";
import * as saveTabSaga from "./sagas/manageExcelTemplateSagas";
import * as downloadExcelSaga from "./sagas/manageExcelTemplateSagas";
import * as manageMenusSagas from "./sagas/manageMenusSagas";
import * as entityRecordSagas from "./sagas/entityRecordSagas";
import * as checklistSagas from "./sagas/checklistSagas";
import * as getLoggedInUserSagas from "./sagas/getLoggedInUserSagas"

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = (store) => (next) => (action) => {
  console.log("Action type:", action.type);
  console.log("Action payload:", action.payload);
  console.log("State before:", store.getState());
  next(action);
  console.log("State after:", store.getState());
};

let initialState = loadState();
const createStoreWithMiddleware = compose(
  applyMiddleware(
    sagaMiddleware,
    loggerMiddleware,
    routerMiddleware(history, sagaMiddleware)
  )
)(createStore);

export const store = createStoreWithMiddleware(mainReducer, initialState);
//On State change getting updated state from redux-store and saving into LocalStorage.
store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);
sagaMiddleware.run(currentPageSagas.getCurrentPageSaga);
sagaMiddleware.run(managePageSagas.getPageSaga);
sagaMiddleware.run(saveTabSaga.saveTabSaga);
sagaMiddleware.run(saveTabSaga.getTabsSaga);
sagaMiddleware.run(downloadExcelSaga.downloadExcelSaga);
sagaMiddleware.run(manageMenusSagas.getMenusSagas);
sagaMiddleware.run(entityRecordSagas.getEntityRecordSagas);
sagaMiddleware.run(checklistSagas.getChecklistSaga);
sagaMiddleware.run(getLoggedInUserSagas.getLoggedInUserSaga);

loadUser(store, userManager);

//export const store = createStore(mainReducer, applyMiddleware(createLogger()));
