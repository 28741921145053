import React, { useState, useEffect } from "react";
import Email from "@material-ui/icons/Mail";
import { Box, Typography } from "@material-ui/core";
import A_IconTextButton from "../../../Atoms/Inputs/Buttons/A_IconTextButton";
import M_ManageEntityForm from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import * as httpCall from "../../../../../Utils/apis/apis";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  cardBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    marginTop: "0.1rem",
    "&:hover": { background: "#00000033" },
  },
  email: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  typoGraphBold: {
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
  typoGraph: {
    fontSize: "0.875rem",
  },
  margin: {
    marginTop: "0.6rem",
  },
});
const O_MeetingSlotCard = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { pageUrl } = useParams();
  const [recordId, setRecordId] = useState();
  const [currentIRCDate, setCurrentIRCDate] = useState({});
  const [currentDate, setCurrentDate] = useState({});
  const [currentTime, setCurrentTime] = useState({});
  const [isBookIRCOpen, setIsBookIRCOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [entity1, setEntity1] = useState({});
  const [entity2, setEntity2] = useState({});
  const [IRCSlots, setIRCSlots] = useState([]);
  useEffect(() => {
    if (
      props.entityRecord &&
      props.entityRecord.data &&
      props.entityRecord.data.entity
    ) {
      let entityRecordData = props.entityRecord.data.entity;
      let templateID =
        props.content && props.content.templateID
          ? props.content.templateID
          : "";
      for (let i in entityRecordData) {
        if (
          entityRecordData[i].pageUrl &&
          entityRecordData[i].pageUrl == pageUrl
        ) {
          setEntity1({
            pageName: entityRecordData[i].pageName
              ? entityRecordData[i].pageName
              : "",
            templateId: templateID,
            pageId: entityRecordData[i].pageId
              ? entityRecordData[i].pageId
              : "",
          });
          setEntity2({
            pageName: "ircslots",
            recordId: "",
            templateId:
              props.content && props.content.bookIRCTemplateId
                ? props.content.bookIRCTemplateId
                : "",
            pageId: entityRecordData[i].pageId
              ? entityRecordData[i].pageId
              : "",
          });
          break;
        }
      }
    }
    getSlot();
  }, []);
  let getSlot = async () => {
    let newData = [];
    let payload =
      props.content && props.content.payload ? props.content.payload : "";
    const response = await httpCall.httpPost(`/IRC/getIRCSlot`, {
      label: "ircslots",
      fields: ["ircDate", "ircTime"],
      filter: {
        field: "ircDate",
        operator: ">",
      },
    });
    const response2 = await httpCall.httpPost(`/IRC/getIRCSlot`, {
      label: "ircportal",
      fields: ["ircDate", "ircTime"],
      filter: {
        field: "ircDate",
        operator: ">",
      },
    });
    for (let i in response2) {
      response.push(response2[i]);
    }

    const responseRecord = await httpCall.httpPost(
      `/IRC/getIRCRecord`,
      payload
    );
    if (responseRecord) {
      for (let i in response) {
        responseRecord.map((record) => {
          if (response[i].id === record.id) {
            response[i] = { ...response[i], ...record };
          }
        });
      }
    }
    let outJSON = await response;
    let dim = [];
    for (let i in outJSON) {
      if (outJSON[i].ircDate) {
        outJSON[i].ircDate =
          outJSON[i].ircDate.split(" ")[0] + " " + outJSON[i].ircTime;
        dim.push(outJSON[i]);
      }
    }
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    const newD = dim.sort(function (a, b) {
      return new Date(a.ircDate) - new Date(b.ircDate);
    });
    let groubedByDate = groupBy(newD, "ircDate");

    for (let i in groubedByDate) {
      let isLast = diff_hours(i) > 48;
      let deals = [];
      for (let record in groubedByDate[i]) {
        let dealData = groubedByDate[i][record].deal && (
          <A_Button
            className={classes.margin}
            label={
              groubedByDate[i][record].deal ? groubedByDate[i][record].deal : ""
            }
            color="default"
            onClick={() => {
              let templateID =
              props.content && props.content.templateID
                ? props.content.templateID
                : "";
              let pageurl = props.content && props.content.redirectUrl
              ? props.content.redirectUrl
              : "";
              let dealId = groubedByDate[i][record] && groubedByDate[i][record].dealId ? groubedByDate[i][record].dealId:"0"
              history.push(
                `/${pageurl}/${dealId}/${templateID}`
              );
              window.location.reload();
            }}
          />
        );
        deals.push(dealData);
      }

      let detail = (
        <Box
          className={classes.cardBox}
          bgcolor={isLast ? "#0000000c" : "#0000007a"}
        >
          <Box>
            <Typography
              style={{
                color: isLast ? "#4687b8" : "#FFFFFF",
              }}
            >
              {changeDateFormat(i) + " " + groubedByDate[i][0].ircTime}
            </Typography>
            <Box style={{ display: "flex", flexWrap: "wrap", marginTop: 2 }}>
              {deals.map((item) => item)}
            </Box>
          </Box>
          <Box>
            <A_Button
              onClick={() => {
                setRecordId(groubedByDate[i].id);
                setCurrentIRCDate(changeDateFormat(i));
                setCurrentTime(groubedByDate[i][0].ircTime);
                setCurrentDate(i);
                setIsOpen(true);
              }}
              color={isLast ? "primary" : "secondary"}
              label="Book"
            />
          </Box>
        </Box>
      );
      let createdData = {
        Date: i,
        Detail: detail,
      };

      newData.push(createdData);
    }
    setIRCSlots(newData);
  };
  let changeDateFormat = (i) => {
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hours: "numeric",
    };
    var today = new Date(i);
    return today.toLocaleDateString("en-US", options);
  };
  let diff_hours = (dt1) => {
    let checkDate = new Date(dt1);
    let today = new Date();
    let diff = (today.getTime() - checkDate.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  };
  return (
    <Box
      style={{
        maxHeight: props.maxHeight ? props.maxHeight : "60vh",
        overflow: "auto",
      }}
    >
      <Typography>
        {props.content && props.content.description
          ? props.content.description.map((e) => {
              if (e.highlight) {
                return (
                  <Typography className={classes.typoGraphBold}>
                    {e.text ? e.text : ""}
                  </Typography>
                );
              } else {
                return (
                  <Typography className={classes.typoGraph}>
                    {e.text ? e.text : ""}
                  </Typography>
                );
              }
            })
          : ""}
      </Typography>
      <div className={classes.email}>
        {props.content &&
        props.content.approvalText &&
        props.content.approvalText.isShow &&
        props.content.approvalText.text &&
        props.content.approvalText.isShow === true
          ? props.content.approvalText.text
          : ""}
        <div>
          <A_Button
            label="Email"
            style={{
              margin: "0 10px",
              marginTop: "10px",
              color: "#ffffff",
              backgroundColor: "#FFA500",
            }}
            icon={<Email />}
          />
          <A_Button
            onClick={() => {
              setIsBookIRCOpen(true);
            }}
            label="Add IC Slot"
            color="primary"
            style={{
              marginTop: "10px",
            }}
          />
        </div>

        {isBookIRCOpen && (
          <M_ManageEntityForm
            edgeLabel={
              props.content && props.content.relationship
                ? props.content.relationship
                : null
            }
            isEditable={false}
            entity={entity2}
            isAddEdge={true}
            isIRCSlot={false}
            open={isBookIRCOpen}
            onClose={() => {
              setIsBookIRCOpen(false);
            }}
          />
        )}
        {isOpen && (
          <M_ManageEntityForm
            IRCRecordId={recordId}
            isEditable={false}
            IRCdate={currentIRCDate ? currentIRCDate : ""}
            IRCSlotDate={currentDate ? currentDate : ""}
            IRCTime={currentTime ? currentTime : ""}
            entity={entity1}
            isIRCSlot={true}
            isAddEdge={true}
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
          />
        )}
      </div>
      {
        <Box
          style={{ marginLeft: "10px", marginRight: "10px", marginTop: "3rem" }}
        >
          {IRCSlots.map((e) => {
            return e.Detail;
          })}
        </Box>
      }
    </Box>
  );
};
function mapStateToProps(state) {
  return {
    entityRecord: state.entityRecord,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(Object.assign({}), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(O_MeetingSlotCard);
