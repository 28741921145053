import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import { currentPageActionTypes } from "../actions/actionTypes";
import * as currentPageActions from "../actions/currentPageActions";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* getCurrentPageSaga() {
  while (true) {
    const action = yield take(currentPageActionTypes.GET_CURRENT_PAGE);
    const { pageUrl, crmId } = action.payload;
    console.log("getCurrentPageSaga is called:", action.payload);

    const state = yield select();
    let config = commonFunctions.getHttpConfig();
    if (
      !config.headers.Authorization ||
      config.headers.Authorization == "Bearer undefined"
    ) {
      const serializedState = sessionStorage.getItem(
        `oidc.user:${process.env.OIDC_AUTHORITY}:MinervaPortal`
      );
      if (serializedState === null) {
        return undefined;
      }
      const appState = JSON.parse(serializedState);
      if (appState && appState.access_token) {
        config.headers.Authorization = "Bearer " + appState.access_token;
      }
    }
    try {
      const res = yield axios.post(
        `${process.env.API_URL}/CRMData/getViewTemplates?pageUrl=${pageUrl}&crmId=${crmId}`,
        null,
        config
      );
      console.log("getCurrentPageSaga response:", res);
      yield put(
        currentPageActions.getCurrentPageSucceeded({
          ...res.data,
          getCurrentPageSucceeded: true,
          isLoading: false,
        })
      );
    } catch (e) {
      yield put(
        currentPageActions.getCurrentPageFailed({
          msg: "Some error occured",
          data: pageUrl,
          getCurrentPageSucceeded: false,
          isLoading: false,
        })
      );
      console.log("can not get current page.");
      console.log(e.message);
    }
  }
}
