export const verifyValidChartRequest = (chartRequest) => {
  let isChartRequestValid = true;
  const paramCount =
    chartRequest && chartRequest.parameters
      ? Object.keys(chartRequest.parameters).length
      : 0;
  let chartNameIndex = 0;
  if (paramCount >= 2) {
    chartNameIndex = 1;
  }
  let chartKey = JSON.stringify(chartRequest);
  let storedChartKeys = localStorage.getItem("chartStorageKeys");
  let chartKeysArr = storedChartKeys ? storedChartKeys.split("_") : [];
  if (chartKeysArr.indexOf(chartKey) > -1) {
    isChartRequestValid = false;
  } else {
    localStorage.setItem("chartStorageKeys", storedChartKeys + "_" + chartKey);
  }
  return isChartRequestValid;
};

export const hasPageFilter = (currentPage) => {
  // check if page has the parameters
  let result = true;
  if (currentPage && currentPage.pageConfig && currentPage.pageConfig.toolbar) {
    let toolbar = JSON.parse(currentPage.pageConfig.toolbar);
    let filters =
      toolbar && toolbar.primary && toolbar.primary.filters
        ? toolbar.primary.filters
        : [];
    if (!(filters.length > 0)) {
      result = false;
    }
  } else {
    result = false;
  }
  return result;
};
