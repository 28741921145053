import React from 'react'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '70%'
};
function MapGoogle(props) {
    let geoLocation = props.data && props.data.geolocation ? JSON.parse(props.data.geolocation) : {}
    let lat = geoLocation && geoLocation.lat ? geoLocation.lat : ""
    let lng = geoLocation && geoLocation.lng ? geoLocation.lng : ""
    return (<LoadScript googleMapsApiKey="AIzaSyD2u77z6reMmpdZxeL1cDkrv58d7SOpE0U" >
        <GoogleMap mapContainerStyle={containerStyle} center={{ lat, lng }} zoom={4}>
            <Marker

                position={{ lat, lng }}
            />
            <></>
        </GoogleMap>
    </LoadScript>
    )
}
export default MapGoogle