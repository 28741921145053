import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import * as httpCall from "../../../../../../Utils/apis/apis";
import A_EnhancedTable from "../../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import M_ReportEditorHeader from "../../../../Molecules/Common/PageHeaders/ReportEditorHeader/M_ReportEditorHeader";
import M_AddReportForm from "../Editor/AddReport/M_AddReportForm";
import A_Skeleton from "../../../../Atoms/Feedback/Skeletons/A_Skeleton";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import M_ManageRow from "../Editor/ManageRow/M_ManageRow";
import A_Button from "../../../../atoms/inputs/buttons/A_Button";
import * as commonFunctions from "../../../../../../Utils/Helpers/common";
import A_CircularIndeternment from "../../../../atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Snackbar from "../../../../atoms/Feedback/Snackbars/A_Snackbar";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // marginTop: "150px",
  },
  container: {
    //overflow: "auto",
    height: "500px",
    //border: "1px solid blue",
    marginLeft: "35%",
    // marginTop: "150px",
  },
  pagesList: {
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "500px",
  },
  customDialogBox: {
    width: "830px",
  },
}));

const getReportRowName = (rw, metrices, onlyMetricName) => {
  let seperator =
    rw.rowType == "standard" ? "" : rw.rowType == "preCalculated" ? "+" : "/";
  let compareId =
    rw.rowType == "standard"
      ? rw.metricId
      : rw.rowType == "preCalculated"
      ? rw.ytdMetric
      : rw.ratioDenominator;
  let rowFound = metrices.filter((metric) => {
    return compareId == metric.id;
  });
  if (onlyMetricName) {
    let rowFound = metrices.filter((metric) => {
      return metric.id == rw.metricId;
    });
    return rowFound.length > 0 ? rowFound[0].name : rw.metricId;
  } else {
    return rowFound.length > 0 && seperator
      ? `${rw.displayName} ${seperator} ${rowFound[0].name}`
      : rw.displayName;
  }
};
const appendRowProperties = (rows, metrices) => {
  let appendedRows = rows.map((row, indx) => {
    //row.id = row.metricId + "_" + indx;
    row.displayNameAppended = getReportRowName(row, metrices);
    row.metricName = getReportRowName(row, metrices, true);
    row.rowBreakAfterText = row.rowBreakAfter ? "Yes" : "No";
    /*  row.style = !row.enabled
      ? { backgroundColor: "rgb(0 0 0 / 4%)" }
      : undefined; */
    row.cellStyle = { displayNameAppended: row.classes };

    return row;
  });
  return appendedRows;
};
const ReportEditor = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [currentReport, setCurrentReport] = React.useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reports, setReports] = useState(undefined);
  const [showAddnewReportForm, setShowAddnewReportForm] = useState(false);
  const [excelReports, setExcelReports] = useState([]);
  const [isLoadingExcelReports, setIsLoadingExcelReports] = useState(false);
  const [isErrorExcelReports, setIsErrorExcelReports] = useState(false);
  const [excelMetrices, setExcelMetrices] = useState([]);
  const [excelReportWiseMetrices, setExcelReportWiseMetrices] = useState(
    undefined
  );
  const [deleteRow, setDeleteRow] = useState(undefined);
  const [deletePopup, setDeletePopup] = useState(false);
  const [reportRows, setReportRows] = useState([]);
  const [isLoadingReportRows, setIsLoadingReportRows] = useState(false);
  const [isErrorReportRows, setIsErrorReportRows] = useState(false);
  const [openManageRowForm, setOpenManageRowForm] = useState(false);
  const [currentRow, setCurrentRow] = useState(undefined);
  const [isDraged, setIsDraged] = useState(false);
  let { crmId } = useParams();
  const [isSavingSortedRow, setIsSavingSortedRow] = useState(false);
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });
  //const investmentId = "d6e57366-9156-468d-8d93-322b13547842";
  const investmentId = crmId ? crmId : "4E6A21C1-CEEA-E811-80D3-005056BE5CE6";

  const defaultRow = {
    investmentId: investmentId,
    metricId: null,
    displayName: "",
    reportId: "",
    inverseParity: false,
    decimalPlaces: 0,
    classes: "",
    enabled: true,
    sortOrder: 0,
    scaling: 0,
    rowBreakAfter: false,
    ratioNumerator: 0,
    ratioDenominator: 0,
    ratioAnnualise: false,
    ratioNPlusOneAvg: false,
    isRatio: false,
    annualisationPeriodBasis: "d",
    isBalance: false,
    ytdMetric: 0,
    ratioDataType: "",
    rowType: "standard",
  };
  useEffect(() => {
    setReports([]);

    return () => {
      setReports(undefined);
    };
  }, [undefined]);

  useEffect(() => {
    getReports();
  }, [reports]);

  useEffect(() => {
    getReportRows();
  }, [currentReport]);

  useEffect(() => {
    getExcelMetrices();
  }, [undefined]);

  // useEffect(() => {
  //   getExcelMetrices();
  // }, [excelReportWiseMetrices]);

  let colmunConfiguration = {
    title: currentReport ? ` ${currentReport.label}` : "",
    noDataText: "No data available.",
    paginate: false,
    searchable: true,
    sortable: false,
    striped: true,
    addButton: true,
    dragable: true,
    action: {
      edit: true,
      delete: true,
    },
    columnConfig: {
      // displayName: {
      //   id: "displayName",
      //   label: "Display Name",
      //   sorting: false,
      //   hide: false,
      //   // "link":"test.com",
      //   linkName: "url",
      // },
      displayNameAppended: {
        id: "displayNameAppended",
        label: "Display Name",
        sorting: false,
        hide: false,
      },
      metricName: {
        id: "metricName",
        label: "Metric",
        sorting: false,
        hide: false,
      },
      decimalPlaces: {
        id: "decimalPlaces",
        label: "Decimal Places",
        sorting: false,
        hide: false,
      },
      rowBreakAfterText: {
        id: "rowBreakAfterText",
        label: "Row Break",
        sorting: false,
        hide: false,
      },
    },
  };

  const getReports = async (setDefaultCurrentReport) => {
    if (!reports) {
      setIsLoading(true);

      //let templateJson= JSON.parse(props.content);
      let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/get-reports/${investmentId}`;
      //let requestPayload = chartRequest;
      //console.log("chart requst payload:", requestPayload);
      //const result = await httpCall.http'Post'(url, requestPayload);
      const result = await httpCall.httpGet(url);
      console.log(result);
      if ("message" in result) {
        setIsError(result);
      } else {
        let reports = commonFunctions.convertToLabelValueFormat(
          "reportId",
          "reportName",
          result
        );
        setReports(reports);
        if (setDefaultCurrentReport) {
          setCurrentReport(reports[reports.length - 1]);
        }
      }
      setIsLoading(false);
    }
  };
  const getExcelMetrices = async (addRowForm) => {
    console.log("fetching reports for: ", currentReport);
    setIsLoadingExcelReports(true);
    //let templateJson= JSON.parse(props.content);
    let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/get-metrics/${investmentId}`;
    const result = await httpCall.httpGet(url);
    console.log(result);
    if (result && "message" in result) {
      setIsErrorExcelReports(result);
    } else {
      if (result) {
        let exMetrices = [];
        let exReports = Object.keys(result).map((ex) => {
          let rpt = {};
          rpt.label = ex;
          rpt.value = ex;
          exMetrices = [...result[ex], ...exMetrices];
          return rpt;
        });

        setExcelReports(exReports);
        setExcelMetrices(exMetrices);
        setExcelReportWiseMetrices(result);
      } else {
        setExcelReports([]);
        setExcelMetrices([]);
        setExcelReportWiseMetrices(undefined);
      }
    }
    setIsLoadingExcelReports(false);
    if (addRowForm) {
      setCurrentRow(addRowForm);
      setOpenManageRowForm(true);
    }
  };
  const getReportRows = async (setDefaultCurrentReportRow) => {
    if (currentReport) {
      console.log("fetching reports for: ", currentReport);
      setIsLoadingReportRows(true);
      //let templateJson= JSON.parse(props.content);
      let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/portfolio-report-editor/list/${investmentId}`;
      //let requestPayload = chartRequest;
      //console.log("chart requst payload:", requestPayload);
      //const result = await httpCall.httpPost(url, requestPayload);
      const result = await httpCall.httpGet(url);
      console.log(result);
      if (result && "message" in result) {
        setIsErrorReportRows(result);
      } else {
        if (result) {
          let filteredReportRows = result.filter(
            (row) => row.reportId === currentReport.reportId
          );
          // let tempstr = JSON.stringify(filteredReportRows);
          //let tempArray = JSON.parse(tempstr);
          appendRowProperties(filteredReportRows, excelMetrices);
          setReportRows(filteredReportRows);
          if (setDefaultCurrentReportRow) {
            setCurrentRow(filteredReportRows[filteredReportRows.length - 1]);
          }
        } else {
          setReportRows([]);
        }
      }
      setIsLoadingReportRows(false);
    }
  };
  //let pagesData = props.pages.data;
  const handleAddButtonClickEvent = (row) => {
    if (!excelMetrices || excelMetrices.length == 0) {
      getExcelMetrices(defaultRow);
    } else {
      setCurrentRow(defaultRow);
      setOpenManageRowForm(true);
    }
  };

  // on edit button click
  const handleEditButton = (e) => {
    let arr = reportRows.filter((item) => e.currentTarget.id == item.id);
    console.log(arr[0]);
    if (!excelMetrices || excelMetrices.length == 0) {
      getExcelMetrices(arr[0]);
    } else {
      setCurrentRow(arr[0]);
      setOpenManageRowForm(true);
    }
  };

  // on delete button click
  const handleDeleteButton = (e) => {
    let id = e.currentTarget.getAttribute("dataId");
    let arr = reportRows.filter((item) => item.id == id);
    setDeleteRow(arr[0]);
    setDeletePopup(true);
  };
  const handleDeleteRowConfirmed = async (e) => {
    if (deleteRow) {
      let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/portfolio-report-editor/${deleteRow.id}`;
      //let requestPayload = deleteRow.id;
      const result = await httpCall.httpDelete(url);
      console.log(result);
      if (result && "message" in result) {
        setIsError(result);
        setIsLoading(false);
      } else {
        setDeleteRow(undefined);
        setDeletePopup(false);
        let currRows = [...reportRows];
        let newRows = currRows.filter((row) => row.id !== deleteRow.id);
        setReportRows(newRows);
      }
    }

    //call api from here.
  };

  const handleDeleteRowCancelled = (e) => {
    setDeleteRow(undefined);
    setDeletePopup(false);
    console.log("delete row cancelled");
  };
  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };
  const handleReportsChange = (selectedValue, id) => {
    console.log("report changed:", selectedValue);
    setCurrentReport(selectedValue);
  };

  const handleShowAddnewReportForm = async () => {
    if (!showAddnewReportForm) {
      getExcelMetrices();
    }
    setCurrentReport(undefined);
    setShowAddnewReportForm(!showAddnewReportForm);
  };

  const handleNewReportSave = async (currReports, importFromExcel) => {
    if (importFromExcel) {
      //get all metrices for this key.
      let newReport = currReports[currReports.length - 1];
      let newMetrices = excelReportWiseMetrices[newReport.reportName];
      let newRowsFromMetrices = newMetrices.map((met) => {
        return {
          investmentId: investmentId,
          metricId: met.id,
          displayName: met.name,
          reportId: newReport.reportId,
          inverseParity: false,
          decimalPlaces: met.type === "percentage" ? 1 : 0,
          classes: met.parentId ? "" : "",
          enabled: met.show,
          sortOrder: met.sortOrder,
          scaling: met.scaling,
          rowBreakAfter: met.rowBreakAfter,
          ratioNumerator: 0,
          ratioDenominator: 0,
          ratioAnnualise: false,
          ratioNPlusOneAvg: false,
          isRatio: false,
          annualisationPeriodBasis: "d",
          isBalance: met && met.isBalance ? met.isBalance : false,
          ytdMetric: 0,
          ratioDataType: "",
          rowType: "standard",
        };
      });

      //saving row
      let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/portfolio-report-editor/bulk-insert`;
      //let requestPayload = { ...newRow };
      const result = await httpCall.httpPost(url, newRowsFromMetrices);
      console.log(result);
      if ("message" in result) {
        setIsError(result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        //setValidationSummary(undefined);
      }
    }
    setReports(currReports);
    setCurrentReport(currReports[currReports.length - 1]);
  };

  const handleRowSave = (isAdd, newRow) => {
    setOpenManageRowForm(false);
    setCurrentRow(defaultRow);
    if (isAdd) {
      let newRows = [...reportRows];
      newRows.push(newRow);
      appendRowProperties(newRows, excelMetrices);
      setReportRows(newRows);
    } else {
      let currRowIndex = reportRows.findIndex((row) => row.id == newRow.id);
      if (currRowIndex > -1) {
        let newRows = [...reportRows];
        //let nArr = newRows.splice(currRowIndex, 1, newRow);
        newRows[currRowIndex] = newRow;
        appendRowProperties(newRows, excelMetrices);
        setReportRows(newRows);
      }
    }
    console.log(reportRows);
  };
  const handleManageRowClose = (isAdd, newRow) => {
    setOpenManageRowForm(false);
    setCurrentRow(defaultRow);
  };

  const handleCancelButton = () => {
    let tempstr = JSON.stringify(reportRows);
    let tempArray = JSON.parse(tempstr);
    appendRowProperties(tempArray, excelMetrices);
    setReportRows(tempArray);
    setIsDraged(false);
  };
  const isDragedHandle = (val) => {
    setIsDraged(true);
  };

  const handleAllRowsSave = async () => {
    setIsSavingSortedRow(true);
    let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/portfolio-report-editor/bulk-update`;
    //let requestPayload = { ...newRow };
    const result = await httpCall.httpPost(url, reportRows);
    console.log(result);
    if ("message" in result) {
      setIsError(result);
      setIsLoading(false);
    } else {
      setIsSavingSortedRow(false);
      setChangesSaved({
        status: true,
        type: "success",
        msg: "Record Saved Successfully",
      });
      setIsLoading(false);
      //setValidationSummary(undefined);
    }
  };

  const handleBackButton = () => {
    setShowAddnewReportForm(false);
    setCurrentReport(undefined);
  };

  return (
    <>
      {
        // commenting and keeping for future reference//
      }
      {/* <M_ReportEditorHeader
        // commented while migrating this page under deal 
        // title="Report Editor"
        // subtitle="DSS"
        showReportsDropdown={currentReport}
        showAddButton={currentReport}
        currentReport={currentReport}
        reports={reports}
        onReportsChange={handleReportsChange}
        onShowAddnewReportForm={handleShowAddnewReportForm}
      /> */}

      {currentReport && (
        <div className={classes.root}>
          {isLoadingReportRows && (
            <Grid
              container
              spacing={1}
              justify="center"
              style={{ marginTop: "12%", marginLeft: 10 }}
            >
              <Grid
                container
                spacing={2}
                //style={{ marginTop: "12%", marginLeft: 10 }}
              >
                <Grid item lg={4}>
                  <A_Skeleton
                    variant="rec"
                    animation="wave"
                    width={"80%"}
                    height={50}
                  />
                </Grid>
                <Grid item lg={6}></Grid>
                <Grid item lg={2}>
                  <A_Skeleton
                    variant="rec"
                    animation="wave"
                    //width={"80%"}
                    height={50}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                justify="center"
                style={{ marginTop: "2%" }}
              >
                <Grid item lg={10}>
                  <A_Skeleton
                    variant="rec"
                    animation="wave"
                    width={"80%"}
                    height={50}
                  />
                </Grid>
                <Grid item lg={10}>
                  <A_Skeleton
                    variant="rec"
                    animation="wave"
                    width={"80%"}
                    height={50}
                  />
                </Grid>
                <Grid item lg={10}>
                  <A_Skeleton
                    variant="rec"
                    animation="wave"
                    width={"80%"}
                    height={50}
                  />
                </Grid>
                <Grid item lg={10}>
                  <A_Skeleton
                    variant="rec"
                    animation="wave"
                    width={"80%"}
                    height={50}
                  />
                </Grid>
                <Grid item lg={10}>
                  <A_Skeleton
                    variant="rec"
                    animation="wave"
                    width={"80%"}
                    height={50}
                  />
                </Grid>
              </Grid>

              {/* <Grid item lg={12}>
                <A_Skeleton
                  variant="rec"
                  animation="wave"
                  //width={30}
                  height={30}
                />
              </Grid>
              <Grid item lg={12}>
                <A_Skeleton
                  variant="rec"
                  animation="wave"
                  //width={30}
                  height={30}
                />
              </Grid>
              <Grid item lg={12}>
                <A_Skeleton
                  variant="rec"
                  animation="wave"
                  //width={30}
                  height={30}
                />
              </Grid> */}
            </Grid>
          )}
          {!isLoadingReportRows && (
            <>
              <div style={{ textAlign: "left", padding: "15px" }}>
                <A_Button
                  color="primary"
                  label="Back"
                  onClick={handleBackButton}
                />
              </div>

              <A_EnhancedTable
                data={reportRows}
                configurations={colmunConfiguration}
                handleAddButtonClick={handleAddButtonClickEvent}
                handleEditButtonClick={handleEditButton}
                handleDeleteButtonClick={handleDeleteButton}
                isDraged={isDragedHandle}
                //className={classes.pagesList}
              />
              {isDraged ? (
                <Grid
                  style={{ textAlign: "right", padding: "10px" }}
                  item
                  xs={12}
                >
                  <A_Button
                    color="primary"
                    label="Cancel"
                    onClick={handleCancelButton}
                  />
                  <A_Button
                    color="primary"
                    label="Save"
                    disabled={isSavingSortedRow}
                    endIcon={
                      isSavingSortedRow ? (
                        <A_CircularIndeternment color="secondary" size={20} />
                      ) : (
                        ""
                      )
                    }
                    onClick={handleAllRowsSave}
                  />
                </Grid>
              ) : (
                ""
              )}
            </>
          )}
          <A_SimpleDialog
            open={openManageRowForm}
            title={
              currentRow && currentRow.id !== 0
                ? currentRow.displayNameAppended
                : "Add new row"
            }
            //title="Add new row"
            maxWidth="sm"
            fullWidth={true}
            className={classes.customDialogBox}
            onClose={handleManageRowClose}
            //onExit={handleExit}
            disableEscapeKeyDown={true}
            dialogContent={
              <M_ManageRow
                currentReport={currentReport}
                currentRow={currentRow}
                rows={reportRows}
                metrices={excelMetrices}
                onRowSave={handleRowSave}
                onManageRowClose={handleManageRowClose}
              />
            }
          ></A_SimpleDialog>
          <A_SimpleDialog
            id="2"
            open={deletePopup}
            onClose={handleDeleteRowCancelled}
            dialogContent={"Are you sure you want to delete this row?"}
            dialogActions={
              <Grid container>
                <Grid item xs={6}>
                  <A_Button
                    label="No"
                    color="default"
                    onClick={handleDeleteRowCancelled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <A_Button
                    label="Yes"
                    color="secondary"
                    onClick={handleDeleteRowConfirmed}
                  />
                </Grid>
              </Grid>
            }
          />
          <A_Snackbar
            open={changesSaved.status}
            message={changesSaved.msg}
            type={changesSaved.type}
            autoHideDuration={2000}
            horizontal="center"
            handleClose={handleSnackbarClose}
          />
        </div>
      )}

      {!currentReport && (
        <section className={classes.container}>
          {isLoading && (
            <Grid container style={{ width: 550 }}>
              <Grid item lg={10}>
                <A_Skeleton
                  variant="rect"
                  animation="wave"
                  width={450}
                  height={50}
                />
              </Grid>
              <Grid item lg={2}>
                <A_Skeleton
                  variant="circle"
                  animation="wave"
                  width={50}
                  height={50}
                />
              </Grid>
            </Grid>
          )}
          {!isLoading && (
            <M_AddReportForm
              variant="outlined"
              investmentId={investmentId}
              reports={reports}
              onReportsChange={handleReportsChange}
              excelReports={excelReports}
              showAddnewReportForm={showAddnewReportForm}
              onShowAddnewReportForm={handleShowAddnewReportForm}
              onReportSave={handleNewReportSave}
            />
          )}
        </section>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(Object.assign({}), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportEditor);
// export default ReportEditor;
