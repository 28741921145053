import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import * as httpCall from "../../../../../../../Utils/apis/apis";
import M_GraphChart from "./GraphCharts/M_GraphChart";
import M_TableChart from "./M_TableCharts/M_TableCharts";
import Skeleton from "@material-ui/lab/Skeleton";
import ErrorBoundary from "../../../../../molecules/common/error/ErrorBoundary";
import { useParams } from "react-router";
import { verifyValidChartRequest, hasPageFilter } from "./M_ChartCard.Helper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));
const M_ChartCard = React.memo((props) => {
  const [chart, setChart] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { crmId } = useParams();
  const pageFilters = props.toolbarSelections.filters;

  // useEffect(() => {
  //   setChart({});
  //   return () => {
  //     setChart(undefined);
  //   };
  // }, [undefined]);

  useEffect(() => {
    //setChart(sampleGraphChart);
    if (props.content && props.content.parameters && crmId) {
      for (const key in props.content.parameters) {
        if (props.content.parameters[key] == "=crmId") {
          props.content.parameters[key] = crmId;
        }
      }
    }
    if (
      props.content &&
      props.content.content &&
      props.content.content.sourceId &&
      crmId
    ) {
      for (const key in props.content.content.sourceId) {
        if (props.content.content.sourceId == "=crmId") {
          props.content.content.sourceId = crmId;
        }
      }
    }
    getChart(props.content, false);
  }, [chart]);

  useEffect(() => {
    getChart(props.content, true);
  }, [pageFilters]);

  const classes = useStyles();

  //TODO: to move out to separate file
  const getChart = async (chartRequest, filtersChanged) => {
    if (!chart || filtersChanged) {
      let url = "/ChartData/getchartdata";
      let requestPayload = JSON.parse(JSON.stringify(chartRequest));
      //Add updated params to chart request
      if (pageFilters && hasPageFilter(props.currentPage)) {
        pageFilters.forEach((pageFilter) => {
          requestPayload.parameters[pageFilter.filterName] = pageFilter.value;
        });
      }
      if (!verifyValidChartRequest(requestPayload)) {
        return;
      }
      setIsLoading(true);
      const result = await httpCall.httpPost(url, requestPayload);
      let chData = result;
      if ("message" in result) {
        setIsError(result);
      } else {
        setChart(chData);
      }
      setIsLoading(false);
    }
  };
  const handleRefreshChart = (chartRequest) => {
    setChart(undefined);
    getChart(chartRequest);
  };
  const handleFilterChange = (key, value, filterArray) => {
    props.content.parameters[key] = value;
    setChart(undefined);
    getChart(props.content);
  };

  return (
    <div
      style={{
        height: "auto",
        minHeight: "300px",
      }}
    >
      {isLoading && (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      )}
      {isError && (
        <div>
          <h4>Some error occured.</h4>
          <p> Error : {isError.message}</p>
          <h5>Please contact support team.</h5>
        </div>
      )}
      {!isLoading &&
        !isError &&
        chart &&
        chart.chartConfiguration &&
        chart.chartConfiguration.type === "graph" && (
          <ErrorBoundary>
            <M_GraphChart
              chart={chart}
              chartRequest={props.content}
              refreshChart={handleRefreshChart}
            />
          </ErrorBoundary>
        )}
      {!isLoading &&
        !isError &&
        chart &&
        chart.chartConfiguration &&
        chart.chartConfiguration.type === "table" && (
          <ErrorBoundary>
            <M_TableChart
              chart={chart}
              crmData={props.currentPage.data}
              handleFilterChange={handleFilterChange}
              parameters={
                props.content && props.content.parameters
                  ? props.content.parameters
                  : {}
              }
            />
          </ErrorBoundary>
        )}
    </div>
  );
});
M_ChartCard.defaultProps = {
  content: {},
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(managePageActions, dispatch);
}

export default connect(mapStateToProps, undefined)(M_ChartCard);
