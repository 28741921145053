import React from "react";
import { connect } from "react-redux";

function ApplicationSettings(props) {
  console.log('app_setting props');
  console.log(props);
  const { user } = props;
  return <h3>Application Settings page will be available soon.</h3>;
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationSettings);
