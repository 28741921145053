import { checklistActionTypes } from "./actionTypes";
export const getChecklist = (payload) => {
  return {
    type: checklistActionTypes.GET_CHECKLIST,
    payload,
  };
};
export const getChecklistSucceeded = (payload) => {
  return {
    type: checklistActionTypes.GET_CHECKLIST_SUCCEEDED,
    payload,
  };
};
export const getChecklistFailed = (payload) => {
  return {
    type: checklistActionTypes.GET_CHECKLIST_FAILED,
    payload,
  };
};

