import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as manageExcelTemplateActions from "../../../../../store/actions/manageExcelTemplateActions";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { Dialog, FormControl, Grid, Typography } from "@material-ui/core";

import Input from "@material-ui/core/Input";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  CancelButton: {
    marginLeft: "250px",
  },
  popUpContent: {
    display: "flex",
  },
  rightContent: {
    width: "100%",
  },
  leftContentSetting: {
    marginTop: "0.2rem",
    marginBottom: "0.8rem",
  },
  cancelButton: {},
  alignCenter: {
    display: "flex",
    justifyContent: "center"

  }
}));

const LookUpMaterics = (props) => {
  const classes = useStyles();
  const [openPopup, setOpenPopup] = useState(props.openPopup);
  const data = props.content.map((item, index) => item);

  const NameSelectData = data.filter(
    (item) => item.name !== props.lookupMetricValue.name
  );

  const LookupData = () => {
    let newData = [];
    if (props.lookupMetricValue.lookupMetricInfo == null) {
      return newData;
    } else {
      let newLookup = props.lookupMetricValue.lookupMetricInfo;
      let newLookupArray = props.lookupMetricValue.lookupMetricInfo.map(
        (item) => item.lookupRefMetricName
      );
      newData = newLookup ? newLookupArray : [];

      return newData;
    }
  };

  const [name, setName] = useState(LookupData !== null ? LookupData : []);
  const handleChange = (event, index) => {
    console.log("event", event.target.value);
    setName(event.target.value);
  };

  //Required
  /*  const handleDelete = (chipToDelete) => () => {
    setName(
      NameSelectData.filter((chip, index) => {
        return index !== chipToDelete;
      })
    );
  }; */

  const LookupMatericsData = name.map((item) =>
    Object.assign({
      lookupMetricId: props.lookupMetricId,
      lookupRefMetricId: 0,
      lookupRefMetricName: item,
    })
  );

  const handleFormChange = () => {
    props.handlelookup(props.lookupMetricId, LookupMatericsData);
    props.callBack(false);
  };

  const onClose = (e) => {
    e.stopPropagation();
    props.callBack(false);
  };

  return (
    <div>
      <A_SimpleDialog
        open={openPopup}
        title={"Select metrics for Custom sum"}
        maxWidth="md"
        dialogContent={
          <React.Fragment>
            <div>
              <div className={classes.popUpContent}>
                <Grid item xs={3}>
                  <Typography
                    className={classes.leftContentSetting}
                    variant="h6"
                  >
                    {props.lookupMetricValue.name}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      multiple
                      variant="outlined"
                      defaultValue={name}
                      onChange={handleChange}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(e) => (
                        <div className={classes.chips}>
                          {e.map((item, value) => (
                            <Chip
                              key={value}
                              label={item}
                              //Required
                              /*  onDelete={handleDelete(value)}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }} */
                            />
                          ))}
                        </div>
                      )}
                      //MenuProps={MenuProps}
                    >
                      {NameSelectData.map((item, index) => (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </div>
            </div>

            <div className={classes.alignCenter}>
              <A_Button color="primary" label="Cancel" onClick={onClose} />
              <A_Button color="primary" label="Ok" onClick={handleFormChange} />
            </div>
          </React.Fragment>
        }
        showclosebutton = {false}
      ></A_SimpleDialog>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    manageExcelTemplates: state.manageExcelTemplates,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(manageExcelTemplateActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LookUpMaterics);
