import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popper from "@material-ui/core/Popper";
import { Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PrintIcon from "@material-ui/icons/Print";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Popover } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import GetAppIcon from "@material-ui/icons/GetApp";
import { httpPost } from "../../../../../Utils/apis/apis";
import ViewListIcon from "@material-ui/icons/ViewList";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import theme from "../../../../Themes/defaultTheme";
import A_IconButton from "../../inputs/buttons/A_IconButton";
import A_Divider from "../../../atoms/dataDisplay/dividers/A_Divider";
import A_Paper from "../../../Atoms/Surfaces/Papers/A_Paper";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import ContactMail from "@material-ui/icons/ContactMail";
import { Box } from "@material-ui/core";
import Default_Avatar from "../../../../../Assets/Images/Defaults/defLogo.png";
import A_ProfilePicture from "../../../Atoms/DataDisplay/ProfilePicture/A_ProfilePicture";
import { useParams } from "react-router";
import M_CardFilter from "../../Inputs/Selects/M_CardFilter";
import FolderIcon from "@material-ui/icons/Folder";
import { useSelector } from "react-redux";
function ListItemLink(props) {
  return props.tooltip ? (
    <Tooltip title={props.tooltip} arrow>
      <ListItem button component="a" {...props} />
    </Tooltip>
  ) : (
    <ListItem button component="a" {...props} />
  );
}

const useDefaultStyles = makeStyles({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  card: {
    minHeight: "5rem",
    margin: "auto 0",
    display: "flex",
    alignItems: "center",
    flex: "0 0 100%",
  },
});
const A_Card = (props) => { 
  const useStyles = makeStyles(props.cardStyle);
  const classes = useStyles();
  const user = useSelector((state) => state.oidc.user);
  const defaultStyle = useDefaultStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl_Export, setAnchorEl_Export] = React.useState(null);
  let { crmId, pageUrl, templateId } = useParams();
  const [image, setImage] = useState("");
  const [addOption,setAddOption] = useState(props.header.optionAdd ||props.header.deleteAdd);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

const idAdd=props.header.optionAdd?"simple-popover" : undefined;
  const exportOpen = Boolean(anchorEl_Export);
  const exportId = exportOpen ? "export-popper" : undefined;
  

  useEffect(() => {
    if (props.header.showPic) {
      upload();
    }
  }, []);
  useEffect(() => {
    setAddOption(props.header.optionAdd)
  }, [props.header.optionAdd]);

  const upload=()=> {
    let subdomain =  new URL(window.location.href).hostname.split(".")[0]
    fetch(`${process.env.DOCUMENT_HELPER}`, {
      method: "GET",   
      headers: {
        filename: "profile_"+crmId,
        type: "image",
        "file-operation": "download",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    })
      .then((response) => {   
        if (!response.ok) {
          throw Error(response.statusText);
        }    
        return response;
      })
      .then((success) => success.blob())
      .then((data) => {
        const imageAsUrl = URL.createObjectURL(data);
        setImage(imageAsUrl);
      });
    
   
  }

  const handleCardMenu = (event) => {
    setAnchorEl(anchorEl !== null ? null : event.currentTarget);

    if (anchorEl_Export !== null) {
      handleExportMenu(event);
    }
  };
  const handleExportMenu = (event) => {
    setAnchorEl_Export(anchorEl_Export !== null ? null : event.currentTarget);
    if (anchorEl !== null) {
      handleCardMenu(event);
    }
  };
  const handleZoom = (props) => {
    props.handleZoom(props);
  };
  const handleEdit = (props) => {
    props.handleEdit(props);
  };
  const handleAdd = (props) => {
    props.handleAdd(props);
  };
  const handleOptionOnAdd = (props) => {
    props.handleOptionOnAdd(props);
  };
  const handleClose = () => {
props.handleDeleteOnAdd(props)
 };


  const handlePdfClick = async () => {
    let convertedFormData = {
      chartId: props.content.props.children.props.content.chartId,
      parameters: props.content.props.children.props.content.parameters,
      fileName: "excelFile",
    };

    let config = commonFunctions.getHttpConfig();
    const response = await httpPost(
      `/ChartData/download-excel`,
      convertedFormData,
      { ...config, responseType: "blob" }
    );

    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "excelFile.xlsx");
    link.click();
    setIsLoading(false);
  };
  const dealFolder = () => {
    window.open(
      " https://minervaapp.sharepoint.com/sites/MinervaCloud/Shared%20Documents/Forms/AllItems.aspx",
      "_blank"
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <Card
        className={classes.root}
        variant={props.variant}
        elevation={props.elevation}
      >
        <Box className={defaultStyle.card}>
          <CardHeader
            classes={{
              title: classes.title,
            }}
            style={{ width: "100%", height: "1rem" }}
            avatar={
              props.header && props.header.showPic ? (
                <A_ProfilePicture
                  src={
                    image
                      ? image
                      : Default_Avatar
                  }
                />
              ) : (
                ""
              )
            }
            title={
              <React.Fragment>
                {props.header.title}
                {<span style={{ padding: "5px" }}></span>}
                {props.header.filtersData
                  ? props.header.filtersData.map((option) => (
                      <M_CardFilter title={option.title} data={option.data} />
                    ))
                  : ""}
                     {props.header && props.header.addFiles ? (
                  <A_Button
                    label="Open Deal Folder"
                    color="primary"
                    startIcon={<FolderIcon />}
                    onClick={() => dealFolder()}
                  />
                ) : (
                  ""
                )}
              </React.Fragment>
            }
            action={
              <React.Fragment>
                {props.header.add && (
                  <A_IconButton
                    style={{ height: "1rem" }}
                    aria-describedby={id}
                    icon={<AddIcon />}
                    onClick={() => handleAdd(props)}
                  />
                )}
                <>
                 {props.header.optionOnAdd && (
                  <A_IconButton
                    style={{ height: "1rem" }}
                    aria-describedby={id}
                    icon={<AddIcon />}
                    onClick={() => handleOptionOnAdd(props)}
                  />
                  
                )}
               <Popover
            id={idAdd}
            open={addOption}
            onClose={handleClose}
            anchorOrigin={{
              vertical:230,
              horizontal: "right",
            }}
          >
         {props.header &&props.header.addParameterOption&&props.header.addParameterOption .length>0 ? (   
           props.header.addParameterOption.map((item)=>{
            return(
              <div>
            <A_Button 
            variant="text"
            label={item.label}
            color={"white"}
            //  className={classes.buttonMargin}
              onClick={() => {
               props.handleClickOptionAdd(item)
               props.handleDeleteOnAdd(props)
              }}
            >
            
            </A_Button>
<A_Divider/>
        </div>
            
          ) 
         
          })):""}
          </Popover>
                </>
                {props.header.edit && (
                  <A_IconButton
                    style={{ height: "1rem" }}
                    aria-describedby={id}
                    icon={<EditIcon />}
                    onClick={() => handleEdit(props)}
                  />
                )}
                {props.header.zoom && (
                  <A_IconButton
                    style={{ height: "1rem" }}
                    aria-describedby={id}
                    icon={<ZoomInIcon />}
                    onClick={() => handleZoom(props)}
                  />
                )}

                {props.header.export && (
                  <A_IconButton
                    aria-describedby={id}
                    onClick={(e) => handleExportMenu(e)}
                    icon={<GetAppIcon />}
                  />
                )}
                <Popper
                  className={classes.paper}
                  id={exportId}
                  open={exportOpen}
                  anchorEl={anchorEl_Export}
                  placement="bottom-end"
                >
                  <A_Paper
                    width={20}
                    height={20}
                    content={
                      <List component="nav" aria-label="card menu">
                        <ListItemLink
                          button
                          onClick={handlePdfClick}
                          tooltip="Excel"
                        >
                          <ListItemIcon>
                            <ViewListIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Excel" />
                        </ListItemLink>
                        <A_Divider />
                        <ListItemLink tooltip="PDF">
                          <ListItemIcon>
                            <PictureAsPdfIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="PDF" />
                        </ListItemLink>
                        <A_Divider />
                        <ListItemLink
                          tooltip="PNG"
                          onClick={() => handleMenuClick("profile_settings")}
                        >
                          <ListItemIcon>
                            <ImageIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="PNG" />
                        </ListItemLink>
                      </List>
                    }
                  />
                </Popper>

                {props.header.secondaryMenu && (
                  <A_IconButton
                    aria-describedby={id}
                    onClick={(e) => handleCardMenu(e)}
                    icon={<MoreVertIcon />}
                  />
                )}
                <Popper
                  className={classes.paper}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  placement="bottom-end"
                >
                  <A_Paper
                    width={25}
                    height={20}
                    content={
                      <List component="nav" aria-label="card menu">
                        <ListItemLink
                          onClick={props.handleClose}
                          tooltip="Print"
                        >
                          <ListItemIcon>
                            <PrintIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Print" />
                        </ListItemLink>
                        <A_Divider />
                        {/* <ListItemLink tooltip="Hide temporarily" onClick={()=>handleMenuClick('profile_settings')}>
                          <ListItemIcon>
                            <VisibilityOffIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Hide" />
                      </ListItemLink>  */}
                        <A_Divider />
                        <ListItemLink
                          tooltip="Card information"
                          onClick={() => handleMenuClick("profile_settings")}
                        >
                          <ListItemIcon>
                            <InfoIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Card Info" />
                        </ListItemLink>
                        <A_Divider />
                        <ListItemLink
                          tooltip="Open in editor"
                          onClick={() => handleMenuClick("open_in_editor")}
                        >
                          <ListItemIcon>
                            <OpenInNewIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary="Open in editor" />
                        </ListItemLink>
                      </List>
                    }
                    size="small"
                  />
                </Popper>
              </React.Fragment>
            }
          />
        </Box>
        <A_Divider />
        <CardContent style={{ marginTop: "10px" }}>
          {/* <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {props.title}
          </Typography> 
          <Typography variant="p" component="div">
            {props.content}
          </Typography>*/}
          {props.content}
        </CardContent>
        {props.action && <A_Divider />}
        <CardActions>{props.action}</CardActions>
      </Card>
    </ThemeProvider>
  );
};
A_Card.defaultProps = {
  variant: "elevation",
  elevation: 3,
  header: { zoom: false },
  cardStyle: {
    root: {
      maxWidth: "100%",
      height: "100%",
      overflow: "visible",
    },
    title: {
      fontSize: 20,
    },
    pos: {
      marginBottom: 0,
    },
  },
};

export default A_Card;
