import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import AddIcon from "@material-ui/icons/Add";
import Slide from "@material-ui/core/Slide/Slide";
import M_AddEntityList from "./M_AddEntityList";
import A_Paper from "../../../../atoms/surfaces/papers/A_Paper";
import A_IconButton from "../../../../Atoms/Inputs/Buttons/A_IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as entityRecordAction from "../../../../../../store/actions/entityRecordAction";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: "-2px",
    marginRight: "-15px",
    zIndex: 999,
  },
  listspacing: {
    padding: "2px",
  },
  AddButton: {
    "&:hover": {
      color: "#a9a9a9",
    },
  },
}));

const M_AddEntity = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleAddEntityList = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const id = open ? "simple-popper" : undefined;
  const handleAddFormClose = () => {
    setAnchorEl(null);
  };
  const [entityList, setEntityList] = useState(
    props.entityRecord &&
      props.entityRecord.data &&
      props.entityRecord.data.entity
      ? props.entityRecord.data.entity
      : {}
  );
  useEffect(() => {
    props.getEntityRecord();
  }, []);

  useEffect(() => {
    if (
      props.entityRecord &&
      props.entityRecord.data &&
      props.entityRecord.data.entity
    ) {
      setEntityList(props.entityRecord.data.entity);
    }
  }, [props.entityRecord]);

  return (
    <React.Fragment>
      <A_IconButton
        color="inherit"
        aria-describedby={id}
        onClick={handleAddEntityList}
        className={classes.AddButton}
        icon={<AddIcon />}
      ></A_IconButton>
      <Popper
        className={classes.paper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
      >
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <div>
            <ClickAwayListener onClickAway={(e) => handleAddEntityList(e)}>
              <div>
                <A_Paper
                className ={classes.PaperHeight}
                  width={30}
                  height={"auto"}
                  content={
                    <M_AddEntityList
                      entitiesList={entityList}
                      onFormClose={handleAddFormClose}
                    />
                  }
                  size="large"
                />
              </div>
            </ClickAwayListener>
          </div>
        </Slide>
      </Popper>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    entityRecord: state.entityRecord,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(entityRecordAction, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(M_AddEntity);
