import React from "react";
import { useState, useRef } from "react";
import { getHttpConfig } from "../../../../../Utils/Helpers/common";
import axios, { post } from "axios";
import "./dropzoneupload.scss";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import CircularDeterminate from "../../../Atoms/Inputs/CircularProgressBar/CircularProgressBar";
import { CloudUpload } from "@material-ui/icons";
import PreviousTemplate from "../Renderers/Views/PreviousTemplate/PreviousTemplate";
import { Divider } from "@material-ui/core";

const M_DropableFileUpload = (props) => {
  const { content } = props;
  const inputFile = useRef(null);
  const [hover, setHover] = useState(false);
  const user = useSelector((state) => state.oidc.user);
  let fileName = "";
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState([]);
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });

  let { pageUrl, crmId } = useParams();

  var inputProps = {
    accept:
      content && content.allowedExtensions
        ? content.allowedExtensions
        : "pdf,pptx,docx,xlsx",
    multiple: content && content.multiple ? content.multiple : false,
    directory: content && content.directory ? content.directory : null,
  };

  const handleFileUpload = async (e, dropedFiles = null) => {
    let resultFiles = [];

    let files = dropedFiles ? dropedFiles : e.target.files;
    let directory =
      inputProps && inputProps.directory ? inputProps.directory + "/" : "";

    for (let file of files) {
      fileName =
        props.content.type && props.content.type.toLowerCase() === "pdffile"
          ? props.content.formData.invoicenumber + "/" + file.name
          : pageUrl + "/" + crmId + "/" + directory + file.name;
      resultFiles.push(file);
    }

    setFile(resultFiles);

    let reader = new FileReader();
    reader.readAsDataURL(resultFiles[0]);
    reader.onload = (e) => {
      const httpConfig = getHttpConfig();
      /* let subdomain = "dev"; */
      //important
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      let header = {};
      setUploading(true);
      if (
        props.content.type &&
        props.content.type.toLowerCase() === "pdffile"
      ) {
        var ext = fileName.split(".").pop();
        if (ext.toLowerCase() === "pdf") {
          const url = `${process.env.DOCUMENT_HELPER}`;
          let header = {
            filename: fileName,
            type: "invoice",
            "file-operation": "upload",
            "process-file": "no",
            "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
            subdomain: subdomain,
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.access_token,
          };
          const formData = { file: e.target.result };
          axios
            .post(url, formData, { headers: header })

            .then(
              (response) => {
                props.onUpload(true);
                let messgae =
                  response && response.message
                    ? response.message
                    : "Uploaded sucessfuly";

                setChangesSaved({
                  status: true,
                  type: "success",
                  msg: messgae,
                });
                setUploading(false);
              },
              (error) => {
                let messgae = error && error.message ? error.message : "Error";
                setChangesSaved({
                  status: true,
                  type: "error",
                  msg: messgae,
                });
                setUploading(false);
              }
            );
        } else {
          setChangesSaved({
            status: true,
            type: "error",
            msg: "Only Pdf file is allowed",
          });
          setUploading(false);
        }
      } else {
        header = {
          filename: fileName,
          "x-functions-key": process.env.EXCEL_UPLOAD_API_KEY,
          subdomain: subdomain,
          Authorization: httpConfig.headers.Authorization,
        };
        const url = `${process.env.EXCEL_UPLOAD_API_URL}`;
        const formData = { file: e.target.result };
        return post(url, formData, { headers: header }).then(
          (response) => {
            let messgae =
              response && response.message
                ? response.message
                : "Uploaded sucessfuly";
            setChangesSaved({
              status: true,
              type: "success",
              msg: messgae,
            });
            setUploading(false);
          },
          (error) => {
            let messgae = error && error.message ? error.message : "Error";
            setChangesSaved({
              status: true,
              type: "error",
              msg: messgae,
            });
            setUploading(false);
          }
        );
      }
    };
    e.preventDefault();
  };
  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  const handleClick = () => {
    console.log(inputFile);
    inputFile.current.click();
  };

  const handleDropFiles = (event) => {
    stopEvent(event);
    setHover(false);
    const { files } = event.dataTransfer;
    handleFileUpload(event, files);
  };

  const stopEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDragOver = (event) => {
    stopEvent(event);
    setHover(true);
  };
  const onDragLeave = (event) => {
    stopEvent(event);
    setHover(false);
  };
  return (
    <div>
      <section style={{ minWidth: "20px" }}>
        <div
          className={
            hover ? "drop-zone-container hover" : "drop-zone-container"
          }
        >
          <CloudUpload color="primary" />
          {!uploading ? (
            <p style={{ color: "#054af7" }}>
              Drag or Drop Files here to upload{" "}
            </p>
          ) : (
            ""
          )}
          {uploading ? <CircularDeterminate /> : ""}
          {!uploading ? (
            <A_Button
              onClick={handleClick}
              onDrop={handleDropFiles}
              onDragLeave={onDragLeave}
              onDragOver={onDragOver}
              color="primary"
              label="Click to Upload"
            />
          ) : (
            ""
          )}
          <input
            {...inputProps}
            className={"fileinput"}
            type="file"
            ref={inputFile}
            onChange={handleFileUpload}
          />
        </div>

        <A_Snackbar
          open={changesSaved.status}
          message={changesSaved.msg}
          type={changesSaved.type}
          autoHideDuration={2000}
          horizontal="center"
          handleClose={handleSnackbarClose}
        />
        {/* <div>{file && file.length > 0 ? file : ""}</div> */}
      </section>
      <Divider />
      <section>
        <PreviousTemplate />
      </section>
    </div>
  );
};

export default M_DropableFileUpload;
