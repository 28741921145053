import React, { useEffect, useState } from "react";
import "react-summernote/dist/react-summernote.css";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router";
import { httpPost, httpPut } from "../../../../../Utils/apis/apis";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles({
  inputGroup: {
    display: "flex",
    fontSize: "0.9rem"
  },
  mainDiv : {
    padding: "4px"
  },
  inputPara: {
    margin: "0px",
    paddingLeft: "60px",
    fontWeight: "600"
  },
  paddingField1: {
    margin: "0px",
    paddingLeft: "50px",
  },
  paddingField2: {
    paddingLeft: "60px",
    marginBottom: "6px"
  }
});

function DealTeam(props) {
  const classes = useStyles();
  const [deatTeamData, setdealTeamData] = React.useState([]);
  let { crmId, pageUrl } = useParams();

  useEffect(() => {
    fetchMyAPI();
  }, []);

  async function fetchMyAPI() {
    let convertedFormData = {
      sourceVertexId: crmId,
      edgeLabel: "dealTeamMember",
      fields: ["firstName", "role"],
    };
    let res = await httpPost(`/CRMData/getEdge`, convertedFormData);
    setdealTeamData(res);
  }
  return (
    <div>
      {deatTeamData.length > 0 && deatTeamData[0].firstName !== undefined
        ? deatTeamData.map((data, index) => (
            <div className={classes.mainDiv}>
                <div className={classes.inputGroup}> <p className={classes.inputPara}>Name </p><p className={classes.paddingField1}>{data.firstName}</p></div> 
                <div className={classes.inputGroup}> <p className={classes.inputPara}>Role </p><p className={classes.paddingField2}>{data.role}</p></div>
                <Divider  />
            </div>
          ))
        : <p>
             <div className={classes.mainDiv}>
                <div className={classes.inputGroup}> <p className={classes.inputPara}>Name </p><p className={classes.paddingField1}></p></div> 
                <div className={classes.inputGroup}> <p className={classes.inputPara}>Role </p><p className={classes.paddingField2}></p></div>
                <Divider  />
            </div>
            </p>}
    </div>
  );
}

export default DealTeam;