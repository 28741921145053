import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Grid from "@material-ui/core/Grid";
import A_Select from "../../../../../../Atoms/Inputs/Selects/A_Select";
import * as helpers from "../../../../../../../../Utils/Helpers/chartHelpers";
import * as common from "../../../../../../../../Utils/Helpers/common";
const useStyles = makeStyles((theme) => ({
  main: {
    height: "100vh",
  },
}));
function executeJS(obj) {
  return Function(
    " return (function(configs,chartData,helpers){let newConfig=configs; " +
      obj +
      " console.log('chart data:', chartData); if(newConfig.series==='chartData'){newConfig.series=chartData;}; return newConfig; })"
  )();
}

const M_GraphChart = (props) => {
 
  let currTheme = common.getTheme();
  Highcharts.setOptions(currTheme.chartTheme);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
    },
    DropdownHeight: {
      height: "3rem",
    },
  }));
  const classes = useStyles();
  let {
    chartName,
    configuration,
    description,
    id,
    javaScript,
    query,
    resultParameters,
    type,
  } = props.chart.chartConfiguration;
  let data = props.chart.data;
  console.log("graph chart :", props.chart);

  //options.series[0].data = data.series;

  //let options = jsHandler(configuration, data, javaScript);
  let configurationRaw = JSON.parse(configuration);
  let chartOptions = executeJS(javaScript)(configurationRaw, data, helpers);
  console.log("chartOptions:", chartOptions);
  console.log("props.chartRequest:", props.chartRequest);
  let filters = [];
  if ("filters" in chartOptions) {
    filters = chartOptions.filters;
  }
  const handleFilters = (selectedValue, id) => {

    let chartRequest = props.chartRequest;
    if (
      chartRequest &&
      chartRequest.parameters &&
      id in chartRequest.parameters
    ) {
      let newParameters = chartRequest.parameters;
      newParameters[id] = selectedValue.value;
      let newChartRequest = {
        chartId: chartRequest.chartId,
        parameters: newParameters,
      };
      props.refreshChart(newChartRequest);
    } else {
      console.log(
        "parameter not found in chart request for chart: ",
        chartOptions.chartName
      );
    }
    //props.handleFilters();
  };
  const getDefaultValue = (filter) => {
    let defaultValue = "";
    if (filter.value) {
      defaultValue = filter.value;
    } else if (props.chartRequest && props.chartRequest.parameters) {
      defaultValue = props.chartRequest.parameters[filter.parameter];
    }
    return defaultValue;
  };
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          {filters.map((filter) => (
            <Grid item>
              <A_Select
                className={classes.DropdownHeight}
                id={filter.parameter}
                label={filter.label}
                options={filter.options}
                defaultValue={getDefaultValue(filter)}
                onChange={handleFilters}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Grid>
    </Grid>
  );
};

M_GraphChart.defaultProps = {
  options: {
    title: {
      text: "Default Chart",
    },
    series: [
      {
        data: [1, 2, 3, 4, 5],
      },
    ],
  },
};
export default M_GraphChart;
