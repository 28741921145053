export const AddFilterToChartCard = (currentTemplate, currentPage) => {
  let updatedTemplate = currentTemplate;
  if (currentPage && currentPage.pageConfig && currentPage.pageConfig.toolbar) {
    let toolbar = JSON.parse(currentPage.pageConfig.toolbar);
    let filters =
      toolbar && toolbar.primary && toolbar.primary.filters
        ? toolbar.primary.filters
        : [];
    //get filters matching current template id
    let filterParams = filters.map((filter) => {
      if (
        filter &&
        filter.templateIds &&
        filter.templateIds.indexOf(currentTemplate.id) > -1
      ) {
        return { filterName: filter.filterName, value: filter.defaultValue };
      }
    });

    //Add parameters to chart >> currentTemplate
    if (filterParams.length > 0) {
      let cards = updatedTemplate.templateJson.cards.map((card) => {
        if (card.content.type == "chart") {
          filterParams.map((param) => {
            if (param) {
              card.content.content.chart.parameters = {
                ...card.content.content.chart.parameters,
                [param.filterName]: param.value,
              };
            }
          });
        }
        //Add parameters to tab>>chart
        else if (card.content.type == "crmdata") {
          if (card.content.content.tabs) {
            card.content.content.tabs.map((tab) => {
              if (tab.content.type == "chart") {
                filterParams.map((param) => {
                  if (param) {
                    tab.content.content.chart.parameters = {
                      ...tab.content.content.chart.parameters,
                      [param.filterName]: param.value,
                    };
                  }
                });
              }
            });
          }
        }
        return card;
      });
      //Update current Template with updated cards
      updatedTemplate.templateJson.cards = cards;
    }
  }

  return updatedTemplate; // returns template Having filters in chart card added
};

export const clearChartKeys = () => {
  //clearChartStorageKeys
  localStorage.removeItem("chartStorageKeys");
};
