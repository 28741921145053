import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import M_RadiosGroup from "../../../Molecules/Common/RadioGroup/M_RadiosGroup";
import A_CheckBox from "../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import A_DatePicker from "../../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import TextField from "@material-ui/core/TextField";
import A_Autocomplete from "../../../Atoms/Inputs/Autocompletes/A_Autocomplete";
import A_SummerNote from "../../../Atoms/Inputs/SummerNote/A_SummerNote";
import { getFormattedDate } from "../../../../../Utils/Helpers/dateUtils";
import A_MultiselectChip from "../../../Atoms/Inputs/MultiselectChip/A_MultiselectChip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import M_DropableFileUpload from "../../../Molecules/Common/File Upload/M_DropableFileUpload";
import M_RadiosGroupConfig from "../../../Molecules/Common/RadioGroup/M_RadiosGroupConfig";
import { parse, evaluate, ComputeEngine } from "@cortex-js/compute-engine";
import DisplaySectionHeader from "../DisplaysectionHeader/DisplaySectionHeader";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "auto",
    height: 500,
    marginTop: "5px",
    marginBottom: "5px",
  },
  inputcontrol: {
    width: 500,
  },
  tableInputcontrol: {
    width: 130,
  },
  inputcontrolTextField1: {
    width: 95,
    margin: -8,
    " underline:before": {
      borderBottom: "none !important",
    },
  },
  inputcontrolTextField: {
    width: 500,
  },
  textColor: {
    color: "darkslategray",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  inputcontrolsmall: {
    width: 450,
  },
  seperatorRowText: {
    fontSize: "15px",
    fontWeight: "bold",
    height: "30px",
    color: "#927979",
    padding: 5,
    borderRadius: 4,
    textAlign: "left",
  },
}));

export const M_FormRenderer = (props) => {
  const classes = useStyles();
  const optionSets = props.optionSets;
  const [formData, setFormData] = useState(props.lastFormData);
  const [date, setDate] = useState();
  var htmlHead = "Html";

  ///For Calculative Fields
  function calculativeFunction(calData) {
    var calArray = [];
    for (const item of calData) {
      if (Object.prototype.toString.call(item) === "[object Array]") {
        calArray.push(calculativeFunction(item));
      } else {
        if (typeof formData[item] == "undefined") {
          calArray.push(item);
        } else {
          calArray.push(parseFloat(formData[item]));
        }
      }
    }

    return calArray;
  }

  const getOptions = (optionSetId) => {
    let options = [];
    let currentOptionSet = optionSets.filter((optionSet) => {
      return optionSet.Id == optionSetId;
    });
    if (currentOptionSet.length > 0) {
      options = currentOptionSet[0].options;
    }
    return options;
  };

  const getMultipleOptions = (optionSetId) => {
    let options = [];
    let currentOptionSet = optionSets.filter((optionSet) => {
      return optionSet.Id == optionSetId;
    });
    let OptionNames = currentOptionSet.map((item) =>
      item.options.map((option) => option.label)
    );
    return OptionNames[0];
  };

  const handleIntegers = (e) => {
    let field = e.target.id;
    let newFormData = formData;
    const reg = /^[0-9\b]+$/;
    let preval = e.target.value;
    const checkValue = reg.test(e.target.value);
    if (e.target.value === "" || checkValue == true) {
      newFormData[field] = e.target.value;
      setFormData(newFormData);
    } else {
      e.target.value = preval.substring(0, preval.length - 1);
    }
  };

  //handle Textboxes
  const handleTextboxes = (e) => {
    let field = e.target.id;
    let newFormData = formData;
    newFormData[field] = e.target.value;

    const Values =
      props &&
      props.elements &&
      props.elements.filter((item) => item.calculative);
    const ce = new ComputeEngine();
    const mainData = Values.map((item) =>
      ce.N(calculativeFunction(item.calculative))
    );
    const test = Values.map((item, index) =>
      typeof mainData[index] == "number"
        ? (newFormData[item.id] = mainData[index].toString())
        : ""
    );
    setFormData(newFormData);
    props.getFormData(newFormData, field);
  };
  const handleTableField = (e, textId) => {
    let field = textId.name;
    let newFormData = formData;
    newFormData[field] = e.target.value;
    setFormData(newFormData);
    const Values = props.elements.filter((item) => item.fieldType == "table");

    const TableRows = Values.map((item) => item.rows);
    const rowData = TableRows.map((item) =>
      item.map((item2) => item2.fields.filter((item3) => item3.calculative))
    );
    const newData = rowData.filter((item) => item.length != 0);
  };

  //handle dropdowns
  const handleDropdowns = (selectedValue, id) => {
    let newFormData = formData;
    newFormData[id] = selectedValue.value;
    setFormData(newFormData);
    props.getFormData(newFormData, id);
  };
 const handleDropable = (selectedValue) => {
   let newFormData = formData;
   newFormData.uploadSuccess = selectedValue;
   setFormData(newFormData);
   props.getFormData(newFormData);
 };



  //handle checkboxes
  const handleCheckbox = (isChecked, checkboxId) => {
    let newFormData = formData;
    if (checkboxId == "privateRecord") {
      let newSecurity = { ...newFormData.security };
      newSecurity.privateRecord = isChecked;
      newFormData.security = newSecurity;
    } else {
      newFormData[checkboxId] = isChecked;
    }
    setFormData(newFormData);
    props.getFormData(newFormData, checkboxId);
  };

  //handle Radio-group
  const handleRadioGroup = (selectedValue, radioGroupId) => {
    let newFormData = formData;
    newFormData[radioGroupId] = selectedValue.value;
    setFormData(newFormData);
    props.getFormData(newFormData);
  };

  //handle date-picker
  const handleDatePicker = (selectedValue, datepickerId) => {
    let newFormData = formData;
    newFormData[datepickerId] = selectedValue;
    const newDate = getFormattedDate(selectedValue, "YYYY-MM-DD");
    setDate(newDate);
    setFormData(newFormData);
    props.getFormData(newFormData, datepickerId);
  };

  //handle lookup / auto-complete
  const handleLookup = (selectedValue, id) => {
    let newFormData = formData;
    newFormData[id] = { ...selectedValue, fieldType: "lookup" };
    setFormData(newFormData);
    props.getFormData(newFormData, id);
  };
  //handle Textboxes
  const handleSummerNote = (e) => {
    e = e.replaceAll("'", "\\'");
    let newFormData = formData;
    newFormData[htmlHead] = e;
    setFormData(newFormData);
  };
  const getHtmlHead = (val) => {
    if (val.fieldType.toLowerCase() === "html") {
      htmlHead = val.id;
      return val.id;
    }
  };
  props.elements.filter(getHtmlHead);
  const formElements = props.elements.map((element, index) => {
    let elementProps = element;
    let content = {
      multiple: true,
      directory: "test",
      type: element.id,
      formData,
    };
    if (props.validationSummary !== undefined) {
      let fieldValidation = props.validationSummary[elementProps.id];
      if (fieldValidation) {
        elementProps.error = fieldValidation.error;
        elementProps.helperText = fieldValidation.error && fieldValidation.msg;
      }
    }
    //setting default values
    if (formData) {
      if (elementProps.fieldType === "bit") {
        elementProps.checked = formData[elementProps.id] ? true : false;
      } else {
        elementProps.defaultValue =
          formData[elementProps.id] === undefined
            ? ""
            : 
            Array.isArray(formData[elementProps.id])
            ? (formData[elementProps.id] = JSON.stringify(
                formData[elementProps.id]
              ))
            : formData[elementProps.id];
      }
      
    }
    return (
      <Grid item xs={12} key={element.id}>
        {(elementProps.fieldType === "string" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "string" &&
          elementProps.visibility === true) ? (
          <A_TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            onChange={handleTextboxes}
            {...elementProps}
          />
        ) : (
          ""
        )}

        {(elementProps.fieldType === "text" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "text" &&
          elementProps.visibility === true) ? (
          <>
            <TextField
              className={classes.inputcontrolTextField}
              variant="standard"
              label={
                elementProps.label == undefined || elementProps.label === null
                  ? elementProps.name
                  : elementProps.label
              }
              {...elementProps}
              disabled={true}
            />
          </>
        ) : (
          ""
        )}
        {(elementProps.fieldType === "percentage" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "percentage" &&
          elementProps.visibility === true) ? (
          <TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            type="number"
            onKeyDown={(event) => {
              if (event.key == "e") {
                event.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={handleTextboxes}
            {...elementProps}
          />
        ) : (
          ""
        )}

        {(elementProps.fieldType === "multiple" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "multiple" &&
          elementProps.visibility === true) ? (
          <TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            type="number"
            onKeyDown={(event) => {
              if (event.key == "e") {
                event.preventDefault();
              }
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">x</InputAdornment>,
            }}
            onChange={handleTextboxes}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === "integer" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "integer" &&
          elementProps.visibility === true) ? (
          <TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            onChange={handleIntegers}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === "float" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "float" &&
          elementProps.visibility === true) ? (
          <TextField
            className={classes.inputcontrolTextField}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            type="number"
            onChange={handleTextboxes}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === "date" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "date" &&
          elementProps.visibility === true) ? (
          <A_DatePicker
            className={classes.inputcontrol}
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            defaultValue={date}
            onChange={handleDatePicker}
            isDateValidOrNot = {props.isDateValidOrNot ? props.isDateValidOrNot : ""}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === "optionset" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "optionset" &&
          elementProps.visibility === true) ? (
          <A_Select
            className={classes.inputcontrol}
            variant="standard"
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            helperText={
              elementProps.helperText == undefined ||
              elementProps.helperText === null
                ? elementProps.description
                : elementProps.helperText
            }
            native={true}
            options={getOptions(elementProps.optionSet)}
            onChange={handleDropdowns}
            {...elementProps}
          />
        ) : (
          ""
        )}

        {/*  WIP Multiple optionset functionality  
        {elementProps.fieldType === "multiple Optionset" && (
          <A_MultiselectChip
            required={true}
            label={"test"}
            names={getMultipleOptions(elementProps.optionSet)}
            handleTabsSeletion={handleTabsSeletion}
            width={202}
            {...elementProps}
          />
        )} */}
        {(elementProps.fieldType === "lookup" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "lookup" &&
          elementProps.visibility === true) ? (
          <A_Autocomplete
            entity={props.entity}
            variant="standard"
            className={classes.inputcontrol}
            label={
              elementProps.label == undefined || elementProps.label === null
                ? elementProps.name
                : elementProps.label
            }
            onChange={handleLookup}
            {...elementProps}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === "radios" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "radios" &&
          elementProps.visibility === true) ? (
          <M_RadiosGroupConfig
            {...elementProps}
            options={getOptions(elementProps.optionSet)}
            onChange={handleRadioGroup}
          />
        ) : (
          ""
        )}
        {(elementProps.fieldType === "section" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "section" &&
          elementProps.visibility === true) ? (
          <DisplaySectionHeader {...elementProps} label={elementProps.label} />
        ) : (
          ""
        )}
        {(elementProps.fieldType === "bit" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "bit" &&
          elementProps.visibility === true) ? (
          <Grid container>
            <Grid item xs={12}>
              <A_CheckBox
                style={{}}
                {...elementProps}
                required={elementProps.required}
                checked={elementProps.checked}
                onChange={handleCheckbox}
                helperText={
                  elementProps.helperText == undefined ||
                  elementProps.helperText === null
                    ? elementProps.description
                    : elementProps.helperText
                }
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {(elementProps.fieldType.toLowerCase() === "html" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType.toLowerCase() === "html" &&
          elementProps.visibility === true) ? (
          <A_SummerNote
            onChange={handleSummerNote}
            disabled={false}
            {...elementProps}
          ></A_SummerNote>
        ) : (
          ""
        )}
        {(elementProps.fieldType === "table" &&
          elementProps.visibility === undefined) ||
        (elementProps.fieldType === "table" &&
          elementProps.visibility === true) ? (
          <TableContainer>
            <Typography
              className={classes.textColor}
              variant="h5"
              color="textSecondary"
            >
              {elementProps.label}
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {elementProps.head.map((columnHead) => (
                    <TableCell>{columnHead}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {elementProps.rows.map((item) => (
                  <TableRow>
                    {item.fields.map((item2) => (
                      <>
                        <TableCell component="th" scope="row">
                          {item2.fieldType == "string" ? (
                            <>
                              <TextField
                                className={classes.inputcontrolTextField1}
                                variant="standard"
                                label={item2.name}
                                InputProps={{
                                  autoFocus: true,
                                }}
                                onChange={(e) => handleTableField(e, item2)}
                              />
                            </>
                          ) : (
                            ""
                          )}
                          {item2.fieldType == "text" ? (
                            <>
                              {item2.calculative != undefined ? (
                                <input
                                  placeholder={
                                    elementProps.label == undefined ||
                                    elementProps.label === null
                                      ? elementProps.name
                                      : elementProps.label
                                  }
                                  className={classes.customInput}
                                  value={formData[elementProps.name]}
                                  disabled
                                />
                              ) : (
                                <Typography>{item2.name}</Typography>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {item2.fieldType == "optionset" ? (
                            <>
                              <A_Select
                                className={classes.tableInputcontrol}
                                variant="standard"
                                label={item2.name}
                                native={true}
                                options={getOptions(item2.optionSet)}
                                onChange={(e) => handleDropdowns(e, item2.name)}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          ""
        )}
        {elementProps.fieldType.toLowerCase() === "document" && (
          <>
            <span>
              {elementProps.label} {elementProps.required ? "*" : ""}
            </span>
            <M_DropableFileUpload content={content} onUpload={handleDropable} />
            {elementProps && elementProps.helperText ? (
              <Typography color="secondary">
                {elementProps.helperText}
              </Typography>
            ) : (
              ""
            )}
          </>
        )}
      </Grid>
    );
  });

  return <section className={classes.container}>{formElements}</section>;
};
export default M_FormRenderer;
