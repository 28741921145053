import React from "react";
import { Box } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Close, Check } from "@material-ui/icons";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import axios, { post } from "axios";
import * as httpCall from "../../../../../Utils/apis/apis";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import { useParams } from "react-router";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useHistory } from "react-router";
export default function M_CheckList(props) {

  const history = useHistory();
  const inputFile = React.useRef([]);
  const { crmId, pageUrl } = useParams();
  const [uploading, setUploading] = React.useState(false);
  const [success, setSucces] = React.useState(false);
  const handleClick = async (event, description, name, id, dealid, index) => {
    switch (name.toLowerCase()) {
      case "download":
        if (description.toLowerCase() == "irc slides") {
          handlePptDownload(id, dealid);
        } else {
          handleDownload(id, dealid, description.split(" ").join(""));
        }
        return;
      case "review":
        handleUpdate(id, dealid).then(() => {
          history.push(
            `/${pageUrl}/${crmId}/ce9ca438-955c-416c-93dd-6d9c60aeb4f9`
          );
          window.location.reload();
        });

        return;
      case "upload":
        handleUploadClick(event, index);
        return;
    }
  };

  const handleUpload = async (event, id, dealid, tag) => {
    setUploading(true);
    let drawDown =
      props.config && props.config.drawDown && props.config.drawDown.fileName
        ? props.config.drawDown.fileName
        : "";
    let lineLevel =
      props.config && props.config.lineLevel && props.config.lineLevel.fileName
        ? props.config.lineLevel.fileName
        : "";
    let resultFiles = [];
    let files =
      event && event.target && event.target.files ? event.target.files : [];
    let fileName = "";
    for (let file of files) {
      let newFile = file.name ? file.name.split(".") : [];
      if (newFile.length == 0) {
        continue;
      }
      let fileTag = tag;
      let type = props.type;
      if (tag === "LineLevelOnBoardingPack") {
        let newFileName = lineLevel.split(".").join(`_${dealid}.`);
        fileTag = newFileName;
        type =
          props.config &&
          props.config.lineLevel &&
          props.config.lineLevel.fileName
            ? props.config.lineLevel.fileName
            : "";
      } else if (tag === "DrawDownNotice") {
        type =
          props.config &&
          props.config.drawDown &&
          props.config.drawDown.filePath
            ? props.config.drawDown.filePath
            : "";
        let newFileName = drawDown.split(".").join(`_${dealid}.`);
        fileTag = newFileName;
      }
      fileName = pageUrl + "/" + dealid + "/" + type + "/" + fileTag;
      resultFiles.push(file);
    }

    const url = `${process.env.DOCUMENT_HELPER}`;
    let reader = new FileReader();
    reader.readAsDataURL(resultFiles[0]);
    reader.onload = (e) => {
      const formData = {
        file: e.target.result,
        record_id: id ? id : "",
        deal_id: dealid,
      };
      const httpConfig = commonFunctions.getHttpConfig();
      //important
      //   let subdomain = "dev";
      // new URL(window.location.href).hostname.split(".")[0]
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      let header = {
        filename: fileName,
        type: "irc-checklist",
        "file-operation": "upload",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Authorization: httpConfig.headers.Authorization,
      };
      axios
        .post(url, formData, { headers: header })
        .then((response) => {
          setSucces(true);
        })
        .then(() => {
          handleUpdate(id, dealid).then(() => {
            window.location.reload();
          });
        });
    };
    setUploading(false);
  };
  const getDependancy = (id) => {
    let obj = props.data.find((item) => item.checlistItemId === id);
    if(!obj){
      return false
    }else{
      return obj.dealChecklist.checklistCompleted ? false : true;
    }
    
  };
  const handleDownload = async (id, dealid, tag) => {
    let documentName = pageUrl + "/" + dealid + "/" + props.type;
    let output = `${tag}.xlsx`;
    let drawDown =
      props.config && props.config.drawDown && props.config.drawDown.fileName
        ? props.config.drawDown.fileName
        : "";
    let lineLevel =
      props.config && props.config.lineLevel && props.config.lineLevel.fileName
        ? props.config.lineLevel.fileName
        : "";
    if (tag === "LineLevelOnBoardingPack") {
      let filepath =
        props.config &&
        props.config.lineLevel &&
        props.config.lineLevel.filePath
          ? props.config.lineLevel.filePath
          : "";
      documentName = pageUrl + "/" + dealid + "/" + filepath;
      output = lineLevel;
    } else if (tag === "DrawDownNotice") {
      let filepath =
        props.config && props.config.drawDown && props.config.drawDown.filePath
          ? props.config.drawDown.filePath
          : "";
      documentName = pageUrl + "/" + dealid + "/" + filepath;
      output = drawDown;
    }

    const url = `${process.env.DOCUMENT_HELPER}`;
    const httpConfig = commonFunctions.getHttpConfig();
    //important
    // let subdomain = "dev";
    // new URL(window.location.href).hostname.split(".")[0]
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    let header = {
      filename: documentName,
      type: "irc-checklist",
      "file-operation": "download",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
      Authorization: httpConfig.headers.Authorization,
    };
    const formData = {
      record_id: id ? id : "",
      deal_id: dealid,
    };
    axios
      .post(url, formData, { responseType: "blob", headers: header })
      .then((response) => {
        const url1 = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url1;
        link.setAttribute("download", output);
        document.body.appendChild(link);
        link.click();
        handleUpdate(id, dealid).then((response) => {
          window.location.reload();
        });
      });
  };
  const handleUploadClick = async (event, index) => {
    await inputFile.current[index].click();
  };
  const handlePptDownload = async (id, dealid) => {
    const pptName =
      props.config &&
      props.config.ppt &&
      props.config.ppt &&
      props.config.ppt.pptName
        ? props.config.ppt.pptName
        : "";
    const pptId =
      props.config &&
      props.config.ppt &&
      props.config.ppt &&
      props.config.ppt.pptId
        ? props.config.ppt.pptId
        : "";
    let convertedFormData = {};

    convertedFormData = {
      parameters: { section: "" },
    };

    let config = commonFunctions.getHttpConfig();
    let url = `${process.env.PPT_DOWNLOAD_URL}/PPT/download/${pptId}`;

    const response = await httpCall.httpPost(url, convertedFormData, {
      ...config,
      responseType: "blob",
    });

    const url1 = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url1;
    link.setAttribute("download", pptName + ".pptx");
    link.click();
    handleUpdate(id, dealid).then(() => {
      window.location.reload();
    });
  };
  const handleUpdate = async (rid, deal_id) => {
    let payload = {
      checklistCompleted: true,
      action: "update",
      id: rid,
      dealId: deal_id,
    };
    await httpCall.httpPut("/DealChecklist/Update", payload);
    return;
  };
  var inputProps = {
    accept: "pdf,pptx,docx,xlsx",
  };
  const handleClose = () => {
    setSucces(false);
  };
  return (
    <>
      {props.data ? (
        props.data.map((item, index) => {
          return (
            <Box paddingTop={"2rem"}>
              <Grid container padding="2rem">
                <Grid item xs={2}>
                  {item.dealChecklist &&
                  item.dealChecklist.checklistCompleted ? (
                    <Check style={{ color: "green" }} />
                  ) : (
                    <Close style={{ color: "red" }} />
                  )}
                </Grid>
                <Grid item xs={5}>
                  {item.itemName}
                </Grid>
                <Grid item xs={5}>
                  <A_Button
                    onClick={(event) => {
                      handleClick(
                        event,
                        item.itemName ? item.itemName : "",
                        item.description ? item.description : "",
                        item.dealChecklist && item.dealChecklist.id
                          ? item.dealChecklist.id
                          : "",
                        item.dealChecklist && item.dealChecklist.dealId
                          ? item.dealChecklist.dealId
                          : "",
                        index
                      );
                    }}
                    label={
                      item.description &&
                      item.description.toLowerCase() == "upload"
                        ? !uploading
                          ? " Upload "
                          : "Please Wait..."
                        : item.description
                    }
                    disabled={
                      item.dependency != ""
                        ? getDependancy(item.dependency)
                        : false
                    }
                    color={"primary"}
                  >
                    {" "}
                  </A_Button>

                  <input
                    {...inputProps}
                    className={"fileinput"}
                    type="file"
                    ref={(el) => (inputFile.current[index] = el)}
                    onChange={(e) => {
                      handleUpload(
                        e,
                        item.dealChecklist && item.dealChecklist.id
                          ? item.dealChecklist.id
                          : "",
                        item.dealChecklist && item.dealChecklist.dealId
                          ? item.dealChecklist.dealId
                          : "",
                        item.itemName ? item.itemName.split(" ").join("") : ""
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <A_Snackbar
                open={success}
                message={"Upload Success"}
                autoHideDuration={2000}
                horizontal="center"
                handleClose={handleClose}
              />
            </Box>
          );
        })
      ) : (
        <>No Chekclist Found</>
      )}{" "}
      <A_Snackbar
        open={success}
        message={"Upload Success"}
        autoHideDuration={2000}
        horizontal="center"
        handleClose={handleClose}
      />
    </>
  );
}
