
import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import * as httpCall from "../../../../../Utils/apis/apis";
import M_CheckList from "../../../Molecules/Common/Checklist/M_CheckList";
import { useParams } from "react-router";
import O_Mail from "../IRC_Mail/O_Mail";
export default function O_CheckList(props) {
  const [data, setData] = React.useState([]);
  const [isComplete, setIsComplete] = React.useState(false);
  const { crmId } = useParams();
  const getData = async () => {
    const response = await httpCall.httpGet(
      `/DealChecklist/GetDealChecklist/${crmId}?WorkFlow=OnBoarding`
    );
    let responseData = await response;

    let filterData = responseData&& Array.isArray(responseData)
      ? responseData.filter((item) => {
          return (
            item.dealChecklist && item.dealChecklist.checklistCompleted == false
          );
        })
      : [];
    setIsComplete(filterData.length == 0 && responseData.length > 0);
    setData(responseData);
  };

  React.useEffect(() => {
    getData();
  }, []);

  return data.length > 0 ? (
    <div>
      {data ? (
        <M_CheckList config={props.config} data={data} type="preIRC" />
      ) : (
        ""
      )}

      <Box
        bgcolor={"#fcf8e3"}
        color={"#fec85f"}
        width={"100%"}
        padding={"1rem"}
        marginY={"1rem"}
      >
        Please note: all items in the checklist must be completed before the IC
        document can be sent
      </Box>

      <Box paddingTop={"2rem"} display={"flex"} justifyContent={"end"}>
        <A_Button label="Reschedule" color={"primary"} />
        {isComplete ? (
          <O_Mail label="Send IC Document" ircID={props.ircID} formData={{}}/>
        ) : (
          <A_Button
            label="Send IC Document"
            disabled={true}
            color={"primary"}
          />
        )}
      </Box>
    </div>
  ) : (
    <></>
  );
}
