import * as React from "react";
import A_TextField from "../TextFields/A_TextField";
import theme from "../../../../Themes/defaultTheme";
import { mergeClasses, ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formFieldsWrapper: {
    display: "flex",
    flexDirection: "row",
    padding: "5px",
  },
  formLabelField: {
    minWidth: "300px",
    paddingTop: "10px",
  },
  formValueField: {
    minWidth: "100px",
  },
}));

const A_FormTextField = (props) => {

  // let value =
  //   props.dataType && props.dataType === "percentage"
  //     ? props.value * 100
  //     : props.value;

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.formFieldsWrapper}>
        <div className={classes.formLabelField}>{props.label}</div>

        <div>
          <A_TextField
            className={classes.formValueField}
            label=""
            variant="standard"
            defaultValue={props.value}
            inputType="value"
            onChange={props.onChange}
            type="number"
          ></A_TextField>
          {props.dataType && props.dataType === "percentage" ? (
            <div style={{ display: "inline-block", marginTop: "10px" }}>
              <p>%</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default A_FormTextField;
