import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_Drawer from "../../../Atoms/Navigation/Drawers/A_Drawer";
import A_List from "../../../Atoms/DataDisplay/Lists/A_List";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as manageMenusAction from "../../../../../store/actions/manageMenusAction";

const useStyles = makeStyles((theme) => ({}));

const M_MainDrawer = (props) => {
  const { openDrawer } = props;
  const classes = useStyles();
  const [menusLoading, setMenusLoading] = useState(false);
  const [menus, setMenus] = useState(
    props.manageMenus &&
      props.manageMenus.data &&
      props.manageMenus.data.length > 0
      ? props.manageMenus.data
      : []
  );

  useEffect(() => {
    setMenusLoading(true);
    props.getMenus(props.user);
  }, []);
  useEffect(() => {
    if (menusLoading && props.manageMenus.getMenusSuccessed) {
      setMenus(props.manageMenus.data);
      setMenusLoading(false);
    }
  }, [props.manageMenus && props.manageMenus.getMenusSuccessed]);

  return (
    <div>
      <A_Drawer
        open={openDrawer}
        onClose={props.handleDrawer}
        //anchor={this.state.anchor}
        content={
          <A_List
            className={classes.font}
            listItems={menus}
            onClose={props.handleDrawer}
          />
        }
      />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    manageMenus: state.manageMenus,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(manageMenusAction, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(M_MainDrawer);
