import {managePageActionTypes} from "../actions/actionTypes";

let initialState = {
    openManagePageForm: false,
    pageSaved: false

};
const managePageReducer = (state = initialState,action) => {  
    switch(action.type){ 
        case managePageActionTypes.GET_PAGE:
            return state; 
        case managePageActionTypes.GET_PAGE_SUCCESSED:
            return action.payload; 
        case managePageActionTypes.GET_PAGE_FAILED:
            return action.payload;
        case managePageActionTypes.RESET_MANAGE_PAGE:            
            return initialState; 
      default:
        return state;
    }
  };
  export default managePageReducer;