import { manageToolbarSelectionsActionType } from "../actions/actionTypes";

let initialState = {
  filters: [],
};

const manageToolbarSelectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case manageToolbarSelectionsActionType.ADD_FILTER:
      const existingFilters = state.filters.filter(
        (item) => item.filterName !== action.payload.filterName
      );
      return { filters: [...existingFilters, action.payload] };
    case manageToolbarSelectionsActionType.RESET_FILTERS:    
      return { filters: [] };
    default:
      return state;
  }
};

export default manageToolbarSelectionsReducer;
