import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import * as httpCall from "../../../../../../../../Utils/apis/apis";

const M_RenderItems = (props) => {
  const { item, classes, id } = props;

  return (
    <ListItem
      alignItems="flex-start"
      // href={"abc/dd"}
      key={id}
      id={id}
      // crmId="11"
      // pageId="22"
      crmId={item.id}
      pageId={item.pageId}
      pageUrl={item.pageUrl}
      etnType={props.etn}
      recordType={props.type}
      button
      onClick={props.handleClick}
    >
      <ListItemAvatar
        key={id + "avatar"}
        id={id + "avatar"}
        // crmId="11"
        // pageId="22"
        crmId={item.id}
        pageId={item.pageId}
        pageUrl={item.pageUrl}
        onClick={props.handleClick}
      >
        <Avatar variant="square" alt="" src={item.image} />
      </ListItemAvatar>
      <ListItemText
        key={id + "textprimary"}
        id={id + "textprimary"}
        key={id + "avatar"}
        id={id + "avatar"}
        // crmId="11"
        // pageId="22"
        crmId={item.id}
        pageId={item.pageId}
        pageUrl={item.pageUrl}
        onClick={props.handleClick}
        primary={
          <>
            <b>{item.title}</b>
            <small style={{ marginLeft: "5px", float: "right" }}>
              {item.date}
            </small>
          </>
        }
        secondary={
          <React.Fragment>
            <Typography
              key={id + "textsecondary"}
              id={id + "textsecondary"}
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {item.subtitle}
            </Typography>

            <br></br>
            {
              <small
                key={id + "textowner"}
                id={id + "textowner"}
                style={{ color: "#377ca7" }}
              >
                {item.owner}
              </small>
            }
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default M_RenderItems;
