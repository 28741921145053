import  React from "react";
import { PDFReader } from "reactjs-pdf-reader";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { Box } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { ArrowForward, ArrowBack } from "@material-ui/icons";

export default function M_PDFReader(props) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const pageChange = (changeBy) => {
    let newPage = currentPage + changeBy;
    if (newPage === 0 || newPage === totalPage) {
      return;
    }
    setCurrentPage(currentPage + changeBy);
  };
  return (
    <div>
      <IconButton  changeBy={-1} onClick={() => pageChange(-1)} >
          <ArrowBack/>
      </IconButton>
      Page: {currentPage} {"  "}
      <IconButton label="Next" changeBy={1} onClick={() => pageChange(1)} >
          <ArrowForward/>
      </IconButton>
      <Box width={"100%"} overflow="auto" maxHeight={"60vh"}>
        <PDFReader
          scale={"1"}
          url="https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf"
          page={currentPage}
          onDocumentComplete={(total) => {
            setTotalPage(total);
          }}
        />
      </Box>
    </div>
  );
}
