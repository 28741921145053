import React, { useState } from "react";
import { MoreVert } from "@material-ui/icons";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";

const useStyles = makeStyles({
  ircHead: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "1.4rem",
    alignItems: "center",
  },
  itemCenter: {
    display: "flex",
    alignItems: "center",
  },
  selected: {
    backgroundColor: "rgba(1,1,1,0.05)",
  },
  buttonMargin: {
    margin: "1.2rem",
  },
  innerBox: {
    padding: "1.4rem",
    marginRight: "2.8rem",
  },
});
export default function M_IRChead(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = React.useState("");
  const [time, settime] = React.useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    getDateTime();
  });

  const getDateTime = () => {
    if (props.modify) {
      let newDateJSON =
        props.data && props.data.properties && props.data.properties.StartDT
          ? JSON.parse(props.data.properties.StartDT)
          : "";
      let newDate = new Date(newDateJSON.dateTime + "Z");
      function addZero(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      }
      let year = newDate.getFullYear();
      let Month = newDate.getMonth()+1;
      let day = newDate.getDate();

      let Datee = year + "-" + Month + "-" + day;

      let h = newDate.getHours();
      let m = addZero(newDate.getMinutes());
      let Timee = h + ":" + m;

      settime(Timee);
      setDate(Datee);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let changeDateFormat = (i) => {
    var options = { year: "numeric", month: "long", day: "numeric" };
    var today = new Date(i);
    return today.toLocaleDateString("en-US", options);
  };

  const renderConfirmPopup = (id) => {
    return (
      <div>
        <div style={{ paddingTop: "20px" }}>
          <A_Button
            onClick={() => {
              props.onDelete ? props.onDelete(id) : "";
              handlePopup();
            }}
            color="primary"
            label="Yes"
            // disabled={downloading}
          />
          <A_Button onClick={handlePopup} color="default" label="Close" />
        </div>
      </div>
    );
  };
  let handlePopup = () => {
    let data = !isOpen;
    setIsOpen(data);
  };

  return (
    <Box
      className={`${classes.ircHead}  ${
        props.selected ? classes.selected : ""
      }`}
    >
      <Box className={classes.itemCenter}>
        <Box className={classes.innerBox}>
          {props.modify == "true" ? (
            props.Icon
          ) : (
            <Typography style={{ color: "#A59275", fontSize: "24" }}>
              IC
            </Typography>
          )}
        </Box>

        <Box>
          <Typography style={{ color: "#337AB7", fontWeight: "bold" }}>
            {props.modify == "true" ? (
              <span>{props.data.properties.Subject}</span>
            ) : (
              <span>
                {" "}
                {changeDateFormat(
                  props.data && props.data.ircDate
                    ? props.data.ircDate.length > 0
                      ? props.data.ircDate.split(" ")[0]
                      : ""
                    : ""
                )}{" "}
              </span>
            )}
          </Typography>
          <Typography style={{ color: "grey" }}>
            {props.modify == "true" ? (
              <h6>
                Date: {date} Time: {time}
              </h6>
            ) : (
              <span>{props.data.ircPurpose}</span>
            )}
          </Typography>
        </Box>
      </Box>
      {props.modify == "true" ? (
        ""
      ) : (
        <span>
          <IconButton
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <MoreVert></MoreVert>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Button
              className={classes.buttonMargin}
              onClick={() => {
                handlePopup();
                handleClose();
              }}
            >
              Delete
            </Button>
          </Popover>
          <A_SimpleDialog
            open={isOpen}
            title="Are you sure you want to delete?"
            fullScreen={false}
            height="400px"
            fullwidth={true}
            maxWidth={"xs"}
            onClose={handlePopup}
            closePopUp={handlePopup}
            dialogContent={renderConfirmPopup(props.data.id)}
          />
        </span>
      )}
    </Box>
  );
}
