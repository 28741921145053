import {manageExcelTemplateDownloadActionType} from "../actions/actionTypes";

    let initialState = {
    data:{},
    downloadExcelSuccessed:false,
    downloadFailed:false
    };


const manageExcelTemplateDownloadReducer = (state = initialState,action) => {  
    switch(action.type){ 
            
        case manageExcelTemplateDownloadActionType.DOWNLOAD_EXCEL:
            return state; 
        case manageExcelTemplateDownloadActionType.DOWNLOAD_EXCEL_SUCCESSED:
            return action.payload; 
        case manageExcelTemplateDownloadActionType.DOWNLOAD_EXCEL_FAILED:
            return action.payload;     
        default:
        return state;
    }
  };

  export default manageExcelTemplateDownloadReducer;