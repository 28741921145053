import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import { ThemeProvider } from "@material-ui/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import theme from "../../../../Themes/defaultTheme";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 95,
    marginTop: 5,
  },
  selectLabel: {
    //marginTop: 7,
    color: "black",
    marginTop: "-1.8px",
    // paddingLeft:"8px"
    // commenting this code.keeping this for future   
    // refrence as it is a atoms which will be used in multiple places  
  },
}));
const A_Select = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.defaultValue);

  
  const handleChange = (event) => {
    if (event.target.value !== "") {
      let selectedOption = props.options.filter(function (option) {
        return option.value == event.target.value;
      })[0];
      setValue(selectedOption.value);
      props.onChange(selectedOption, props.id);
    } else {
      setValue(event.target.value);
      props.onChange({value : event.target.value}, props.id);
    }
  };

  let options = props.options.map((option) => (
    // if(props.displayEmpty){

    // }
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));
  let optionsNative = () => {
    let options = props.options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
    if (props.displayEmpty) {
      options.unshift(<option key={null} value=""></option>);
    }
    return options;
  };
  return (
    <ThemeProvider theme={theme}>
      <FormControl
        error={props.error}
        key={'formcontrol'+props.id}
        id={'formcontrol'+props.id}
        variant={props.variant}
        className={classes.formControl}
        required={props.required}
      >
        <InputLabel className={classes.selectLabel} key={props.id + "_label" } id={props.id + "_label"}>
          {props.label}
        </InputLabel>
        <Select
          key={props.id}
          id={props.id}
          labelId={props.id + "_label"}         
          value={value}
          native={props.native}
          //variant={props.variant}
          disabled={props.disabled}
          required={props.required}
          autoWidth={props.autoWidth}
          displayEmpty={props.displayEmpty}
          inputProps={{ readOnly: props.readOnly }}
          color={props.color}
          className={props.className}
          onChange={handleChange}          
        >
          {props.native ? optionsNative() : options}
        </Select>
        <FormHelperText 
          key={'helptext'+props.id}
          id={'helptext'+props.id}
          >{props.helperText}
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
};
A_Select.defaultProps = {
  id: "xxxx",
  //label: "Select",
  value: "",
  variant: "outlined",
  color: "primary",
  disabled: false,
  autoWidth: false,
  displayEmpty: true,
  error: false,
  required: false,
  options: [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ],
  native: true,
};
A_Select.propTypes = {
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  autoWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  onClick: PropTypes.func,
};
export default A_Select;
