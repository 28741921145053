import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import * as httpCall from "../../../../../../../Utils/apis/apis";
import { getEvaluatedValue } from "../../../../../../../Utils/Helpers/common";
import { useParams } from "react-router";
import EditIcon from "@material-ui/icons/Edit";
import A_IconButton from "../../../../../atoms/inputs/buttons/A_IconButton";
import M_ManageEntityForm from "../../../Navigations/AddEntity/M_ManageEntityForm";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
}));
const sampledata = [
  {
    image:
      "https://i.picsum.photos/id/4/200/300.jpg?hmac=y6_DgDO4ccUuOHUJcEWirdjxlpPwMcEZo7fz1MpuaWg",
    link: "/pages/contacts/aebd6f0a-1d6a-e611-80bc-005056be5ce6",
    title: "Fernando Plaza",
    subtitle: "GTT",
    information: "Chairman",
    tags: [
      {
        Investor: true,
        Candidate: false,
        Consultant: true,
      },
    ],
  },
  {
    image:
      "https://i.picsum.photos/id/344/200/200.jpg?hmac=VNTdN95obd1fhoO6M8Pw3vxCOWmCrUPGGqavLT9ewl8",
    link: "/pages/contacts/aebd6f0a-1d6a-e611-80bc-005056be5ce6",
    title: "Carlos Rico",
    subtitle: "GTT",
    information: "CEO",
    tags: [
      {
        Investor: false,
        Candidate: true,
        Consultant: false,
      },
    ],
  },
  {
    image:
      "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    link: "/pages/contacts/aebd6f0a-1d6a-e611-80bc-005056be5ce6",
    title: "Fernando Cañizares",
    subtitle: "GTT",
    information: "CFO",
    tags: [
      {
        Investor: false,
        Candidate: true,
        Consultant: false,
      },
    ],
  },
  {
    image:
      "https://i.picsum.photos/id/344/200/200.jpg?hmac=VNTdN95obd1fhoO6M8Pw3vxCOWmCrUPGGqavLT9ewl8",
    link: "/pages/contacts/aebd6f0a-1d6a-e611-80bc-005056be5ce6",
    title: "Carlos Rico1",
    subtitle: "GTT",
    information: "CEO",
    tags: [
      {
        Investor: false,
        Candidate: true,
        Consultant: false,
      },
    ],
  },
  {
    image:
      "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    link: "/pages/contacts/aebd6f0a-1d6a-e611-80bc-005056be5ce6",
    title: "Fernando Cañizares1",
    subtitle: "GTT",
    information: "CFO",
    tags: [
      {
        Investor: false,
        Candidate: true,
        Consultant: false,
      },
    ],
  },
  {
    image:
      "https://i.picsum.photos/id/4/200/300.jpg?hmac=y6_DgDO4ccUuOHUJcEWirdjxlpPwMcEZo7fz1MpuaWg",
    link: "/pages/contacts/aebd6f0a-1d6a-e611-80bc-005056be5ce6",
    title: "Fernando Plaza1",
    subtitle: "GTT",
    information: "Chairman",
    tags: [
      {
        Investor: true,
        Candidate: false,
        Consultant: true,
      },
    ],
  },
  {
    image:
      "https://i.picsum.photos/id/344/200/200.jpg?hmac=VNTdN95obd1fhoO6M8Pw3vxCOWmCrUPGGqavLT9ewl8",
    link: "/pages/contacts/aebd6f0a-1d6a-e611-80bc-005056be5ce6",
    title: "Carlos Rico2",
    subtitle: "GTT",
    information: "CEO",
    tags: [
      {
        Investor: false,
        Candidate: true,
        Consultant: false,
      },
    ],
  },
  {
    image:
      "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    link: "/pages/contacts/aebd6f0a-1d6a-e611-80bc-005056be5ce6",
    title: "Fernando Cañizares3",
    subtitle: "GTT",
    information: "CFO",
    tags: [
      {
        Investor: false,
        Candidate: true,
        Consultant: false,
      },
    ],
  },
  {
    image:
      "https://i.picsum.photos/id/344/200/200.jpg?hmac=VNTdN95obd1fhoO6M8Pw3vxCOWmCrUPGGqavLT9ewl8",
    link: "/pages/contacts/aebd6f0a-1d6a-e611-80bc-005056be5ce6",
    title: "Carlos Rico3",
    subtitle: "GTT",
    information: "CEO",
    tags: [
      {
        Investor: false,
        Candidate: true,
        Consultant: false,
      },
    ],
  },
  {
    image:
      "https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI",
    link: "/pages/contacts/aebd6f0a-1d6a-e611-80bc-005056be5ce6",
    title: "Fernando Cañizares4",
    subtitle: "GTT",
    information: "CFO",
    tags: [
      {
        Investor: false,
        Candidate: true,
        Consultant: false,
      },
    ],
  },
];
const M_ResultCard = (props) => {
  const [state, setState] = useState(props.stateObj);
  const [contactInfo, setContactInfo] = useState();
  const [resultData, setResultData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { crmId } = useParams();
  useEffect(() => {
    setResultData([]);
  }, [undefined]);

  useEffect(() => {
    //setResultData(sampledata);
    getResultTemplateData();
  }, [resultData]);

  const classes = useStyles();
  const [checked, setChecked] = React.useState([1]);

  const handleEdit = (card, item, recordId) => {
    const templateID = card.edit.parameters.templateId;
    const NewData = { templateId: templateID, recordId: recordId };
    const openEditForm = state.openEditForm;

    setState((prevState) => ({
      ...prevState,
      openEditForm: !openEditForm,
      cardProps: openEditForm === true ? null : item,
      paramEntity: NewData,
    }));
  };

  const handleCloseForm = () => {
    setState((prevState) => ({
      ...prevState,
      openEditForm: false,
      cardProps: null,
      paramEntity: null,
    }));
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const getResultTemplateData = async () => {
    if (!resultData) {
      if (props.content) {
        props.content["id"] =
          props.content["id"] && props.content["id"][0] === "=" && crmId
            ? crmId
            : props.content["id"];
        let requestPayload = props.content;
        let url = "/CRMData/getCRMResultData";
        const result = await httpCall.httpPost(url, requestPayload);

        if ("message" in result) {
          setIsError(result);
        } else {
          let template = result.template;
          let data = result.data;
          setContactInfo(result);
          let templateJson = JSON.parse(template.templateJson);
          let finalData = data.map((row) => {
            let newRow = {};
            newRow["id"] = row.id;
            for (let field in templateJson) {
              let fieldValue = templateJson[field];
              newRow[field] = fieldValue;
              newRow["pageUrl"] = row.label;
              if (fieldValue && fieldValue[0] === "=") {
                newRow[field] = getEvaluatedValue(fieldValue, row.properties);
              }
            }
            if (templateJson["tags"] && templateJson["tags"].length > 0) {
              for (const [i, item] of templateJson["tags"].entries()) {
                for (let field in item) {
                  let fieldValue = item[field];
                  newRow[field] = fieldValue;
                  newRow["pageUrl"] = row.label;
                  if (fieldValue && fieldValue[0] === "=") {
                    newRow["tags"][i][field] = getEvaluatedValue(
                      fieldValue,
                      row.properties
                    );
                  }
                }
              }
            }
            return newRow;
          });
          setResultData(finalData);
        }
        setIsLoading(false);
      }
    }
  };
  const getTags = (tags) => {
    if (!tags || tags.length < 1) {
      return;
    }
    let tagsList = tags[0];

    return Object.keys(tagsList).map((tag) => {
      return tagsList[tag] ? (
        <Chip key={tagsList[tag]} size="small" label={tagsList[tag]} />
      ) : (
        ""
      );
    });
  };
  //getResultTemplateData();
  const renderResult =
    resultData &&
    resultData.map((record, index) => {
      const labelId = `checkbox-list-secondary-label-${record.title}`;
      return (
        <span key={record.title}>
          <ListItem button>
            <ListItemAvatar>
              <Avatar
                alt={`Avatar n°${record.title}`}
                src={record.image}
                //src={`src/assets/images/defaults/default_avatar.JPG`}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <a
                  href={
                    record.pageUrl && record.id
                      ? record.pageUrl + "/" + record.id
                      : record.id
                  }
                >
                  {record.title}
                </a>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {record.subtitle}
                  </Typography>
                  <br />
                  {record.information}
                </React.Fragment>
              }
            />
            {/* May be required in future
             <ListItemSecondaryAction>
                            <Checkbox
                                edge="end"
                                onChange={handleToggle(value)}
                                checked={checked.indexOf(value) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemSecondaryAction> */}
            <A_IconButton
              icon={<EditIcon />}
              onClick={() =>
                handleEdit(props.content, contactInfo.data, record.id)
              }
            />
          </ListItem>
          <ListItem>{getTags(record.tags)}</ListItem>
          <Divider />
        </span>
      );
    });

  return (
    <div style={{ height: "400px", overflowY: "auto" }}>
      {isLoading && <p>Loading data</p>}
      {isError && (
        <div>
          <h4>Some error occured.</h4>
          <p> Error : {isError.message}</p>
          <h5>Please contact support team.</h5>
        </div>
      )}
      {!isError ? (
        resultData && resultData.length > 0 ? (
          <List dense className={classes.root}>
            {renderResult}
          </List>
        ) : (
          <div className={classes.noData}>
            <p>WE ARE SORRY, NO CONTENT FOUND!</p>
          </div>
        )
      ) : (
        ""
      )}

      {state.openEditForm && (
        <M_ManageEntityForm
          crmData={props.currentPage.data}
          isEditable={true}
          entity={state.paramEntity}
          open={state.openEditForm}
          editChart={true}
          onClose={() => handleCloseForm()}
        />
      )}
    </div>
  );
};
M_ResultCard.defaultProps = {
  content: {},
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(managePageActions, dispatch);
}

export default connect(mapStateToProps, undefined)(M_ResultCard);
