import React from 'react'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export default function Rags(props){
return(
   <>
    {props.style && props.style.color ? 
        <FiberManualRecordIcon
        style={props.style} 
         />
    
     : ''} 
   </> 
) 
}