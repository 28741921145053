import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
    root: {
      marginTop:"30px"
      },
      section: {
          marginTop:"30px",
          marginBottom:"20px"

      }

});

function DisplaySectionHeader(props) {
    const classes = useStyles();
  return (
    <div>
    <hr  className={classes.root}></hr>
      <h5  className={classes.section}>{props.label}</h5>
    </div>
  );
}

export default DisplaySectionHeader;
