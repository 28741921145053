import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


const M_NoContent=(props)=>{
    return(

        <List className={props.classes.root}>        
          <ListItem 
            // alignItems="flex-start" 
            style={{textAlign:"center"}} 
          >      
            <ListItemText
              primary={
                  <b>No results</b>
              }        
            />
          </ListItem>
        </List>
      )
}

export default M_NoContent;