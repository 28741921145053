import * as React from "react";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { Box } from "@material-ui/core";
import { HistoryRounded } from "@material-ui/icons";
import { ArrowForward,ArrowBack,Close } from "@material-ui/icons";
import * as httpCall from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
export default function O_DealStatus(props) {
    let [guideline,setGuideline] = React.useState("")
    
    const[data,setData] = React.useState([
        {
            "state": "Developing",
            "guideline": "Requirements for moving this deal to pipeline:<br/>Willing seller<br/>Indicative valuation<br/>Business plan that meets minimum investment size and returns<br/>"
        },
        {
            "state": "Pipeline",
            "guideline": "Requirements for moving this deal to priority:<br/>Willing Seller<br/>Defined price range<br/>Defined process<br/>Investable management team and business plan<br/>Detailed information available<br/>"
        },
        {
            "state": "Priority",
            "guideline": "Requirements for moving this deal to completion:<br/>Deal terms agreed<br/>Structuring and legal<br/>"
        },
        {
            "state": "Completion",
            "guideline": "Requirements for moving this deal to portfolio :<br/>"
        }

    ],)
    React.useEffect(()=>{
    
    setButton()

    },[])
 
   
    let setButton = async ()=>{
      
        for(let i in data){
            if(data[i].state == props.data.currentStatus){
                setGuideline(data[i].guideline)
                break
               
            }
        }
    }
    return (
      
            <Box sx={{p:"1.2rem 0"}}>
                <p dangerouslySetInnerHTML={{__html:guideline}}>

                </p>
            </Box>
            
            
     
        
    )
}
