import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import A_Avatar from "../../../../Atoms/DataDisplay/Avatars/A_Avatar";

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  titleContent: {
    paddingTop: "10px",
    paddingLeft: "2.5%",
  },
  title: {
    marginLeft: "5px",
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  titleSection: {
    padding: "10px",
  },
  seperator: {
    fontSize: "20px",
    marginBottom: "2px",
  },
  subtitle: {
    fontSize: "15px",
  },
  logo: {
    marginLeft: "6px",    
  },
}));

const M_TemplatePageTitle = (props) => {
  const classes = useStyles();
  const [logoImage, setLogoImage] = useState();
  const user = useSelector((state) => state.oidc.user);
  const baseUrl = process.env.API_URL;

  useEffect(() => {
    getImage();
  }, []);

  const getImage = () => {
    let subdomain =  new URL(window.location.href).hostname.split(".")[0]
    fetch(`${process.env.DOCUMENT_HELPER}`, {
      method: "GET",   
      headers: {
        filename: props.logo,
        type: "image",
        "file-operation": "download",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((success) => success.blob())
      .then((data) => {
        const imageAsUrl = URL.createObjectURL(data);
        setLogoImage(imageAsUrl);
      });
  };

  return (
    <div className={classes.titleWrapper}>
      {/* Commented due to moving icon from Template to card headers */}
      {/* <div className={classes.logo}>
        <A_Avatar
          alt="Logo"
          text={props.title? props.title[0]: ""}
          src={logoImage}
          size={"medium"}
        />
      </div> */}

      <div className={classes.titleContent}>
        <span className={classes.title}>{props.title}</span>
        {props.subtitle && (
          <>
            <ChevronRightIcon className={classes.seperator} />
            <span className={classes.subtitle}>{props.subtitle}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default M_TemplatePageTitle;
