import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import {manageMenusActionType} from "../actions/actionTypes";
import * as manageMenusAction  from "../actions/manageMenusAction";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* getMenusSagas() {
    while(true){
        const action = yield take(manageMenusActionType.GET_MENUS);
        const {tenantId}={tenantId: 'd6e57366-9156-468d-8d93-322b13547842'};
        // const {pageId,crmId} = action.payload;
        console.log('get Menus is called:',action.payload); 
        
        const state= yield select();
        let config=commonFunctions.getHttpConfig(); 
        
        if((!config.headers.Authorization || config.headers.Authorization=="Bearer undefined") 
        && (action && action.payload && action.payload && action.payload.access_token)){
            config.headers.Authorization="Bearer "+ action.payload.access_token
        }
        
        try { 
            const res = yield axios.get(`${process.env.API_URL}/menuItems/getUIMenu`,config);                      
            console.log('get menus response:', res);           
            yield put(
                manageMenusAction.getMenusSuccessed({data:res.data.menu ,getMenusSuccessed:true} )                
            );
          } catch (e) {
            yield put(
                manageMenusAction.getMenusFailed({msg: 'Some error occured',getMenusFailed: true})  
                 
              );
            
          }
    }
};

