import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import M_Companies from "./Views/Companies/M_Companies";
import M_Contacts from "./Views/Contacts/M_Contacts";
import M_Deals from "./Views/Deals/M_Deals";
import M_Meeting from "./Views/Meeting/M_Meeting";
import A_Snackbar from "../../../../../Atoms/Feedback/Snackbars/A_Snackbar";
import * as httpCall from "../../../../../../../Utils/apis/apis";
const useStyles = makeStyles((theme) => ({
  //   root: {
  //     flexGrow: 1,
  //   },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#fff",
    //background:"#337ab7"
    background: "rgb(34, 34, 34)",
  },
  root: {
    flexGrow: 1,
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: "80vh",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
}));

const M_SearchResult = (props) => {
  const classes = useStyles();
  const [contact, setContact] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [meeting, setMeeting] = React.useState("");
  const [deal, setDeal] = React.useState("");
  const [error, setError] = React.useState({
    status: false,
    msg: "",
  });

  let templateIds = {
    Deal: "45d829a7-f50c-499c-959d-d49dd9071ecf",
    Company: "72b252a1-99f4-4261-9820-ff14f4266c97",
    Contact: "d0909fbb-78a5-40db-a24c-9efb5558bb8d",
    Meeting: "140f96f1-5644-4e53-8b2b-833958147256",
  };

  const handleError = (msg, type) => {
    setError({
      status: true,
      msg: msg,
    });
  };
  const handleSnackbarClose = () => {
    setError({
      status: false,
      msg: "",
    });
  };
  const getData = async () => {
    let crmRecordSearches = [
      {
        label: "contact",
        keyword: props.value,
        searchOn: ["firstName", "lastName"],
        templateId: "d0909fbb-78a5-40db-a24c-9efb5558bb8d",
      },
      {
        label: "company",
        keyword: props.value,
        searchOn: ["Company Name"],
        templateId: "72b252a1-99f4-4261-9820-ff14f4266c97",
      },
      {
        label: "meeting",
        keyword: props.value,
        searchOn: ["Subject"],
        templateId: "140f96f1-5644-4e53-8b2b-833958147256",
      },
      {
        label: "deal",
        keyword: props.value,
        searchOn: ["projectName"],
        templateId: "45d829a7-f50c-499c-959d-d49dd9071ecf",
      },
    ];

    let url = "/CRMData/searchCRMRecords";
    let requestPayload = crmRecordSearches;
    const result = await httpCall.httpPost(url, requestPayload, {
      headers: { Authorization: "Bearer " + props.user.access_token },
    });

    var getcontacts = result.filter(function (el) {
      return el.pageUrl === "contact";
    });
    var getcompanys = result.filter(function (el) {
      return el.pageUrl === "company";
    });
    var getmeetings = result.filter(function (el) {
      return el.pageUrl === "appointments";
    });
    var getdeals = result.filter(function (el) {
      return el.pageUrl === "deal";
    });
    setContact(getcontacts);
    setCompany(getcompanys);
    setMeeting(getmeetings);
    setDeal(getdeals);
  };
  React.useEffect(() => {
    getData();
  }, [props.value]);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        style={{
          height: "100vh",
          marginTop: "100px",
          position: "fixed",
          zIndex: "99999999999",
          background: "rgb(34, 34, 34)",
          opacity: 0.9,
        }}
      >
        <Grid item xs={3}>
          <Paper className={classes.paper}>Contact</Paper>
          <M_Contacts
            user={props.user}
            type="contacts"
            value={props.value}
            handleClick={props.handleClick}
            onError={handleError}
            data={contact}
          />
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>Companies</Paper>
          <M_Companies
            user={props.user}
            type="accounts"
            value={props.value}
            handleClick={props.handleClick}
            onError={handleError}
            data={company}
          />
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>Meetings</Paper>
          <M_Meeting
            user={props.user}
            type="appointments"
            value={props.value}
            handleClick={props.handleClick}
            onError={handleError}
            data={meeting}
          />
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>Deals</Paper>
          <M_Deals
            user={props.user}
            type="opportunities"
            value={props.value}
            handleClick={props.handleClick}
            onError={handleError}
            data={deal}
          />
        </Grid>
        {error.status ? (
          <A_Snackbar
            open={error.status}
            message={error.msg}
            type="error"
            autoHideDuration={3000}
            horizontal="center"
            handleClose={handleSnackbarClose}
          />
        ) : (
          ""
        )}
      </Grid>

      {/* </Container> */}
    </React.Fragment>
  );
};

export default M_SearchResult;
