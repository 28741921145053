import {managePageActionTypes} from "./actionTypes";

export const getPage=(payload)=> {    
    return {
      type: managePageActionTypes.GET_PAGE, 
      payload     
    };
}
export const getPageSuccessed=(payload)=> {    
    return {
      type: managePageActionTypes.GET_PAGE_SUCCESSED, 
      payload     
    };
}

export const getPageFailed=(payload)=> {    
    return {
      type: managePageActionTypes.GET_PAGE_FAILED, 
      payload     
    };
}
export const resetManagePage=(payload)=> {    
  return {
    type: managePageActionTypes.RESET_MANAGE_PAGE, 
    payload     
  };
}