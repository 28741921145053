import { Typography } from "@material-ui/core";
import React from "react";
import A_Card from "../../../../../Atoms/Surfaces/Cards/A_Card";

const content = "Its a map card";
const M_MapCard = (props) => {
  return (
    <div>
      {/*  <A_Card  content={<Typography>Its a map card</Typography>}>
                       
                   </A_Card> */}
      <Typography>{props.content}</Typography>
    </div>
  );
};

export default M_MapCard;
