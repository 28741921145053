import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import { managePageActionTypes } from "../actions/actionTypes";
import * as managePageActions from "../actions/managePageActions";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* getPageSaga() {
  while (true) {
    const action = yield take(managePageActionTypes.GET_PAGE);
    const { tenantId, pageId } = {
      tenantId: "d6e57366-9156-468d-8d93-322b13547842",
      pageId: "b80fab96-4742-448b-b751-f54319d28d1f",
    };
    let { pageName, templateId, recordId } = action.payload;
    if (
      !recordId ||
      recordId == null ||
      recordId == "null" ||
      recordId == undefined
    ) {
      recordId = "ff706305-ad28-4649-9440-2292e9ce31bc";
    }
    console.log("getPageSaga is called:", action.payload);

    let config = commonFunctions.getHttpConfig();
    try {
      let res = yield axios.post(
        `${process.env.API_URL}/CRMData/getFormTemplate?templateId=${templateId}&crmId=${recordId}`,
        null,
        config
      );
      console.log("getPageSaga response:", res);
      if (action.payload && action.payload.hasContainer) {
        let documentData = yield axios.get(
          `${process.env.API_URL}/GenericDocument/${action.payload.hasContainer}/${recordId}`,
          config
        );
        res.data.data = documentData.data;
      }
      //const resTemplates = yield axios.get(`${process.env.API_URL}/${tenantId}/PageTemplate?pageId=${pageId}`,config);
      //let convertedData = res.data;
      //convertedData=resTemplates.data=="Empty"? [] : resTemplates.data;

      yield put(
        managePageActions.getPageSuccessed({
          data: res.data,
          openManagePageForm: true,
        })
      );
    } catch (e) {
      managePageActions.getPageFailed({
        msg: "Some error occured",
        data: pageId,
        getPage: true,
        openManagePageForm: false,
      });
      console.log("can not get page.");
      console.log(e.message);
    }
  }
}
