import React, { useEffect, useState } from "react";
import axios, { post } from "axios";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import ContactMail from "@material-ui/icons/ContactMail";
import A_SummerNote from "../../../Atoms/Inputs/SummerNote/A_SummerNote";
import "react-summernote/dist/react-summernote.css";
import { httpPost, httpPut } from "../../../../../Utils/apis/apis";
import { connect } from "react-redux";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router";
import M_FormDropableFileUpload from "../../../Molecules/Common/File Upload/M_FormDropableFileUpload";
import { getHttpConfig } from "../../../../../Utils/Helpers/common";
const useStyles = makeStyles({
  inputGroup: {
    display: "flex",
  },
  inputLabel: {
    paddingRight: "1.2rem",
    fontWeight: "bold",
  },
  inputField: {
    width: "350px",
  },
  spanError: {
    color: "red",
    margin: "0",
    padding: "0 0 0 40px",
  },
  span: {
    color: "#ff6666",
  },
});

function IRC_Mail(props) {
  let { crmId, pageUrl, templateId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [formData, setFormData] = React.useState(props.formData);
  const user = useSelector((state) => state.oidc.user);
  const classes = useStyles();
  var PersonName = props.user;
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });

  function valid(formData) {
    if (
      formData.subject == null ||
      formData.subject == undefined ||
      formData.subject == ""
    ) {
      setToError("required ");
    } else if (
      formData.recipients == null ||
      formData.recipients == undefined ||
      formData.recipients == ""
    ) {
      setSubjectError("Not empty");
    } else if (
      formData.remark == null ||
      formData.remark == undefined ||
      formData.remark == ""
    ) {
      updateemailRequestError("Not empty");
    } else {
      setToError("");
      setSubjectError("");
      updateemailRequestError("");
      return true;
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setToError("");
    setSubjectError("");
    updateemailRequestError("");
    setError("");
  };

  function toEmail(event, value) {
    let newFormData = formData;
    newFormData["recipients"] = value;
    setFormData(newFormData);
  }
  function ccEmail(event, value) {
    let newFormData = formData;
    newFormData["ccUsers"] = value;
    setFormData(newFormData);
  }
  function subjectEmail(event) {
    let newFormData = formData;
    newFormData["subject"] = event.target.value;
    setFormData(newFormData);
  }
  function handleSummerNote(e) {
    let newFormData = formData;
    newFormData["remark"] = e;
    setFormData(newFormData);
  }
  const setFileData = (event, files) => {
    event.preventDefault();
    setFiles(files);
    event.preventDefault();
  };
  const uploadFile = (file) => {
    let directory = "attachments";
    let fileName = pageUrl + "/" + crmId + "/" + directory + "/" + file.name;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const httpConfig = getHttpConfig();
      // let subdomain = "dev";
      //important
      let subdomain = new URL(window.location.href).hostname.split(".")[0];

      var ext = fileName.split(".").pop();
     
      const url = `${process.env.DOCUMENT_HELPER}`;
      let header = {
        filename: fileName,
        type: "email",
        "file-operation": "upload",
        "process-file": "no",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.access_token,
      };
      const newFormData = { file: e.target.result };
      axios
        .post(url, newFormData, { headers: header })

        .then(
          (response) => {
            let messgae =
              response && response.message
                ? response.message
                : "Uploaded sucessfuly";
            setChangesSaved({
              status: true,
              type: "success",
              msg: messgae,
            });
          },
          (error) => {
            let messgae = error && error.message ? error.message : "Error";
            setChangesSaved({
              status: true,
              type: "error",
              msg: messgae,
            });
          }
        );
    };
  };
  const showResult = async () => {
    for (let item in files) {
      uploadFile(files[item]);
    }
    if (
      formData.subject == null ||
      formData.subject == undefined ||
      formData.subject == "" ||
      formData.recipients == null ||
      formData.recipients == undefined ||
      formData.recipients == "" ||
      formData.remark == null ||
      formData.remark == undefined ||
      formData.remark == ""
    ) {
      alert("Please Fill Required Details");
    } else {
      setError("");
      let filesData = [];
      for (let item in files) {
        let directory = "attachments";
        let filePath =
          pageUrl + "/" + crmId + "/" + directory + "/" + files[item].name;
        filesData.push(filePath);
      }
      const data = {
        id: "",
        label: "ircemail",
        properties: {
          subject: formData.subject,
          from: PersonName,
          recipients: JSON.stringify(formData.recipients),
          remark: formData.remark,
          ccUsers: JSON.stringify(formData.ccUsers),
          attachGUID: props.ircID,
          isMailSent: false,
          responses: "[]",
          attachments: filesData.join(","),
        },
        edges: [],
        security: {
          read: {
            groupids: [],
            users: [],
          },
          update: {
            groupids: [],
            users: [],
          },
          full: {
            groupids: [],
            users: [],
          },
          privateRecord: true,
        },
      };
      var pageId = props.currentPage.pageConfig.id;
      const response = await httpPost(`/CRMData/insert?pageId=${pageId}`, data);

      if (response.id != null) {
        var data2 = {
          sourceVertexId: props.ircID,
          destinationVertexId: response.id,
          edgeLabel: "hasircemail",
        };
        const responce2 = await httpPut(`/CRMData/addEdge`, data2);

        httpPut(`/DealChecklist/Complete/${crmId}`).then((response) => {
          window.location.reload();
        });
      }
      setChangesSaved({
        status: true,
        type: "success",
        msg: "Mail Sent Succesfully",
      });
      handleClose();
    }
  };
  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  return (
    <div>
      <A_Button
        onClick={handleClickOpen}
        label={props.label}
        style={{
          color: "#ffffff",
          backgroundColor: "#FFA500",
        }}
        icon={<ContactMail />}
      />
      <A_Snackbar
        open={changesSaved.status}
        message={changesSaved.msg}
        type={changesSaved.type}
        autoHideDuration={2000}
        vertical="bottom"
        horizontal="center"
        handleClose={handleSnackbarClose}
      />
      <A_SimpleDialog
        open={open}
        title="Email"
        height="110"
        fullWidth="1000px"
        dialogContent={
          <div>
            <div className={classes.inputGroup}>
              {" "}
              <label className={classes.inputLabel}>
                To<span className={classes.span}>*</span>
              </label>
              <Autocomplete
                multiple
                id="tags-filled"
                options={emails.map((option) => option.email)}
                freeSolo
                defaultValue={formData.recipients}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    className={classes.inputField}
                    placeholder="email"
                    {...params}
                    required={true}
                    //  helperText={error}
                  />
                )}
                onChange={(event, value) => {
                  toEmail(event, value);
                }}
              />
            </div>
            <br />
            <div className={classes.inputGroup}>
              {" "}
              <label className={classes.inputLabel}>CC</label>&nbsp;
              <Autocomplete
                multiple
                id="tags-filled"
                options={emails.map((option) => option.email)}
                defaultValue={formData.ccUsers}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    className={classes.inputField}
                    placeholder="email"
                    {...params}
                  />
                )}
                onChange={(event, value) => {
                  ccEmail(event, value);
                }}
              />
            </div>
            <br />
            <div className={classes.inputGroup}>
              {" "}
              <label className={classes.inputLabel}>
                Subject<span className={classes.span}>*</span>
              </label>
              <TextField
                className={classes.inputField}
                id="standard-basic"
                size="large"
                label=""
                placeholder=""
                variant="standard"
                onChange={subjectEmail}
                value={formData.subject}
                required
              />
            </div>
            <br />
            <label className={classes.inputLabel}>
              Request<span className={classes.span}>*</span>
            </label>{" "}
            <A_SummerNote
              height="80"
              onChange={handleSummerNote}
              defaultValue={formData.remark}
              disabled={false}
            ></A_SummerNote>{" "}
            <br />
            <M_FormDropableFileUpload files={files} setFiles={setFileData} />
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "140px" }}
              type="button"
              onClick={showResult}
            >
              Send Mail{" "}
            </Button>
          </div>
        }
        onClose={handleClose}
      />
    </div>
  );
}
const emails = [
  { email: "shubham.saini@equipped.ai", name: "shubham s" },
  { email: "pallavi.saini@equipped.ai", name: "pallavi" },
  { email: "jatin.kumar@equipped.ai", name: "jatin" },
  { email: "vishal.pundir@equipped.ai", name: "vishal" },
  { email: "vineet.sharma@equipped.ai", name: "vineet" },
  { email: "prateek.agrawal@equipped.ai", name: "prateek" },
  { email: "sunil.kundu@equipped.ai", name: "sunil" },
  { email: "sachin.sharma@equipped.ai", name: "sachin" },
  { email: "gaurav.arora@equipped.ai", name: "gaurav" },
  { email: "shashank.sharma@equipped.ai", name: "shashank" },
  { email: "ravina.vijayvergiya@equipped.ai", name: "ravina" },
  { email: "matthew.mckenna@equipped.ai", name: "matthew" },
];

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    managePage: state.managePage,
    user: state.oidc.user.profile.email,
  };
}
export default connect(mapStateToProps)(IRC_Mail);
