import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import AddIcon from "@material-ui/icons/Add";
import { Alert, AlertTitle } from "@material-ui/lab";
import A_IconButton from "../../../../../Atoms/inputs/buttons/A_IconButton";
import A_TextField from "../../../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../../../Atoms/Inputs/Selects/A_Select";
import A_Button from "../../../../../atoms/inputs/buttons/A_Button";
import A_CircularIndeternment from "../../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import * as validators from "../../../../../../../Utils/Helpers/validations";
import * as httpCall from "../../../../../../../Utils/apis/apis";
import A_Skeleton from "../../../../../Atoms/Feedback/Skeletons/A_Skeleton";
import { Button } from "@material-ui/core";
import { IconButton, TableCell, TableRow, TableContainer, Table, TableBody, Tabs, Tab, Box, TablePagination } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";



const useStyles = makeStyles((theme) => ({
  container: {
    width: 450,
   
  },
  inputcontrol: {
    width: 450,
  },
  inputcontrol1: {
    //width: 450,
    marginTop: "1rem",
  },
  inputcontrolDropdown: {
    width: 400,
  },
  section: {
    display: "inline-flex",
    position: "absolute",
    right: "8px",
    marginTop: "5rem",
  },
  btnshow: {
    marginTop: "10px",
  },
  seperatorRow: {
    //border: "1px solid red",
    width: "450px !important",
  },
  seperatorRowText: {
    fontSize: "15px",
    backgroundColor: "lightblue",
    height: "30px",
    color: "black",
    padding: 5,
    borderRadius: 4,
    textAlign: "center",
  },
  createrecordbutton: {
    textTransform: "none",
  },
  accordian: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "transparent !important",
    },
    "&.MuiAccordion-root": {
      border: "none !important",
      boxShadow: "none !important",
      background: "transparent !important",
    },
  },
  expandIcon: {
    "& .MuiAccordionSummary-content": {
      margin: "0px !important"
    },
    "& .MuiAccordionSummary-expandIcon": {
      transform: "rotate(0deg) !important",
      position: "absolute",
      left: 0,
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg) !important",
      position: "absolute",
      left: 0,
    },
  },
  customExpandableContainer: {
    height: "125px",
  },
  heading: {
    margin: "12px 30px !important",
  },
  inputcontrolAccordian: {
    width: "417px",
  },
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const M_AddReportForm = (props) => {
  const classes = useStyles();
  const [newReportName, setNewReportName] = useState("");
  const [isLoadingSaveReport, setIsLoadingSaveReport] = useState(false);
  const [isErrorSaveReport, setIsErrorSaveReport] = useState(false);
  const [validationSummary, setValidationSummary] = useState(undefined);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    props.onShowAddnewReportForm();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTextboxes = (e) => {
    setNewReportName(e.target.value);
  };

  const handleValidations = () => {
    let isValidated = true;
    let validations = {};
    if (!validators.isRequired(newReportName)) {
      isValidated = false;
      validations["txtNewReport"] = {
        error: true,
        msg: "This field is required",
      };
    }
    //report name duplicate
    if (validators.isExists(newReportName, "reportName", props.reports)) {
      isValidated = false;
      validations["txtNewReport"] = {
        error: true,
        msg: "Report name already exists",
      };
    }
    setValidationSummary(validations);
    return isValidated;
  };
  const handleReportSave = async () => {
    if (handleValidations()) {
      setIsLoadingSaveReport(true);
      let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/create-report/${props.investmentId}`;
      let requestPayload = {
        //reportId: Math.floor(Math.random() * 100),
        reportName: newReportName,
      };
      //console.log("chart requst payload:", requestPayload);
      //const result = await httpCall.httpPost(url, requestPayload);
      const result = await httpCall.httpPost(url, requestPayload);
      console.log(result);
      if ("message" in result) {
        setIsErrorSaveReport(result);
        setIsLoadingSaveReport(false);
      } else {
        let currReports = [...props.reports];
        let newReportObject = {
          value: result.reportId,
          label: result.reportName,
          ...result,
        };
        currReports.push(newReportObject);
        setIsLoadingSaveReport(false);
        setValidationSummary(undefined);
        props.onReportSave(currReports);
      }
    }
  };

  const handleExcelReportChange = async (selectedValue, id) => {
    console.log(selectedValue);
    if (selectedValue) {
      setIsLoadingSaveReport(true);
      let url = `${process.env.PortalAdminAPIUrl}/api/ReportEditor/create-report/${props.investmentId}`;
      let requestPayload = {
        reportName: selectedValue.value,
      };
      const result = await httpCall.httpPost(url, requestPayload);
      console.log(result);
      if ("message" in result) {
        setIsErrorSaveReport(result);
        setIsLoadingSaveReport(false);
      } else {
        let currReports = [...props.reports];
        let newReportObject = {
          value: result.reportId,
          label: result.reportName,
          ...result,
        };
        currReports.push(newReportObject);
        setIsLoadingSaveReport(false);
        setValidationSummary(undefined);
        props.onReportSave(currReports, true);
      }
    }
  };

  const handleCancel = () => {
    props.onShowAddnewReportForm(false);
    
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
  
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
  
      if (order !== 0) return order;
  
      return a[1] - b[1];
    });
  
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
  
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const renderPagingnation = () => {
    return props.reports && props.reports.length > 5 ? (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props.reports.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    ) : (
      ""
    );
  };

  return (
    <section className={classes.container}>
      <Grid container spacing={5} justify="center">
      <Box border={1} borderColor={'black'}>
        <Box>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab key = {0} label = {'Reports'} {...a11yProps(0)}></Tab>
          <Tab key = {1} label = {'Templates'} {...a11yProps(1)}></Tab>
        </Tabs>
        </Box>
        <TabPanel value = {tabValue} index = {0}>
          <TableContainer>
            {!props.showAddnewReportForm && (
              <Table
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
              >
                      <TableBody>
                      {props.reports && props.reports.length > 0 
                        ? stableSort(props.reports, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ).map((report) => { 
                          return (
                          <>
                          <TableRow>
                            <TableCell>
                              <Grid container spacing={2}>
                                <Grid item xs={9}>
                                <p style={{marginTop : "19px"}}>{report.label}</p>
                                </Grid>
                                <Grid item spacing={3}>
                                <IconButton>
                                <EditIcon
                                  key={report.id}
                                  id={report.id}
                                  onClick={() => {props.onReportsChange(report)}}
                                />
                                </IconButton>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                          </>
                          )
                        }) : ""
                        } 
                      </TableBody>
              </Table>
            )}   
          </TableContainer>   
          {renderPagingnation()} 
        </TabPanel>
        <TabPanel value = {tabValue} index = {1}>
          {props.showAddnewReportForm && props.excelReports.length == 0 && (
          <Grid container spacing={5} justify="center">
            <Grid item lg={12}>
              <div className={classes.noData}>
                <p>WE ARE SORRY, NO CONTENT FOUND!</p>
              </div>
            </Grid>
          </Grid>
        )}

        {props.showAddnewReportForm && props.excelReports.length > 0 && (
          <>
            <Grid item xs={12}>
              <A_Select
                className={classes.inputcontrol}
                label="Create report from a template section"
                helperText=""
                native={true}
                variant="filled"
                options={props.excelReports}
                onChange={handleExcelReportChange}
              />
             
              <Accordion classes={{ root: classes.accordian }}>
                <AccordionSummary
                  className={classes.expandIcon}
                  expandIcon={<ArrowRightIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Create custom Report
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.customExpandableContainer}>
                  {" "}
                  <Grid item xs={12} className={classes.inputcontrol1}>
                    <A_TextField
                      id="txtNewReport"
                      className={classes.inputcontrolAccordian}
                      label="Report Name"
                      required={true}
                      error={
                        validationSummary &&
                        validationSummary.txtNewReport &&
                        validationSummary.txtNewReport.error
                      }
                      helperText={
                        validationSummary &&
                        validationSummary.txtNewReport &&
                        validationSummary.txtNewReport.msg
                      }
                      value={newReportName}
                      onChange={handleTextboxes}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {isLoadingSaveReport && (
                      <section>
                        {" "}
                        <span>Saving</span> <A_CircularIndeternment />
                      </section>
                    )}
                    {!isLoadingSaveReport && (
                      <section className={classes.section}>
                        <A_Button
                          label="Cancel"
                          color="default"
                          variant="outlined"
                          onClick={handleCancel}
                        />
                        <A_Button
                          label="Save"
                          disabled={isLoadingSaveReport}
                          color="primary"
                          variant="outlined"
                          onClick={handleReportSave}
                        />
                      </section>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              {isErrorSaveReport && (
                <Alert severity="error" style={{ width: "425px" }}>
                  <AlertTitle>{isErrorSaveReport.name}</AlertTitle>
                  <strong>
                    Something went wrong. Try again or contact admin
                  </strong>
                  <br />
                  {isErrorSaveReport.message}
                </Alert>
              )}
            </Grid>
          </>
        )}
        </TabPanel>
      </Box>
      </Grid>
    </section>
  );
};

export default M_AddReportForm;
