import { Typography } from "@material-ui/core";
import React from "react";
import A_Card from "../../../../../Atoms/Surfaces/Cards/A_Card";

const content = "Its a news card";
const M_NewsCard = (props) => {
  return (
    <div>
      <Typography>{props.content}</Typography>
    </div>
  );
};

export default M_NewsCard;
