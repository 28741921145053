import React, { useEffect, useState } from "react";
import PageContext from "../../../../../contexts/pageContext";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { getFormattedDate } from "../../../../../Utils/Helpers/dateUtils";
import M_DropableFileUpload from "../../../Molecules/Common/File Upload/M_DropableFileUpload";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { useParams } from "react-router";
import { httpPost, httpPut } from "../../../../../Utils/apis/apis";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loggedInUserIdActions from "../../../../../store/actions/loggedInUserIdActions";

const ViewFields = (props) => {
  const [fields, setFields] = React.useState(props.fields ? props.fields : []);
  let { crmId , pageUrl} = useParams();
  const [dealTeamData, setdealTeamData] = React.useState(false);
  const [showButton, setShowButton] = React.useState(false);
  const useStyles = makeStyles({
    table: {
      //minWidth: 650,
    },
    Label: {
      fontWeight: "600",
    },
  });
  const classes = useStyles();
  useEffect(() => {
    props.getLoggedInUser();
    props.syncEdgeLabel ? fetchMyAPI() : "";
  }, []);

  async function fetchMyAPI() {
    let convertedFormData = {
      sourceVertexId: crmId,
      edgeLabel: props.syncEdgeLabel,
    };
    let getEdgeRes = await httpPost(`${process.env.API_URL}/CRMData/getEdge`, convertedFormData);

    for (var i = 0; i < getEdgeRes.length; i++) {
      var loggedinID = props.loggedIn.data[0].id;
      var loggedEmail = props.loggedIn.data[0].properties.email;
      if (crmId == loggedinID || loggedEmail == getEdgeRes[i].createdBy) {
        setdealTeamData(false);
        break;
      } else {
        setdealTeamData(true);
      }
    }
  }

  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });

  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  const GetTextField = (props) => {
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" className={classes.Label}>
          {field.label}
        </TableCell>
        <TableCell align={`${field.align || "left"}`}>
          {getFieldValue(field, crmData)}
        </TableCell>
      </TableRow>
    );
  };
  const GetCheckboxField = (props) => {
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" className={classes.Label}>
          {field.label}
        </TableCell>
        <TableCell align={`${field.align || "left"}`}>
          {getFieldValue(field, crmData)}
        </TableCell>
      </TableRow>
    );
  };

  const verifyAndGetUrlWithHttp = (field, crmData) => {
    let url = getFieldValue(field, crmData);

    if (url && url.length > 4) {
      url = url.substring(0, 4) === "http" ? url : "http://" + url;
    }

    return url;
  };
  const ButtonAction = async () => {
    var data = {
      sourceVertexId: props.loggedIn.data[0].id,
      destinationVertexId: crmId, //Seached user,company
      edgeLabel: props.syncEdgeLabel,
      destinationVertexLabel:pageUrl
    };
    const responce2 = await httpPut(`${process.env.API_URL}/CRMData/addEdge`, data);
    console.log(responce2);
    setShowButton(true);
    setChangesSaved({
      status: true,
      type: "success",
      msg: "Synced",
    });
  };
  const GetLinkField = (props) => {
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" className={classes.Label}>
          {field.label}
        </TableCell>
        <TableCell align={`${field.align || "left"}`}>
          {field && field.inNewTab ? (
            <a
              style={{ display: "table-cell" }}
              href={verifyAndGetUrlWithHttp(field, crmData)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getFieldValue(field, crmData)}
            </a>
          ) : (
            <a
              href={verifyAndGetUrlWithHttp(field, crmData)}
              rel="noopener noreferrer"
            >
              {getFieldValue(field, crmData)}
            </a>
          )}
        </TableCell>
      </TableRow>
    );
  };
  const GetHtmlField = (props) => {
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell component="th" scope="row" colSpan={2}>
          <div style={{ padding: "15px" }}>
            <div dangerouslySetInnerHTML={{ __html: crmData[field.id] }}></div>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const GetDocumentField = (props) => {
    let content = {
      multiple: true,
      directory: "test",
    };
    let { field, crmData } = props;
    return (
      <TableRow key={field.id}>
        <TableCell
          component="th"
          scope="row"
          colSpan={2}
          className={classes.Label}
        >
          {field.label}
          <M_DropableFileUpload content={content} />
        </TableCell>
      </TableRow>
    );
  };

  const GetButtonField = (props) => {
    let content = {
      multiple: true,
      directory: "test",
    };
    let { field, crmData } = props;
    return (
      <>
        {dealTeamData ? (
          <TableRow key={field.id}>
            <TableCell
              component="th"
              scope="row"
              colSpan={2}
              className={classes.Label}
            >
              <A_Button
                size="small"
                color="primary"
                label={field.label}
                onClick={ButtonAction}
                disabled={showButton}
              />
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
      </>
    );
  };

  const GetContentFieldType = (props) => {
    let { field, crmData } = props;

    return (
      <>
        {field &&
        field.fieldType &&
        (field.fieldType == "date" ||
          field.fieldType == "percentage" ||
          field.fieldType == "multiple" ||
          field.fieldType == "lookup" ||
          field.fieldType == "optionset" ||
          field.fieldType == "radios" ||
          field.fieldType == "float" ||
          field.fieldType == "text") ? (
          <GetTextField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == "hypertext" ? (
          <GetLinkField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == "html" ? (
          <GetHtmlField {...props} />
        ) : (
          ""
        )}
        {field &&
        field.fieldType &&
        field.fieldType.toLowerCase() === "string" &&
        field.validations.includes("http") ? (
          <GetLinkField {...props} />
        ) : field &&
          field.fieldType &&
          field.fieldType.toLowerCase() === "string" ? (
          <GetTextField {...props} />
        ) : (
          ""
        )}
        {field &&
        field.fieldType &&
        field.fieldType.toLowerCase() === "document" ? (
          <GetDocumentField {...props} />
        ) : (
          ""
        )}
        {field && field.fieldType && field.fieldType == "bit" ? (
          <GetCheckboxField {...props} />
        ) : (
          ""
        )}
        {field &&
        field.fieldType &&
        field.fieldType.toLowerCase() === "button" ? (
          <GetButtonField {...props} />
        ) : (
          ""
        )}
      </>
    );
  };
  const getFieldValue = (field, crmData) => {
    let multipleLabel = "";
    let percentLabel = "";
    let val = "";
    if (field.fieldType == "bit") {
      val = crmData[field.id] === "true" ? "Yes" : "No";

      return val;
    }
    if (field.fieldType == "multiple") {
      multipleLabel = field.id;
    } else multipleLabel = "";

    if (field.fieldType == "percentage") {
      percentLabel = field.id;
    } else percentLabel = "";

    if (field.fieldType == "date") {
      if (crmData[field.id] !== undefined) {
        const dateValue = crmData[field.id];
        return getFormattedDate(dateValue, field.formatType);
      }
    }

    if ("value" in field || crmData === undefined) {
      val = field.value;
    } else {
      if (percentLabel && crmData[field.id] !== undefined) {
        if (crmData[field.id] !== "") {
          val = crmData[field.id] + "%";
        }
      } else if (multipleLabel && crmData[field.id] !== undefined) {
        if (crmData[field.id] !== "") {
          val = crmData[field.id] + "x";
        }
      } else {
        val = crmData[field.id];
      }
    }

    return val;
  };
  return (
    <PageContext.Consumer>
      {({ pageConfig, crmData }) => {
        return (
          <div>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                {fields &&
                  fields.length > 0 &&
                  fields.map((field) => {
                    return (
                      <GetContentFieldType field={field} crmData={crmData} />
                    );
                  })}
              </TableBody>
            </Table>
            <A_Snackbar
              open={changesSaved.status}
              message={changesSaved.msg}
              type={changesSaved.type}
              autoHideDuration={2000}
              vertical="bottom"
              horizontal="center"
              handleClose={handleSnackbarClose}
            />
          </div>
        );
      }}
    </PageContext.Consumer>
  );
};

function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(Object.assign({}, loggedInUserIdActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewFields);
