import * as React from "react";
import axios, { post } from "axios";
import { Box, Typography, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as httpCall from "../../../../../Utils/apis/apis";
import A_Accordion from "../../../Atoms/Surfaces/Accordions/A_Accordion";
import { useParams } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import O_CheckList from "../../../Organisms/Common/Checklist/O_Checklist";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import TodayIcon from "@material-ui/icons/Today";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { httpGet, httpPost, httpPut } from "../../../../../Utils/apis/apis";

const useStyles = makeStyles({
  boxBorder: {
    borderBottom: 1,
    borderColor: "divider",
  },
  boxFlex: {
    display: "flex",
    alignItems: "center",
  },
  heading: {
    width: "45%",
    height: "2.4rem",
    display: "inline-flex",
    alignItems: "center",
  },
  marginAuto: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2.4rem",
  },
  marginTop2: {
    marginTop: "2rem",
  },
  Label: {
    fontWeight: "600",
  },
  main: {
    height: "338px",
    width: "720px",
    paddingLeft: "20px",
    marginBottom: "10px",
  },
  calender: {
    border: "3px solid grey",
    backgroundColor: "#f5f5f0",
    display: "flex",
    padding: "20px 10px 0px 5px",
  },
  icon: {
    height: "80px",
    width: "60px",
    color: "#3d5afe",
    paddingBottom: "20px",
  },
  color: {
    color: "black",
  },
  selected: {
    backgroundColor: "rgba(1,1,1,0.05)",
  },
  buttonMargin: {
    margin: "1.2rem",
  },
  innerBox: {
    padding: "1.4rem",
    marginRight: "2.8rem",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function M_IRCdetail(props) {
  const classes = useStyles();
  const { crmId } = useParams();
  const [value, setValue] = React.useState(0);
  const [ircData, setircData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRemarkLoading, setIsRemarkLoading] = React.useState(false);
  const [responseData, setResponseData] = React.useState([]);
  const [remarkData, setRemarkData] = React.useState([]);
  const [result, setResult] = React.useState("");
  const [domainName, setdomainName] = React.useState("");
  const [date, setDate] = React.useState("");
  const [time, settime] = React.useState("");
  let responseIndex = null;
  React.useEffect(() => {
    getDetails();
    getRemark();
    if (props.modify) {
      let outJson = JSON.parse(
        props.data && props.data.properties && props.data.properties.Organizer
          ? props.data.properties.Organizer
          : {}
      );
      let orgEmail =
        outJson.emailAddress && outJson.emailAddress.address
          ? outJson.emailAddress.address
          : "";

      let domainName =
        orgEmail.split("@").length > 1 ? orgEmail.split("@")[1] : "";
      setdomainName(domainName);
      var domain = "Domain";
      var name = domainName + " ";
      setResult(name.concat(domain));
    }
    // getResponse();
  }, [props]);
  let changeDateFormat = (i) => {
    var options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hours: "numeric",
      min: "numeric",
    };
    var today = new Date(i);
    return today.toLocaleDateString("en-US", options);
  };

  let getDetails = () => {
    let irc = {};
    let response = props.ircData;

    for (let i in response) {
      if (response[i].id == props.id) {
        irc.ircPurpose = response[i].ircPurpose ? response[i].ircPurpose : "";
        irc.timing = response[i].timing ? response[i].timing : "";
        irc.verbalUpdate = response[i].verbalUpdate
          ? response[i].verbalUpdate
          : "";
        irc.budgetApproved = response[i].budgetApproved
          ? response[i].budgetApproved
          : "";
        irc.nextUpdateMethod = response[i].nextUpdateMethod
          ? response[i].nextUpdateMethod
          : "";
        irc.aimlApproval = response[i].aimlApproval
          ? response[i].aimlApproval
          : "";
        irc.luxBoardMeeting = response[i].luxBoardMeeting
          ? response[i].luxBoardMeeting
          : "";
        irc.comments = response[i].comments ? response[i].comments : "";
        irc.ircChair = response[i].ircChair ? response[i].ircChair : "";
        irc.ircCommittee = response[i].ircCommittee
          ? response[i].ircCommittee
          : "";
        irc.attendees = response[i].attendees ? response[i].attendees : "";
        irc.minutes = response[i].minutes ? response[i].minutes : "";
      }
    }
    setircData(irc);
  };
  const getResponse = async () => {
    if (props.id) {
      setIsLoading(true);
      let payload = props.resPayload ? props.resPayload : "";
      if (payload && payload.sourceId && payload.sourceId == "=ircId") {
        payload.sourceId = props.id ? props.id : "";
      }
      let response = await httpCall.httpPost(
        `/CRMChartData/getGenericCRMData`,
        payload
      );

      let res = [];
      for (let i in response) {
        let data = JSON.parse(response[i].responses);
        for (let i in data) {
          res.push({
            defaultExpanded: i == 0 ? true : false,
            summary: (
              <Box>
                <Box>{data[i].senderEmail}</Box>
                <Box>{changeDateFormat(Date())}</Box>
              </Box>
            ),
            detail: data[i].body,
          });
        }
      }
      setResponseData(res);
      setIsLoading(false);
    }
  };
  const getRemark = async () => {
    if (props.id) {
      setIsRemarkLoading(true);
      let payload = {
        sourceId: props.id ? props.id : "",
        sourceLabel: "ircportal",
        label: "ircemail",
        fields: ["remark", "attachments"],
      };

      let response = await httpCall.httpPost(
        `/CRMChartData/getGenericCRMData`,
        payload
      );

      setRemarkData(response ? response : []);
      setIsRemarkLoading(false);
    }
  };

  const renderDocument = (item) => {
    let files = item.attachments.split(",");

    return (
      <div>
        <Typography style={{ fontSize: "1.8rem", color: "9c8662" }}>
          Documents
        </Typography>
        {files.map((value) => {
          let path = value.split("/");
          let fileName = path[path.length - 1];
          return (
            <div
              className=""
              onClick={() => {
                fileDownload(fileName, value);
              }}
            >
              <Typography style={{ fontSize: "1.2rem", color: "6ea1cc" }}>
                {fileName}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };
  const fileDownload = (filename, filepath) => {
    const url = `${process.env.DOCUMENT_HELPER}`;
    const httpConfig = commonFunctions.getHttpConfig();
    //important
    // let subdomain = "dev";
    // new URL(window.location.href).hostname.split(".")[0]
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    let header = {
      filename: filepath,
      type: "email",
      "file-operation": "download",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
      Authorization: httpConfig.headers.Authorization,
    };
    const formData = {};
    axios
      .post(url, formData, { responseType: "blob", headers: header })
      .then((response) => {
        const url1 = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url1;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
  };
  function a11yProps(index, isResponses) {
    if (isResponses) {
      responseIndex = index;
    }
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    if (responseIndex && newValue == responseIndex) {
      getResponse();
    }
    setValue(newValue);
  };
  React.useEffect(() => {
    setValue(0);
    getDateTime();
  }, [props.id]);
  const getDateTime = () => {
    if (props.modify) {
      let newDateJSON =
        props.data && props.data.properties && props.data.properties.StartDT
          ? JSON.parse(props.data.properties.StartDT)
          : "";
      let newDate = new Date(newDateJSON.dateTime + "Z");
      function addZero(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      }
      
      let year = newDate.getFullYear();
      let Month = newDate.getMonth()+1;
      let day = newDate.getDate();

      let Datee = year + "-" + Month + "-" + day;

      let h = newDate.getHours();
      let m = addZero(newDate.getMinutes());
      let Timee = h + ":" + m;

      settime(Timee);
      setDate(Datee);
    }
  };

  const [def, setDef] = React.useState(props.default);

  function handleChanges(event) {
    setDef(event.target.value);
  }
  const resetRadioState = () => {
    setDef("Select");
  };

  async function handleChangess() {
    let selectedRadio = def;

    if (!props.loggedinUserData[0].properties.meetingPrefernce) {
      var prefObj = [
        {
          domain: domainName,
          preference: selectedRadio,
        },
      ];
    } else {
      var pref = props.loggedinUserData[0].properties.meetingPrefernce;
      var prefObj = JSON.parse(pref);
      var formData = {
        domain: domainName,
        preference: selectedRadio,
      };

      prefObj.push(formData);
    }

    let convertedFormData = {
      id: props.loggedinUser,
      label: "contact",
      properties: {
        meetingPrefernce: JSON.stringify(prefObj),
      },
    };
    let addMeetingPref = await httpPut(
      `${process.env.API_URL}/CRMData/update?pageUrl=contact`,
      convertedFormData
    );

    let convertedFormData2 = {
      id: props.data.id,
      label: "calenderEvents",
      properties: {
        IsTagged: "true",
      },
    };

    let AddIsTaggedpref = await httpPut(
      `${process.env.API_URL}/CRMData/update?pageUrl=contact`,
      convertedFormData2
    );
    props.callback();
    resetRadioState();
  }

  if (props.modify) {
    return (
      <>
        <div className={classes.main}>
          <div className={classes.calender}>
            <div>
              <TodayIcon className={classes.icon} />
            </div>
            <div style={{ paddingLeft: "10px" }}>
              <div>
                <h6>
                  Date: {date} Time: {time}
                </h6>
                <h5>
                  {props.data &&
                  props.data.properties &&
                  props.data.properties.Subject
                    ? props.data.properties.Subject
                    : ""}
                </h5>
              </div>
            </div>
          </div>
          <div>
            <h4 style={{ paddingTop: "10px" }}>Preference</h4>
            <RadioGroup defaultValue={def} onChange={handleChanges}>
              <FormControlLabel
                className={classes.color}
                value="All"
                control={<Radio color="primary" />}
                label={"Always tag my meetings with " + "" + result}
                checked={def === "All"}
              />
              <FormControlLabel
                className={classes.color}
                value="Select"
                control={<Radio color="primary" />}
                label={"Ask before tagging my meetings with " + "" + result}
                checked={def === "Select"}
              />
              <FormControlLabel
                className={classes.color}
                value="None"
                control={<Radio color="primary" />}
                label={"Never tag my meetings with " + "" + result}
                checked={def === "None"}
              />
            </RadioGroup>
          </div>
          <div>
            <A_Button
              onClick={() => handleChangess()}
              color="primary"
              label="Tag this meeting"
            />
            <A_Button
              onClick={() => handledel(index, data)}
              color="secondary"
              label="Do not tag"
              onClick={resetRadioState}
            />
          </div>
        </div>
      </>
    );
  } else {
  return (
    <Box>
      <Box className={classes.boxBorder}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {props.config.map((item, index) => {
            return (
              <Tab
                label={item.label}
                {...a11yProps(
                  index,
                  item.isResponses ? item.isResponses : false
                )}
              />
            );
          })}
        </Tabs>
      </Box>

      {props.config.map((item, index) => {
        if (item.id == "checklist") {
          return (
            <TabPanel value={value} index={index}>
              <O_CheckList
                ircID={props.id}
                config={props.content ? props.content : {}}
              />
            </TabPanel>
          );
        }
        return (
          <TabPanel value={value} index={index}>
            {item.fields ? (
              item.fields.map((data, index) => {
                return (
                  <>
                    <Box className={classes.boxFlex}>
                      {!data.isHTML ? (
                        <Typography
                          className={`${classes.heading} ${classes.Label}`}
                        >
                          {data.label}
                        </Typography>
                      ) : (
                        ""
                      )}

                      {ircData[data.field] ? (
                        data.isHTML ? (
                          <div
                            className=""
                            dangerouslySetInnerHTML={{
                              __html: ircData[data.field],
                            }}
                          ></div>
                        ) : data.isDate ? (
                          changeDateFormat(ircData[data.field].split(" ")[0])
                        ) : (
                          ircData[data.field]
                        )
                      ) : (
                        ""
                      )}
                    </Box>

                    {data.id && data.id.toLowerCase() == "remark" && remarkData
                      ? remarkData.map((item) => {
                          return (
                            <div className="">
                              <div
                                className={classes.marginTop2}
                                dangerouslySetInnerHTML={{
                                  __html: item.remark ? item.remark : "",
                                }}
                              ></div>
                              {renderDocument(item)}
                            </div>
                          );
                        })
                      : ""}
                  </>
                );
              })
            ) : item.isResponses ? (
              <ResponsePanel
                isLoading={isLoading}
                classes={classes.marginAuto}
                responseData={responseData ? responseData : []}
              ></ResponsePanel>
            ) : (
              ""
            )}
          </TabPanel>
        );
      })}
    </Box>
  );
    }
}

function ResponsePanel(props) {
  return (
    <>
      {props.isLoading ? (
        <Box className={props.classes}>
          <CircularProgress />
        </Box>
      ) : (
        <A_Accordion
          accordions={props.responseData ? props.responseData : []}
        />
      )}
    </>
  );
}
