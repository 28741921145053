import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import A_IconButton from "../../../../Atoms/Inputs/Buttons/A_IconButton";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import M_ToolbarFilter from "../Toolbar/M_ToolbarFilter";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { httpPost, httpGet } from "../../../../../../Utils/apis/apis";
import * as commonFunctions from "../../../../../../Utils/Helpers/common";
import Slide from "@material-ui/core/Slide/Slide";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import A_CircularIndeternment from "../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import A_Paper from "../../../../Atoms/Surfaces/Papers/A_Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popper from "@material-ui/core/Popper";
import A_Divider from "../../../../Atoms/DataDisplay/Dividers/A_Divider";
import { resetToolbarFilters } from "../../../../../../store/actions/manageToolbarSelectionsActions";
import A_SimpleDialog from "../../../../Atoms/feedback/dialogs/A_SimpleDialog";
import A_DatePicker from "../../../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import A_Select from "../../../../Atoms/Inputs/Selects/A_Select";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePowerpoint } from "@fortawesome/free-solid-svg-icons";
import ChangeLog from "../../ChangeLog/ChangeLog.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HistoryIcon from "@material-ui/icons/History";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import * as currentPageActions from "../../../../../../store/actions/currentPageActions";

const useStyles = makeStyles((theme) => ({
  dropdowns: {
    display: "flex",
    flexDirection: "row",
    gridColumnGap: "15px",
  },
  secondaryMenuButton: {
    marginLeft: "-19px",
  },

  listspacing: {
    padding: "2px",
  },
  paper: {
    padding: theme.spacing(1),
    marginTop: "-2px",
    marginRight: "-15px",
    zIndex: 999,
  },
}));

const M_PageToolbar = (props) => {
  console.log("currentPage", props.currentPage.pageConfig.pageSecurity);
  const classes = useStyles();
  const [spacing, setSpacing] = React.useState(1);
  const [pageFilters, setPageFilters] = React.useState(undefined);
  const { templateId, crmId } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElement, setAnchorElement] = React.useState(null);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [pptLoading, setPptLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [changeLog, setChangeLog] = React.useState(false);
  const dispatch = useDispatch();
  const toolbar = props.toolbar;
  const optionSets = props.optionSets;
  const [isdownloadExcelPopup, setIsdownloadExcelPopup] = React.useState(false);
  const [itemData, setItemData] = React.useState([]);

  var selectedSeries = null;

  // Triple Dots Popup START

  const handleOptions = (event) => {
    setAnchorElement(anchorElement ? null : event.currentTarget);
  };
  const openOptions = Boolean(anchorElement);

  const OptionId = openOptions ? "simple-popper" : undefined;
  const handleAddFormClose = () => {
    setAnchorElement(null);
  };
  const handleClickOpen = (entity) => {
    //props.getPage(entity);
    // setEntity(entity);
    setOpen(true);
  };
  const handleClickChangeLog = () => {
    setChangeLog(true);
  };

  let handlePopup = () => {
    setOpen(false);
  };
  let handleChangelogPopup = () => {
    setChangeLog(false);
  };
  //Conditions closed

  React.useEffect(() => {
    if (toolbar && toolbar.primary && toolbar.primary.filters) {
      const inScopeFilters = toolbar.primary.filters.filter(
        (item) => item.templateIds && item.templateIds.indexOf(templateId) > -1
      );
      if (inScopeFilters && inScopeFilters.length > 0) {
        setPageFilters(inScopeFilters);
      } else {
        setPageFilters(undefined);
      }
    } else {
      setPageFilters(undefined);
    }
  }, [templateId]);

  const openPopup = Boolean(anchorEl);
  React.useEffect(() => {
    dispatch(resetToolbarFilters());
  }, [pageFilters]);

  const id = open ? "simple-popper" : undefined;

  const handlePptDownload = async (ppt, name) => {
    setPptLoading(true);
    const pptName = name.label;
    const pptId = name.id;
    let convertedFormData = {};
    if (pptId !== null) {
      if (ppt.parameters !== null) {
        convertedFormData = {
          parameters: name.parameters,
        };
      } else {
        convertedFormData = {};
      }

      let config = commonFunctions.getHttpConfig();
      let url = `${process.env.PPT_DOWNLOAD_URL}/PPT/download/${pptId}`;

      const response = await httpPost(url, convertedFormData, {
        ...config,
        responseType: "blob",
      });
      setPptLoading(false);

      const url1 = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url1;
      link.setAttribute("download", pptName + ".pptx");
      link.click();
    }
  };
  const handlePptList = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleButtonClicked = async (
    item,
    templateId,
    crmId,
    params,
    callBack
  ) => {
    setButtonLoading(true);
    let newParam = Object.assign(item.parameters);
    if (newParam) {
      for (const key in newParam) {
        if (
          newParam[key] &&
          typeof newParam[key] == "string" &&
          newParam[key].charAt(0) == "="
        ) {
          if (newParam[key] == "=crmId") {
            newParam[key] = crmId;
          }
        }
      }
      newParam["series"] = params["series"];
      newParam["year"] = params["fromYear"] ? params["fromYear"] : Date();
    }

    let fileName = "ExcelTemplate.xlsx";
    let config = commonFunctions.getHttpConfig();
    let url = `${process.env.PortalAdminAPIUrl}/api/ExcelTemplates/GetExcelTemplate`;
    const response = await httpPost(url, newParam, {
      ...config,
      responseType: "blob",
    });
    setIsdownloadExcelPopup(false);
    setButtonLoading(false);
    callBack(false);
    const url1 = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url1;
    link.setAttribute("download", fileName);
    link.click();
  };
  const renderDownloadExcelPopup = (callback) => {
    const [dateRange, setDateRange] = React.useState("sindleYear");
    const [isDownloadable, setIsDownloadable] = React.useState(false);
    let downloading = false;
    const filterObject = {
      dateRange: dateRange,
      singleYear: new Date(),
      startMonth: new Date(),
      endMonth: new Date(),
      series: selectedSeries ? selectedSeries : "Actual",
    };
    const requestPayload = {
      Tabs:
        props.manageExcelTemplates && props.manageExcelTemplates.length > 0
          ? props.manageExcelTemplates.map((item) => item.tabTitle)
          : [],
    };

    const seriesOptions = [
      { label: "Actual", value: "Actual" },
      { label: "Forecast", value: "Forecast" },
      { label: "Budget", value: "Budget" },
    ];
    const handleDateChange = (date, id) => {
      filterObject[id] = date;
    };
    const handleSeriesChange = (val, id) => {
      filterObject[id] = val.value;
      selectedSeries = val.value;
    };
    const getDateRange = (data) => {
      let end = data["startMonth"];
      let start = data["endMonth"];
      var months;
      months = (start.getFullYear() - end.getFullYear()) * 12;
      months -= end.getMonth();
      months += start.getMonth();
      return months <= 0 ? 0 : months;
    };
    const handleDownloadExcel = (e) => {
      setIsDownloadable(true);
      let year = null;
      let startMonth = null;
      let incrementMonth = null;
      if (filterObject["dateRange"] == "sindleYear") {
        startMonth = 1;
        incrementMonth = 11;
        let d = new Date(filterObject.singleYear);
        year = d.getFullYear();
      } else {
        filterObject["singleYear"] = undefined;
        let d = new Date(filterObject.startMonth);
        incrementMonth = getDateRange(filterObject);
        year = d.getFullYear();
        startMonth = 1 + d.getMonth();
      }
      let param = {};
      param["series"] = filterObject["series"];
      param["fromYear"] = year;
      param["incrementMonth"] = incrementMonth + 1;
      param["dealId"] = crmId;
      param["startMonth"] = startMonth;
      e.target["innerText"] = "Downloading....";
      e.currentTarget["style"]["opacity"] = 0.4;
      e.currentTarget["disabled"] = true;
      downloading = true;
      param["requestPayload"] = requestPayload;

      handleButtonClicked(
        itemData,
        templateId,
        crmId,
        param,
        setIsDownloadable
      );
    };
    return (
      <div>
        <A_DatePicker
          id="singleYear"
          views={["year"]}
          defaultValue={filterObject.singleYear}
          onChange={handleDateChange}
          format="yyyy"
          label="Financial Year"
        />

        <div>
          <A_Select
            className={classes.selctBoxFilter}
            id="series"
            options={seriesOptions}
            defaultValue={filterObject.series}
            onChange={handleSeriesChange}
          />
        </div>

        <div style={{ paddingTop: "20px" }}>
          <A_Button
            onClick={handleDownloadExcel}
            color="primary"
            label="Download Template"
            disabled={isDownloadable}
            endIcon={
              downloading ? (
                <A_CircularIndeternment color="secondary" size={20} />
              ) : (
                <></>
              )
            }
          />
        </div>
      </div>
    );
  };
  const handleClosePopup = () => {
    setIsdownloadExcelPopup(false);
  };
  return (
    <div className="grid-item ">
      {/*Toolbar starts here */}
      <Grid container spacing={spacing} className="page-toolbar">
        <Grid item className={classes.dropdowns} key={templateId}>
          {toolbar &&
            pageFilters &&
            pageFilters.map((pageFilter) => {
              return (
                <M_ToolbarFilter
                  {...pageFilter}
                  optionSets={optionSets}
                  templateId={templateId}
                  investmentId={crmId}
                />
              );
            })}
        </Grid>
        <Grid item>
          {toolbar &&
            toolbar.primary &&
            toolbar.primary.downloadButton &&
            toolbar.primary.downloadButton.show && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <A_Button
                  style={{
                    display: "inline-flex",
                    justifySelf: "end",
                    alignItem: "center",
                  }}
                  edge="start"
                  color="primary"
                  aria-label="add records"
                  label="Download"
                  endIcon={<GetAppIcon />}
                />
              </Box>
            )}
        </Grid>

        {toolbar &&
        toolbar.primary &&
        toolbar.primary.buttons &&
        toolbar.primary.buttons.length > 0
          ? toolbar.primary.buttons.map((item) =>
              item && item.show && templateId == item.templateId ? (
                <Grid item>
                  {" "}
                  <A_Button
                    edge="start"
                    color="primary"
                    aria-label="add records"
                    label={item.label}
                    onClick={(e) => {
                      setItemData(item);
                      setIsdownloadExcelPopup(true);
                    }}
                    endIcon={
                      buttonLoading ? (
                        <A_CircularIndeternment color="secondary" size={20} />
                      ) : (
                        <GetAppIcon />
                      )
                    }
                  ></A_Button>
                </Grid>
              ) : (
                ""
              )
            )
          : ""}

          {toolbar &&
          toolbar.primary &&
          toolbar.primary.downloadExcelButton &&
          toolbar.primary.downloadExcelButton.show && 
          toolbar.primary.downloadExcelButton.templateId === templateId &&
          (
            
            <>
              <Grid item>
                <A_Button
                  color="primary"
                  label={toolbar.primary.downloadExcelButton.label}
                  onClick = { () => {props.downloadExcelTemplatePopUp('ExceltemplateDownload')}}
                >
                </A_Button>
              </Grid>
            </>
          )}        

        {toolbar &&
          toolbar.primary &&
          toolbar.primary.downloadPptButton &&
          toolbar.primary.downloadPptButton.show && (
            <>
              <Grid item>
                <A_Button
                  edge="start"
                  color="primary"
                  aria-label="add records"
                  label={toolbar.primary.downloadPptButton.label}
                  onClick={handlePptList}
                  endIcon={
                    pptLoading ? (
                      <A_CircularIndeternment color="secondary" size={20} />
                    ) : (
                      <GetAppIcon />
                    )
                  }
                ></A_Button>
              </Grid>

              <Popper
                className={classes.paper}
                id={id}
                open={openPopup}
                anchorEl={anchorEl}
                placement="bottom-end"
              >
                <Slide
                  direction="down"
                  in={openPopup}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    <ClickAwayListener onClickAway={(e) => handlePptList(e)}>
                      <div>
                        <A_Paper
                          className={classes.PaperHeight}
                          width={30}
                          height={"auto"}
                          content={
                            toolbar &&
                            toolbar.primary &&
                            toolbar.primary.downloadPptButton &&
                            toolbar.primary.downloadPptButton.presentation &&
                            toolbar.primary.downloadPptButton.presentation.map(
                              (item, index) => (
                                <List
                                  component="nav"
                                  aria-label="user area"
                                  className={classes.listspacing}
                                >
                                  <ListItem
                                    button
                                    onClick={() =>
                                      handlePptDownload(
                                        toolbar.primary.downloadPptButton,
                                        item
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faFilePowerpoint}
                                      style={{ marginRight: "10px" }}
                                    />
                                    <ListItemText
                                      className="List-Font"
                                      primary={item.label}
                                    />
                                  </ListItem>

                                  <A_Divider />
                                </List>
                              )
                            )
                          }
                          size="large"
                        />
                      </div>
                    </ClickAwayListener>
                  </div>
                </Slide>
              </Popper>
            </>
          )}
        <Grid item>
          {toolbar && toolbar.primary && toolbar.primary.print && (
            <A_IconButton
              edge="start"
              color="inherit"
              aria-label="Print"
              icon={<PrintIcon />}
              className="page-header-print-button"
            ></A_IconButton>
          )}
        </Grid>
        <Grid item className={classes.secondaryMenuButton}>
          {toolbar && toolbar.secondary && (
            <A_IconButton
              edge="start"
              color="inherit"
              onClick={handleOptions}
              aria-label="secondaryMenuPage"
              icon={<MoreVertIcon />}
            ></A_IconButton>
          )}
          <Popper
            className={classes.paper}
            id={OptionId}
            open={openOptions}
            anchorEl={anchorElement}
            placement="bottom-end"
          >
            <Slide direction="down" in={openOptions} mountOnEnter unmountOnExit>
              <div>
                <ClickAwayListener onClickAway={(e) => handleOptions(e)}>
                  <div>
                    <A_Paper
                      className={classes.PaperHeight}
                      width={30}
                      height={"auto"}
                      content={
                        <List
                          component="nav"
                          aria-label="user area"
                          className={classes.listspacing}
                        >
                          
                        {/* 
                        WIP FOR RECORD MERGE
                        <ListItem button onClick={() => handleClickOpen()}>
                            <ListItemIcon>
                              <MergeTypeIcon />
                            </ListItemIcon>

                            <ListItemText
                              className="List-Font"
                              primary="Merge Records"
                            />
                          </ListItem>

                          <A_Divider /> */}
                          {(props &&
                            props.currentPage &&
                            props.currentPage.pageConfig &&
                            props.currentPage.pageConfig.pageSecurity &&
                            props.currentPage.pageConfig.pageSecurity.Update ==
                              true) ||
                          (props &&
                            props.currentPage &&
                            props.currentPage.pageConfig &&
                            props.currentPage.pageConfig.pageSecurity &&
                            props.currentPage.pageConfig.pageSecurity.Full ==
                              true) ? (
                            <ListItem
                              button
                              onClick={() => handleClickChangeLog()}
                            >
                              <ListItemIcon>
                                <HistoryIcon />
                              </ListItemIcon>
                              <ListItemText
                                className="List-Font"
                                primary="Record Audit Trail"
                              />
                            </ListItem>
                          ) : (
                            ""
                          )}
                        </List>
                      }
                      size="large"
                    />
                    
                    {/* 
                    WIP RECORD MERGE
                    {open && (
                      <A_SimpleDialog
                        //entity={entity}
                        fullScreen={false}
                        height="400px"
                        fullWidth={true}
                        maxWidth={"md"}
                        open={open}
                        //handlePopup
                        onClose={handlePopup}
                        closePopUp={handlePopup}
                        dialogContent={
                          <RecordMerge searchOn={toolbar.secondary.searchOn} />
                        }
                        //onClose={handleClose}
                      />
                    )} */}
                    {changeLog && (
                      <A_SimpleDialog
                        //entity={entity}
                        fullScreen={false}
                        height="400px"
                        fullWidth={true}
                        maxWidth={"md"}
                        open={changeLog}
                        onClose={handleChangelogPopup}
                        closePopUp={handleChangelogPopup}
                        dialogContent={<ChangeLog />}
                        //onClose={handleClose}
                      />
                    )}
                  </div>
                </ClickAwayListener>
              </div>
            </Slide>
          </Popper>
        </Grid>
      </Grid>
      <A_SimpleDialog
        open={isdownloadExcelPopup}
        title="Download Excel Template"
        fullScreen={false}
        height="400px"
        fullwidth={true}
        maxWidth={"xs"}
        onClose={handleClosePopup}
        closePopUp={handleClosePopup}
        dialogContent={renderDownloadExcelPopup(function () {
          handleClosePopup();
        })}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(M_PageToolbar);

