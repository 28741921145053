import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import A_IconButton from "../../../../atoms/inputs/buttons/A_IconButton";
import AddIcon from "@material-ui/icons/Add";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import A_Select from "../../../../atoms/inputs/selects/A_Select";
import "../../../../../../Assets/css/main.scss";
const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: "20px",
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  seperator: {
    marginBottom: "-5px",
  },
  subtitle: {
    fontSize: "15px",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    height: "auto",
    width: "auto",
    elevation: 0,
  },
  dropdowns: {
    marginTop: "-15px",
  },
  secondaryMenuButton: {
    marginLeft: "-19px",
  },
}));

const M_ReportEditorHeader = (props) => {
  const classes = useStyles();
  return (
    <div>
      {/* fix left and max with to adject toolbar overlaping  */}
      <div className="header" style={{left:"32%" , maxWidth:"67%"}}>
        <div className="grid-item page-title">
          <section className="title-section">
            <span className={classes.title}>{props.title}</span>
            {/* <ChevronRightIcon className={classes.seperator} /> */}
            <span className={classes.subtitle}>{props.subtitle}</span>
          </section>
        </div>

        <div className="grid-item">
          <Grid container spacing={1} className="page-toolbar">
            {props && props.currentReport && (
              <Grid item>
                <label>Reports</label>
              </Grid>
            )}
            <Grid item className={classes.dropdowns}>
              {props && props.currentReport && (
                <A_Select
                  native={true}
                  variant="standard"
                  required={true}
                  label=""
                  value="2"
                  error={false}
                  options={props && props.reports}
                  onChange={props.onReportsChange}
                  defaultValue={
                    props && props.currentReport && props.currentReport.value
                  }
                />
              )}
            </Grid>
            <Grid item>
              {props.showAddButton && (
                <A_IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  size="medium"
                  icon={<AddIcon />}
                  className={classes.menuButton}
                  onClick={props.onShowAddnewReportForm}
                ></A_IconButton>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default M_ReportEditorHeader;
