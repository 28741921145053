import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DisplaySections from "../DisplaySection/DisplaySection";
import ViewFields from "../Fields/ViewFields";
import M_ChartCard from "../../../Molecules/Common/Renderers/Views/Charts/M_ChartCard";
import M_MapCard from "../../../Molecules/Common/Renderers/Views/MapCards/M_MapCard";
import M_ResultCard from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCard";
import O_ChartDataEntry from "../../../Organisms/Common/ChartData/O_ChartDataEntry";
import O_DealStatus from "../../../Organisms/Common/DealStatus/O_DealStatus";
import O_Guidlines from "../../../Organisms/Common/DealStatus/O_Guidlines";
import { clearChartKeys } from "../../../Pages/Common/PageLayout/P_PageLayout.Helper"; //TODO- Move this function into global common file.
import O_PostCheckList from "../../../Organisms/Common/Checklist/O_PostChecklist";
import O_lendersApproached from "../../../Organisms/Common/LendersApproached/O_lendersApproached";
import O_activeFacility from "../../../Organisms/Common/ActiveFacility/O_activeFacility";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const DisplayTabs = React.memo((props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    clearChartKeys();
    setValue(newValue);
  };
  const [tabs, setTabs] = React.useState(
    props.tabs && props.tabs.length > 0 ? props.tabs : []
  );
  return (
    <div className={classes.root}>
      {/* TODO: Below line has no sense, to be removed */}
      {tabs && tabs.length > 0 ? tabs.map((item, index) => {}) : ""}
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs && tabs.length > 0
            ? tabs.map((item, index) => {
                return (
                  <Tab key={index} label={item.title} {...a11yProps(index)} />
                );
              })
            : ""}
        </Tabs>
      </AppBar>
      {tabs && tabs.length > 0
        ? tabs.map((item, index) => {
            return (
              <Box>
                {item &&
                item.content &&
                item.content.type &&
                item.content.type == "dealStatus" ? (
                  <O_DealStatus data={props.data} />
                ) : (
                  ""
                )}
                <TabPanel key={index} value={value} index={index}>
                  {item && item.content && item.content.sections ? (
                    <DisplaySections sections={item.content.sections} />
                  ) : (
                    ""
                  )}
                  {item && item.content && item.content.fields ? (
                    <ViewFields fields={item.content.fields} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == "chart" ? (
                    <M_ChartCard
                      content={
                        item.content.content && item.content.content.chart
                          ? {
                              ...item.content.content.chart,
                              chartId: item.content.content.chart.id,
                              id: undefined,
                            }
                          : {}
                      }
                    />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == "maps" ? (
                    <M_MapCard content={item.content.content} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == "resultTemplate" ? (
                    <M_ResultCard content={item.content.content} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == "chartEntry" ? (
                    <O_ChartDataEntry chart={item.content.content.chart} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == "dealStatus" &&
                  props.data &&
                  props.data.currentStatus &&
                  props.data.currentStatus.toLowerCase() !== "completion" ? (
                    <O_Guidlines data={props.data} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == "dealStatus" &&
                  props.data &&
                  props.data.currentStatus &&
                  props.data.currentStatus.toLowerCase() === "completion" ? (
                    <O_PostCheckList
                      config={item.content.content ? item.content.content : {}}
                      data={props.data}
                    />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == "activeFacility" ? (
                    <O_activeFacility data={props.data} />
                  ) : (
                    ""
                  )}
                  {item &&
                  item.content &&
                  item.content.type &&
                  item.content.type == "lendersApproached" ? (
                    <O_lendersApproached data={props.data} />
                  ) : (
                    ""
                  )}
                </TabPanel>
              </Box>
            );
          })
        : ""}
    </div>
  );
});
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
  };
}
export default connect(mapStateToProps, undefined)(DisplayTabs);
