import React, { useEffect, useState } from "react";
import { Box, List, Typography, Link, Grid, AppBar, Tabs, Tab, TableCell, TableRow, Table, TableBody } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { httpPost, httpDelete } from "../../../../../Utils/apis/apis";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import A_Chip from "../../../Atoms/DataDisplay/Chips/A_Chip";
import { getFormattedDate } from "../../../../../Utils/Helpers/dateUtils";
import { Button } from "@material-ui/core";
import { Popover } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { useParams } from "react-router";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_IconButton from "../../../Atoms/Inputs/Buttons/A_IconButton";
import EditIcon from "@material-ui/icons/Edit";
import M_ManageEntityForm from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm";

const useStyles = makeStyles({
  ircHead: {
    width: "200%",
    display: "flex",
    justifyContent: "space-between",
    padding: "1.4rem",
    alignItems: "center",
  },
  itemCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonMargin: {
    margin: "1.2rem",
  },
  innerBox: {
    padding: "0.3rem",
    marginRight: "1rem",
  },
  link: {
    color: "primary",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  tablerow : {
    maxWidth : "100%"
  },
  border : {
    border : 1,
    borderBlockColor : 'black'
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function O_LendersApproached(props) {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let { crmId } = useParams();
  const [handleChange, setHandleChange] = useState(true);
  const [data, setData] = React.useState([]);
  const [currentLenderData, setCurrentLenderData] = React.useState({});
  const [openEditForm, setOpenEditform] = useState(false);
  const [entity, setEntity] = useState({});

  const handleEdit = (item, templateIDs) => {
    const templateID = item.approval && item.approval === "Approved" ?  templateIDs[0]: templateIDs[1];
    const NewData = { templateId: templateID, recordId: item._partitionKey};
    setOpenEditform(!openEditForm);
    setEntity(NewData);
  }

  const handleCloseForm = ()=>{
    setOpenEditform(false);
    setEntity(false);
}

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  React.useEffect(() => {
    apiCall();
  }, [handleChange]);

  const apiCall = async () => {
    let convertedFormData = {
      sourceId: crmId,
      sourceLabel: "deal",
      label: "lendersapproached",
      fields: [],
    };
    const response = await httpPost(
      `/CRMChartData/getGenericCRMData`,
      convertedFormData
    );
    setData(response);
    setCurrentLenderData(response ? response[0] : {});
  };

  const renderConfirmPopup = (id) => {
    return (
      <div>
        <div style={{ paddingTop: "20px" }}>
          <A_Button
            onClick={() => {
              handleDelete();
              handlePopup();
            }}
            color="primary"
            label="Yes"
          />
          <A_Button onClick={handlePopup} color="default" label="Close" />
        </div>
      </div>
    );
  };
  let handlePopup = () => {
    let data = !isOpen;
    setIsOpen(data);
  };
  const handleDelete = async () => {
    const response = await httpDelete(
      `/CRMData/delete?pageUrl=deal&crmId=${selectedId}&softDelete=true`
    );
    setHandleChange(!handleChange);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const changeLenderData = (item) => {
    setCurrentLenderData(item);
  }
  return (
    <>
    <Grid container spacing = {2}>
    <Grid item xs={10}>
    
    <Grid container spacing={2}>
      <Grid item xs = {5}>
      <Box>
      {data && data.length > 0
        ? data.map((item, index) => {
            return (
              <>
                <Box className={classes.itemCenter}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box className={classes.innerBox}>
                      <AccountBalanceIcon color="primary" />
                    </Box>

                    <Box>
                      <Typography style={{color : "#0288d1", cursor: "pointer"}} className={classes.link} onClick = {() => changeLenderData(item)}>{item.lender}</Typography>
                      {item.approval && item.approval === 'Approved'? (
                        <Typography
                          style={{ color: "grey", fontSize: "0.8rem" }}
                        >
                          Approved
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box style={{ padding: "0.8rem", marginLeft: "0.8rem" }}>
                      {item.approval && item.approval === 'Approved' ? (
                        <A_Chip label="Active" color="primary" size="small" />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} maxWidth={"40%"}>
                    <Box>
                      <Typography style={{ fontSize: "0.9rem" }}>
                        {getFormattedDate(item.dateshown, "MMM-YYYY")}
                      </Typography>
                    </Box>

                    <IconButton
                      aria-describedby={id}
                      variant="contained"
                      onClick={(event) => {
                        handleClick(event, item._partitionKey);
                      }}
                    >
                      <MoreVert></MoreVert>
                    </IconButton>
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Button
                        className={classes.buttonMargin}
                        onClick={() => {
                          handlePopup();
                          handleClose();
                        }}
                      >
                        Delete
                      </Button>
                    </Popover>
                    <A_SimpleDialog
                      open={isOpen}
                      title="Are you sure you want to delete?"
                      fullScreen={false}
                      height="400px"
                      fullwidth={true}
                      maxWidth={"xs"}
                      onClose={handlePopup}
                      closePopUp={handlePopup}
                      dialogContent={renderConfirmPopup()}
                    />
                  </Box>
                </Box>
                <Divider></Divider>
              </>
            );
          })
        : "We are sorry.No content found!!!!"}
      </Box>
      </Grid>
      <Grid item xs = {7}>
      <Grid container spacing={2}>
      <Box border={1} borderColor={'black'} >
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab key = {0} label = {'Facility'} {...a11yProps(0)}></Tab>
              <Tab key = {1} label = {'Documentation'} {...a11yProps(1)}></Tab>
              <Tab key = {2} label = {'General Undertakings'} {...a11yProps(2)}></Tab>
            </Tabs>
          </Box>
          <TabPanel value = {tabValue} index = {0}>
            <h5>{"Parties"}</h5>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{"Facility Manager"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.facilityManager}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{"Borrower"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.borrower}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>{"Lender 1"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.lender1}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>{"Lender 2"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.lender2}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <br />
            <h5>{"Payments"}</h5>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{"Facility Start Date"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.facilityStartDate}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{"Maturity"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.maturity}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>{"First Payment Date"}</TableCell>
                  <TableCell>{getFormattedDate(currentLenderData.firstPaymentDate, 'MMM-YYYY')}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>{"Payment Frequency(months)"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.paymentFrequency}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>{"Facility Manager"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.facilityManager}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <br />
            <h5>{"Facility Details"}</h5>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{"Commitment"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.commitment}</TableCell>
                </TableRow>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    overflow: "auto",
                  }}
                >
                  <Grid item xs={5}>
                    <Typography
                      className={classes.label}
                      variant="p"
                      style={{ fontWeight: "bold" }}
                    >
                      Commitment
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                      <div
                        dangerouslySetInnerHTML={{ __html: currentLenderData && currentLenderData.commitmentDetails }}
                      ></div>
                  </Grid>
                </Grid>
                <TableRow>
                  <TableCell>{"Currency"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.currency}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>{"Facility Type"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.facilityType}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>{"Advance Rate"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.advanceRate}</TableCell>
                </TableRow>

                
                <TableRow>
                  <TableCell>{"Fixed Floating"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.fixedFloating}</TableCell>
                </TableRow>
                
                <TableRow>
                  <TableCell>{"Benchmark Index"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.benchmarkIndex}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{"Interest Rate Notes"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.interestRateNotes}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{"Margin"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.margin}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{"Financial Covenants"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.prePaymentProvisions}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{"assignment"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.Assignment}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{"Guarantee"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.guarantee}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{"Information Undertakings"}</TableCell>
                  <TableCell>{currentLenderData && currentLenderData.informationUndertakings}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value = {tabValue} index = {1}>
            <Table>
              <TableRow>
                    <TableCell>{"Date Shown"}</TableCell>
                    <TableCell>{getFormattedDate(currentLenderData.dateshown, 'MMM-YYYY')}</TableCell>
              </TableRow>

              <TableRow>
                    <TableCell>{"Term Sheet"}</TableCell>
                    <TableCell>{currentLenderData && currentLenderData.termSheet}</TableCell>
              </TableRow>

              <TableRow>
                    <TableCell>{"SFA Document"}</TableCell>
                    <TableCell>{currentLenderData && currentLenderData.sfaDocument}</TableCell>
              </TableRow>

              <TableRow>
                    <TableCell>{"Appetite/Amount"}</TableCell>
                    <TableCell>{currentLenderData && currentLenderData.appetiteAmount}</TableCell>
              </TableRow>

              <TableRow>
                    <TableCell>{"Leverage/Pricing"}</TableCell>
                    <TableCell>{currentLenderData && currentLenderData.leveragePricing}</TableCell>
              </TableRow>

              <TableRow>
                    <TableCell>{"Other Conditions"}</TableCell>
                    <TableCell>{currentLenderData && currentLenderData.otherConditions}</TableCell>
              </TableRow>
            </Table>
            <br />
           <h5>{"Status"}</h5>
           <Table>
              <TableRow>
                    <TableCell>{"NDA"}</TableCell>
                    <TableCell>{currentLenderData && currentLenderData.nda}</TableCell>
              </TableRow>

              <TableRow>
                    <TableCell>{"Approval"}</TableCell>
                    <TableCell>{currentLenderData && currentLenderData.approval}</TableCell>
              </TableRow>

              <TableRow>
                    <TableCell>{"Documentation"}</TableCell>
                    <TableCell>{currentLenderData && currentLenderData.documentation}</TableCell>
              </TableRow>
           </Table>
          </TabPanel>
          <TabPanel value = {tabValue} index = {2}>
            <Table>
              <TableRow>
                <TableCell>{"General Documenatation"}</TableCell>
                <TableCell>
                  <div
                  dangerouslySetInnerHTML={{ __html: currentLenderData && currentLenderData.generalDocumentation }}>
                </div>
                  </TableCell>

              </TableRow>
            </Table>
          </TabPanel>
      </Box>
      </Grid>
      </Grid>
    </Grid>
    </Grid>
    <Grid item xs={2}>
    <Box>
        <A_IconButton
          icon={<EditIcon />}
          onClick={() => handleEdit(currentLenderData, ["e4444c29-a8a4-4b28-8caf-15c34a1d43c3", "f2bdbb26-2d94-4c69-a96f-c09b6022f6bf"])}
        /> 
    </Box>
    </Grid>
    </Grid>
    {openEditForm && (
      <M_ManageEntityForm
      crmData={currentLenderData}
      isEditable={true}
      entity={entity}
      open= {openEditForm}
      editChart={true}
      onClose={()=>handleCloseForm()}
      />
    )}
    </>

  );
}
