import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from "../../../../Themes/defaultTheme";

const A_CircularIndeternment = (props) => {
  return (
    <ThemeProvider theme={theme}>
       <CircularProgress color={props.color} size={props.size}/>
    </ThemeProvider>
  );
};
A_CircularIndeternment.defaultProps = {
  color: "primary",
  size:"30"
};

export default A_CircularIndeternment;
