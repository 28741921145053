import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as managePageActions from "../../../../../store/actions/managePageActions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { Alert, AlertTitle } from "@material-ui/lab";
import A_Button from "../../Inputs/Buttons/A_Button";
import M_FormRenderer from "../../../Molecules/Common/Renderers/M_FormRenderer";
import * as validators from "../../../../../Utils/Helpers/validations";
import { useParams } from "react-router";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import M_fileUpload from "../../../Molecules/Common/File Upload/M_FileUpload";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  rightbutton: {
    float: "right",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    padding: "0px 5px 10px 5px !important",
  },
  formsPanel: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  buttonsPanel: {
    paddingTop: "10px",
  },
}));

function getSteps(steps) {
  let stepsLabels = steps.map((step) => {
    return step.label;
  });
  return stepsLabels;
}

const A_HorizontalStepper = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(props.activeStep);

  const [skipped, setSkipped] = useState(new Set());
  const [validationSummary, setValidationSummary] = useState(undefined);
  const [invalidDate, setInvalidDate] = useState(false);
  const steps = getSteps(props.steps);
  var Editable = props.isEditable;
  let { crmId } = useParams();
  let fields = props.fields;
  let optionSets = props.optionSets;
  const showStepper = steps && steps.length > 1;
  const isStepOptional = (step) => {};

  const isDateValidOrNot = (inValidDate) => {
    setInvalidDate(inValidDate);
  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  async function handleDelete() {
    let convertedFormData = {
      crmid: crmId,
      pageUrl: pageUrl,
    };
  }
  const handleNext = () => {
    if (handleValidations() && !invalidDate) {
      let newSkipped = skipped;
      if (activeStep === steps.length - 1) {
        handleSave();
      } else {
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        props.onActiveStepChanged((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    props.onActiveStepChanged((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    console.log("handleReset:", activeStep);
    setActiveStep(0);
  };

  const getStepContent = (index, steps) => {
    let stepElements = [];
    let elementsProps = [];
    let contentRaw = steps[index];
    if (contentRaw) {
      if ("content" in contentRaw) {
        stepElements = contentRaw.content;
      } else if ("sections" in contentRaw) {
        stepElements = contentRaw.sections;
      } else if ("fields" in contentRaw) {
        stepElements = contentRaw.fields;
      }
      elementsProps = stepElements.map((element) => {
        let elementProps = {};
        let fieldProperties = fields.filter((field) => {
          return element.fieldId === field.id;
        })[0];

        if (fieldProperties !== undefined) {
          elementProps = { ...fieldProperties, ...element };
        } else {
          elementProps = element;
        }
        return elementProps;
      });
    }

    elementsProps.push({
      fieldType: "permissions",
    });

    return elementsProps;
  };
  const handleValidations = () => {
    let validations = {};
    let isValidated = true;
    let elements = getStepContent(activeStep, props.steps);

    for (let i = 0; i < elements.length; i++) {
      let currElement = elements[i];
      let currElementValue =
        props.formData === undefined
          ? props.formData
          : props.formData[currElement.id];
      //isRequired validations

      if (props.formData.uploadSuccess != true) {
        if (currElement.required) {
          if (!validators.isRequired(currElementValue)) {
            isValidated = false;
            validations[currElement.id] = {
              error: true,
              msg: "This field is required",
            };
          }
        } else {
          currElement.error = false;
          currElement.helperText = "";
        }
      }

      //field validations i.e. regex validations

      if (
        validators.isRequired(currElement.validations) &&
        validators.isRequired(currElementValue)
      ) {
        if (
          !validators.isRegexValidated(
            currElementValue,
            currElement.validations
          )
        ) {
          isValidated = false;
          validations[currElement.id] = {
            error: true,
            msg: "Please enter valid value",
          };
        }
      }
    }

    setValidationSummary(validations);
    return isValidated;
  };
  const handleSave = () => {
    props.onSave();
  };
  let { pageUrl } = useParams();
  return (
    <div className={classes.root}>
      {props.isError && (
        <Alert severity="error">
          <AlertTitle>{props.isError.name}</AlertTitle>
          <strong>Something went wrong</strong>
          <br />
          {props.isError.message}
        </Alert>
      )}
      {props.formSavedResponse && "id" in props.formSavedResponse && (
        <div>
          <Alert
            severity="success"
            action={
              <A_Button
                label="Go to the created record"
                className={classes.button}
                href={`/${
                  props.isEditable == true ? pageUrl : props.entity.pageUrl
                }/${props.formSavedResponse.id}`}
                variant="outlined"
                color="primary"
              ></A_Button>
            }
          >
            <AlertTitle>Success</AlertTitle>
            <strong>Record saved successfully</strong>
          </Alert>
        </div>
      )}

      {props.closeFormWarning && (
        <Alert
          severity="warning"
          action={
            <div>
              <A_Button
                label="Don't close form"
                onClick={props.onHandleWarningOkButton}
                className={classes.button}
                color="primary"
                variant="outlined"
              ></A_Button>
              <A_Button
                label="Close form anyway"
                color="secondary"
                onClick={props.onHandleWarningCloseButton}
                className={classes.button}
                variant="outlined"
              ></A_Button>
            </div>
          }
        >
          <AlertTitle>Warning</AlertTitle>
          <strong>
            Are you sure you want to close this form? You will loose progress so
            far!
          </strong>
        </Alert>
      )}

      {!props.formSavedResponse && activeStep < steps.length && (
        <div>
          {showStepper && (
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}

          <div className={classes.formsPanel}>
            <M_FormRenderer
              // securityRestriction={props.securityRestriction} // required for Temp level security permission
              crmData={props.crmData}
              isEditable={props.isEditable}
              entity={props.entity}
              getFormData={props.onFormDataChange}
              validationSummary={validationSummary}
              elements={getStepContent(activeStep, props.steps)}
              optionSets={optionSets}
              lastFormData={props.formData}
              activeStep={props.activeStep}
              security={props.security}
              isDateValidOrNot = {isDateValidOrNot}
            />

            <div className={classes.buttonsPanel}>
              <A_Button
                label="Back"
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              ></A_Button>
              {isStepOptional(activeStep) && (
                <A_Button
                  label="Skip"
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                ></A_Button>
              )}

              <A_Button
                label={activeStep === steps.length - 1 ? "Finish" : "Next"}
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={(classes.button, classes.rightbutton)}
              ></A_Button>
              {activeStep === steps.length - 1 &&
              Editable === true &&
              props.entity.templateId ==
                "40727291-1fae-41bf-9ce9-e88f022fd84f" ? (
                <A_Button
                  label="Delete"
                  variant="contained"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    marginRight: "10px",
                  }}
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                  className={(classes.button, classes.rightbutton)}
                ></A_Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    managePage: state.managePage,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(managePageActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(A_HorizontalStepper);
