import React, { useEffect, useState } from "react";
import * as httpCall from "../../../../../Utils/apis/apis";
import Avatar from "@material-ui/core/Avatar";
import Default_Avatar from "../../../../../Assets/Images/Defaults/defLogo.png";
import {
  Box,
  ListItem,
  ListItemText,
  Typography,
  List,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import "./D_Deals.scss";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  mutiTags: {
    width: "auto",
    padding: "0px 13px",
    height: "33px",
    borderRadius: "11px",
    marginRight: "6px",
    fontSize: "13px",
    color: "white",
    fontWeight: "500",
  },
  mutiTagsContailner: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    marginBottom: "12px",
    paddingLeft: "16px",
  },
  boxContainer: {
    marginBottom: "10px",
  },
}));

export default function O_Pipeline(props) {
  const classes = useStyles();
  const [dealData, setDealData] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.oidc.user);

  useEffect(() => {
    getDealData();
  }, []);
const getURL = (url)=>{
  let data = url.split("/")
  data[1] = "deal"
  return data.join("/")
}
  let getDealData = async (d) => {
    setIsLoading(true);
    if (
      props.content &&
      props.content.chart &&
      props.content.chart.parameters &&
      props.content.chart.parameters.dealStatus
    ) {
      let dealStatus = props.content.chart.parameters.dealStatus;

      let dealType = props && 
                    props.content && 
                    props.content.chart && 
                    props.content.chart.parameters && 
                    props.content.chart.parameters.dealType ? props.content.chart.parameters.dealType : "";
      const response = await httpCall.httpPost(
        `/CRMChartData/getDealWithStatus?dealType=${dealType}&dealStatus=${dealStatus}`
      );
      setIsLoading(false);
      let apiRes = await response;
      setDealData(apiRes ? apiRes : []);
    }
    setIsLoading(false);
  };
  const tagsBackgroundColor = ["#6c757d", "#17a2b8", "#28a745a1"];

  const imgLink = "";
  const itemSecond = {
    marginLeft: "10px",
  };

  return (
    <>
      {isLoading && (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      )}
      {dealData && dealData.length > 0
        ? dealData.map((item, index) => {
            return (
              <a
                href={getURL(item.url)}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <Box className={classes.boxContainer} key={item.id}>
                  <ListItem>
                    <ListItemText>
                      <Box style={{ display: "flex" }}>
                        <Box>
                          {/* <A_Avatar size="small" variant="square" src={imgLink}  />     */}
                          <DealLogo id={item.id} token={user.access_token} name={item.projectName} />
                        </Box>
                        <Box style={itemSecond}>
                          <Typography variant="h6">
                            {item.projectName}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ color: "#3a3a3aa3" }}
                          >
                            {item.projectName}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="dealsCustom">
                        <Typography
                          variant="body2"
                          style={{ color: "red !important" }}
                          dangerouslySetInnerHTML={{
                            __html: item.dealDescription,
                          }}
                        >
                          {/* {item.dealDescription} */}
                        </Typography>
                      </Box>
                    </ListItemText>
                  </ListItem>
                  <List className={classes.mutiTagsContailner}>
                    {item.originatedBy && (
                      <ListItem
                        className={classes.mutiTags}
                        style={{ backgroundColor: "#6c757d" }}
                      >
                        {item.originatedBy}
                      </ListItem>
                    )}
                    {item.country && (
                      <ListItem
                        className={classes.mutiTags}
                        style={{ backgroundColor: "#17a2b8" }}
                      >
                        {item.country}
                      </ListItem>
                    )}
                    {item.sectorGroup && (
                      <ListItem
                        className={classes.mutiTags}
                        style={{ backgroundColor: "#28a745a1" }}
                      >
                        {item.sectorGroup}
                      </ListItem>
                    )}
                    {/* {getTags(item.tags)} */}
                  </List>
                  {index !== dealData.length - 1 ? <Divider /> : ""}
                </Box>
              </a>
            );
          })
        : ""}
    </>
  );
}


const DealLogo = React.memo(function Logo(props) {
  const [logoImage, setLogoImage] =React.useState('')
  React.useEffect(() => {
    getImage()
  }, [props.id]);

  const getImage = () => {
    let subdomain =  new URL(window.location.href).hostname.split(".")[0]
    fetch(`${process.env.DOCUMENT_HELPER}`, {
      method: "GET",   
      headers: {
        filename: "profile_"+props.id,
        type: "image",
        "file-operation": "download",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.token,
      },
    })
      .then((response) => {  
        if (!response.ok) {
          throw Error(response.statusText);
        }     
        return response;
      })
      .then((success) => success.blob())
      .then((data) => {
        const imageAsUrl = URL.createObjectURL(data);
       setLogoImage(imageAsUrl);
      });
  };

  return(
    <Avatar
    alt={props.name}
    src={logoImage ? logoImage : Default_Avatar}
    style={{ width: "50px", height: "50px" }}
    variant="square"
  />
  );
});