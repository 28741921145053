import React, { useEffect, useState, useCallback } from "react";
import A_FormTextField from "../../../Atoms/Inputs/FormFields/A_FormTextField";
import A_FormSelectField from "../../../Atoms/Inputs/FormFields/A_FormSelectField";
import M_ChartDataEntry from "../../../Molecules/Common/ChartData/M_ChartDataEntry";
import * as httpCall from "../../../../../Utils/apis/apis";
import { convertToEntryMetrics } from "./ChartDataEntryMapper";
import { useParams } from "react-router";
import Skeleton from "@material-ui/lab/Skeleton";

const O_ChartDataEntry = (props) => {
  const [dataPoints, setDataPoints] = useState(undefined);
  const [chartConfig, setChartConfig] = useState(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { crmId } = useParams();
  if (props.chart.parameters.investment == "=crmId") {
    props.chart.parameters.investment = crmId;
  }
  const fetchChartCallback = useCallback(async () => {
    let chartApiData = await getChart(props.chart);
    
    const chartMetriPoints = convertToEntryMetrics(
      chartApiData.data,
      props.formName
    );
    setDataPoints(chartMetriPoints);
    setIsLoading(false);
  }, [chartConfig]);

  useEffect(() => {
    setChartConfig({
      investmentId: props.chart.parameters.investment,
      chartId: "e0e29ad9-cebe-4aa4-815f-5eb17e787ed5",
      assetId: props.chart.parameters.investment,
      date: "9999-12-31",
      seriesName: "Actual",
    });
  }, []);
  useEffect(() => {
    fetchChartCallback();
  }, [fetchChartCallback]);

    //commented code maight be required for future use
  // const getStaticChart = async () => {
  //   const request = {
  //     chartId: chartConfig.chartId,
  //     parameters: {
  //       dt: chartConfig.date,
  //       investment: chartConfig.investmentId,
  //     },
  //   };
  //   return await getChart(request);
  // };
  const updateChartData = async (updatedRows) => {
    setIsSaving(true);
    let url = `${process.env.PortalAdminAPIUrl}/api/ManualUpdate/approval-data/insert`;
    const requetPayload = updatedRows.map((item) => {
      return {
        investmentId: chartConfig.investmentId,
        assetId: chartConfig.assetId,
        ...item,
      };
    });
    try {
      await httpCall.httpPost(url, requetPayload);
    } finally {
      setIsSaving(false);
    }
  };

  const getChart = async (chartRequest) => {
    const url = "/ChartData/getchartdata";
    const requestPayload = chartRequest;
    const result = await httpCall.httpPost(url, requestPayload);
    return result;
  };

  const onSaveUpdates = (updatedRows) => {
    updateChartData(updatedRows);
    //console.log(updatedRows);
  };
  const onCancelUpdates = () => {
    fetchChartCallback();
  };

  return (
    <div>
      {chartConfig && dataPoints ? (
        <M_ChartDataEntry
          data={dataPoints}
          onSaveUpdates={onSaveUpdates}
          isSaving={isSaving}
          onCancelUpdates={onCancelUpdates}
          seriesName={chartConfig.seriesName}
        />
      ) : isLoading ? (<>
        <Skeleton variant="text" width={"100%"} height={80}/>
        <Skeleton variant="text" width={"100%"} height={80}/>
        <Skeleton variant="text" width={"100%"} height={80}/>
        </>):(
        <p>WE ARE SORRY, NO CONTENT FOUND!</p>)}
    </div>
  );
};

export default O_ChartDataEntry;
