import { showField } from "../../../../../Utils/Helpers/common";
export const handleShowHide = (cards, crmDataForShow) => {
  let visibleCards =
    cards && cards.length > 0
      ? cards.filter((card) => {
          if (
            !card.show ||
            (card.show && showField(card.show, crmDataForShow))
          ) {
            return card;
          }
        })
      : [];

  let visibleCardContent = visibleCards.map((card) => {
    if ("tabs" in card.content.content) {
      let visibleTabs =
        cards && cards.length > 0
          ? card.content.content.tabs.filter((element) => {
              if (
                !element.show ||
                (element.show && showField(element.show, crmDataForShow))
              ) {
                return element;
              }
            })
          : [];
      visibleTabs.map((tab) => {
        if ("fields" in tab.content) {
          let visibleTabFields =
            visibleTabs && visibleTabs.length > 0
              ? tab.content.fields.filter((element) => {
                  if (
                    !element.show ||
                    (element.show && showField(element.show, crmDataForShow))
                  ) {
                    return element;
                  }
                })
              : [];
          tab.content.fields = visibleTabFields;
        }
      });
      card.content.content.tabs = visibleTabs;
    }
    if ("fields" in card.content.content) {
      let visibleFields =
        cards && cards.length > 0
          ? card.content.content.fields.filter((element) => {
              if (
                !element.show ||
                (element.show && showField(element.show, crmDataForShow))
              ) {
                return element;
              }
            })
          : [];
      card.content.content.fields = visibleFields;
    }
    return card;
  });
  return visibleCardContent;
};
export const templateToShow = (currentPage) => {
  let visibleTemplates = currentPage.templates.filter((template) => {
    if (template.templateJson.length > 0) {
      let templateConfig = JSON.parse(template.templateJson);
      if (
        template.templateType === "view" &&
        (!templateConfig.show ||
          (templateConfig.show &&
            showField(templateConfig.show, currentPage.data)))
      ) {
        return template;
      }
    } else {
      return template;
    }
  });

  return visibleTemplates;
};
