import React, { useState, useEffect } from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const M_CardFilter = (props) => {
  const [year, setYear] = React.useState("");
  const handleChange = (event) => {
    setYear(event.target.value);
  };

  return (
    <FormControl style={{ height: "1rem", marginLeft: "5px" }}>
      <InputLabel
        style={{
          color: "black",
          fontSize: "1.2rem",
          fontWeight: "450",
          marginBottom: "20px",
        }}
      >
        {props.title}
      </InputLabel>
      <NativeSelect
        style={{
          width: "150px",
          border: "1px solid gray",
          borderRadius: "4px",
          paddingLeft: "10px",
        }}
        value={year}
        onChange={handleChange}
      >
        {props.data.map((options) => (
          <option value={options.label}>{options.label}</option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default M_CardFilter;
