import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../../utils/oidc/userManager";
import * as httpCall from "../../../Utils/apis/apis";
import defaultMinervaTheme from "../../Themes/defaultMinervaTheme";
import { getHttpConfig } from "../../../Utils/Helpers/common";
import { useHistory } from "react-router-dom";

const CallbackPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const getTheme = async (user) => {
    setIsLoading(true);

    let config = {
      headers: {
        Authorization: "Bearer " + null,
      },
    };
    if (user) {
      config = {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      };
    }
    let redirectUrl = null;

    //user validation for subdomain
    let apiUrl = `/common/validsubdomainuser`;
    const result = await httpCall.httpGet(apiUrl, config);
    if (result) {
      if (user.state.pathname != "") {
        redirectUrl = user.state.pathname;
      } else {
        apiUrl = "/common/getDefaultRedirectURLForUser";
        redirectUrl = await httpCall.httpGet(apiUrl, config);
      }
    } else {
      redirectUrl = "/securelogout";
    }

    //get colour scheme
    apiUrl = "/ColourScheme";
    let colourSchemeResult = await httpCall.httpGet(apiUrl, config);
    if ("message" in colourSchemeResult) {
      const defaultSerializedTheme = JSON.stringify(defaultMinervaTheme);
      localStorage.setItem("minervaTheme", defaultSerializedTheme);
    } else {
      const serializedTheme = JSON.stringify(colourSchemeResult);
      localStorage.setItem("minervaTheme", serializedTheme);
    }
    setIsLoading(false);
    history.push(redirectUrl ? redirectUrl : "/dashboard");
  };
  const successCallback = (user) => {
    getTheme(user);
  };

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={(error) => {
        console.error(error);
        //props.dispatch(push("/"));
      }}
    >
      <div>
        {isLoading && "Please wait while setting up minerva for you."}
        {!isLoading && "Redirecting..."}
      </div>
    </CallbackComponent>
  );
};
function mapStateToProps(state, ownProps) {
  return state;
}
export default connect(mapStateToProps)(CallbackPage);
