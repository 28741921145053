import React from "react";
import PropTypes, { nominalTypeHack } from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import theme from "../../../../themes/defaultTheme";
import { withStyles } from "@material-ui/core/styles";
import * as common from "../../../../../Utils/Helpers/common";
import { getFormattedDate } from "../../../../../Utils/Helpers/dateUtils";
import TableFunction from "../Tables/TableFunction";
import Rag from "../../../Molecules/Common/Rag/Rag";
import A_Button from "../../Inputs/Buttons/A_Button";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { useSelector } from "react-redux";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}
const getBorderGroupBased = (groups, index, isDimention, isHeader) => {
  if (
    isDimention &&
    groups &&
    groups.length > 0 &&
    groups[0] &&
    groups[0] == 1
  ) {
    return "1px solid rgba(224, 224, 224, 1)";
  } else if (
    !isDimention &&
    groups &&
    groups.length > 0 &&
    groups.find((element) => element == index + 1)
  ) {
    return "1px solid rgba(224, 224, 224, 1)";
  } else {
    return null;
  }
};
const getStyleBasedOnColumType = (config, rowDataType = null) => {
  if (config && config.type) {
    let type = rowDataType ? rowDataType : config["type"];
    switch (type) {
      case "text":
        return { textAlign: "left" };
      case "number":
        return { textAlign: "right" };
      case "percentage":
        return { textAlign: "right" };
      case "Percentage":
        return { textAlign: "right" };
      case "multiple":
        return { textAlign: "right" };
      case "date":
        return { textAlign: "center" };
      case "Accounting":
        return { textAlign: "right" };
      case "financial":
        return { textAlign: "right" };
      default:
        break;
    }
  }
};
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    col,
    action,
    allowSelection,
    dimensionName,
    dimensionConfig,
    Groups,
    groupLabel,
    groupArr,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const renderColumnGroups = (Groups) => {
    return (
      <TableRow>
        {allowSelection &&
        (allowSelection.selectRow || allowSelection.selectAll) ? (
          <TableHeaderCell align="left" colSpan={1}></TableHeaderCell>
        ) : (
          ""
        )}
        {dimensionConfig && groupLabel ? (
          <TableHeaderCell align="left" colSpan={1}>
            {groupLabel ? groupLabel : ""}
          </TableHeaderCell>
        ) : (
          ""
        )}
        {Groups.map((item) => {
          return (
            <TableHeaderCell
              align={item.span > 1 ? "center" : "center"}
              colSpan={item.span}
            >
              {item.label}
            </TableHeaderCell>
          );
        })}

        {action && (action.edit || action.delete) ? (
          <TableHeaderCell></TableHeaderCell>
        ) : (
          ""
        )}
      </TableRow>
    );
  };
  const renderSelectAllCheckBox = () => {
    return allowSelection &&
      (allowSelection.selectRow || allowSelection.selectAll) ? (
      <TableHeaderCell>
        {allowSelection.selectAll ? (
          <div className={classes.checkCustom}>
            <Checkbox
              color="primary"
              style={{ padding: "0px", margin: "0px" }}
              iconStyle={{ fill: "white" }}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </div>
        ) : (
          ""
        )}
      </TableHeaderCell>
    ) : (
      ""
    );
  };
  const renderDimentionHeaderLabel = () => {
    if (dimensionConfig && dimensionConfig.hide) {
      return "";
    }
    const { groupArr } = props;
    let borderR = "";
    borderR = getBorderGroupBased(groupArr, 1, "dimention");
    return dimensionName ? (
      <TableHeaderCell
        style={{ borderRight: borderR }}
        key={
          dimensionConfig && dimensionConfig.label
            ? dimensionConfig.label
            : dimensionName
        }
      >
        {dimensionConfig && dimensionConfig.label
          ? dimensionConfig.label
          : dimensionName}
      </TableHeaderCell>
    ) : (
      ""
    );
  };

  const renderColumHeader = (props) => {
    const { groupArr } = props;

    const sortArrbasedOnCategory = (col, props) => {
      return props.resultData &&
        props.resultData.categories &&
        props.resultData.categories.length > 0
        ? props.resultData.categories.filter((item) => col[item])
        : [];
    };
    return col
      ? sortArrbasedOnCategory(col, props).map((headCell, index) => {
          let borderR = "";
          borderR = getBorderGroupBased(groupArr, index + 1, null);
          return !col[headCell].hide ? (
            col[headCell].sorting ? (
              <TableHeaderCell
                key={col[headCell].id}
                sortDirection={orderBy === col[headCell].id ? order : false}
                style={{
                  ...getStyleBasedOnColumType(col[headCell]),
                  borderRight: borderR,
                }}
              >
                <TableSortLabel
                  active={orderBy === col[headCell].id}
                  direction={orderBy === col[headCell].id ? order : "asc"}
                  onClick={createSortHandler(col[headCell].id)}
                >
                  {col[headCell].label}
                  {orderBy === col[headCell].id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableHeaderCell>
            ) : (
              <TableHeaderCell
                key={col[headCell].id}
                style={{
                  ...getStyleBasedOnColumType(col[headCell]),
                  borderRight: borderR,
                }}
              >
                {col[headCell].label}
              </TableHeaderCell>
            )
          ) : (
            ""
          );
        })
      : "";
  };

  const { chartTheme } = common.getTheme() ? common.getTheme() : {};
  const { TableChart } = chartTheme ? chartTheme : {};
  const { style } = TableChart && TableChart.header ? TableChart.header : {};

  const TableHeader = withStyles((theme) => ({
    root: {
      backgroundColor: style && style.background ? style.background : "",
    },
  }))(TableHead);

  const TableHeaderCell = withStyles((theme) => ({
    root: {
      color: style && style.color ? style.color : "",
    },
  }))(TableCell);

  return (
    <TableHeader>
      {Groups && Groups.length > 0 ? renderColumnGroups(Groups) : ""}

      <TableRow className={classes.checkBoxSelected}>
        {renderSelectAllCheckBox()}
        {renderDimentionHeaderLabel(props)}
        {renderColumHeader(props)}
        {action && (action.edit || action.delete) ? (
          <TableHeaderCell></TableHeaderCell>
        ) : (
          ""
        )}

        {/* 
          commenting below code as functions for the same has been created for cleaning code, 
          will remove commented code once functionalities for the same 
          verfied by other teams  */}

        {/* {
                (allowSelection) && (allowSelection.selectRow || allowSelection.selectAll) ? 
                    (<TableCell padding="checkbox">
                        {
                            allowSelection.selectAll ? (
                              <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                              />
                            ) : ""
                        }
                      </TableCell>) :
                      ("")
              } */}

        {/* {dimensionName ? (
          <TableCell
            key={
              dimensionConfig && dimensionConfig.label
                ? dimensionConfig.label
                : dimensionName
            }
          >
            {dimensionConfig && dimensionConfig.label
              ? dimensionConfig.label
              : dimensionName}
          </TableCell>
        ) : (
          ""
        )} */}
        {/* 
        {col
          ? Object.keys(col).map((headCell) =>
            !col[headCell].hide ? (
              col[headCell].sorting ? (
                <TableCell
                  key={col[headCell].id}
                  sortDirection={orderBy === col[headCell].id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === col[headCell].id}
                    direction={orderBy === col[headCell].id ? order : "asc"}
                    onClick={createSortHandler(col[headCell].id)}
                  >
                    {col[headCell].label}
                    {orderBy === col[headCell].id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell key={col[headCell].id}>
                  {col[headCell].label}
                </TableCell>
              )
            ) : (
              ""
            )
          )
          : ""} */}
      </TableRow>
    </TableHeader>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,

  numSelected: PropTypes.number.isRequired,

  onRequestSort: PropTypes.func.isRequired,

  onSelectAllClick: PropTypes.func.isRequired,

  order: PropTypes.oneOf(["asc", "desc"]).isRequired,

  orderBy: PropTypes.string.isRequired,

  rowCount: PropTypes.number.isRequired,

  col: PropTypes.object.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),

    paddingRight: theme.spacing(1),
  },

  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,

          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,

          backgroundColor: theme.palette.secondary.dark,
        },

  title: {
    flex: "1 1 100%",

    marginTop: "30px",

    marginBottom: "20px",
  },

  searchBox: {
    height: "3rem",
    marginRight: "10px",
    marginLeft: "0%",
    minWidth: "22%",
    position: "relative",
    right: "11%",
  },

  formControl: {
    margin: theme.spacing(1),

    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  addButton: {
    marginRight: "0px",

    width: "30px",

    height: "30px",
  },

  selectBox: {
    margin: "10px",
    height: "3rem",
    float: "left",
  },

  checkBoxSelected: {
    // background: "none",
    // "&:hover": {
    //   backgroundColor: "none !important",
    // },
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  const { title } = props.config;
  const { searchable } = props.config;
  const { allowSelection } = props.config;
  const { addButton } = props.config;
  const { filters } = props.config;
  const { data } = props;

  const handleChangeSearch = (event) => {
    // return results
    props.onChange(event, data);
  };

  const handleOnChangeFilter = (e, node) => {
    let value = e.value;
    let key = node;
    props.onFilterChange(key, value, filters);
  };

  return (filters && filters.length > 0) || searchable || title || addButton ? (
    <Toolbar style={{ float: "right", minWidth: "90%" }}>
      <div style={{ minWidth: title ? "20%" : "10%" }}>
        <h3>{title}</h3>
      </div>
      <div
        style={{
          marginRight: "5%",
          display: "inline-flex",
          position: "relative",
          right: "12%",
        }}
      >
        {filters && filters.length > 0
          ? filters.map((item) => (
              <A_Select
                className={classes.selectBox}
                paramName={item.parameter}
                onChange={(e) => handleOnChangeFilter(e, item.parameter)}
                defaultValue={
                  props.parameters && props.parameters[item.parameter]
                    ? props.parameters[item.parameter]
                    : item.active
                }
                options={item.options}
              />
            ))
          : ""}
      </div>
      {searchable ? (
        <A_TextField
          className={classes.searchBox}
          onChange={handleChangeSearch}
          label=""
          type="search"
          variant="outlined"
          adornment={{ position: "start", value: <SearchIcon /> }}
        />
      ) : (
        ""
      )}
      {addButton ? (
        <AddIcon
          id="addButton"
          className={classes.addButton}
          onClick={props.handleAddButtonClick}
        />
      ) : (
        ""
      )}
    </Toolbar>
  ) : (
    ""
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  checkCustom: {
    background: "white",
    padding: 0,
    margin: 0,
  },
  table: {
    // minWidth: "750",
    // marginBottom: "30px",
    // margin: "10px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  checkBoxSelected: {
    background: "transparent !important",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  total_row: {
    fontWeight: "bold",
    borderTop: "2px solid #888 !important",
    borderBottom: "2px solid #888 !important",
  },
  italic: {
    fontStyle: "italic",
  },
  bold: {
    fontWeight: "bold",
  },
  inactive: {
    opacity: "0.4",
  },
  indent: {
    paddingLeft: "30px !important",
  },
  indent1: {
    paddingLeft: "20px !important",
  },
  indent2: {
    paddingLeft: "40px !important",
  },
  indent3: {
    paddingLeft: "60px !important",
  },
  small: {
    fontSize: "85%",
  },
  small1: {
    fontSize: "75%",
  },
  small2: {
    fontSize: "65%",
  },
  densetable: {
    border: "0px",
    paddingTop: "3px",
    paddingBottom: "3px",
  },
  topBorder: {
    borderTop: "2px solid #888 !important",
    borderBottom: "none",
  },
}));

const checkDefaultColumnConfiguration = (columnConfig = {}, data) => {
  data && data.categories && data.categories.length > 0
    ? data.categories.map((item) => {
        if (!columnConfig[item]) {
          columnConfig[item] = {
            label: item,
            type: "text",
            sorting: false,
          };
        }
      })
    : "";

  return columnConfig;
};

const getGroupArr = (groups) => {
  let resArr = [];
  groups && groups.length > 0
    ? groups.reduce((previousValue, currentValue) => {
        currentValue && currentValue.span && parseInt(currentValue.span)
          ? resArr.push(previousValue + parseInt(currentValue.span))
          : null;
        return currentValue && currentValue.span && parseInt(currentValue.span)
          ? previousValue + parseInt(currentValue.span)
          : null;
      }, 0)
    : [];
  return resArr;
};
const A_TableChart = (props) => {
  const user = useSelector((state) => state.oidc.user);
  const classes = useStyles();
  const columnConfig =
    props.configurations && props.configurations.columnConfig
      ? checkDefaultColumnConfiguration(
          props.configurations.columnConfig,
          props.resultData
        )
      : checkDefaultColumnConfiguration(undefined, props.resultData);

  const { dimension } = props.configurations;
  const { configurations } = props;
  configurations["columnConfig"] = columnConfig;
  const { Groups } = props.configurations;
  const { groupLabel } = props.configurations;
  const { action } = props.configurations;
  const { allowSelection } = props.configurations;
  const { striped } = props.configurations;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(
    props.configurations && props.configurations.condensed
      ? props.configurations.condensed
      : false
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(
    configurations && configurations.paginate
      ? 5
      : props.data && props.data.length > 5
      ? props.data.length + 1
      : 5
  );
  const [data, setData] = React.useState(props.data);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  const dataOrignal = props.data;
  let dimensionName =
    props.resultData &&
    props.resultData.dimensions &&
    props.resultData.dimensions.length > 0
      ? props.resultData.dimensions[0]
      : "";

  let groupArr = getGroupArr(Groups);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((data, index) => index);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleUnselectAllClick = (event) => {
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSearchChange = (event) => {
    if (!event.target || !event.target.value || event.target.value == "") {
      setData(dataOrignal);
      return;
    }

    let valuSearch = event.target.value;
    let results = [];
    for (var i = 0; i < dataOrignal.length; i++) {
      for (let key in dataOrignal[i]) {
        if (
          dataOrignal[i][key].toLowerCase().indexOf(valuSearch.toLowerCase()) !=
          -1
        ) {
          results.push(dataOrignal[i]);
          break;
        }
      }
    }

    setData(results);
  };

  const loadChart = (event, config, data, row) => {
    let showDetail =
      props.configurations && props.configurations.showDetail
        ? props.configurations.showDetail
        : {};

    let cellConfiguration = config;
    props.handleClickableCell(event, cellConfiguration, showDetail, data, row);
  };
  const numberWithCommas = (x) => {
    if (x !== null) {
      let str = x.toString().split(".");
      str[0] = str[0] ? str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
      return str.join(".");
    } else {
      return x;
    }
  };
  const handlePositiveNumber = (num) => {
    if (typeof num == "number") {
      return Math.abs(num);
    } else if (
      typeof num == "string" &&
      num &&
      num.length > 0 &&
      num[0] == "-"
    ) {
      return num.substring(1);
    } else {
      return num;
    }
  };
  const getOffsetObject = (config) => {
    return config.varianceFormat &&
      config.varianceFormat.thresholds &&
      config.varianceFormat.thresholds.length > 0
      ? config.varianceFormat.thresholds
      : [0, 0];
  };

  const getColorObject = (config, props, row) => {
    let availableColor =
      config &&
      config.varianceFormat &&
      config.varianceFormat.thresholdsColor &&
      config.varianceFormat.thresholdsColor.length > 0
        ? config.varianceFormat.thresholdsColor
        : ["red", "#880", "green"];
    let currentRowConfiguration =
      props.configurations &&
      props.configurations.rowFormats &&
      props.configurations.rowFormats[row[dimensionName]]
        ? props.configurations.rowFormats[row[dimensionName]]
        : null;
    return currentRowConfiguration &&
      (currentRowConfiguration.inverse || currentRowConfiguration.parity)
      ? availableColor.reverse()
      : availableColor;
  };
  const getColorBasedOnConfigs = (res, offsetObject, offsetColorObject) => {
    let tempRes = null;
    if (res && !isNaN(res) && parseInt(res)) {
      tempRes = parseInt(res);
    }
    return tempRes && tempRes > offsetObject["1"]
      ? offsetColorObject["2"]
      : tempRes < offsetObject["0"]
      ? offsetColorObject["0"]
      : offsetColorObject["1"];
  };
  const validateNumber = (data) => {
    if (data && !isNaN(data) && typeof data == "string") {
      data = parseInt(data);
    }
    return data;
  };

  const columnDataHandle = (res, config, str = "") => {
    if (!res || res == null || res == "null") {
      return "";
    } else if (res && !isNaN(res) && parseInt(res) && parseInt(res) > 0) {
      return config.symbol ? config.symbol + res + str : res + str;
    } else if (res && !isNaN(res) && parseInt(res) && parseInt(res) < 0) {
      return config.symbol
        ? config.symbol + "(" + handlePositiveNumber(res) + str + ")"
        : "(" + handlePositiveNumber(res) + str + ")";
    } else if (res && !isNaN(res) && parseFloat(res) && parseFloat(res) < 0) {
      return config.symbol
        ? config.symbol + "(" + handlePositiveNumber(res) + str + ")"
        : "(" + handlePositiveNumber(res) + str + ")";
    } else if (res && !isNaN(res) && parseFloat(res) && parseFloat(res) > 0) {
      return config.symbol ? config.symbol + res + str : res + str;
    } else if (res == "0" || res == "-0") {
      return "0";
    } else {
      return res;
    }
  };

  const formatDataBasedOnType = (
    data,
    config,
    variance,
    row,
    rowType = null,
    rowDataConfig = null
  ) => {
    let configuration = config;
    let color = "";
    let type = rowType ? rowType : configuration["type"];
    let offsetObject = { ...getOffsetObject(config) };
    let offsetColorObject = { ...getColorObject(config, props, row) };
    let colorCell = config.variance ? true : false;
    if (rowDataConfig) {
      let { decimal } = rowDataConfig;
      config["decimal"] = decimal ? decimal : config["decimal"];
    }

    if (configuration && configuration.clickable) {
      return (
        <a
          onClick={(event) => loadChart(event, config, data, row)}
          href="javascript:void(0)"
        >
          {" "}
          {data}
        </a>
      );
    } else if (configuration && type == "text") {
      return data;
    } else if (configuration && type == "number") {
      data = validateNumber(data);
      let res =
        data && data.toFixed
          ? data.toFixed(config.decimal ? config.decimal : 0)
          : data;
      color =
        colorCell && offsetObject
          ? getColorBasedOnConfigs(res, offsetObject, offsetColorObject)
          : color;
      res = columnDataHandle(res, config);
      res = res ? res.toLocaleString("en-GB") : res;
      res = res ? numberWithCommas(res) : res;
      res = TableFunction(res);
      return <span style={{ color: color }}> {res}</span>;
    } else if (configuration && type == "multiple") {
      data = validateNumber(data);
      let res =
        data && data.toFixed
          ? data.toFixed(config.decimal ? config.decimal : 2)
          : data;

      color =
        colorCell && offsetObject
          ? getColorBasedOnConfigs(res, offsetObject, offsetColorObject)
          : color;
      res = res ? numberWithCommas(res) : res;
      res = res + "x";
      res = columnDataHandle(res, config);
      res = TableFunction(res);
      return <span style={{ color: color }}> {res}</span>;
    } else if (configuration && type == "date") {
      return getFormattedDate(data, config.format, "MMM-yy");
    } else if (
      configuration &&
      (type == "percentage" || type == "Percentage")
    ) {
      data = validateNumber(data);
      data = data ? data * (!config.scaling ? 100 : config.scaling) : data;
      let res =
        data && data.toFixed
          ? data.toFixed(config.decimal ? config.decimal : 1)
          : data;
      color =
        colorCell && offsetObject
          ? getColorBasedOnConfigs(res, offsetObject, offsetColorObject)
          : color;
      res = columnDataHandle(res, config, "%");
      res = numberWithCommas(res);
      res = TableFunction(res);

      return <span style={{ color: color }}> {res}</span>;
    } else if (configuration && type == "financial") {
      data = validateNumber(data);
      let res =
        data && data.toFixed
          ? data.toFixed(config.decimal ? config.decimal : 0)
          : data;
      color =
        colorCell && offsetObject
          ? getColorBasedOnConfigs(res, offsetObject, offsetColorObject)
          : color;
      res = columnDataHandle(res, config);
      res = res ? res.toLocaleString("en-GB") : res;
      res = res ? numberWithCommas(res) : res;
      res = TableFunction(res);
      return <span style={{ color: color }}> {res}</span>;
    } else if (configuration && type == "html"){
      return isHtmlTypeData(row);
    }else {
      return data;
    }
  };

  const isHtmlTypeData = (row) => {
    return (
      <div dangerouslySetInnerHTML={{__html : row && row.comments ? row.comments : ""}}></div>
    );
  };

  const isLinkTypeData = (row, configurations, key) => {
    return (
      <a href={row[configurations.columnConfig[key].linkName]}>{row[key]} </a>
    );
  };

  const ragsFun = (row, key) => {
    return (
      <Rag
        style={{
          color:
            row[key] === "Green"
              ? "green"
              : row[key] === "Red"
              ? "red"
              : row[key] === "Amber"
              ? "#FFBF00"
              : "",
          fontSize: 35,
        }}
      />
    );
  };

  const isHighlighted = (row, key, configuration) => {
    return (
      <>
        {configuration.condition && configuration.condition === "pastDate" ? (
          <>
            {row[key] &&
            new Date(row[key]) &&
            new Date(row[key]).setHours(0, 0, 0, 0) <
              new Date().setHours(0, 0, 0, 0) ? (
              <span
                style={{
                  background: configuration.hightlightColor,
                  color: "#fff",
                  padding: "5px",
                }}
              >
                {row[key]}
              </span>
            ) : (
              <span style={{ padding: "5px" }}>{row[key]}</span>
            )}
          </>
        ) : (
          <span
            style={{
              background: configuration.hightlightColor,
              color: "#fff",
              padding: "5px",
            }}
          >
            {row[key]}
          </span>
        )}
      </>
    );
  };

  const doneAndCloseIcons = (cellValue)=>{
    if(cellValue==='true' || cellValue===true) {
      return <DoneIcon />
    }else{
      return <CloseIcon />
    }
  }
  const renderPdf = (hasPdf) => {
    if (hasPdf && hasPdf != "") {
      return (

        <>
          <Button onClick={() => { onDownload(hasPdf) }}>
            <PictureAsPdf
            > </PictureAsPdf>
          </Button>
        </>
      )
    }
    else {
      return ("")
    }

  }
  const onDownload = async (fileName) => {
    fileName=fileName.split(",")[0];
    const url = `${process.env.DOCUMENT_HELPER}`;
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    let header = {
      filename: fileName,
      type: "invoice",
      "file-operation": "download",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    // const formData = { file: e.target.result };
    const response = await axios
      .post(url, "", { responseType: "blob", headers: header })
    const url1 = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url1;
    link.setAttribute("download", fileName + ".pdf");
    document.body.appendChild(link);
    link.click()
  };

  //nested Table column
  const isChildItem = (list) => {
    return list.map((item) => {
      return (
        <>
          <TableRow key={index}>
            <TableCell>{item.label}</TableCell>
            <br></br>
          </TableRow>
        </>
      );
    });
  };

  const renderDimention = (row, dimentionObj, name, rowclasstype, groups,cellBackgroundColor) => {
    if (dimentionObj && dimentionObj.hide) {
      return "";
    }
    let openInNewWindow = null;
    if (dimentionObj && dimentionObj["openInNewWindow"]) {
      openInNewWindow = dimentionObj["openInNewWindow"];
    }

    return Object.keys(row).map((key, index) => {
      if (key == name) {
        let border = "";
        border = getBorderGroupBased(groupArr, index, "dimention");
        let style =rowclasstype.split(",");
        const multipleStyle = style && style.map((item) => classes[item]);

        return (
          <TableCell
            style={{ borderRight: border, background : cellBackgroundColor }}
            className={
              rowclasstype
                ? dense
                  ? clsx(multipleStyle, classes.densetable)
                  : clsx(multipleStyle)
                : dense
                ? classes.densetable
                : ""
            }
          >
            {dimentionObj && dimentionObj.link ? (
              <a
                href={row[dimentionObj.link]}
                target={openInNewWindow ? "_blank" : ""}
              >
                {row[key]}
              </a>
            ) : (
              row[key]
            )}
          </TableCell>
        );
      }
    });
  };

  const renderPagingnation = () => {
    return configurations && configurations.paginate ? (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    ) : (
      ""
    );
  };

  const renderNoDataText = () => {
    return (
      <TableRow
        style={{
          height: (dense ? 33 : 53) * emptyRows,
        }}
      >
        <TableCell colSpan={6}>{configurations.noDataText}</TableCell>
      </TableRow>
    );
  };
  const renderCheckBoxRow = (isItemSelected, labelId, index) => {
    return allowSelection &&
      (allowSelection.selectRow || allowSelection.selectAll) ? (
      <TableCell padding="checkbox">
        {allowSelection.selectRow ? (
          <Checkbox
            color="primary"
            onClick={(event) => handleClick(event, index)}
            checked={isItemSelected}
            data-testid="cbShowHide"
            inputProps={{ "aria-labelledby": labelId }}
          />
        ) : (
          ""
        )}
      </TableCell>
    ) : (
      ""
    );
  };

  const getRowFormat = (rowConfig, row, name) => {
    if (row[name] && rowConfig[row[name]] && rowConfig[row[name]].classes) {
      return rowConfig[row[name]].classes;
    } else {
      return "";
    }
  };
  const getRowDataFormat = (rowConfig, row, name) => {
    if (row[name] && rowConfig[row[name]] && rowConfig[row[name]].type) {
      return rowConfig[row[name]].type;
    } else {
      return null;
    }
  };
  const getRowConfig = (rowConfig, row, name) => {
    if (row[name] && rowConfig[row[name]] && rowConfig[row[name]].type) {
      return rowConfig[row[name]];
    } else {
      return null;
    }
  };
  const getRowBreak = (rowConfig, row, name) => {
    if (row[name] && rowConfig[row[name]] && rowConfig[row[name]].rowBreak) {
      return true;
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    setData(dataOrignal);
  }, [dataOrignal]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            config={configurations}
            data={data}
            onChange={handleSearchChange}
            onFilterChange={props.onFilterChange}
            handleAddButtonClick={props.handleAddButtonClick}
            parameters={props.parameters}
          />

          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                resultData={props.resultData}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                col={columnConfig}
                action={action}
                Groups={Groups}
                groupLabel={groupLabel}
                allowSelection={allowSelection}
                dimensionName={dimensionName}
                dimensionConfig={dimension}
                groupArr={groupArr}
              />

              <TableBody>
                {data && data.length > 0
                  ? stableSort(data, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        let cellBackgroundColor = striped && index % 2 === 0 ? '#f9f9f9' : ""; 
                        const isItemSelected = isSelected(index);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        // Table column
                        const rowclasstype = getRowFormat(
                          props.configurations &&
                            props.configurations.rowFormats
                            ? props.configurations.rowFormats
                            : {},
                          row,
                          dimensionName
                        );
                        const rowDataType = getRowDataFormat(
                          props.configurations &&
                            props.configurations.rowFormats
                            ? props.configurations.rowFormats
                            : {},
                          row,
                          dimensionName
                        );
                        const rowDataConfig = getRowConfig(
                          props.configurations &&
                            props.configurations.rowFormats
                            ? props.configurations.rowFormats
                            : {},
                          row,
                          dimensionName
                        );
                        const rowBreak = getRowBreak(
                          props.configurations &&
                            props.configurations.rowFormats
                            ? props.configurations.rowFormats
                            : {},
                          row,
                          dimensionName
                        );

                        return (
                          <>
                            <TableRow
                              style={{ background : '#fafafa'  }}
                              className={classes.checkBoxSelected}
                              key={data.name}
                              tabIndex={-1}
                              aria-checked={isItemSelected}
                              rowclasstype={rowclasstype}
                            >
                              {/* select row check box  */}
                              {renderCheckBoxRow(
                                isItemSelected,
                                labelId,
                                index
                              )}

                              {/* render Dimention value  */}
                              {renderDimention(
                                row,
                                dimension,
                                dimensionName,
                                rowclasstype,
                                Groups,
                                cellBackgroundColor 
                              )}

                              {row
                                ? Object.keys(row).map((key, index) => {
                                    let borderR = "";
                                    borderR = getBorderGroupBased(
                                      groupArr,
                                      index,
                                      null
                                    );

                                    return configurations &&
                                      configurations.columnConfig &&
                                      configurations.columnConfig[key] &&
                                      !configurations.columnConfig[key].hide ? (
                                      <TableCell
                                        className={
                                          rowclasstype &&
                                          rowclasstype != "indent" &&
                                          rowclasstype != "indent2" &&
                                          rowclasstype != "indent3"
                                            ? dense
                                              ? [
                                                  classes[rowclasstype],
                                                  classes.densetable,
                                                ].join(" ")
                                              : classes[rowclasstype]
                                            : dense
                                            ? classes.densetable
                                            : {}
                                        }
                                        key={key}
                                        style={{
                                          ...(configurations.columnConfig[key]
                                            .style,
                                          getStyleBasedOnColumType(
                                            configurations.columnConfig[key],
                                            rowDataType
                                          )),
                                          borderRight: borderR,
                                          background : cellBackgroundColor
                                        }}
                                      >
                                        {configurations.columnConfig[key].link
                                          ? isLinkTypeData(
                                              row,
                                              configurations,
                                              key
                                            )
                                          : Array.isArray(row[key])
                                          ? isChildItem(row[key])
                                          : configurations.columnConfig[key] &&
                                            configurations.columnConfig[key]
                                              .ragsColor &&
                                            configurations.columnConfig[key]
                                              .ragsColor === true
                                          ? ragsFun(row, key)
                                          : configurations.columnConfig[key] &&
                                            configurations.columnConfig[key]
                                              .highlighted &&
                                            configurations.columnConfig[key]
                                              .highlighted === true
                                          ? isHighlighted(
                                              row,
                                              key,
                                              configurations.columnConfig[key]
                                            )
                                          : configurations.columnConfig[key] &&
                                            configurations.columnConfig[key]
                                              .doneIconView &&
                                            configurations.columnConfig[key]
                                              .doneIconView === true
                                          ? doneAndCloseIcons(row[key])
                                         : configurations.columnConfig[key] &&
                                           configurations.columnConfig[key]
                                              .pdfDownload ? renderPdf(row[key])
                                          : formatDataBasedOnType(
                                              row[key],
                                              configurations.columnConfig[key],
                                              configurations.columnConfig[key]
                                                .variance,
                                              row,
                                              rowDataType,
                                              rowDataConfig
                                            )}
                                      </TableCell>
                                    ) : (
                                      ""
                                    );
                                  })
                                : ""}

                              {action && (action.edit || action.delete) ? (
                                <TableCell
                                  align="left"
                                  style={{ display: "flex" }}
                                >
                                  {action.edit ? (
                                    <IconButton>
                                      <EditIcon
                                        key={row.id}
                                        id={row.id}
                                        onClick={props.handleEditButtonClick}
                                      />
                                    </IconButton>
                                  ) : (
                                    ""
                                  )}

                                  {action.delete ? (
                                    <IconButton>
                                      <DeleteIcon
                                        key={index}
                                        id={row.id}
                                        onClick={props.handleDeleteButtonClick}
                                      />
                                    </IconButton>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>
                            {rowBreak && <br />}
                          </>
                        );
                      })
                  : renderNoDataText()}
              </TableBody>
            </Table>
          </TableContainer>
          {renderPagingnation()}

          {/* {configurations && configurations.paginate ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : (
            ""
          )} */}
          {allowSelection &&
          (allowSelection.selectRow || allowSelection.selectAll) ? (
            <A_Button
              label="Mark as paid"
              color="primary"
              onClick={handleUnselectAllClick}
            />
          ) : (
            ""
          )}
        </Paper>
      </div>
    </ThemeProvider>
  );
};

A_TableChart.defaultProps = {
  configurations: {
    title: "Heading",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: true,
    striped: true,
    allowSelection: {
      selectRow: true,
      selectAll: true,
    },
    groupLabel: "test",
    Groups: [
      {
        label: "test",
        span: "2",
      },
      {
        label: "test 1",
        span: "1",
      },
    ],
    columnConfig: {
      uname: {
        id: "uname",
        label: "User Name",
        sorting: false,
        hide: false,
      },

      ContactNo: {
        id: "ContactNo",
        label: "Contact No",
        sorting: true,
        hide: false,
      },

      email: {
        id: "email",
        label: "Email",
        sorting: true,
        hide: false,
      },
    },
  },
  data: [],
};

export default A_TableChart;
