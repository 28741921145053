import React from "react";
import PropTypes from "prop-types";
import { log_error } from "../../../../../utils/logger/logger";
import "./ErrorBoundary.scss";
import A_Card from "../../../atoms/surfaces/cards/A_Card";
import A_Button from "../../../atoms/inputs/buttons/A_Button";
import MailIcon from "@material-ui/icons/Mail";
import { Box, Typography } from "@material-ui/core";
import { ERROR_REPORTING_MAIL } from "../../../../../Utils/config/config";

export default class ErrorBoundary extends React.Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false,
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo, error });
  }

  handleRefresh = () => {
    window.location = `mailto:${ERROR_REPORTING_MAIL}`;
  };
  handleErrorReporting = () => {
    log_error(
      this.state.error.message.toString(),
      this.state.errorInfo.componentStack.toString()
    );
  };
  render() {
    const { hasError, errorInfo } = this.state;
    const ErrrText = "There was a problem loading this data";

    if (hasError) {
      const ErrorDetail = () => {
        if (
          process.env.ENVIRONMENT.toLowerCase() === "prod" ||
          process.env.ENVIRONMENT.toLowerCase() === "uat"
        ) {
          return <Typography variant="h6">{ErrrText}</Typography>;
        }
        return (
          <React.Fragment>
            <b className="error-message">{this.state.error.message}</b>
            <br />

            <span className="error-detail">
              {errorInfo && errorInfo.componentStack.toString()}
            </span>
            <br />
          </React.Fragment>
        );
      };
      const ErrorActions = () => {
        return (
          <React.Fragment>
            <A_Button
              label="Report this issue"
              color="primary"
              variant="outlined"
              style={{ border: "none" }}
              size="V"
              startIcon={<MailIcon />}
              onClick={this.handleRefresh}
            />
          </React.Fragment>
        );
      };
      return (
        <Box
          style={{
            position: "inherit",
            color: "rgb(64 56 84 / 94%)",
            textAlign: "center",
            marginTop: "100px",
          }}
        >
          <ErrorDetail />
          <ErrorActions />
        </Box>
      );
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
