import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { httpPost } from "../../../../../Utils/apis/apis";

const useStyles = makeStyles((theme) => ({
  container: {},
  left: {
    float: "left",
    width: "80%",
  },
  right: {
    float: "right",
  },
  hideSchedule: {
    display: "none",
  },
  alignSchedule: {
    marginTop: "12px",
  },
}));

export default function O_activeFacility(props) {
  const classes = useStyles();
  let { crmId } = useParams();
  const [data, setData] = useState({});

  const [call, setCall] = useState(true);
  React.useEffect(() => {
    apiCall();
  }, []);

  const apiCall = async () => {
    let convertedFormData = {
      sourceId: crmId,
      sourceLabel: "deal",
      label: "lendersapproached",
      fields: [],
    };

    const response = await httpPost(
      `/CRMChartData/getGenericCRMData`,
      convertedFormData
    );
    let filtered = response.filter((item) => item.approval === "Approved");
    if (filtered.length > 0) {
      setData(filtered[0]);
    }
  };

  return (
    <>
      <section className={classes.container}>
        <h5>Parties</h5>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Borrower
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {data.borrower ? data.borrower : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Guarantor
            </Typography>
          </Grid>

          <Grid item xs={7}>
            {data.guarantor ? data.guarantor : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Lender(s)
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.lenders ? data.lenders : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            overflow: "auto",
          }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Commitment
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.commitmentDetails ? (
              <div
                dangerouslySetInnerHTML={{ __html: data.commitmentDetails }}
              ></div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Divider></Divider>
        <br />
        <h5>Details</h5>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Facility Start Date
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.facilityStartDate ? data.facilityStartDate : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Maturity
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.maturity ? data.maturity : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Fixed/Floating
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.fixedFloating ? data.fixedFloating : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Benchmark Index
            </Typography>
          </Grid>
          <Divider></Divider>
          <Grid item xs={7}>
            {data.benchmarkIndex ? data.benchmarkIndex : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Payment Frequency
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.paymentFrequency ? data.paymentFrequency : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          <Grid item xs={5}>
            <Typography
              className={classes.label}
              variant="p"
              style={{ fontWeight: "bold" }}
            >
              Next Payment Date
            </Typography>
          </Grid>
          <Grid item xs={7}>
            {data.nextPaymentDate ? data.nextPaymentDate : ""}
          </Grid>
        </Grid>
        <Divider></Divider>
      </section>
    </>
  );
}
