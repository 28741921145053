import React from "react";
import { connect } from "react-redux";
import userManager from "../../../../utils/oidc/userManager";
import {
  loadSubscriptionsStart,
  loadSubscriptionsSuccess,
} from "../../../../Actions";
import { withTranslation } from "react-i18next";
class MainPage extends React.Component {
  // load the subscriptions
  componentWillMount() {
    this.props.dispatch(loadSubscriptionsStart());
  }

  render() {
    const { user, channels } = this.props;
    const { t: translate } = this.props;
    return (
      <div style={{ marginTop: "80px", marginLeft: "30px" }}>
        {user && (
          <div>
            <h3>
              {translate("Welcome")},{" "}
              {user ? user.profile.name : "Mister Unknown"}!
            </h3>
            <p> {translate("WelcomeText")}</p>
          </div>
        )}
        {!user && (
          <div>
            <h3>You have been successfully logged out.</h3>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    //channels: state.subscriptions.channels
  };
}

export default withTranslation()(connect(mapStateToProps)(MainPage));
