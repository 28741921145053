export const currentPageActionTypes = {
  GET_CURRENT_PAGE: "GET_CURRENT_PAGE",
  GET_CURRENT_PAGE_SUCCESSED: "GET_CURRENT_PAGE_SUCCESSED",
  GET_CURRENT_PAGE_FAILED: "GET_CURRENT_PAGE_FAILED",
};
export const pageTemplatesActionTypes = {
  GET_PAGE_TEMPLATES: "GET_PAGE_TEMPLATES",
  GET_PAGE_TEMPLATES_SUCCESSED: "GET_PAGE_TEMPLATES_SUCCESSED",
  GET_PAGE_TEMPLATES_FAILED: "GET_PAGE_TEMPLATES_FAILED",
};

export const managePageActionTypes = {
  GET_PAGE: "GET_PAGE",
  GET_PAGE_SUCCESSED: "GET_PAGE_SUCCESSED",
  GET_PAGE_FAILED: "GET_PAGE_FAILED",
  RESET_MANAGE_PAGE: "RESET_MANAGE_PAGE",
};
export const manageExcelTemplateActionType = {
  GET_TABS: "GET_TABS",
  GET_TABS_SUCCESSED: "GET_TABS_SUCCESSED",
  GET_TABS_FAILED: "GET_TABS_FAILED",
  SET_TABS: "SET_TABS",
  SAVE_TABS: "SAVE_TABS",
  SAVE_TABS_SUCCESSED: "SAVE_TABS_SUCCESSED",
  SAVE_TABS_FAILED: "SAVE_TABS_FAILED",
  DOWNLOAD_EXCEL: "DOWNLOAD_EXCEL",
  DOWNLOAD_EXCEL_SUCCESSED: "DOWNLOAD_EXCEL_SUCCESSED",
  DOWNLOAD_EXCEL_FAILED: "DOWNLOAD_EXCEL_FAILED",
};

export const manageExcelTemplateDownloadActionType = {
  DOWNLOAD_EXCEL: "DOWNLOAD_EXCEL",
  DOWNLOAD_EXCEL_SUCCESSED: "DOWNLOAD_EXCEL_SUCCESSED",
  DOWNLOAD_EXCEL_FAILED: "DOWNLOAD_EXCEL_FAILED",
};
export const manageMenusActionType = {
  GET_MENUS: "GET_MENUS",
  GET_MENUS_SUCCESSED: "GET_MENUS_SUCCESSED",
  GET_MENUS_FAILED: "GET_MENUS_EXCEL_FAILED",
  SET_URL: "SET_URL",
};
export const entityRecordActionType = {
  GET_ENTITY_RECORD: "GET_ENTITY_RECORD",
  GET_ENTITY_RECORD_SUCCEEDED: "GET_ENTITY_RECORD_SUCCEEDED",
  GET_ENTITY_RECORD_FAILED: "GET_ENTITY_RECORD_FAILED",
};
export const manageToolbarSelectionsActionType = {
  ADD_FILTER: "ADD_FILTER",
  RESET_FILTERS: "RESET_FILTERS"
};
export const checklistActionTypes = {
  GET_CHECKLIST: "GET_CHECKLIST",
  GET_CHECKLIST_SUCCEEDED: "GET_CHECKLIST_SUCCEEDED",
  GET_CHECKLIST_FAILED: "GET_CHECKLIST_FAILED",
 
};
export const loggedInUserIdActionTypes = {
  GET_LOGGED_IN_USER_TYPE: "GET_LOGGED_IN_USER_TYPE",
  GET_LOGGED_IN_USER_TYPE_SUCCEEDED: "GET_LOGGED_IN_USER_TYPE_SUCCEEDED",
  GET_LOGGED_IN_USER_TYPE_FAILED: "GET_LOGGED_IN_USER_TYPE_FAILED",
 
};
