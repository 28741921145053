import React, { useEffect, useState } from "react";
import A_FormTextField from "../../../Atoms/Inputs/FormFields/A_FormTextField";
import A_FormSelectField from "../../../Atoms/Inputs/FormFields/A_FormSelectField";
import A_FormButton from "../../../Atoms/Inputs/FormFields/A_FormButton";
import { getFormattedDate } from "../../../../../Utils/Helpers/dateUtils";

const M_ChartDataEntry = (props) => {
  const [metrics, setMetrics] = useState([]);
  const [existingMetrics, setExistingMetrics] = useState([]);
  const [existingupdatedPoints, setExistingupdatedPoints] = useState([]);
  const [selectedDataPoint, setSelectedDataPoint] = useState(
    props.data &&
      props.data.points &&
      props.data.points.length > 0 &&
      props.data.points[0].date
      ? props.data.points[0].date
      : ""
  );
  const [entryPointOptions, setEntryPointOptions] = useState([]);
  const [updatedPoints, setUpdatedPoints] = useState([]);

  const dataPoints = props.data && props.data.points ? props.data.points : [];

  useEffect(() => {
    if (dataPoints) {
      const options = dataPoints.map((item) => ({
        label: getFormattedDate(item.date, "MMM-yyyy"),
        value: item.date,
      }));
      setEntryPointOptions(options);
    }
  }, [props.data]);
  useEffect(() => {
    if (selectedDataPoint) {
      const selectedPoint = dataPoints.find(
        (item) => item.date === selectedDataPoint
      ); 
      setExistingMetrics(JSON.parse(JSON.stringify(selectedPoint.metrics)));
      setMetrics(selectedPoint.metrics);
      const metricsExistingValues= selectedPoint && selectedPoint.metrics && selectedPoint.metrics.filter(items=>items.value!==null && items.value!=='')
      setExistingupdatedPoints(JSON.parse(JSON.stringify(metricsExistingValues)))

    } else {
      setMetrics([]);
    }
  }, [selectedDataPoint]);

  const onSelectedEntry = (selectedEntry, id) => {
    setSelectedDataPoint(selectedEntry.value);
  };
  const onSave = () => {
    props.onSaveUpdates(updatedPoints);
  };
  const onCancel = () => {
    props.onCancelUpdates();    
    setMetrics(JSON.parse(JSON.stringify(existingMetrics)));
    // setSelectedDataPoint("");
  };
  const onValueChange = (metric, updatedVal, type) => {
    let updatedValue = updatedVal;
    if (type == "percentage") {
      updatedValue = updatedValue / 100;
    }
    const modifiedMetric = metrics.find((item) => item.id === metric.id);
    modifiedMetric.value = updatedValue;
    modifiedMetric.isModified = true;
    const modifiedMetrics = metrics.map(
      (item) => metrics.find((o) => o.id === item.id) || modifiedMetric
    );
    setMetrics(modifiedMetrics);

    const existingPoint = updatedPoints.find(
      (item) => item.metricid === metric.id
    );

    if (existingPoint) {
      if (updatedValue == "") {
        const checkExistingValue = existingMetrics.find(
          (item) => item.id === metric.id
        );
        checkExistingValue &&
        (checkExistingValue.value == "" || checkExistingValue.value == null)
          ? updatedPoints.splice(
              updatedPoints.findIndex((e) => e.metricid === metric.id),
              1
            )
          : (existingPoint.value = updatedValue);
      } else {
        existingPoint.value = updatedValue;
      }
    } else {
      const updatedMetricPoint = {
        date: metric.date,
        metricid: metric.id,
        series: props.seriesName,
        value: updatedValue,
      };
      setUpdatedPoints([...updatedPoints, updatedMetricPoint]);
    }
  };
  return (
    <div>
      <span>{props.data.formName}</span>
      <div>
        <A_FormSelectField
          label={props.data.keyField}
          options={entryPointOptions}
          value={selectedDataPoint}
          onChange={onSelectedEntry}
        />
      </div>
      {metrics &&
        metrics
          .filter((item) => {
            return selectedDataPoint === item.date;
          })
          .map((item) => (
            <div key={`${item.id}_${selectedDataPoint}`}>
              <A_FormTextField
                label={item.name}
                dataType={item.dataType}
                value={
                  item.value !== null
                    ? item.dataType == "percentage"
                      ? item.value * 100
                      : item.value
                    : ""
                }
                onChange={(e) =>
                  onValueChange(item, e.target.value, item.dataType)
                }
              />
            </div>
          ))}
      {selectedDataPoint && (
        <div>
          <A_FormButton
            onSave={onSave}
            onCancel={onCancel}
            saveDisabled={updatedPoints.length == 0}
            isSaveInProgress={props.isSaving}
            saveLabel="Update"
            cancelLabel="Cancel"
          ></A_FormButton>
        </div>
      )}
    </div>
  );
};

export default M_ChartDataEntry;
