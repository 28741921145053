import { manageMenusActionType } from "./actionTypes";

export const getMenus = (payload) => {
  return {
    type: manageMenusActionType.GET_MENUS,
    payload,
  };
};
export const getMenusSuccessed = (payload) => {
  return {
    type: manageMenusActionType.GET_MENUS_SUCCESSED,
    payload,
  };
};

export const getMenusFailed = (payload) => {
  return {
    type: manageMenusActionType.GET_MENUS_FAILED,
    payload,
  };
};
