import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as manageExcelTemplateActions from "../../../../../store/actions/manageExcelTemplateActions";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Grid from "@material-ui/core/Grid";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import A_Checkbox from "../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import LookUpMaterics from "./LookUpMatrics";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // marginTop: "70px",
    textAlign: "center",
    minHeight: "70vh",
  },
  dragable: {
    cursor: "move",
  },
  selctBox: {
    minWidth: 180,
    height: "35px",
  },
  lookupbutton:{
    margin:"5px",
    height: "35px",
    color:"black",
    border:"1px solid black"
  },
  checkboxLabel: {
    margin: 0
  },
  checkboxRoot: {
    padding: 0
  },
  displayNameWrapper: {
    wordBreak: "break-all"
  }
}));

const ExcelTemplateContent = (props) => {
  const classes = useStyles();
  const [isSaved, setIsSaved] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [contents, setContents] = React.useState(props.content);
  let dragedItemIndex =null;
  const currentItem = props.item;
  const [addRow, setAddRow] = React.useState(false);
  const [editRow, setEditRow] = React.useState(false);
  const [maxCount, setMaxCount] = React.useState(false);
  const [lookupMetricValue, setLookupMetricValue] = React.useState([]);
  const [lookupMetricId, setLookupMetricId] = React.useState(null);
  const [openPopup, setOpenPopup] = React.useState(false);

  const { investmentId } = props;
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });

  const optionType = [
    { value: "$", label: "Currency" },
    { value: "#", label: "General" },
    { value: "header", label: "Header" },
    { value: "x", label: "Multiple" },
    { value: "number", label: "Number" },
    { value: "percentage", label: "Percentage" },
  ];
  const signOptions = [
    { label: "Negative", value: -1 },
    { label: "Positive", value: 0 },
    { label: "Unknown", value: 2 },
  ];

  const commonFilter = (id, key, valueToChange) => {
    let resultArray = contents.map((item) => {
      if (item.sortOrder == id) {
        item[key] = valueToChange;
        item["action"] =
          item["action"] && item["action"] == "INSERT" ? "INSERT" : "UPDATE";
      }
      return item;
    });

    setContents([...resultArray]);
  };

  const checkCounterValidation = (currentLevel, id, type) => {
    let res = contents.filter((item) => {
      if (item.sortOrder != id) {
        if (type == "positive" && item.level == currentLevel) {
          return true;
        } else if (type == "negative" && item.level == currentLevel - 1) {
          return true;
        }
      } else {
        return false;
      }
    });
    if (res && res.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkForLeftArrayDisabled = (level, id, type, index) => {
    let currentLevel = level;
    let nextLevel =
      contents &&
      contents.length > 1 &&
      contents[index + 1] &&
      contents[index + 1].level
        ? contents[index + 1].level
        : 0;
    let lastIndex = contents && contents.length > 0 ? contents.length - 1 : 0;
    if (index == lastIndex) {
      return false;
    } else if (nextLevel < currentLevel) {
      return false;
    } else {
      return true;
    }
  };

  const checkForRightArrayDisabled = (level, id, type, index) => {
    let currentLevel = level;

    if (currentLevel == 0) {
      return false;
    } else {
      return true;
    }
  };
  const isDisabledCheckCounterValidation = (currentLevel, id, type, index) => {
    return type == "positive"
      ? checkForLeftArrayDisabled(currentLevel, id, type, index)
      : checkForRightArrayDisabled(currentLevel, id, type, index);
  };
  const moveChildLevel = (opration, item, ind, parentLevel) => {
    contents.sort((a, b) => a.sortOrder - b.sortOrder);

    for (let index = ind - 1; index > -1; index--) {
      let childLevel =
        contents &&
        contents[index] &&
        contents[index].level &&
        contents[index].level
          ? contents[index].level
          : 0;

      if (childLevel <= parentLevel) {
        break;
      } else if (childLevel > parentLevel) {
        contents[index].level =
          opration == "substraction"
            ? contents[index].level - 1
            : contents[index].level + 1;
      }
    }
    return contents;
  };

  const leftMoveHandle = (e, id, level, index) => {
    let item =
      contents && contents.length > 0 && contents[index]
        ? contents[index]
        : null;

    let resultArray = moveChildLevel("substraction", item, index, item.level);

    if (item && item.sortOrder == id && item.level > 0) {
      let res = checkCounterValidation(item.level, id, "negative");
      item.level = res ? item.level - 1 : item.level;
      item["action"] =
        item["action"] && item["action"] == "INSERT" ? "INSERT" : "UPDATE";
      contents[index] = item;
    }

    setContents([...resultArray]);
    setIsCalculated(setParentValue(),null);
  };
  const rightMoveHandle = (e, id, level, index) => {
    let item =
      contents && contents.length > 0 && contents[index]
        ? contents[index]
        : null;
    let resultArray = moveChildLevel("addtion", item, index, item.level);

    if (item && item.sortOrder == id) {
      let res = checkCounterValidation(item.level, id, "positive");
      item.level = res ? item.level + 1 : item.level;
      item["action"] =
        item["action"] && item["action"] == "INSERT" ? "INSERT" : "UPDATE";
    }

    setContents([...resultArray]);
    setIsCalculated(setParentValue());
  };
  const typeChangeHandle = (e, id) => {
    commonFilter(id, "type", e.value);
  };

  const signedChangeHandle = (e, id) => {
    commonFilter(id, "signConstraint", e.value);
  };
  const rowBreakCheckBoxChangeHandle = (e, id) => {
    commonFilter(id, "rowBreakAfter", e);
  };
  const previousMonthCheckBoxChangeHandle = (e, id) => {
    commonFilter(id, "previousMonthUsage", e);
  };
  const showTemplateCheckboxChangeHandle = (e, id) => {
    commonFilter(id, "showOnTemplate", e);
  };
  const handleRowDoubleClick = (e, id) => {
    commonFilter(id, "edited", true);
  };

  const handleLookUpMatrics = (e, item) => {
    const data = item;
    const id = item.sortOrder;
    setLookupMetricId(id);
    setLookupMetricValue(data);
    setOpenPopup(!openPopup);
  };
  const handlelookup = (id, value) => {
    commonFilter(id, "lookupMetricFlag", true);
    commonFilter(id, "lookupMetricInfo", value);
  };

  const handleEditedRow = (e, id) => {
    if (e.key === "Enter" || !e.key) {
      commonFilter(id, "name", e.target.value);
      commonFilter(id, "edited", undefined);
    }
  };

  const handleCallBack = (val) => {
    setOpenPopup(val);
  };
  let childSets=[]
  const moveLeftAllChildItem=(item,id)=>{
    let childArry= item.filter((item)=>item.parentId==id)
    if(childArry && childArry.length>0){
      childArry[0].level=childArry[0].level-1;
      childSets.push(childArry[0])
      return moveLeftAllChildItem(item,childArry[0].id)
    }else{
      return childSets
    }
    
  }
  
  const handleDeleteRow = (e, id) => {
    var res = [];
    let deletedParentId=null;
    let resultArray = contents.map((item) => {
      if (item.sortOrder != id) {
        //return item
        res.push(item);
      } else {
        if (item["action"] && item["action"] == "INSERT") {
          //return undefined
        } else {
          item["action"] = "DELETE";
          res.push(item);
          deletedParentId=item.id

        }

        //return item
      }
    });
    
    let leveledResult = moveLeftAllChildItem(res ,deletedParentId)
    let finalResultAarry=[]
    res.map((item) => {
      let res = leveledResult.filter(
        (itemChild) => itemChild.sortOrder == item.sortOrder
      );
      if (res && res.length > 0) {
        finalResultAarry.push(res[0]);
      } else {
        finalResultAarry.push(item);
      }
    });
    // let resultArray = contents.filter((item) => item.sortOrder!=id)
    setContents([...finalResultAarry]);
  };

  const Renderitems = (props) => {
    const classes = useStyles()
    let { item } = props;
    let ind = props.index;
    
    

    if (item && item.action && item.action == "DELETE") {
      return "";
    }
    const [addRowBetween, setAddRowBetween] = React.useState(false);
    const [addIcon, setAddIcon] = React.useState(false);

    const handleAddRowBetween = () => {
      setAddRowBetween(true);
    };
    const handleAfterAddRowBetween = (e, order, ind, item) => {
      props.handleAfterAddRowBetweenParent(e, order, ind, item);
      setAddRowBetween(false);
    };
    const handleAddRowByEnter = (e, order, ind, item) => {
      if (e.key == "Enter") {
        e.preventDefault();
        props.handleAfterAddRowBetweenParent(e, order, ind, item);
        setAddRowBetween(false);
      }
    };
    const handleDragStart = (e, index) => {
      e.dataTransfer.effectAllowed = "move";
      dragedItemIndex = index;
      
    };
    const handleDragOver = (e) => {
      if (e.preventDefault) {
        e.preventDefault();
      }
      e.dataTransfer.dropEffect = "move";
      return false;
    };
    const handleDrop = (e, index) => {  
      props.handleDragAndDroped(dragedItemIndex, index);
    };
    return (
      <>
        <Grid
          key={"row" + ind}
          id={"row" + ind}
          draggable="true"
          onDrop={(e)=>handleDrop(e,ind)}
          onDragOver={handleDragOver}
          onDrag={(event)=>handleDragStart(event,ind)}
          container
          onMouseEnter={() => setAddIcon(true)}
          onMouseLeave={() => setAddIcon(false)}
          spacing={1}
          style={{
           
            alignItems: "center",
            border: "1px solid rgba(224, 224, 224, 1)",
            margin: "0px",
          }}
        >
          <Grid item xs={1}>
            <ArrowLeftIcon
              style={
                isDisabledCheckCounterValidation(
                  item.level,
                  item.sortOrder,
                  "negative",
                  ind
                )
                  ? {}
                  : { opacity: "0.2", pointerEvents: "none" }
              }
              key={"left" + ind}
              id={"left" + ind}
              onClick={(e) =>
                leftMoveHandle(e, item.sortOrder, item.level, ind)
              }
            />
            <ArrowRightIcon
              style={
                isDisabledCheckCounterValidation(
                  item.level,
                  item.sortOrder,
                  "positive",
                  ind
                )
                  ? {}
                  : { opacity: "0.2", pointerEvents: "none" }
              }
              key={"right" + ind}
              id={"right" + ind}
              onClick={(e) =>
                rightMoveHandle(e, item.sortOrder, item.level, ind)
              }
            />
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              cursor:"move",
              textAlign: "left",
              paddingLeft: item.level < 1 ? "0em" : item.level * 2 + "em",
            }}
            onDoubleClick={(e) => handleRowDoubleClick(e, item.sortOrder)}
          >
            <Typography>
              <Box fontWeight={item.isCalculated ? "fontWeightBold" : ""} classes={{root: classes.displayNameWrapper}}>
                {!item.edited ? (
                  item.name
                ) : (
                  <A_TextField
                    type="text"
                    key={"sortorder" + ind}
                    id={"sortorder" + ind}
                    defaultValue={item.name}
                    onKeyPress={(e) => handleEditedRow(e, item.sortOrder)}
                    onBlur={(e) => handleEditedRow(e, item.sortOrder)}
                  />
                )}
              </Box>
            </Typography>
          </Grid>

          {/* <Grid item xs={1}>
                          <ArrowForwardIcon onClick={(e) => rightMoveHandle(e, item.sortOrder)} />
                      </Grid> */}
          {/* <Grid item xs={1}>
          {item.level}
        </Grid> */}
          <Grid item xs={2} style={{ height: "50px"  ,cursor:"move"}}>
            <A_Select
              key={"type" + ind}
              id={"type" + ind}
              className={classes.selctBox}
              options={optionType}
              defaultValue={item.type}
              onChange={(e) => typeChangeHandle(e, item.sortOrder)}
            />
          </Grid>
          <Grid item xs={2} style={{ height: "50px"  ,cursor:"move"}}>
            <A_Select
              key={"signOptions" + ind}
              id={"signOptions" + ind}
              className={classes.selctBox}
              options={signOptions}
              defaultValue={item.signConstraint}
              onChange={(e) => signedChangeHandle(e, item.sortOrder)}
            />
          </Grid>
          <Grid item xs={1} style={{ height: "50px"  ,cursor:"move"}}>
            <A_Button
              className={classes.lookupbutton}
              key={"lookup" + ind}
              id={"lookup" + ind}
              color="primary" 
              variant="outlined"           
              label={item.lookupMetricInfo && item.lookupMetricInfo.length ? 
                <div style={{ backgroundColor: '#fff', width: '45px' }}>{item.lookupMetricInfo.length}</div>: 
                "SELECT"}
              onClick={(e) => handleLookUpMatrics(e, item, item.sortOrder)}
            />
          </Grid>

          <Grid item xs={1}  style={{ cursor:"move"}}>
            <A_Checkbox
              key={"previous" + ind}
              id={"previous" + ind}
              checked={item.previousMonthUsage}
              onChange={(e) =>
                previousMonthCheckBoxChangeHandle(e, item.sortOrder)
              }
              labelClass={classes.checkboxLabel}
              allClasses={{root: classes.checkboxRoot}}
            />
          </Grid>

          <Grid item xs={1} style={{ cursor:"move"}}>
            <A_Checkbox
              key={"rowbreak" + ind}
              id={"rowbreak" + ind}
              checked={item.rowBreakAfter}
              onChange={(e) => rowBreakCheckBoxChangeHandle(e, item.sortOrder)}
              labelClass={classes.checkboxLabel}
              allClasses={{root: classes.checkboxRoot}}
            />
          </Grid>
          <Grid item xs={1} style={{ cursor:"move"}}>
            <A_Checkbox
              key={"showtemplate" + ind}
              id={"showtemplate" + ind}
              checked={item.showOnTemplate}
              onChange={(e) =>
                showTemplateCheckboxChangeHandle(e, item.sortOrder)
              }
              labelClass={classes.checkboxLabel}
              allClasses={{root: classes.checkboxRoot}}
            />
          </Grid>

          <Grid
            item
            xs={1}
            onDoubleClick={(e) => handleRowDoubleClick(e, item.sortOrder)}
          >
            {/* <span style={{cursor:moveBy}} draggable="true"><DragHandleIcon/></span> */}
            {/* <EditIcon
              key={"edit" + ind}
              id={"edit" + ind}
              onClick={(e) => handleRowDoubleClick(e, item.sortOrder)}
            /> */}
            <DeleteIcon
              key={"delete" + ind}
              id={"delete" + ind}
              onClick={(e) => handleDeleteRow(e, item.sortOrder)}
            />
          </Grid>
          {addIcon ? (
            <div style={{ width: "100%", textAlign: "left" }}>
              <AddCircleOutlineIcon
                onClick={handleAddRowBetween}
                style={{
                  position: "relative",
                  margin: "-12px",
                  left: "178px",
                }}
              />
            </div>
          ) : (
            ""
          )}
        </Grid>
        {addRowBetween ? (
          <Grid
            className={classes.triggerElement}
            key={"row" + ind}
            id={"row" + ind}
            container
            spacing={1}
            style={{
              alignItems: "center",
              border: "1px solid rgba(224, 224, 224, 1)",
              margin: "0px",
            }}
          >
            <Grid item xs = {1}>
            </Grid>
            <Grid item xs = {2}>
            <Typography style={{ padding: "10px" }}>
              <Box fontWeight={item.isCalculated ? "fontWeightBold" : ""}>
                <A_TextField
                  type="text"
                  label="Add Metric"
                  key={"sortorder" + ind}
                  id={"sortorder" + ind}
                  defaultValue=""
                  onKeyPress={(e) =>
                    handleAddRowByEnter(e, item.sortOrder, ind, item)
                  }
                  onBlur={(e) =>
                    handleAfterAddRowBetween(e, item.sortOrder, ind, item)
                  }
                />
              </Box>
            </Typography>
          </Grid>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  };
  const handleAddRowClick = () => {
    setAddRow(true);
  };
  const handleCancelButton = () => {
    setAddRow(false);
    props.onCancelRowChange()

  };
  const handleBlurEvent = (e) => {
    if (e && e.target && e.target.value) {
      // let sortOrder =
      //   contents && contents.length > 0
      //     ? contents[contents.length - 1].sortOrder + 1
      //     : contents.length + 1;
      let sortOrder =
        contents && contents.length > 0
          ? contents[contents.length - 1].sortOrder + 1
          : (props.index + 1) * 1000 + 1;
      let currentObject = {
        investmentId: investmentId,
        sortOrder: sortOrder,
        showOnTemplate: true,
        signConstraint: 2,
        type: "$",
        rowBreakAfter: false,
        name: e.target.value,
        level: 0,
        previousMonthUsage: false,
        action: "INSERT",
        isBalance: false,
        isFixed: false,
        isCalculated: false,
      };
      contents.push(currentObject);

      // set component state local
      setContents([...contents]);

      e.target.value = null;
      setAddRow(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlurEvent(e);
    }
  };

  const setParentValue = () => {
    let result = contents.map((item, index) => {
      const found = contents.find(
        (element, ind) => element.level < item.level && ind > index
      );
      if (found) {
        item["parentName"] = found.name;
      } else {
        item["parentName"] = null;
      }
      return item;
    });

    return result;
  };
  const setIsCalculated = (item ,val) => {


          if(!val || val!="save"){
            let parents=[]
            for (let index = 0; index < item.length; index++) {

              if (item[index].action && item[index].action == "DELETE") {
                continue
              }
              if(item[index].parentName){
                parents.push(item[index].parentName)
              }
              let name =item[index].name
              if(name == item[index].parentName){
                continue
              }
              if(parents && parents.length>0 && parents.find(element => element ==name) ){
                item[index].action = item[index].action == "NOCHANGE" ? "UPDATE" : item[index].action;
                item[index].isCalculated = true;
                if(parents.indexOf(name)>-1){
                  //keeping for now
                  //parents.splice(parents.indexOf(name), 1);
                  var newarr = parents.filter(function(a){return a !== name})
                  parents=newarr
                }    
                
              }else{
                item[index].action = item[index].action == "NOCHANGE" ? "UPDATE" : item[index].action;
                item[index].isCalculated = false;
              }
            }    

            return item
          }else{
            return item
          }
    
    // commenting it and keeping it for future refrence 
    // let res = item.map((val) => {
    //   if (val.action && val.action == "DELETE") {
    //     return val;
    //   }
    //   let filteredValue = item.filter((v) => v.parentName == val.name);
    //   if (filteredValue && filteredValue.length > 0) {
    //     if (!val.isCalculated) {
    //       val.action = val.action == "NOCHANGE" ? "UPDATE" : val.action;
    //     }
    //     val.isCalculated = true;
    //     return val;
    //   }
    //   if (val.isCalculated) {
    //     val.action = val.action == "NOCHANGE" ? "UPDATE" : val.action;
    //   }
    //   val.isCalculated = false;
    //   return val;
    // });
    // return res;
  };
  const handleSaveButton = () => {
    let requestJson = props.manageExcelTemplates && props.manageExcelTemplates.length>0 ? props.manageExcelTemplates.map((item) => {
      item.templateMetrics =
        item && item.templateMetrics && item.templateMetrics.length > 0
          ? setIsCalculated(item.templateMetrics ,'save')
          : [];
      item.id = undefined;
      return item;
    }):[];
    if (props.deletedArray && props.deletedArray.length > 0) {
      requestJson = [...requestJson, ...props.deletedArray];
    }

    props.saveTabs({ data: requestJson, investmentId: investmentId });

    setIsLoading(true);
    // setIsSaved(true)
  };
  const handleSnackbarClose = () => {
    // setIsSaved(false)
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };

  React.useEffect(() => {
    // if(aftersaved){
    //     setAftersaved(false)
    //     return
    // }
    // setParentValue()
    let result = setParentValue();
    let arr = props.manageExcelTemplates && props.manageExcelTemplates.length>0 ? props.manageExcelTemplates.map((row) => {
      if (row.id && row.id == currentItem.id) {
        row.templateMetrics = [...result];
      }
      return row;
    }):[];
    props.setTabs([...arr]);
  }, [contents]);

  React.useEffect(() => {
    if (isLoading && props.manageExcelTemplates.saveTabsSuccessed) {
      //setIsSaved(true)
      setIsLoading(false);
      setChangesSaved({
        status: true,
        type: "success",
        msg: "Changes saved successfully",
      });
      //props.setTabs([...props.manageExcelTemplates.data])
      props.setTabValue(props.index, [...props.manageExcelTemplates.data]);
      // setAftersaved(true)
      // props.setTabValue(props.index,[...props.manageExcelTemplates.data])
      // let json = props.manageExcelTemplates.data[props.index].templateMetrics[0];
      // setContents(json)
      // props.manageExcelTemplates.data[props.index]
    }
  }, [
    props.manageExcelTemplates && props.manageExcelTemplates.saveTabsSuccessed,
  ]);

  React.useEffect(() => {
    if (props.manageExcelTemplates.errorMessage && isLoading) {
      // loading ends
      setIsLoading(false);
      // saved  message
      setChangesSaved({
        status: true,
        type: "error",
        msg: props.manageExcelTemplates.errorMessage,
      });
      props.setTabs([...props.manageExcelTemplates.data]);
    }
  }, [props.manageExcelTemplates && props.manageExcelTemplates.errorMessage]);

  const handleAddRowBetweenParentEvent = (e, order, ind, item) => {
    if (e.target.value) {
      let currentObject = {
        investmentId: investmentId,
        sortOrder: order + 1,
        showOnTemplate: true,
        signConstraint: 2,
        type: "$",
        rowBreakAfter: false,
        name: e.target.value,
        level: item && item.level ? item.level : 0,
        previousMonthUsage: false,
        action: "INSERT",
        isBalance: false,
        isFixed: false,
        isCalculated: false,
      };
      contents.splice(ind + 1, 0, currentObject);
      contents.join();

      for (let index = ind + 2; index < contents.length; index++) {
        contents[index]["sortOrder"] =
          contents[index] && contents[index].sortOrder
            ? contents[index].sortOrder + 1
            : index;
        contents[index]["action"] =
          contents &&
          contents[index] &&
          contents[index]["action"] &&
          (contents[index]["action"] == "INSERT" ||
            contents[index]["action"] == "DELETE")
            ? contents[index]["action"]
            : "UPDATE";
      }

      // set component state local
      setContents([...contents]);
      e.target.value = null;
      setAddRow(false);
    }
  };

  const handleDragAndDroped = (dragInd, dropInd) => {
    let tempArray = contents;
    let tempOrder = tempArray[dragInd]["sortOrder"];
    let tempLevel = tempArray[dragInd]["level"];
    let tempIscalculated = tempArray[dragInd]["isCalculated"];

    tempArray[dragInd]["action"] =
      tempArray[dragInd]["action"] == "INSERT" ? "INSERT" : "UPDATE";
    tempArray[dropInd]["action"] =
      tempArray[dropInd]["action"] == "INSERT" ? "INSERT" : "UPDATE";

    tempArray[dragInd]["sortOrder"] = tempArray[dropInd]["sortOrder"];
    tempArray[dropInd]["sortOrder"] = tempOrder;

    tempArray[dragInd]["isCalculated"] = tempArray[dropInd]["isCalculated"];
    tempArray[dropInd]["isCalculated"] = tempIscalculated;

    tempArray[dragInd]["level"] = tempArray[dropInd]["level"];
    tempArray[dropInd]["level"] = tempLevel;

    setContents([...tempArray]);
  };
  return (
    <div>
      <div className={classes.root}>
        <Grid container spacing={3} alignItems="center">
          <Grid
            container
            spacing={3}
            style={{ paddingTop: 20, paddingBottom: 10 }}
            style={{
              alignItems: "center",
              border: "2px solid rgba(224, 224, 224, 1)",
              margin: "0px",
            }}
          >
            <Grid item xs={1} style={{ fontWeight: "bold" }}>
              Move Left/Right
            </Grid>
            <Grid item xs={2} style={{ fontWeight: "bold" }}>
              Display Name
            </Grid>

            {/* <Grid item xs={1} style={{ fontWeight: "bold" }}>
                    Move Down
                    </Grid> */}
            {/* <Grid item xs={1} style={{ fontWeight: "bold" }}>
            Level
          </Grid> */}
            <Grid item xs={2} style={{ fontWeight: "bold" }}>
              Type
            </Grid>
            <Grid item xs={2} style={{ fontWeight: "bold" }}>
              Signed
            </Grid>
            <Grid item xs={1} style={{ fontWeight: "bold" }}>
              Custom sum
            </Grid>

            <Grid item xs={1} style={{ fontWeight: "bold" }}>
              Use parent value from previous month
            </Grid>
            <Grid item xs={1} style={{ fontWeight: "bold" }}>
              Row Break
            </Grid>
            <Grid item xs={1} style={{ fontWeight: "bold" }}>
              Show in Template
            </Grid>
            <Grid item xs={1} style={{ fontWeight: "bold" }}></Grid>
          </Grid>

          {contents && contents.length > 0
            ? contents
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((item, index) => {
                  return (
                    <>
                      {
                        <Renderitems
                          item={item}
                          index={index}
                          handleAfterAddRowBetweenParent={
                            handleAddRowBetweenParentEvent
                          }
                          handleDragAndDroped={
                            handleDragAndDroped
                          }
                        />
                      }
                    </>
                  );
                })
            : ""}
        </Grid>

        <Grid container spacing={3} style={{ paddingBottom: "40px" }}>
        <Grid item xs={1}>
            {/* Move Up */}
          </Grid>
          <Grid item xs={2}>
            {!addRow ? (
              /*<AddIcon onClick={handleAddRowClick} /> */ ""
            ) : (
              <A_TextField
                key={"displayname"}
                id={"displayname"}
                type="text"
                label="Enter Display Name"
                defaultValue={null}
                onKeyPress={handleKeyDown}
                onBlur={handleBlurEvent}
                className={classes.displayNameWrapper}
              />
            )}
          </Grid>

          <Grid item xs={1}>
            {/* Move Down */}
          </Grid>
          {/* <Grid item xs={1}> */}
          {/* Level */}
          {/* </Grid> */}
          <Grid item xs={2}>
            {/* Type */}
          </Grid>
          <Grid item xs={2}>
            {/* Signed */}
          </Grid>
          <Grid item xs={1}>
            {/* Use Previous */}
          </Grid>
          <Grid item xs={1}>
            {/* Use Previous */}
          </Grid>
          <Grid item xs={1}>
            {/* Row Break */}
          </Grid>
          <Grid item xs={1}>
            {/* Show in Template */}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {/* <Grid item xs={8}>
                        
                </Grid> */}
          {/* <Grid item xs={4}>
                        <A_Button  color="primary" label="Add Record" onClick={handleAddRowClick} />
                        <A_Button  color="primary" label="Cancel" onClick={handleCancelButton} />
                        <A_Button color="primary" label="Save" onClick={handleSaveButton}/>
                </Grid> */}
          {/* <Grid item xs={0}>
                        
                </Grid> */}
        </Grid>

        <A_Snackbar
          open={changesSaved.status}
          message={changesSaved.msg}
          type={changesSaved.type}
          autoHideDuration={3000}
          horizontal="center"
          handleClose={handleSnackbarClose}
        />
        {openPopup && (
          <LookUpMaterics
            openPopup={openPopup}
            handlelookup={handlelookup}
            lookupMetricId={lookupMetricId}
            investmentId={investmentId}
            callBack={handleCallBack}
            lookupMetricValue={lookupMetricValue}
            content={props.content}
          />
        )}
      </div>

      <div>
        <footer
          style={{
            display: "flex",
            width: "100%",
            position: "sticky",
            zIndex: "999999999",
            flexDirection: "column",
          }}
        >
          <Grid style={{ textAlign: "right" }} item xs={12}>
            {!(props.isdownloadExcelPopup || openPopup) ? <A_Button
              color="primary"
              label="Add Record"
              onClick={handleAddRowClick}
            /> : ""}
            {!(props.isdownloadExcelPopup || openPopup) ? <A_Button
              color="primary"
              label="Cancel"
              onClick={handleCancelButton}
            /> : ""}
            {!(props.isdownloadExcelPopup || openPopup) ? <A_Button
              color="primary"
              label="Save"
              disabled={isLoading}
              endIcon={
                isLoading ? (
                  <A_CircularIndeternment color="secondary" size={20} />
                ) : (
                  ""
                )
              }
              onClick={handleSaveButton}
            /> : ""}
          </Grid>

          {/* <BottomNavigation >
                <BottomNavigationAction label="Recents" value="recents" icon={<A_Button  color="primary" label="Add Record" onClick={handleAddRowClick} />} />
    
        </BottomNavigation> */}
        </footer>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    manageExcelTemplates: state.manageExcelTemplates,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(manageExcelTemplateActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExcelTemplateContent);
