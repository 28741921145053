import React from "react";
import { Router, Route, IndexRoute, Switch } from "react-router";

import ProtectedRoute from "./protectedRoutes";
import MainPage from "../Components/Factory/Pages/Common/mainPage";
import forceLogout from "../Components/Factory/Pages/Common/forceLogout";
import CallbackPage from "../Components/Utils/Oidc/Callback";
import M_Playground from "../components/Factory/Molecules/Common/M_Playground";
import Layout from "../Components/Factory/Pages/Common/Layouts/M_Layout";
import LoginPage from "../Components/Factory/Pages/Common/loginPage";
import UserProfile from "../components/Factory/Pages/Common/UserProfile";
import ApplicationSettings from "../components/Factory/Pages/Common/ApplicationSettings";
import M_Layout from "../Components/Factory/Pages/Common/Layouts/M_Layout";
import P_PageLayout from "../components/Factory/Pages/Common/PageLayout/P_PageLayout";
import PageNotFound from "./PageNotFound";

export default function Routes(props) {
  return (
    <Switch>
      <Route exact path="/" component={LoginPage} />
      <Route path="/callback" component={CallbackPage} />
      <ProtectedRoute path="/dashboard" component={MainPage} />
      <ProtectedRoute path="/securelogout" component={forceLogout} />
      <ProtectedRoute path="/playground" component={M_Playground} />
      <ProtectedRoute path="/cardlayout" component={M_Layout} />
      <ProtectedRoute path="/layout" component={Layout} />
      <ProtectedRoute path="/user_profile" component={UserProfile} />
      <ProtectedRoute path="/app_settings" component={ApplicationSettings} />
      <ProtectedRoute
        path="/:pageUrl/:crmId/:templateId?"
        component={P_PageLayout}
      />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}
