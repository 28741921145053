import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { httpPut } from "../../../../../Utils/apis/apis";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loggedInUserIdActions from "../../../../../store/actions/loggedInUserIdActions";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";

const useStyles = makeStyles((theme) => ({
  color: {
    color: "black",
  },
}));

const UserPrefrences = (props) => {
  const classes = useStyles();
  const [radio, setRadio] = React.useState(props.values);

  var formData = {};

  function handleChanges(event, value) {
    formData["radio"] = event.target.value;
  }
  async function handleChangess(event, value) {
    let selectedRadio = formData["radio"];
    setRadio(selectedRadio);
    let convertedFormData = {
      id: props.LogedUserId,
      label: "contact",
      properties: {
        userPreference: selectedRadio,
      },
    };
    let res = await httpPut(
      `/CRMData/update?pageUrl=contact`,
      convertedFormData
    );
  }

  return (
    <div>
      <div>
        <RadioGroup defaultValue={radio} onChange={handleChanges}>
          <FormControlLabel
            className={classes.color}
            value="All"
            control={<Radio color="primary" />}
            label="Tag my all non-recuring External meetings"
          />
          <FormControlLabel
            className={classes.color}
            value="Select"
            control={<Radio color="primary" />}
            label="Let me choose which meeting Automatically tagged"
          />
          <FormControlLabel
            className={classes.color}
            value="None"
            control={<Radio color="primary" />}
            label="Do not Tag my Meetings"
          />
        </RadioGroup>
        <A_Button
          onClick={handleChangess}
          label="Submit"
          size="small"
          color="primary"
          style={{ marginLeft: "310px" }}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(Object.assign({}, loggedInUserIdActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPrefrences);
