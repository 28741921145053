import { loadState } from "../LocalState/localState";
/* List of supported browser with minimum version supported. */
export const supportedBrowsersList = [
  {
    name: "Chrome",
    version: "63.0",
    upgradeLink: "https://www.google.com/intl/en_in/chrome/",
  },
  {
    name: "Edge",
    version: "42",
    upgradeLink: "https://www.microsoft.com/en-us/edge",
  },
  {
    name: "Safari",
    version: "10.12",
    upgradeLink: "https://support.apple.com/downloads/safari",
  },
];

/* User related functions */
export const getCurrentUser = (isAll) => {
  let user = null;
  const appState = loadState();
  if (appState) {
    user = appState.oidc.user;
  }
  try {
    if (user === null) {
      return {};
    }
    if (isAll) {
      return user;
    }
    let userInitials = `${user.profile.given_name.substring(
      0,
      1
    )}${user.profile.family_name.substring(0, 1)}`;
    let displayName = `${user.profile.given_name} ${user.profile.family_name}`;
    let userDesignation =
      user.profile&&user.profile.designation ? user.profile.designation: "";

    return { userInitials, displayName, userDesignation };
  } catch (e) {
    console.log("getCurrentUser failed: ", e);
  }
};

/* ends here User related functions */

export const getClientBrowser = () => {
  let browserInfo = {
    name: "",
    version: "",
  };
  let { userAgent } = navigator;
  if (userAgent.indexOf("Edge") > -1) {
    browserInfo.name = "Microsoft Edge";
    browserInfo.version = userAgent
      .split("Edge/")[1]
      .split(" ")[0]
      .substring(0, 5);
  }
  if (userAgent.indexOf("Firefox") > -1) {
    browserInfo.name = "Firefox";
    browserInfo.version = userAgent
      .split("Firefox/")[1]
      .split(" ")[0]
      .substring(0, 5);
  }
  if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
    browserInfo.name = "Safari";
    browserInfo.version = userAgent
      .split("Safari/")[1]
      .split(" ")[0]
      .substring(0, 5);
  }
  if (userAgent.indexOf("Chrome") > -1) {
    browserInfo.name = "Chrome";
    browserInfo.version = userAgent
      .split("Chrome/")[1]
      .split(" ")[0]
      .substring(0, 5);
  }
  return browserInfo;
};

//This functions checks for supported browsers.
export const isUnsupportedBrowser = () => {
  let isUnsupported = false;
  const clientBrowser = getClientBrowser();
  let matchedBrowser = supportedBrowsersList.filter((browser) => {
    return browser.name === clientBrowser.name;
  });

  if (matchedBrowser.length === 0) {
    isUnsupported = true;
  } else if (
    matchedBrowser[0].name === clientBrowser.name &&
    parseFloat(matchedBrowser[0].version) > parseFloat(clientBrowser.version)
  ) {
    isUnsupported = true;
  }
  return isUnsupported;
};

//This function returns http config to sent in http calls.
export const getHttpConfig = () => {
  let currentUser = getCurrentUser(true);
  let access_token = null;
  if (currentUser !== null) {
    access_token = currentUser.access_token;
  }
  return {
    headers: {
      Authorization: "Bearer " + access_token,
    },
  };
};

export const getEvaluatedValue = (expression, data) => {
  // if(expression==="=/pages/8f3c1b23-d7fc-4a6b-aa30-ea9afb6f9012/+d.firstName"){

  // }
  try {
    let expArry = expression.slice(1, 1000).split("d.");
    let resolvedExp = [];
    for (let i = 0; i < expArry.length; i++) {
      let fieldKeyArry = expArry[i].split("+");

      for (let j = 0; j < fieldKeyArry.length; j++) {
        let fieldKeyRaw = fieldKeyArry[j];
        let fieldKeyTrimmed = fieldKeyArry[j].trim();
        if (fieldKeyTrimmed) {
          if (fieldKeyTrimmed in data) {
            let fieldValue = data[fieldKeyTrimmed];
            resolvedExp.push(fieldValue);
          } else {
            resolvedExp.push(fieldKeyRaw);
          }
        }
      }
    }
    let finalStringTrimmed = resolvedExp.join("");
    let finalString = finalStringTrimmed
      .replace(/(['])/g, " ")
      .replace("  ", "");
    console.log("resolved Exp:", finalString);
    return finalString;
  } catch (e) {
    console.log("getEvaluatedValue error: ", e);
    return undefined;
  }
};

export const showField = (expressionFirst, dataFirst) => {
  if (dataFirst) {
    let operators = {
      eq: "==",
      ne: "!==",
      lt: "<",
      gt: ">",
      le: "<=",
      ge: ">=",
    };
    let globalComparators = {
      now: new Date(),
      currentUser: "ratan",
      env: "dev",
    };
    let logicalOprators = { AND: "&&", OR: "||" };
    let finalExpReadable = "(";
    let finalExpression = "(";
    function evalShow(expression, data) {
      let opr = expression.type;
      let conditions =
        expression && expression.conditions ? expression.conditions : null;

      for (let i = 0; i < conditions.length; i++) {
        let { type, field, op, value } = conditions[i];
        if (!type) {
          let compareOpr = operators[op];
          let compareWithValue = value;
          if (
            typeof compareWithValue === "string" &&
            compareWithValue.indexOf("~") > -1
          ) {
            compareWithValue =
              globalComparators[compareWithValue.slice(1, 1000)];
          }
          if (!compareOpr) {
          } else {
            finalExpReadable =
              finalExpReadable = `${finalExpReadable} ${field} ${compareOpr} ${compareWithValue} ${
                i === conditions.length - 1 ? "" : logicalOprators[opr]
              }`;
            finalExpression = `${finalExpression} "${
              data[field]
            }" ${compareOpr} "${compareWithValue}" ${
              i === conditions.length - 1 ? "" : logicalOprators[opr]
            }`;
          }
        } else {
          finalExpReadable = `${finalExpReadable} (`;
          finalExpression = `${finalExpression} (`;
          evalShow(conditions[i], data);
        }
        if (i == conditions.length - 1) {
          finalExpReadable = `${finalExpReadable} )`;
          finalExpression = `${finalExpression} )`;
        }
      }
    }
    if (expressionFirst === true || expressionFirst === false) {
      return true;
    }
    evalShow(expressionFirst, dataFirst);
    return eval(finalExpression);
  } else {
    return true;
  }
};

export const getTheme = () => {
  const minervaTheme = localStorage.getItem("minervaTheme");
  if (minervaTheme === "null") {
    return minervaTheme;
  }
  return JSON.parse(minervaTheme);
};

//This function returns a valid source for dropdowns i.e. in label, value format
export const convertToLabelValueFormat = (
  id,
  name,
  options,
  addEmptyOption
) => {
  let convertedOptions = addEmptyOption ? [{ value: "", label: "Select" }] : [];
  for (let i = 0; i < options.length; i++) {
    let item = options[i];
    convertedOptions.push({ ...item, value: item[id], label: item[name] });
  }
  return convertedOptions;
};
