import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import * as httpCall from "../../../../../../../../../Utils/apis/apis";

import M_NoContent from "../../Contents/M_NoContent";
import M_RenderItems from "../../Contents/M_RenderItems";
import M_RenderSkeletion from "../../Contents/M_RenderSkeletion";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#fff",
    background: "#337ab7",
  },
  root: {
    flexGrow: 1,
    width: "100%",

    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: "80vh",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
}));

// const searchItems=(props,item ,classes ,id)=>{

//     return(
//       <ListItem alignItems="flex-start" href={item.link} key={id} button onClick={props.handleClick}>
//         <ListItemAvatar>
//           <Avatar variant="square" alt="" src={item.image} />
//         </ListItemAvatar>
//         <ListItemText
//           primary={
//               <b>{item.title}</b>
//           }
//           secondary={
//             <React.Fragment>
//               <Typography
//                 component="span"
//                 variant="body2"
//                 className={classes.inline}
//                 color="textPrimary"
//               >
//                 {item.subtitle}
//               </Typography>

//               <br></br>{<small style={{color:"#377ca7"}}>{item.info1}</small>}
//             </React.Fragment>
//           }
//         />
//     </ListItem>
//     )
// }
// const renderSkeletion=(classes)=>{
//     // const classes = useStyles();
//     return(
//         <List className={classes.root}>
//         <ListItem alignItems="flex-start">
//         <ListItemText
//             primary={
//                     <div style={{display:"flex"}}>
//                         <Skeleton animation="wave"  variant="square" width={40} height={40} />
//                         <Skeleton animation="wave"  width={"100%"}  style={{marginLeft:"15px"}}/>
//                     </div>
//             }
//             secondary={
//               <React.Fragment>
//                 <Typography
//                   component="span"
//                   variant="body2"
//                   className={classes.inline}
//                   color="textPrimary"
//                 >
//                    {<Skeleton animation="wave"  width={"100%"}  />}
//                 </Typography>

//                 {<Skeleton animation="wave"  width={"100%"}  />}
//               </React.Fragment>
//             }
//           />
//         </ListItem>
//         <Divider variant="inset" component="li" />
//       </List>
//     )
// }
// const noRecordFound=(classes)=>{

//   return(

//     <List className={classes.root}>
//       <ListItem alignItems="flex-start" >
//         <ListItemText
//           primary={
//               <b>No results</b>
//           }
//         />
//       </ListItem>
//     </List>
//   )
// }
const M_Contacts = (props) => {
  const classes = useStyles();
  const { templateId, value } = props;
  const [loading, setLoading] = React.useState(false);
  const [resultData, setResultData] = React.useState([]);

  const getData = () => {
    let result = props.data;
    if (result < 0) {
      props.onError(result.message, "contacts");
      setLoading(false);
    } else {
      setResultData(result);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    setLoading(true);
    getData();
  }, [value]);

  return (
    <React.Fragment>
      {loading ? <M_RenderSkeletion classes={classes} /> : ""}

      {!loading && resultData && resultData.length > 0 ? (
        <List className={classes.root}>
          {resultData.map((item, index) => (
            <M_RenderItems
              type={props.type}
              id={index}
              key={index}
              item={item}
              classes={classes}
              handleClick={props.handleClick}
            />
          ))}
        </List>
      ) : (
        ""
      )}
      {!loading && resultData && resultData.length < 1 ? (
        <M_NoContent classes={classes} />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default M_Contacts;
