import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, Typography, List } from '@material-ui/core';
import PieChart from "@material-ui/icons/PieChart";
import "./kpicallbox.scss";

export default function KpiCallBox(props) {
console.log('vvv',props.item.content.kpiBox)
  return (
    <div id="kpiCallBox">
      <Grid container spacing={3}>
        {props.item && props.item !==null && props.item.content && props.item.content !==null && props.item.content.kpiBox && props.item.content.kpiBox.length>0 && props.item.content.kpiBox.map(item=>{
          return  <Grid item xs={12} sm={6} md={4} lg={3}>
          <Box className='flexContainer'>
            <Box className='flex1stItem'>
              {item.ocon ==='piechart' &&(
                <PieChart className='iconFOnt' />
              )}            
            </Box>
            <Box className='flex2ndItem'>
            <Typography className='content2nd1st'>
            {item.label}
            </Typography>
            <Typography className='content2st2nd'>
            <span id={item.id}></span>
            </Typography>
              </Box>
          </Box>
      </Grid>
        })}
     
      </Grid>
    </div>
  );
}
