import React from "react";
import "./layout.scss";
import throttle from "lodash/throttle";
import A_Card from "../../../atoms/surfaces/cards/A_Card";
import InlineRecords from "../../../../../assets/images/defaults/inline-records.jpg";
import A_SimpleDialog from "../../../atoms/feedback/dialogs/A_SimpleDialog";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import M_NewsCard from "../../../Molecules/Common/Renderers/Views/NewsCards/M_NewsCard";
import M_ChartCard from "../../../Molecules/Common/Renderers/Views/Charts/M_ChartCard";
import M_MapCard from "../../../Molecules/Common/Renderers/Views/MapCards/M_MapCard";
import ViewFields from "../Fields/ViewFields";
import DisplaySections from "../DisplaySection/DisplaySection";
import DisplayTabs from "../DisplayTabs/DisplayTabs";
import M_ResultCard from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCard";
import ExcelTemplate from "../ExcelTemplate/ExcelTemplate";
import ReportEditor from "../ReportEditor/MainPage/ReportEditor";
import M_ManageEntityForm from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm";
import M_DropableFileUpload from "../../../Molecules/Common/File Upload/M_DropableFileUpload";
import PreviousTemplate from "../../../Molecules/Common/Renderers/Views/PreviousTemplate/PreviousTemplate";
import ErrorBoundary from "../../../molecules/common/error/ErrorBoundary";
import O_ChartDataEntry from "../../../Organisms/Common/ChartData/O_ChartDataEntry";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import O_DealTimeline from "../../../Organisms/Common/DealTimeline/O_DealTimeline";
import O_Pipeline from "../../../Organisms/Common/Pipeline/O_Pipeline";
import KpiCallBox from "../../../Organisms/Common/KpiCallBox/KpiCallBox";
import M_DealStatusBar from "../../../Molecules/Common/StatusBar/M_StatusBar";
import O_Historychart from "../../..//Organisms/Common/Historychart/O_Historychart";
import O_IRCDetails from "../../../Organisms/Common/IRCDetails/O_IRCDetails";
import O_MeetingSlotCard from "../../../Organisms/Common/MeetingSlotCard/O_MeetingSlotCard";
import Guidelines from "../../../Organisms/Common/Guidelines/Guidelines";
import ChecklistMaster from "../Checklist/ChecklistMaster";
import DealTeam from "../../../Pages/Common/DealTeam/DealTeam";
import O_CheckList from "../../../Organisms/Common/Checklist/O_Checklist";
import MapGoogle from "../../../Atoms/Map/A_GoogleMap";
import O_activeFacility from "../../../Organisms/Common/ActiveFacility/O_activeFacility";
import O_LendersApproached from "../../../Organisms/Common/LendersApproached/O_lendersApproached";
import M_PDFReader from "../../../Molecules/Common/PDFReader/M_PDFReader";
import M_MeetingPreference from "../MeetingPreferences/M_MeetingPreference";
import Image from "../../../Atoms/Image/A_Image";

class Layout extends React.Component {
  constructor(props) {
    super(props);

    let device = "";
    if (window.innerWidth < 600) {
      device = "xs";
    } else if (window.innerWidth >= 600) {
      device = "md";
    }
    this.state = {
      device: device,
      openCardZoom: false,
      openEditForm: false,
      openAddForm: false,
      openExport: false,
      formEntity: {},
      paramEntity: {},
      templateId: {},
      recordId: null,
      isIRCEdit: false,
      optionOnAdd:false,
      deleteOnAdd:false,
    };
  }

  handleClickOpen = (entity) => {
    this.setState((prevState) => {
      return {
        open: prevState.open === true,
      };
    });
  };

  handleZoom = (card, item) => {
    this.setState((prevState, prevProps) => {
      return {
        openCardZoom: !prevState.openCardZoom,
        cardProps: prevState.openCardZoom === true ? null : item,
      };
    });
  };

  handleEdit = (card, item, content) => {
    const templateID = card.edit.parameters.templateId;
    let recId = this.state.isIRCEdit ? this.state.recordId : null;
    const NewData = { templateId: templateID, recordId: recId };

    this.setState((prevState, prevProps) => {
      return {
        openEditForm: !prevState.openEditForm,
        cardProps: prevState.openEditForm === true ? null : item,
        paramEntity: NewData,
      };
    });
  };
  handleAdd = (card, item, content) => {
    const data = this.props.currentPage.pageConfig;
    const templateId = card.add.parameters.templateId;
    const parameters = card.add.parameters;
    let recordId = "";
    const NewData = { templateId, data, parameters, recordId };

    this.setState((prevState, prevProps) => {
      return {
        openAddForm: !prevState.openAddForm,
        cardProps: prevState.openAddForm === true ? null : item,
        templateId: templateId,
        paramEntity: NewData,
      };
    });
  };
  handleOptionOnAdd = (card, item, content) => {
    this.setState((prevState) => {
      return {
        optionOnAdd:true,
      };
    });
  };
  handleDeleteOnAdd = (card, item, content) => {
    this.setState((prevState) => {
      return {
        optionOnAdd:false,
      };
    });
  };

  handleCloseForm = () => {
    this.setState((prevState, prevProps) => {
      return {
        openEditForm: false,
        // cardProps: null,
        // paramEntity: null,
      };
    });
  };
  handleExport = (card, item) => {
    this.setState((prevState, prevProps) => {
      return {
        openExport: !prevState.openExport,
        cardProps: prevState.openExport === true ? null : item,
      };
    });
  };
  handleRecordID = (recId) => {
    this.setState((prevState) => {
      return {
        recordId: recId,
        isIRCEdit: true,
      };
    });
  };
  defaultCard = {
    gridColumn: "span 3",
  };
  handleClickOptionAdd = (item) => {
  const data = this.props.currentPage && this.props.currentPage.pageConfig ?this.props.currentPage.pageConfig:{};
      const templateId =item.templateId;
      const parameters = item;
     let recordId = "";
     const NewData = { templateId, data, parameters, recordId };
 
      this.setState((prevState, prevProps) => {
       return {
         openAddForm: !prevState.openAddForm,
        cardProps: prevState.openAddForm === true ? null : this.props.cards[0],
         templateId: templateId,
         paramEntity: NewData,
        };
      });
   };
 

  render() {
    var doit;
    const getDeviceConfig = (width) => {
      if (width < 600) {
        return "xs";
      } else if (width >= 600) {
        return "md";
      }
    };
    const calcInnerWidth = throttle(() => {
      let deviceType = getDeviceConfig(window.innerWidth);

      if (deviceType == "md" || deviceType == "xs") {
        this.setState({ device: deviceType });
      }
    }, 500);

    window.onresize = function () {
      clearTimeout(doit);
      doit = setTimeout(calcInnerWidth, 100);
    };

    const renderCardStyle = (item, device) => {
      const resultObject = item.position[device];
      return resultObject;
    };
    //TODO - Move showContentConditonal to separate component
    const showContentConditonal = (contentType, content, item) => {
      const { syncEdgeLabel } = item.header;
      switch (contentType) {
        case "crmdata":
          if (content && content.tabs) {
            return (
              <ErrorBoundary>
                <DisplayTabs tabs={content.tabs} data={this.props.data} />
              </ErrorBoundary>
            );
          } else if (content && content.sections) {
            return (
              <ErrorBoundary>
                <DisplaySections sections={content.sections} />
              </ErrorBoundary>
            );
          } else if (content && content.fields) {
            return (
              <ErrorBoundary>
                <ViewFields
                  fields={content.fields}
                  syncEdgeLabel={syncEdgeLabel}
                />
              </ErrorBoundary>
            );
          }
          break;
        case "chart":
          return (
            <ErrorBoundary>
              <M_ChartCard
                content={
                  content && content.chart
                    ? {
                        ...content.chart,
                        chartId: content.chart.id,
                        id: undefined,
                      }
                    : {}
                }
              />
            </ErrorBoundary>
          );
        case "maps":
          return (
            <ErrorBoundary>
              <M_MapCard content={content} />
            </ErrorBoundary>
          );
        case "news":
          return (
            <ErrorBoundary>
              <M_NewsCard content={content} />
            </ErrorBoundary>
          );
        case "resultTemplate":
          return (
            <ErrorBoundary>
              <M_ResultCard
                content={content}
                stateObj={this.state}
                item={item}
              />
            </ErrorBoundary>
          );
        case "documents":
          return (
            <ErrorBoundary>
              <M_DropableFileUpload content={content} />
            </ErrorBoundary>
          );
        case "previousTemplate":
          return (
            <ErrorBoundary>
              <PreviousTemplate content={content} />
            </ErrorBoundary>
          );
        case "chartEntry":
          return (
            <ErrorBoundary>
              <O_ChartDataEntry chart={content.chart} />
            </ErrorBoundary>
          );
        case "timeline":
          return (
            <ErrorBoundary>
              <O_DealTimeline content={content} />
            </ErrorBoundary>
          );
        case "guidelines":
          return (
            <ErrorBoundary>
              <Guidelines content={content} />
            </ErrorBoundary>
          );
        case "mapgoogle":
          return (
            <ErrorBoundary>
              <MapGoogle content={content} data={this.props.data} />
            </ErrorBoundary>
          );
        case "ircDetail":
          return (
            <ErrorBoundary>
              <O_Historychart maxHeight={content.maxHeight} content={content} />
            </ErrorBoundary>
          );
        case "dealAdministration":
          return (
            <ErrorBoundary>
              <O_IRCDetails content={content} callBack={this.handleRecordID} />
            </ErrorBoundary>
          );
        case "bookingSlot":
          return (
            <ErrorBoundary>
              <O_MeetingSlotCard
                maxHeight={content.maxHeight}
                content={content}
              />
            </ErrorBoundary>
          );
        case "dealsPipeLine":
          return (
            <ErrorBoundary>
              <O_Pipeline content={content} />
            </ErrorBoundary>
          );
        case "checklistMaster":
          return (
            <ErrorBoundary>
              <ChecklistMaster
                data={this.props.data.checklistResponse}
                percentComplete={this.props.data.checklistStatistics}
              />
            </ErrorBoundary>
          );
        case "activeFacility":
          return (
            <ErrorBoundary>
              <O_activeFacility />
            </ErrorBoundary>
          );
        case "lendersApproached":
          return (
            <ErrorBoundary>
              <O_LendersApproached />
            </ErrorBoundary>
          );
        case "managedBy":
          return (
            <ErrorBoundary>
              <DealTeam content={this.props.data} />
            </ErrorBoundary>
          );
        case "checklist":
          return (
            <ErrorBoundary>
              <O_CheckList ircID={this.state.recordId} config={content} />
            </ErrorBoundary>
          );
        case "PDFReader":
          return (
            <ErrorBoundary>
              <M_PDFReader />
            </ErrorBoundary>
          );
        case "Meeting_Preference":
          return (
            <ErrorBoundary>
              <M_MeetingPreference />
            </ErrorBoundary>
          );
        case "image":
          return (
            <ErrorBoundary>
              <Image content={this.props.data}></Image>
            </ErrorBoundary>
          );
        // case "dealsearchbar":
        //   return <SearchBar  />
      }
    };
    const getArticle = (item, device, index) => {
      const { title, toolbar, showPic, filtersData, addFiles } = item.header;
      const { type, content } = item.content;
      return (
        <article
          key={index}
          style={
            item && item.position
              ? renderCardStyle(item, device)
              : this.defaultCard
          }
        >
          {type == "dealstatusbar" ? (
            <ErrorBoundary>
              <M_DealStatusBar />{" "}
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type == "reportEditor" ? (
            <ErrorBoundary>
              <ReportEditor />{" "}
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type == "excelEditor" ? (
            <ErrorBoundary>
              <ExcelTemplate
                isExcelTemplateDownload={this.props.isExcelTemplateDownload}
                handleClosePopup={this.props.handleClosePopup}
              />
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type == "KpiCallBox" ? (
            <ErrorBoundary>
              <KpiCallBox item={item} />
            </ErrorBoundary>
          ) : (
            ""
          )}
          {type != "KpiCallBox" &&
          type != "reportEditor" &&
          type != "excelEditor" &&
          type != "dealstatusbar" ? (
            <A_Card
              elevation={2}
              width={50}
              header={{
                title: title ? title : "",
                filtersData: filtersData ? filtersData : "",
                addFiles: addFiles ? addFiles : "",
                showPic: showPic ? showPic : false,
                // zoom: toolbar && toolbar.zoom ? toolbar.zoom : false,
                // this feature is not require now, but i am keeping it commented as it may require in future
                secondaryMenu: false,
                optionAdd:this.state.optionOnAdd,
                addParameterOption:item && item.header&&item.header.toolbar &&item.header.toolbar.optionOnAdd?item.header.toolbar.optionOnAdd.parameters:{},
                callBack:this.handleClickOptionAdd,
                add:
                  toolbar && toolbar.add && toolbar.add.show
                    ? toolbar.add.show
                    : false,
                    optionOnAdd:
                    toolbar && toolbar.optionOnAdd && toolbar.optionOnAdd.show
                      ? toolbar.optionOnAdd.show
                      : false,
                edit:
                  toolbar && toolbar.edit && toolbar.edit.show
                    ? toolbar.edit.show
                    : false,
                export:
                  toolbar && toolbar.export && toolbar.export.show
                    ? toolbar.export.show
                    : false,
              }}
              action={
                item.footer && item.footer.content
                  ? handleFooter(item.footer)
                  : ""
              }
              content={
                <React.Fragment>
                  {content
                    ? showContentConditonal(type, content, item)
                    : item.fallbackMsg}
                </React.Fragment>
              }
              handleZoom={() => this.handleZoom(toolbar, item)}
              handleEdit={() => this.handleEdit(toolbar, item, content)}
              handleAdd={() => this.handleAdd(toolbar, item, content)}
              handleOptionOnAdd={() => this.handleOptionOnAdd(toolbar,item,content)}
              handleDeleteOnAdd={() => this.handleDeleteOnAdd(toolbar,item,content)}
              handleExport={() => this.handleExport(toolbar, item, content)}
              handleClickOptionAdd={this.handleClickOptionAdd}
            />
          ) : (
            ""
          )}
        </article>
      );
    };

    const handleFooter = (footer) => {
      if (footer.type === "buttons") {
        return footer.content.map((button) => {
          return <A_Button {...button} />;
        });
      } else {
        return footer.content;
      }
    };
    return (
      <>
        <article className="page-content-inner">
          {this.props.cards && this.props.cards.length > 0
            ? this.props.cards.map((item, index) => {
                {
                  return getArticle(item, this.state.device, index);
                }
              }, this)
            : ""}
        </article>

        <A_SimpleDialog
          open={this.state.openCardZoom}
          //title="Dialog Title"
          fullScreen={false}
          fullWidth={true}
          maxWidth={"md"}
          dialogContent={
            this.state.cardProps ? (
              <A_Card
                elevation={2}
                width={50}
                header={{
                  title: this.state.cardProps.header.title
                    ? this.state.cardProps.header.title
                    : "",
                  zoom: false,
                  secondaryMenu: false,
                  add: false,
                  edit: false,
                  export: false,
                }}
                content={
                  <React.Fragment>
                    {this.state.cardProps.content.content ? (
                      this.state.cardProps.content.type == "text" ? (
                        this.state.cardProps.content.content
                      ) : (
                        <img src={InlineRecords} />
                      )
                    ) : (
                      this.state.cardProps.fallbackMsg
                    )}
                  </React.Fragment>
                }
              />
            ) : (
              ""
            )
          }
          dialogActions={
            <A_Button
              label="Close"
              size="small"
              onClick={() => this.handleZoom("close")}
            />
          }
        />
        {this.state.openEditForm && (
          <ErrorBoundary>
            <M_ManageEntityForm
              crmData={
                this.state.isIRCEdit
                  ? this.props.managePage &&
                    this.props.managePage.data &&
                    this.props.managePage.data.data
                    ? this.props.managePage.data.data
                    : ""
                  : this.props.currentPage.data
              }
              isEditable={true}
              entity={this.state.paramEntity}
              open={this.state.openEditForm}
              onClose={() => this.handleCloseForm()}
            />
          </ErrorBoundary>
        )}
        {this.state.openAddForm && (
          <ErrorBoundary>
            <M_ManageEntityForm
              showPopup={true}
              templateId={this.state.templateId}
              //crmData={this.props.currentPage.data}
              entity={this.state.paramEntity}
              open={this.state.openAddForm}
              onClose={() => this.handleCloseForm()}
            />
          </ErrorBoundary>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    entityRecord: state.entityRecord,
    managePage: state.managePage,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(Object.assign({}), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
