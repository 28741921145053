import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import theme from "../../../../Themes/defaultTheme";
const A_IconButton = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <IconButton style={props.style}
        aria-label="upload picture"
        component="span"
        color={props.color}
        size={props.size}
        disabled={props.disabled}
        onClick={props.onClick}
        onDoubleClick={props.onDoubleClick}
        className={props.className}
      >
        {props.icon}
      </IconButton>
    </ThemeProvider>
  );
};
A_IconButton.defaultProps = {
  color: "primary",
  size: "medium",
  //style: {padding:'0px'}
};
A_IconButton.propTypes = {
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium"]),
  icon: PropTypes.element,

  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
};
export default A_IconButton;
