import {manageMenusActionType} from "../actions/actionTypes";

let initialState = {};
const manageMenusReducer = (state = initialState,action) => {  
    switch(action.type){ 
      case manageMenusActionType.GET_MENUS:
        let currState={...state};
        currState.isLoading=true;
        return currState; 
      case manageMenusActionType.GET_MENUS_SUCCESSED:
        return action.payload; 
      case manageMenusActionType.GET_MENUS_FAILED:
          return action.payload;         
      default:
        return state;
    }
  };
  export default manageMenusReducer;