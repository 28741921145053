import { currentPageActionTypes } from "./actionTypes";

export const getCurrentPage = (payload) => {
  return {
    type: currentPageActionTypes.GET_CURRENT_PAGE,
    payload,
  };
};
export const getCurrentPageSucceeded = (payload) => {
  return {
    type: currentPageActionTypes.GET_CURRENT_PAGE_SUCCESSED,
    payload,
  };
};

export const getCurrentPageFailed = (payload) => {
  return {
    type: currentPageActionTypes.GET_CURRENT_PAGE_FAILED,
    payload,
  };
};
