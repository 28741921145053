import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import * as managePageActions from "../../../../../../store/actions/managePageActions";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_HorizontalStepper from "../../../../Atoms/Navigation/Stepper/A_HorizontalStepper";
import { httpPost, httpPut } from "../../../../../../Utils/apis/apis";
import { showField } from "../../../../../../Utils/Helpers/common";
import ErrorBoundary from "../../../../molecules/common/error/ErrorBoundary";

const getDefaultValues = (steps) => {
  let elementsWithDefaultValues = [];
  for (let i = 0; i < steps.length - 1; i++) {
    let step = steps[i];
    if ("content" in step) {
      let elements = step.content.filter((ele) => {
        return ele.defaultValue && ele.defaultValue !== "";
      });
      if (elements.length > 0) {
        elementsWithDefaultValues = elementsWithDefaultValues.concat(elements);
      }
    }
  }
  return elementsWithDefaultValues;
};
const security = {
  read: {
    groupids: [""],
    users: [""],
  },
  update: {
    groupids: [""],
    users: [""],
  },
  full: {
    groupids: [""],
    users: [],
  },
  privateRecord: false,
};
const M_ManageEntityForm = (props) => {
  const [open, setOpen] = useState(props.open);
  const [showFormWarning, setShowFormWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formSavedResponse, setFormSavedResponse] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);

  const [formData, setFormData] = useState({ security, ...props.crmData });

  const [steps, setSteps] = useState([]);
  const [allSteps, setAllSteps] = useState([]);
  const [fields, setFields] = useState([]);
  const [optionSets, setOptionSets] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  let { crmId } = useParams();
  let { pageUrl } = useParams();
  let recordId = crmId;

  if (
    props.entity != null &&
    props.entity.recordId == "" &&
    props.editChart == true
  ) {
    props.entity.recordId = crmId;
  }

  if (props.entity != null && props.entity.recordId == null) {
    props.entity.recordId = crmId;
  }

  useEffect(() => {
    props.getPage(props.entity, recordId, props.templateId);
  }, [props.entity]);

  useEffect(() => {
    let templateJson = undefined;
    if (
      props.managePage &&
      props.managePage.data &&
      props.managePage.data.template
    ) {
      templateJson = props.managePage.data.template.templateJson;
      setFields(props.managePage.data.pageConfig.fields);
      setOptionSets(props.managePage.data.pageConfig.optionSets);
    }
    templateJson =
      templateJson !== "" && templateJson !== undefined
        ? JSON.parse(templateJson)
        : {};

    const stepsRaw = "stepper" in templateJson ? templateJson.stepper : [];
    if (stepsRaw.length > 0) {
      let formDataCopy = { ...formData };
      let elementsWithDefaultValues = getDefaultValues(stepsRaw);
      elementsWithDefaultValues.forEach((ele, index) => {
        let elementValue = formDataCopy[ele.id];
        if (!elementValue || (elementValue && elementValue == "")) {
          formDataCopy[ele.id] = ele.defaultValue;
        }
      });
      if (!props.editChart) {
        setFormData(formDataCopy);
      } else {
        if (props.entity && props.entity.hasContainer) {
          setFormData(props.managePage.data.data);
        } else {
          setFormData(props.managePage.data);
        }
      }

      setAllSteps(stepsRaw);
      //check for show-hide conditon on first load of form

      let visibleSteps = handleShowHide(stepsRaw);
      setSteps(visibleSteps);
    }
  }, [props.managePage]);

  const handleClose = (value) => {
    if (formSavedResponse || isError) {
      handleFinish();
    } else {
      setShowFormWarning(true);
    }
    setFormSavedResponse(false);
    setConfirmationPopup(false);
  };
  const handleWarningOkButton = () => {
    setShowFormWarning(!showFormWarning);
  };
  const handleWarningCloseButton = () => {
    setOpen(false); //closing form-dialog
    setFormSavedResponse(false);
    setConfirmationPopup(false);
    props.onClose();
    props.resetManagePage();
  };
  const handleFinish = () => {
    setOpen(false); //closing form-dialog
    setFormSavedResponse(false);
    setConfirmationPopup(false);
    setShowFormWarning(false); //closing incomplete-form warning.
    props.onClose(); //closing add-entities-list
  };
  const setEdgeLabelData = (newFormData) => {
    let edgearr = [];
    for (const key in newFormData) {
      if (
        key &&
        newFormData[key] &&
        typeof newFormData[key] == "object" &&
        newFormData[key]["fieldType"] &&
        newFormData[key]["fieldType"] == "lookup"
      ) {
        let resEdgeObject = {
          //inverse: true,
          label: newFormData[key]["edgeLabel"],
          guid: [newFormData[key]["id"]],
        };
        edgearr.push(resEdgeObject);
        newFormData[key] = undefined;
      }
    }
    return edgearr;
  };
  const handleFormSaved = async () => {
    setIsLoading(true);
    let newFormData = { ...formData };
    let managedByTitle =
      newFormData &&
      newFormData.managedBy &&
      newFormData.managedBy.properties &&
      newFormData.managedBy.properties.title
        ? newFormData.managedBy.properties.title
        : "";
    let edge = setEdgeLabelData(newFormData);

    let security = { ...newFormData.security };
    delete newFormData.security;
    let label = "";
    let pageId = "";
    if (props.isIRCSlot) {
      newFormData.ircDate = props.IRCSlotDate;
      newFormData.ircTime = props.IRCTime;
    }
    if (props.showPopup == true) {
      label =
        props.entity &&
        props.entity.parameters &&
        props.entity.parameters.pageUrl
          ? props.entity.parameters.pageUrl
          : "";
      pageId =
        props.entity && props.entity.data && props.entity.data.id
          ? props.entity.data.id
          : "";
    } else {
      label =
        props.entity && props.entity.pageName && props.entity.pageName
          ? props.entity.pageName
          : "";
      pageId = props.entity && props.entity.pageId ? props.entity.pageId : "";
    }
    if (
      props.entity &&
      props.entity.data &&
      props.entity.data.type &&
      props.entity.data.type === "Non-CRM"
    ) {
      pageId =
        props.managePage && props.managePage.data
          ? props.managePage.data.pageConfig.id
          : pageId;
    }
    let convertedFormData = {
      id: "null",
      label: label,
      type: "vertex",
      properties: newFormData,
      security: security,
      edges: edge,
    };

    let isAdd = false;
    if (convertedFormData.id == "null") {
      isAdd = true;
    }
    if (
      props.entity &&
      props.entity.parameters &&
      props.entity.parameters.hasContainer
    ) {
      const response = await httpPost(
        `/GenericDocument/${props.entity.parameters.hasContainer}/insert`,
        convertedFormData.properties
      );
      if ("message" in response) {
        setIsError(response);
        setIsLoading(false);
      } else {
        location.reload();
      }
    } else {
      const response = await httpPost(
        `/CRMData/${isAdd ? "insert" : "edit"}?pageId=${pageId}`,
        convertedFormData
      );
      let properties = convertedFormData.properties
        ? convertedFormData.properties
        : "";
      const res = response;
      let edgelabelData = "";
      if (props.showPopup == true || props.isAddEdge === true) {
        if (props.isAddEdge === true) {
          for (let i in edge) {
            crmId = edge[i].guid[0];
            edgelabelData = edge[i].label ? edge[i].label : "";
            break;
          }
        }
        edgelabelData = edgelabelData
          ? edgelabelData
          : props.edgeLabel
          ? props.edgeLabel
          : "";
                for (let i in edge) {
            if (edge[i].label === "inbound_invoice") {
              crmId = edge[i].guid[0];
              let url =
                "/inboundinvoice/" +
                res.id +
                "/9c3cb68f-2def-4c42-af95-1363febf6e13";
              let invoiceUrlData = {
                id: res.id,
                label: label,
                type: "vertex",
                properties: { url: url },
                security: security,
                edges: edge,
              };
              if (
                props.entity &&
                props.entity.parameters &&
                props.entity.parameters.pageUrl
              ) {
                const response = await httpPut(
                  `/CRMData/${"update"}?pageUrl=${
                    props.entity.parameters.pageUrl
                  }`,
                  invoiceUrlData
                );
              }
            }
          }
        
        let convertedFormData = {
          sourceVertexId: crmId,
          destinationVertexId: res.id,
          edgeLabel:
            props.entity &&
            props.entity.parameters &&
            props.entity.parameters.edgeLabel
              ? props.entity.parameters.edgeLabel
              : edgelabelData,
          properties:
            props.entity &&
            props.entity.parameters &&
            props.entity.parameters.isDealTeam
              ? {
                  firstName: managedByTitle,
                  role: newFormData.userRole,
                }
              : properties,
        };
        props.entity &&
        props.entity.parameters &&
        props.entity.parameters.createEdge
          ? (convertedFormData.properties = undefined)
          : "";
        if (props.isIRCSlot) {
          let payload = {
            sourceVertexId:
              response &&
              response.edges &&
              response.edges.length > 0 &&
              response.edges[0].guid &&
              response.edges[0].guid.length > 0
                ? response.edges[0].guid[0]
                : "",
            destinationVertexId: response.id,
            edgeLabel:
              response &&
              response.edges &&
              response.edges.length > 0 &&
              response.edges[0].label
                ? response.edges[0].label
                : "",
          };
          await httpPut(`/CRMData/addEdge`, payload);
          let payloadChecklist = {
            dealId: response.edges[0].guid[0],
            dealWorkFlowState: "OnBoarding",
            dateActive: props.IRCSlotDate.split(" ")[0],
            ircPurpose: response.properties.ircPurpose,
            dealStatus: "priority",
            action: "insert",
            dealIrcId: response.id,
          };
          await httpPost(`/DealChecklist/Insert`, payloadChecklist);
        } else {
          await httpPut(`/CRMData/addEdge`, convertedFormData);
        }

        setSteps([]);
        setOpen(false);
        location.reload();
      }

      if ("message" in response) {
        setIsError(response);
        setIsLoading(false);
      } else {
        if ("id" in response) {
          let { id } = response;
          setShowFormWarning(false);
          setFormSavedResponse(response);
          if (showPopup == false) {
            setConfirmationPopup(false);
          } else {
            setConfirmationPopup(true);
          }

          setIsLoading(false);
        }
      }
    }
  };

  const handleEditSaved = async () => {
    setIsLoading(true);
    let newFormData = { ...formData };
    let security = { ...newFormData.security };
    let edge = setEdgeLabelData(newFormData);
    delete newFormData.security;

    let convertedFormData = {
      id: props.IRCRecordId
        ? props.IRCRecordId
        : props.entity.recordId !== ""
        ? props.entity.recordId
        : crmId,
      label: pageUrl,
      type: "vertex",
      properties: newFormData,
      security: security,
      edges: edge,
    };
    let isAdd = false;
    if (convertedFormData.id == "null") {
      isAdd = true;
    }
    let response = "";
    if (props.entity && props.entity.hasContainer) {
      response = await httpPut(
        `/GenericDocument/${props.entity.hasContainer}/${newFormData.id}/update`,
        newFormData
      );
    } else {
      response = await httpPut(
        `/CRMData/${"update"}?pageUrl=${pageUrl}`,
        convertedFormData
      );
    }
    setOpen(false);
    if ("message" in response) {
      setIsError(response);
      setIsLoading(false);
    } else {
      if ("id" in response) {
        let { id } = response;
        setShowFormWarning(false);
        setFormSavedResponse(response);
        setIsLoading(false);
      }
    }
    let payload = {
      sourceVertexId:
        convertedFormData &&
        convertedFormData.edges &&
        convertedFormData.edges.length > 0 &&
        convertedFormData.edges[0].guid &&
        convertedFormData.edges[0].guid.length > 0
          ? convertedFormData.edges[0].guid[0]
          : "",
      destinationVertexId: convertedFormData.id,
      edgeLabel:
        convertedFormData &&
        convertedFormData.edges &&
        convertedFormData.edges.length > 0 &&
        convertedFormData.edges[0].label
          ? convertedFormData.edges[0].label
          : "",
    };
    payload.sourceVertexId &&
      payload.destinationVertexId &&
      payload.edgeLabel &&
      (await httpPut(`/CRMData/addEdge`, payload));

    setSteps([]);
    location.reload();
  };

  const handleActiveStep = (actStep) => {
    setActiveStep(actStep);
  };
  const handleFormData = (formData, fieldId) => {
    let visibleSteps = handleShowHide(allSteps, fieldId);
    setSteps(visibleSteps);
    setFormData(formData);
  };
  const handleShowHide = (allSteps, fieldId = undefined) => {
    let visibleSteps = allSteps.filter((step) => {
      if (!step.show || (step.show && showField(step.show, formData))) {
        return step;
      }
    });
    let visibleStepsNfields = visibleSteps.map((step) => {
      let visibleFields = step.content.filter((field) => {
        if (fieldId && field.id == fieldId) {
          field.focus = true;
        } else {
          field.focus = false;
        }
        if (!field.show || (field.show && showField(field.show, formData))) {
          return field;
        }
      });
      let newStep = {
        label: step.label,
        content: visibleFields,
      };

      return newStep;
    });
    return visibleStepsNfields;
  };

  return (
    <>
      <A_SimpleDialog
        style={{ margin: "auto", width: 300 }}
        open={open}
        IRCdate={props.IRCdate ? props.IRCdate : ""}
        title={
          props.managePage &&
          props.managePage.data &&
          props.managePage.data.template
            ? props.managePage.data.template.name
            : ""
        }
        maxWidth="sm"
        //width={300}
        onClose={handleClose}
        disableEscapeKeyDown={true}
        dialogContent={
          <React.Fragment>
            {props.managePage && !props.managePage.openManagePageForm && (
              <span>Loading form</span>
            )}
            {steps.length > 0 && (
              <ErrorBoundary>
                <A_HorizontalStepper
                  crmData={props.crmData}
                  isEditable={props.isEditable}
                  showPopup={props.showPopup}
                  steps={steps}
                  fields={fields}
                  optionSets={optionSets}
                  onFinish={
                    props.isEditable ? handleEditSaved : handleFormSaved
                  }
                  closeFormWarning={showFormWarning}
                  onHandleWarningOkButton={handleWarningOkButton}
                  onHandleWarningCloseButton={handleWarningCloseButton}
                  onSave={props.isEditable ? handleEditSaved : handleFormSaved}
                  isLoading={isLoading}
                  formSavedResponse={formSavedResponse}
                  confirmationPopup={confirmationPopup}
                  isError={isError}
                  activeStep={activeStep}
                  onActiveStepChanged={handleActiveStep}
                  onFormDataChange={handleFormData}
                  formData={formData}
                  entity={props.entity}
                />
              </ErrorBoundary>
            )}
          </React.Fragment>
        }
      ></A_SimpleDialog>
    </>
    // <A_VerticalStepper steps={steps} />
  );
};

M_ManageEntityForm.defaultProps = {
  title: "Add",
  label: [{ textFieldLabel: " Date" }, { textFieldLabel: " time" }],
  color: "primary",
};

function mapStateToProps(state) {
  return {
    managePage: state.managePage,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(managePageActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(M_ManageEntityForm);
