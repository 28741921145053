import React from "react";
import _ from 'lodash'
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { PostAdd } from "@material-ui/icons";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_MultiselectChip from "../../../Atoms/Inputs/MultiselectChip/A_MultiselectChip";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as manageExcelTemplateActions from "../../../../../store/actions/manageExcelTemplateActions";
import * as manageExcelTemplateDownloadAction from "../../../../../store/actions/manageExcelTemlateDownloadActions";
import Grid from "@material-ui/core/Grid";
import ExcelTemplateContent from "./ExcelTemplateContent";
import A_SimpleDialog from "../../../Atoms/feedback/dialogs/A_SimpleDialog";
import A_DatePicker from "../../../Atoms/Inputs/DateTimePickers/A_DatePicker";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import Skeleton from "@material-ui/lab/Skeleton";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { TabScrollButton } from "@material-ui/core";
import { useParams } from "react-router";

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // marginTop: "70px"
  },
  dragable: {
    // cursor: "move"
  },
  deleteButton: {
    marginTop: "15px",
    marginLeft: "10px",
  },
  rightAlign: {
    marginLeft: "auto",
    padding: "10px 23px",
  },
  selctBoxFilter: {
    minWidth: 240,
  },
  postAddIcon: {
    margin: '14px 12px',
    cursor: 'pointer'
  },
  textFieldWrapper: {
    minWidth: 150,
    display: 'flex'
  }
}));

const getfilteredTabs = (arr) => {
  let res = arr.map((item, index) => {
    item["id"] = index + 1;
    return item;
  });
  res && res.length > 0
    ? res.sort(function (a, b) {
        if (
          a &&
          a.templateMetrics &&
          a.templateMetrics.length > 0 &&
          a.templateMetrics[0].sortOrder &&
          a.templateMetrics[0].sortOrder &&
          b &&
          b.templateMetrics &&
          b.templateMetrics.length > 0 &&
          b.templateMetrics[0].sortOrder
        ) {
          if (a.templateMetrics[0].sortOrder < b.templateMetrics[0].sortOrder)
            return -1;
          if (a.templateMetrics[0].sortOrder > b.templateMetrics[0].sortOrder)
            return 1;
          return 0;
        }
      })
    : res;
  return res;
};
var selectedSeries = null;
let initialState = [
  {
    tabTitle: "Default Tab",
    id: "1",
    templateMetrics: [
      {
        sortOrder: 1001,
        parentName: null,
        name: "Default Metrix",
        level: 0,
        type: "header",
        isBalance: false,
        isFixed: false,
        isCalculated: false,
        signConstraint: 0,
        rowBreakAfter: false,
        showOnTemplate: true,
        action: "INSERT",
      },
    ],
  },
];
const ExcelTemplate = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [showTextField, setShowTextField] = React.useState(false);
  let tempVal = "";
  let ele = null;
  const [isdownloadExcelPopup, setIsdownloadExcelPopup] = React.useState(false);
  const [showTabAsFields, setShowTabAsFields] = React.useState(false);
  // const [tabArray, setTabArray] = React.useState(props.manageExcelTemplates && props.manageExcelTemplates.length > 0 ? getfilteredTabs(props.manageExcelTemplates) : [])
  const [tabs, setTabs] = React.useState([]);
  const [newlyAddedTab, setNewlyAddedTab] = React.useState({});

  const [deletedArray, setDeletedArray] = React.useState([]);
  const [isTabDelete, setIsTabDelete] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [deleting, setDeleting] = React.useState(false);
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    type: "success",
    msg: "",
  });
  const [loadingTabs, setLoadingTabs] = React.useState(true);
  let { crmId } = useParams();
  const [investmentId, setInvestmentId] = React.useState(
    crmId ? crmId : "4E6A21C1-CEEA-E811-80D3-005056BE5CE6"
  );
  // const [investmentId , setInvestmentId] =React.useState("4E6A21C1-CEEA-E811-80D3-005056BE5CE6")

  // blur event on tabs after entering tab name
  const handleBlurEvent = (e) => {
    setShowTextField(false);
    if (e.target.value) {
      let arrLength = tabs.length + 1;
      setTabs([
        ...tabs,
        { tabTitle: e.target.value, templateMetrics: [], id: arrLength },
      ]);
      props.setTabs([
        ...tabs,
        { tabTitle: e.target.value, templateMetrics: [], id: arrLength },
      ]);
      setValue(arrLength-1);
      // props.setTabs([...props.manageExcelTemplates, { "title": e.target.value, "content": [] ,"id":arrLength}])
    } else {
      // let arrLength=tabs.length+1
      setTabs([...tabs]);
      props.setTabs([...tabs]);
    }
  };

  const handleEditedBlurEvent = (e, val) => {
    setShowTabAsFields(false);
    if (e.target.value) {
      let arrNonChanged = tabs.filter((item) => item.id != val);
      let arrChanged = tabs.filter((item) => item.id == val);
      if (arrChanged && arrChanged.length > 0) {
        arrChanged[0].tabTitle = e.target.value;
        let metrics =
          arrChanged[0] && arrChanged[0].templateMetrics.length > 0
            ? arrChanged[0].templateMetrics.map((item) => {
                item["action"] =
                  item["action"] &&
                  (item["action"] == "INSERT" || item["action"] == "DELETE")
                    ? item["action"]
                    : "UPDATE";
                return item;
              })
            : [];
        arrChanged[0].templateMetrics = metrics;
        arrChanged[0]["showTabAsFields"] = false;
      }
      setTabs([...arrNonChanged, ...arrChanged]);
      props.setTabs([...arrNonChanged, ...arrChanged]);
    } else {
      // let arrLength=tabs.length+1
      setTabs([...tabs]);
      props.setTabs([...tabs]);
    }
  };

  const handleChange = (event, newValue) => {   
      setValue(newValue);
  };

  const handleDoubleClick = (e, id) => {
    showTextField && setShowTextField(false);

    let arr = tabs.map((item) => ({...item, showTabAsFields: item.id == e  }));
    setTabs([...arr]);
    props.setTabs([...arr]);
  };

  const onClickPostAdd = () => {
    setShowTextField(true);
    if(tabs.some(el => el.showTabAsFields)) {
      const arr = tabs.map((item) => ({...item, showTabAsFields: false  }));
      setTabs([...arr]);
      props.setTabs([...arr]);  
    }
  }
  const handleDragStart = (e) => {
    ele = e.target;

    e.target.style.opacity = "0.4";
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.innerHTML);
  };
  const handleDragOver = (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }

    e.dataTransfer.dropEffect = "move";

    return false;
  };

  const handleDrop = (e) => {
    if (e.stopPropagation) {
      e.stopPropagation(); // stops the browser from redirecting.
    }

    if (ele != e.target) {
      ele.style.opacity = "";
      e.target.style.opacity = "";
      let temphtml = ele.innerHTML;
      ele.innerHTML = e.target.innerHTML;
      e.target.innerHTML = temphtml;
      e.target.innerHTML = e.dataTransfer.getData("text/html");
    }

    return false;
  };

  const handleKeyDownAddTextBox = (e, id) => {
    if (e.key === "Enter") {
      if (!id) {
        handleBlurEvent(e);
      } else {
        handleEditedBlurEvent(e, id);
      }
    }
  };
  const handleDeleteTab = (e, id) => {
    if (id == 0) {
      setShowTextField(false);
    } else if (id && id > 0) {
      setIsTabDelete(true);
      setDeleteId(id);
      return;
      // let arr = tabs.filter((item) => item.id != id)

      // setTabs([...arr])
      // props.setTabs([...arr])

      // //// hold deleted items
      // let arrChanged = tabs.filter((item) => item.id == id)
      // if(arrChanged && arrChanged.length>0){

      //     let metrics =arrChanged[0] &&
      //     arrChanged[0].templateMetrics.length>0 ?
      //     arrChanged[0].templateMetrics.map(item=>{
      //         if(item["action"] && item["action"]=="INSERT"){
      //             return false

      //         }else{
      //             item["action"]="DELETE"
      //             return item
      //         }

      //     }):[];
      //     arrChanged[0].templateMetrics=metrics
      // }
      // let finalRes= [...arr , ...arrChanged]
      // // setDeletedArray([...arrChanged ,...deletedArray])

      // props.saveTabs({data:finalRes ,investmentId:investmentId})
    }
  };

  const getDeletedIndex = (arr) => {
    let res =
      arr && arr.length > 0
        ? arr.map((item, index) => {
            item.templateMetrics = item.templateMetrics.map((child, i) => {
              let rowIndex = i + 1;
              let tabIndex = (index + 1) * 1000;
              child.sortOrder = tabIndex + rowIndex;
              child.action =
                child.action == "NOCHANGE" ? "UPDATE" : child.action;
              return child;
            });
            return item;
          })
        : [];
    return res;
  };
  const confrimDelete = () => {
    let arr = tabs.filter((item) => item.id != deleteId);
    //// hold deleted items
    let arrChanged = tabs.filter((item) => item.id == deleteId);
    if (arrChanged && arrChanged.length > 0) {
      let metrics =
        arrChanged[0] && arrChanged[0].templateMetrics.length > 0
          ? arrChanged[0].templateMetrics.map((item) => {
              if (item["action"] && item["action"] == "INSERT") {
                return false;
              } else {
                item["action"] = "DELETE";
                return item;
              }
            })
          : [];
      arrChanged[0].templateMetrics = metrics;
    }
    let value = getDeletedIndex(arr);
    let finalRes = [...value, ...arrChanged];
    // setDeletedArray([...arrChanged ,...deletedArray])
    // setIsTabDelete(false)
    props.saveTabs({ data: finalRes, investmentId: investmentId });
    setDeleteId(null);
    setDeleting(true);
    
   };

  const closeDeleteBox = () => {
    setIsTabDelete(false);
    setDeleteId(null);
  };

  const handleDownloadExcel = () => {
    setIsdownloadExcelPopup(true);
  };
  const handleClosePopup = () => {
    setIsdownloadExcelPopup(false);
    props.handleClosePopup('ExceltemplateDownload');
  };

  React.useEffect(() => {
    if(props.isExcelTemplateDownload)
      setIsdownloadExcelPopup(true);
  }, [props.isExcelTemplateDownload]);

  React.useEffect(() => {
    props.getTabs({ investmentId: investmentId });
  }, []);

  // React.useEffect(() => {
  //         props.setTabs([...tabs])
  // }, [tabs]);

  React.useEffect(() => {
    if (deleting && props.manageExcelTemplates.saveTabsSuccessed) {
      setChangesSaved({
        status: true,
        type: "success",
        msg: "deleted successfully",
      });
      setIsTabDelete(false);
      setValue(0);
      setTabs([...props.manageExcelTemplates.data]);
      setDeleteId(null);
      setDeleting(false);
      props.setTabs([...props.manageExcelTemplates.data]);
    }
  }, [
    props.manageExcelTemplates && props.manageExcelTemplates.saveTabsSuccessed,
  ]);

  React.useEffect(() => {
    if (props.manageExcelTemplates && props.manageExcelTemplates.length > 0) {
      setLoadingTabs(false);
      setTabs([...getfilteredTabs(props.manageExcelTemplates)]);
    } else if (
      props.manageExcelTemplates &&
      props.manageExcelTemplates.length == 0
    ) {
      initialState[0].templateMetrics[0]["investmentId"] = investmentId;
      props.setTabs([...getfilteredTabs(initialState)]);
    }
  }, [props.manageExcelTemplates]);

  React.useEffect(() => {
    return function cleanup() {
      props.setTabs(null);
      setTabs(null);
    };
  }, []);

  const onCancelRowChange = () => {
    props.setTabs([]);
    props.getTabs({ investmentId: investmentId });
  };

  const renderLoader = () => {
    return (
      <div className={classes.root}>
        <Typography variant="h1">
          <Skeleton
            animation="wave"
            style={{ marginTop: "-160px", padding: "25" }}
          />
        </Typography>
        <Skeleton animation="wave" style={{ padding: "20", margin: "20px" }} />
        <Skeleton animation="wave" style={{ padding: "20", margin: "20px" }} />
        <Skeleton animation="wave" style={{ padding: "20", margin: "20px" }} />
        <Skeleton animation="wave" style={{ padding: "20", margin: "20px" }} />
        <Skeleton animation="wave" style={{ padding: "20", margin: "20px" }} />
        <Skeleton
          animation="wave"
          style={{
            padding: "40",
            width: "20%",
            marginRight: "46px",
            marginTop: "25vh",
            textAlign: "right",
            float: "right",
          }}
        />
      </div>
    );
  };

  const renderDownloadExcelPopup = (callback) => {
    const [dateValue, setDateValue] = React.useState(new Date());
    const [dateRange, setDateRange] = React.useState("sindleYear");

    let downloading = false;
    const filterObject = {
      dateRange: dateRange,
      singleYear: new Date(),
      startMonth: new Date(),
      endMonth: new Date(),
      series: selectedSeries ? selectedSeries : "Actual",
    };
    const requestPayload = {
      Tabs:
        props.manageExcelTemplates && props.manageExcelTemplates.length > 0
          ? props.manageExcelTemplates.map((item) => item.tabTitle)
          : [],
    };

    const seriesOptions = [
      { label: "Actual", value: "Actual" },
      { label: "Forecast", value: "Forecast" },
      { label: "Budget", value: "Budget" },
      { label: "Underwritten", value: "Underwritten" },
    ];
    const onDateRangeChange = (e, val) => {
      filterObject.dateRange = val;
      setDateRange(val);
    };
    const handleDateChange = (date, id) => {
      filterObject[id] = date;
    };

    const handleSeriesChange = (val, id) => {
      filterObject[id] = val.value;
      selectedSeries = val.value;
    };
    const getDateRange = (data) => {
      let end = data["startMonth"];
      let start = data["endMonth"];
      var months;
      months = (start.getFullYear() - end.getFullYear()) * 12;
      months -= end.getMonth();
      months += start.getMonth();
      return months <= 0 ? 0 : months;
    };
    const handleDownloadExcel = (e) => {
      if (
        !requestPayload ||
        !requestPayload.Tabs ||
        requestPayload.Tabs.length < 1
      ) {
        return;
      }
      let year = null;
      let startMonth = null;
      let incrementMonth = null;
      if (filterObject["dateRange"] == "sindleYear") {
        startMonth = 1;
        incrementMonth = 11;
        let d = new Date(filterObject.singleYear);
        year = d.getFullYear();
      } else {
        filterObject["singleYear"] = undefined;
        let d = new Date(filterObject.startMonth);
        incrementMonth = getDateRange(filterObject);
        year = d.getFullYear();
        startMonth = 1 + d.getMonth();
      }
      let param = {};
      param["series"] = filterObject["series"];
      param["fromYear"] = year;
      param["incrementMonth"] = incrementMonth + 1;
      param["dealId"] = investmentId;
      param["startMonth"] = startMonth;
      e.target["innerText"] = "Downloading....";
      e.currentTarget["style"]["opacity"] = 0.4;
      e.currentTarget["disabled"] = true;
      downloading = true;
      param["requestPayload"] = requestPayload;
      props.downloadExcel(param);
    };

    React.useEffect(() => {
      if (props.manageExcelTemplateDownload.downloadExcelSuccessed) {
        const url = window.URL.createObjectURL(
          new Blob([props.manageExcelTemplateDownload.data])
        );
        const link = document.createElement("a");
        link.href = url;
        // This has to discussed and fixed w.r.t the standardized name of the project
        let docName = `${props.currentPage.data["Investment Name"]}_${props.manageExcelTemplateDownload.year}.xlsx`;
        link.setAttribute("download", docName); 
        link.click();
        callback();
        props.downloadExcelSuccessed({});
      }
    }, [
      props.manageExcelTemplateDownload &&
        props.manageExcelTemplateDownload.downloadExcelSuccessed,
    ]);

    const handleTabsSeletion = (value) => {
      requestPayload.Tabs = value;
      console.log(value);
    };
    return (
      <div>
        <div>
          <RadioGroup
            id="type"
            // className={classes.RadioGroup}
            defaultValue={dateRange}
            // onChange={handleInputChange}
          >
            <FormControlLabel
              control={
                <Radio
                  color="primary"
                  value="sindleYear"
                  id="type"
                  onClick={(e) => onDateRangeChange(e, "sindleYear")}
                  // onClick={onClickCrmEvent}
                />
              }
              label="Financial Year"
            ></FormControlLabel>

            <FormControlLabel
              control={
                <Radio
                  value="range"
                  id="type"
                  color="primary"
                  onClick={(e) => onDateRangeChange(e, "range")}
                  // onClick={onClickEvent}
                />
              }
              label="Date Range"
            ></FormControlLabel>
          </RadioGroup>
        </div>

        {dateRange == "sindleYear" ? (
          <A_DatePicker
            id="singleYear"
            views={["year"]}
            defaultValue={filterObject.singleYear}
            onChange={handleDateChange}
            format="yyyy"
          />
        ) : (
          ""
        )}
        {dateRange == "range" ? (
          <>
            <div>
              <A_DatePicker
                id="startMonth"
                views={["year", "month"]}
                defaultValue={filterObject.startMonth}
                label="Start Month"
                onChange={handleDateChange}
                format="default"
              />
            </div>
            <div>
              <A_DatePicker
                id="endMonth"
                views={["year", "month"]}
                defaultValue={filterObject.endMonth}
                label="End Month"
                onChange={handleDateChange}
                format="default"
              />
            </div>
          </>
        ) : (
          ""
        )}

        <div>
          <A_Select
            className={classes.selctBoxFilter}
            id="series"
            options={seriesOptions}
            defaultValue={filterObject.series}
            onChange={handleSeriesChange}
          />
        </div>

        <div style={{ paddingTop: "10px" }}>
          <A_MultiselectChip
            required={true}
            message={"Please select at least one tab to download excel"}
            names={requestPayload.Tabs}
            label={"Tabs"}
            handleTabsSeletion={handleTabsSeletion}
            width={202}
          />
        </div>
        <div style={{ paddingTop: "20px" }}>
          <A_Button
            onClick={handleDownloadExcel}
            color="primary"
            label="Download Template"
            disabled={downloading}
            endIcon={
              downloading ? (
                <A_CircularIndeternment color="secondary" size={20} />
              ) : (
                <></>
              )
            }
          />
        </div>
      </div>
    );
  };
  const clearDeleteArr = () => {
    setDeletedArray(null);
  };
  const setTabValue = (value, arr) => {
    setValue(null);
    props.setTabs([]);

    setTimeout(() => {
      props.setTabs([...arr]);
      setValue(value);
    }, 200);
  };
  const handleSnackbarClose = () => {
    setChangesSaved({
      status: false,
      type: "",
      msg: "",
    });
  };
  return (
    <div className={classes.root}>
      {tabs && tabs.length > 0 ? (
        <span
          className={classes.rightAlign}
          style={{
            minWidth: "185px",
            right: "2%",
            position: "fixed",
            marginTop: "-70px",
            padding: "10px",
            float: "right",
            marginRight: "30px",
            padding: "10px,",
            zIndex: "9",
          }}
        >
        </span>
      ) : (
        ""
      )}
      <AppBar position="static" color="default">
        <Grid container spacing = {1}>
        <Grid item xs = {10}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"

          // style={{width:"90%"}}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs && tabs.length > 0
            ? tabs.map((item, index) => {
                return item && !item.showTabAsFields ? (
                  <Tab
                    // draggable="false"
                    // onDrop={handleDrop}
                    // onDragOver={handleDragOver}
                    // onDragStart={handleDragStart}
                    // className={classes.dragable}
                    key={item.id}
                    label={item.tabTitle}
                    {...a11yProps(index)}
                    onDoubleClick={() => handleDoubleClick(item.id)}
                  />
                ) : (
                  <div className={classes.textFieldWrapper}>
                    <A_TextField
                      label="Enter Tab Name"
                      value={item.id}
                      defaultValue={item.tabTitle}
                      onKeyPress={(e) => handleKeyDownAddTextBox(e, item.id)}
                      onBlur={(event) => handleEditedBlurEvent(event, item.id)}
                    />
                    <ClearIcon
                      className={classes.deleteButton}
                      onClick={(e) => handleDeleteTab(e, item.id)}
                    />
                  </div>
                );
              })
            : ""}
        </Tabs>
        </Grid>
        <Grid item xs = {2} style={{textAlign: "center"}}>
        {showTextField ? (
            <>
              <A_TextField
                // style={{minWidth:"150px",padding:"0px 10px"}}
                label="Enter Tab Name"
                defaultValue={tempVal}
                onKeyPress={handleKeyDownAddTextBox}
                onBlur={handleBlurEvent}
              />
              {/* <ClearIcon
                className={classes.deleteButton}
                onClick={(e) => handleDeleteTab(e, 0)}
              /> */}
            </>
          ) : (
            <PostAdd className={classes.postAddIcon} onClick={onClickPostAdd} />
          )}
          </Grid>
          </Grid>
      </AppBar>

      {!loadingTabs && tabs && tabs.length > 0
        ? tabs.map((item, index) => {
            return (
              <TabPanel key={index} value={value} index={index}>
                {item && item.templateMetrics ? (
                  <ExcelTemplateContent
                    isdownloadExcelPopup = {isdownloadExcelPopup}
                    onCancelRowChange={onCancelRowChange}
                    setTabValue={setTabValue}
                    index={index}
                    clearDeleteArr={clearDeleteArr}
                    deletedArray={deletedArray}
                    content={item.templateMetrics}
                    item={item}
                    investmentId={investmentId}
                  />
                ) : (
                  ""
                )}
              </TabPanel>
            );
          })
        : renderLoader()}

      <A_SimpleDialog
        open={isTabDelete}
        title=""
        fullScreen={false}
        height="400px"
        fullwidth={true}
        maxWidth={"xs"}
        onClose={handleClosePopup}
        dialogContent={
          !deleting ? (
            "Are you sure you want to delete this tab"
          ) : (
            <A_CircularIndeternment />
          )
        }
        dialogActions={
          <Grid container>
            <Grid item xs={6}>
              <A_Button label="No" color="default" onClick={closeDeleteBox} />
            </Grid>
            <Grid item xs={6}>
              <A_Button label="Yes" color="secondary" onClick={confrimDelete} />
            </Grid>
          </Grid>
        }
      />
      <A_SimpleDialog
        open={isdownloadExcelPopup}
        title="Download Excel Template"
        fullScreen={false}
        height="400px"
        fullwidth={true}
        maxWidth={"xs"}
        onClose={handleClosePopup}
        closePopUp={handleClosePopup}
        dialogContent={renderDownloadExcelPopup(function () {
          handleClosePopup();
        })}
      />
      <A_Snackbar
        open={changesSaved.status}
        message={changesSaved.msg}
        type={changesSaved.type}
        autoHideDuration={2000}
        horizontal="center"
        handleClose={handleSnackbarClose}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    manageExcelTemplates: state.manageExcelTemplates,
    manageExcelTemplateDownload: state.manageExcelTemplateDownload,
    currentPage: state.currentPage,
    filters: state.filters,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    Object.assign(
      {},
      manageExcelTemplateActions,
      manageExcelTemplateDownloadAction
    ),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExcelTemplate);
