import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import theme from "../../../../themes/defaultTheme";

const A_AppBar = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <AppBar variant={props.variant} color={props.color} position={props.position}>
      {props.content}
      </AppBar>
    </ThemeProvider>
  );
};
A_AppBar.defaultProps = {
  content: "Minerva Cloud",
  color:"primary",
  variant: "elevation",
  position: "static"
};
export default A_AppBar;
