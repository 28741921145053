import React from "react";
import M_LayoutMenu from "../../../Molecules/Common/Navigations/LayoutMenu/M_LayoutMenu";

const O_LayoutMenu = (props) => {
  const { data } = props;
  return (
    <M_LayoutMenu
      {...props}
      layouts={data}
      openLayoutDrawer={props.openLayoutDrawer}
      callback={(currentItem) => props.callbackParent(currentItem)}
    />
  );
};

export default O_LayoutMenu;
