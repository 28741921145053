import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { ListItemText } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import * as httpCall from "../../../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import axios, { post } from "axios";
import excel from "../../../../../../../Assets/Images/Defaults/excel.png";
import * as commonFunctions from "../../../../../../../Utils/Helpers/common";
import { getFormattedDate } from "../../../../../../../Utils/Helpers/dateUtils";
const useStyles = makeStyles((theme) => ({
  CustomCard: {
    maxHeight: "50vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
  },
}));

const PreviousTemplate = (props) => {
  const classes = useStyles();
  const [reports, setReports] = useState([]);

  let { crmId } = useParams();
  const investmentId = crmId ? crmId : "4E6A21C1-CEEA-E811-80D3-005056BE5CE6";
  const fileListWithDisplayName = (fileList) => {
    let listwithdisplayname = [];
    if(fileList ){
    fileList.map((file) => {
      let fileArr = file.file.split("/");
      if (fileArr.length > 3) {
        file = { ...file, fileDisplayName: fileArr[3] };
        listwithdisplayname.push(file);
      }
    });
  }
    return listwithdisplayname;
  };
  React.useEffect(() => {
    const getReports = async () => {
      let url=""
      if(investmentId!=="0" ){
         url = `${process.env.API_URL}/DimSource/getDimSourceWithInvestmentId/${investmentId}`;
      const result = await httpCall.httpGet(url);
      if (result) {
        let filesList = fileListWithDisplayName(result);
        setReports(filesList);
      }
    };
  }
    getReports();
  }, []);
  const handleClick = async (e) => {
    const documentName = e.file;
    const url = `${process.env.DOCUMENT_HELPER}`;
    const httpConfig = commonFunctions.getHttpConfig();
    //important
    /* let subdomain =
      "dev";  new URL(window.location.href).hostname.split(".")[0] */
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    let header = {
      filename: documentName,
      type: "excel",
      "file-operation": "download",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
      Authorization: httpConfig.headers.Authorization,
    };
    axios
      .post(
        url,
        {
          method: "GET",
        },
        { responseType: "blob", headers: header }
      )
      .then((response) => {
        const url1 = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url1;
        link.setAttribute("download", e.fileDisplayName);
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <div className={classes.CustomCard}>
      {reports && reports.length > 0
        ? reports.map((item) => (
            <List style={{ width: "332px" }}>
              <Divider style={{ width: "332px" }} />
              <div style={{ display: "inline-flex" }}>
                <ListItemAvatar style={{ margin: "auto" }}>
                  <Avatar src={excel} variant="square" />
                </ListItemAvatar>
                <ListItem
                  alignItems="flex-start"
                  button
                  onClick={() => handleClick(item)}
                >
                  <ListItemText
                    primary={item.fileDisplayName}
                    secondary={
                      "Uploaded on " +
                      getFormattedDate(item.dateLoaded, "DD-MM-YYYY")
                    }
                  />
                </ListItem>
              </div>
            </List>
          ))
        : ""}
    </div>
  );
};

export default PreviousTemplate;
