import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import M_IRChead from "../../../Molecules/Common/DealAdministartion/M_IRChead";
import { Divider } from "@material-ui/core";
import { useParams } from "react-router";
import { httpGet, httpPost } from "../../../../../Utils/apis/apis";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import TodayIcon from "@material-ui/icons/Today";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loggedInUserIdActions from "../../../../../store/actions/loggedInUserIdActions";
import M_IRCdetail from "../../../Molecules/Common/DealAdministartion/M_IRCdetail";

const useStyles = makeStyles({
  noData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "smaller",
    fontWeight: "500",
  },
  icon: {
    height: "40px",
    width: "50px",
    color: "#3d5afe",
  },
});
function M_MeetingPreference(props) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [selectedIRC, setSelectedIRC] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [propsdata, setPropsData] = useState({});
  const [currentuser, setCurrentuser] = React.useState("");
  const [currentuserdata, setcurrentuserdata] = React.useState("");
  const [sendcurrentuser, setsendCurrentuser] = React.useState("");

  const handleID = (id, item) => {
    setPropsData(item);
    setSelectedIRC(id);
  };
  const callback = () => {
    getData();
  };
  const handleSelect = (id, ind, item) => {
    return selectedIRC ? id == selectedIRC : ind == 0;
  };
  let getData = async () => {
    if (data.length == 0) {
      setIsLoading(true);
    }
    if (currentuser !== "") {
      setsendCurrentuser(currentuser);
      var response = await httpGet(
        `${process.env.API_URL}/Meeting/GetUserMeetings?userId=${currentuser}`
      );
      if (response.length > 0) {
        setPropsData(response[0]);
      }
      setIsLoading(false);
      setData(response);
    }
  };
  useEffect(() => {
    props.getLoggedInUser();
    setCurrentuser(
      props.loggedIn &&
        props.loggedIn.data &&
        props.loggedIn.data.length &&
        props.loggedIn.data[0]
        ? props.loggedIn.data[0].id
        : ""
    );
    setcurrentuserdata(
      props.loggedIn && props.loggedIn.data ? props.loggedIn.data : ""
    );
    getData();
  }, [currentuser]);

  function handleSave(index) {
    var data = meetingData.filter((item, ind) => index != ind);
    setmeetingData(data);
  }

  return (
    <>
      {isLoading && (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      )}

      <Box display={"flex"}>
        {!isLoading ? (
          data.length > 0 ? (
            <>
              <Box
                width={"60%"}
                borderRight={"2px solid #f0f0f0"}
                style={{ maxHeight: "70vh", overflow: "auto" }}
              >
                {data.map((item, index) => {
                  return (
                    <Box
                      onClick={() => {
                        handleID(item.id, item);
                      }}
                    >
                      <M_IRChead
                        modify="true"
                        Icon={<TodayIcon className={classes.icon} />}
                        data={item}
                        selected={handleSelect(item.id, index, item)}
                      ></M_IRChead>
                      <Divider></Divider>
                    </Box>
                  );
                })}
              </Box>

              <Box
                width={
                  props.content && props.content.displayIRCList ? "60%" : "100%"
                }
                style={{ maxHeight: "50vh", overflow: "auto" }}
              >
                <M_IRCdetail
                  data={propsdata}
                  loggedinUserData={currentuserdata}
                  loggedinUser={currentuser}
                  modify="true"
                  callback={callback}
                  default="Select"
                />
              </Box>
            </>
          ) : (
            <div className={classes.noData}>
              <p>NO MEETING FOUND!</p>
            </div>
          )
        ) : (
          ""
        )}
      </Box>
    </>
  );
}
function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(Object.assign({}, loggedInUserIdActions), dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(M_MeetingPreference);
