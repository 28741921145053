
import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import theme from "../../../../Themes/defaultTheme";
const A_IconTextButton = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Button style={props.style}
        aria-label="upload picture"
        component="span"
        color={props.color}
        variant="contained"
        size={props.size}
        disabled={props.disabled}
        onClick={props.onClick}
        onDoubleClick={props.onDoubleClick}
        className={props.className}
        startIcon={props.icon}
        
      >
         {props.label}
      </Button>
    </ThemeProvider>
  );
};
A_IconTextButton.defaultProps = {
  color: "warning",
  size: "medium",
  label: "Button",
  variant: "contained",
  style: {marginRight:"10px",color:"#ffffff",backgroundColor:"#FFA500"}
};
A_IconTextButton.propTypes = {
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary","warning"]),
  size: PropTypes.oneOf(["small", "medium"]),
  icon: PropTypes.element,

  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
};
export default A_IconTextButton;
