import React from "react";
import ViewFields from "../Fields/ViewFields";
import M_ChartCard from "../../../Molecules/Common/Renderers/Views/Charts/M_ChartCard";
import M_MapCard from "../../../Molecules/Common/Renderers/Views/MapCards/M_MapCard";
import M_ResultCard from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCard";

const DisplaySections = (props) => {
  const [sections, setSections] = React.useState(
    props.sections ? props.sections : []
  );
  return (
    <div>
      {sections && sections.length > 0
        ? sections.map((item, index) => {
            return (
              <div key={item.id}>
                <h5 key={item.id + "title"}>{item.title}</h5>
                {item.content && item.content.fields ? (
                  <ViewFields key={index} fields={item.content.fields} />
                ) : (
                  ""
                )}
                {item &&
                item.content &&
                item.content.type &&
                item.content.type == "chart" ? (
                  <M_ChartCard
                    content={
                      item.content.content && item.content.content.chart
                        ? {
                            ...item.content.content.chart,
                            chartId: item.content.content.chart.id,
                            id: undefined,
                          }
                        : {}
                    }
                  />
                ) : (
                  ""
                )}
                {item &&
                item.content &&
                item.content.type &&
                item.content.type == "maps" ? (
                  <M_MapCard content={item.content.content} />
                ) : (
                  ""
                )}
                {item &&
                item.content &&
                item.content.type &&
                item.content.type == "resultTemplate" ? (
                  <M_ResultCard content={item.content.content} />
                ) : (
                  ""
                )}
                <hr />
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default DisplaySections;
