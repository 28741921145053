import * as React from "react";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { Box } from "@material-ui/core";
import { HistoryRounded } from "@material-ui/icons";
import { ArrowForward, ArrowBack, Close } from "@material-ui/icons";
import * as httpCall from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Divider from "../../../atoms/dataDisplay/dividers/A_Divider";
export default function O_DealStatus(props) {
  let [guideline, setGuideline] = React.useState("");
  const [preButton, setPre] = React.useState();
  const [nextButton, setNext] = React.useState();
  const { templateId, crmId, pageUrl } = useParams();
  const [killPopup, setKillPopup] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setButton();
  }, []);

  const getData = async () => {
    const response = await httpCall.httpGet(
      `/DealChecklist/GetDealChecklist/${crmId}?WorkFlow=Closing`
    );
    let responseData = await response;

    for (var i = 0; i < responseData.length; i++) {
      if (
        responseData[i].dealChecklist &&
        responseData[i].dealChecklist.checklistCompleted === false &&
        props.data.currentStatus == "Completion"
      ) {
        return true;
      }
    }
    return false;
  };

  let updateStatus = async (d, status) => {
    let payload = {
      id: d,
      currentStatus: status,
    };
    await httpCall.httpPost(
      `/CRMData/updateDealStatus?pageUrl=${pageUrl}`,
      payload
    );
    if (status.toLowerCase() == "completion") {
      const response = await httpCall.httpGet(
        `/DealChecklist/GetDealChecklist/${crmId}?WorkFlow=Closing`
      );
      let responseData = await response;
      if(responseData.length>0){
        window.location.reload()
        return
      }
      let newPayload = {
        dealId: crmId,
        dealWorkFlowState: "closing",
        action: "insert",
      };
      await httpCall.httpPost(`/DealChecklist/Insert`, newPayload);
    }
    window.location.reload(false);
  };

  let setButton = async () => {
    var isDisabled = await getData();
    let payload =
      props.data && props.data.statusPipeline ? props.data.statusPipeline : "";
    let response = await httpCall.httpGet(
      `/Common/GetDealWorkFlow?WorkFlowID=${payload}`
    );
    let pipelineData = response && response.items ? response.items : [];
    setData(pipelineData);
    for (let i in pipelineData) {
      if (
        pipelineData[i].state &&
        props.data &&
        props.data.currentStatus &&
        props.data.currentStatus == pipelineData[i].state
      ) {
        setGuideline(
          pipelineData[i].guideline ? pipelineData[i].guideline : ""
        );
        if (i > 0) {
          setPre(
            <A_Button
              style={{ margin: "0.3rem 0" }}
              onClick={() => {
                updateStatus(crmId, pipelineData[i - 1].state);
              }}
              startIcon={<ArrowBack />}
              label={pipelineData[i - 1].state ? pipelineData[i - 1].state : ""}
              color="primary"
            />
          );
        }
        if (pipelineData && i < pipelineData.length) {
          let d = +i + 1;
          setNext(
            <A_Button
              style={{ margin: "0.3rem 0" }}
              onClick={() => {
                updateStatus(
                  crmId,
                  pipelineData[d].state ? pipelineData[d].state : ""
                );
              }}
              endIcon={<ArrowForward />}
              label={pipelineData[d].state ? pipelineData[d].state : ""}
              color="primary"
              disabled={isDisabled}
            />
          );
        }
        break;
      }
    }
  };
  const renderConfirmPopup = (callback) => {
    return (
      <div>
        <div style={{ paddingTop: "20px" }}>
          <A_Button
            onClick={() => {
              updateStatus(crmId, "Killed");
            }}
            color="primary"
            label="Yes"
            // disabled={downloading}
          />
          <A_Button onClick={handleKillPopup} color="default" label="Close" />
        </div>
      </div>
    );
  };
  let handleKillPopup = () => {
    let data = !killPopup;
    setKillPopup(data);
  };
  return (
    <Box>
      <Box style={{ display: "flex", padding: "1.2rem 0", flexWrap: "wrap" }}>
        {/* {data.map} */}
        {props.data &&
        props.data.currentStatus &&
        props.data.currentStatus == "Tracking" &&
        data &&
        data.length > 0 ? (
          <A_Button
            style={{ margin: "0.3rem 0" }}
            onClick={() => {
              updateStatus(crmId, data[0].state);
            }}
            endIcon={<ArrowForward />}
            label={data[0].state}
          />
        ) : props.data &&
          props.data.currentStatus &&
          props.data.currentStatus != "Killed" ? (
          <A_Button
            style={{ margin: "0.3rem 0" }}
            onClick={() => {
              updateStatus(crmId, "Tracking");
            }}
            startIcon={<HistoryRounded />}
            label="Tracking"
          />
        ) : (
          ""
        )}
        {preButton}
        {nextButton}
        {props.data &&
        props.data.currentStatus &&
        props.data.currentStatus == "Killed" &&
        data &&
        data.length > 0 ? (
          <A_Button
            style={{ margin: "0.3rem 0" }}
            onClick={() => {
              updateStatus(crmId, data[0].state);
            }}
            startIcon={<Close />}
            label="Revive"
          />
        ) : (
          <A_Button
            style={{ margin: "0.3rem 0" }}
            onClick={() => {
              handleKillPopup();
            }}
            startIcon={<Close />}
            label="Kill"
          />
        )}
      </Box>
      <A_Divider></A_Divider>
      <A_SimpleDialog
        open={killPopup}
        title="Are you sure you want to kill this deal?"
        fullScreen={false}
        height="400px"
        fullwidth={true}
        maxWidth={"xs"}
        onClose={handleKillPopup}
        closePopUp={handleKillPopup}
        dialogContent={renderConfirmPopup(function () {
          handleKillPopup();
        })}
      />
    </Box>
  );
}
