import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { filter, result } from "lodash";
import * as httpCall from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import axios, { post } from "axios";
import * as commonFunctions from "../../../../../Utils/Helpers/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Radio from "@material-ui/core/Radio";
import ReactJson from "react-json-view";

import { FormControl } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    //width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  searchBox: {
    left: 120,
  },
}));

const ChangeLog = (props) => {
  let { pageUrl, crmId, templateId } = useParams();
  console.log("props", props);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [changeLogData, setChangeLogData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [radio, setRadio] = React.useState("selected");

  React.useEffect(() => {
    const getReports = async () => {
      const url = `${process.env.CHANGE_LOG_URL}`;
      const httpConfig = commonFunctions.getHttpConfig();
      //important
      /* let subdomain =
        "dev";  new URL(window.location.href).hostname.split(".")[0] */
      let subdomain = new URL(window.location.href).hostname.split(".")[0];
      let header = {
        crmId: "eaa8a696-9276-4074-83a3-b5a0b5eaf3de",
        "x-functions-key": process.env.CHANGE_LOG_KEY,
        subdomain: subdomain,
        Authorization: httpConfig.headers.Authorization,
      };
      axios.get(url, { headers: header }).then((response) => {
        setChangeLogData(response.data);
        setLoading(false);
      });
    };

    getReports();
  }, []);
  console.log("result", changeLogData);

  function EnhancedTableHead(props) {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        {/*  {changeLogData.map((row, index) => {
          return renderCell(row);
        })} */}
      </TableHead>
    );
  }
 
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  }));

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
         Record Audit Trail
        </Typography>
      </Toolbar>
    );
  };

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const formattedData = (item) => {
    var date = item * 1000;
    const dateObject = new Date(date);
    const humanDateFormat = dateObject.toLocaleString();
    return <TableCell>{humanDateFormat} </TableCell>;
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableCell align="center">Modified By</TableCell>
                <TableCell align="center">Modified Date</TableCell>
                <TableCell align="center">Field Changed</TableCell>
                <TableCell align="center">New Value</TableCell>
                <TableCell align="center">Previous Value</TableCell>
              </TableHead>
              <TableBody>
                {changeLogData.map((item) => (
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell component="th" scope="row" padding="none">
                      {item.modifiedBy}
                    </TableCell>

                    {formattedData(item.modifiedDate)}

                    <TableCell align="left">{item.fieldChanged}</TableCell>

                    {typeof item.newValue == "string" ? (
                      <TableCell align="left">
                        {item.newValue}
                      </TableCell>
                    ) : (
                      <TableCell>
                        <ReactJson enableClipboard={false}  src={item.newValue} />
                      </TableCell>
                    )}

                    {typeof item.previousValue == "string" ||
                    item.previousValue == null ? (
                      <TableCell  align="left">{item.previousValue}</TableCell>
                    ) : (
                      <TableCell>
                        <ReactJson enableClipboard={false} src={item.previousValue} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </div>
  );
};
export default ChangeLog;

