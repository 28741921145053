import { currentPageActionTypes } from "../actions/actionTypes";

let initialState = {};
const currentPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case currentPageActionTypes.GET_CURRENT_PAGE:
      let currState = { ...state, isLoading: true };
      return currState;
    case currentPageActionTypes.GET_CURRENT_PAGE_SUCCESSED:
      return action.payload;
    case currentPageActionTypes.GET_CURRENT_PAGE_FAILED:
      return action.payload;
    //   case currentPageActionTypes.MANAGE_FIELDS:
    //     let fields=action.payload;
    //     return {...state,fields};
    //   case currentPageActionTypes.MANAGE_TOOLBAR:
    //     let toolbar=action.payload;
    //     return {...state, toolbar};
    default:
      return state;
  }
};
export default currentPageReducer;
