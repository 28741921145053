import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as httpCall from "../../../../../../Utils/apis/apis";
import { useDispatch } from "react-redux";
import { addToolbarFilter } from "../../../../../../store/actions/manageToolbarSelectionsActions";
import Select from "@material-ui/core/Select";
import { clearChartKeys } from "../../../../Pages/Common/PageLayout/P_PageLayout.Helper";

const useStyles = makeStyles((theme) => ({
  filtersPanel: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "5px",
  },

  filterSelect: {
    color: "white",
    "MuiInput-colorSecondary.MuiInput-underline": "Green !important",

    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
    paddingLeft: "6px",
    paddingRight: "6px",
  },

  filterSelectOption: {
    background: "white",
    color: "black",
  },
  underline: {
    borderBottom: "2px solid white",
  },
}));

const M_ToolbarFilter = (props) => {
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState("");
  const availableOptionSets = props.optionSets;
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    getFilterOptions(props.optionSetName);
  }, []);
  useEffect(() => {
    dispatch(
      addToolbarFilter({ filterName: props.filterName, value: selectedValue })
    );
  }, [selectedValue]);

  const getFilterOptions = useCallback(async (optionSetName) => {
    let optionSetFilters = [];
    if (optionSetName === "fundsField") {
      optionSetFilters = [
        { label: "Fund-1", value: "Fund1" },
        { label: "Fund-2", value: "Fund2" },
        { label: "Fund-3", value: "Fund3" },
        { label: "Fund-4", value: "Fund4" },
      ];
    } else {
      if (availableOptionSets) {
        optionSetFilters = getOptions(optionSetName);
      }
      if (optionSetFilters.length === 0) {
        const chartData = await getChart({
          chartId: optionSetName,
          parameters: { investmentId: props.investmentId },
        });
        if (chartData && chartData.data && chartData.data.series) {
          const filterOptions = chartData.data.series.map((chartPoint) => {
            return { label: chartPoint.data[0], value: chartPoint.name[0] };
          });
          optionSetFilters = filterOptions;
        }
      }
    }
    if (optionSetFilters.find((item) => item.value === props.defaultValue)) {
      setSelectedValue(props.defaultValue);
    }
    setFilterOptions(optionSetFilters);
  });

  const getChart = async (chartRequest) => {
    let url = "/ChartData/getchartdata";
    let requestPayload = chartRequest;
    const result = await httpCall.httpPost(url, requestPayload);
    let chData = result;
    return chData;
  };

  const getOptions = (optionSetName) => {
    let options = [];
    let currentOptionSet = availableOptionSets.filter((optionSet) => {
      return optionSet.optionSetName === optionSetName;
    });
    if (currentOptionSet.length > 0) {
      options = currentOptionSet[0].options;
    }
    return options;
  };

  const onFilterChange = (selectedOption, filterId) => {
    clearChartKeys();
    setSelectedValue(selectedOption.target.value);
  };

  let optionsNative = (filterName) => {
    let options = filterOptions.map((option) => (
      <option
        className={classes.filterSelectOption}
        key={option.value}
        value={option.value}
      >
        {option.label}
      </option>
    ));
    return options;
  };

  return (
    <div
      key={`${props.templateId}_${props.filterName}_panel`}
      className={classes.filtersPanel}
    >
      <Select
        key={props.filterName + "_options"}
        className={classes.filterSelect}
        label={props.filterLabel}
        value={selectedValue}
        error={false}
        native={true}
        defaultValue={props.defaultValue}
        onChange={onFilterChange}
      >
        {optionsNative(props.filterLabel)}
      </Select>
    </div>
  );
};

export default M_ToolbarFilter;
