import React, { useEffect, useState } from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import { TimelineOppositeContent } from "@material-ui/lab";
import TimelineDot from "@material-ui/lab/TimelineDot";
import * as httpCall from "../../../../../Utils/apis/apis";
import { Notifications } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router";
import { Box } from "@material-ui/core";
import {
  DeveloperBoard,
  AccountTreeOutlined,
  PriorityHigh,
  Remove,
} from "@material-ui/icons";
export default function O_DealTimeline(props) {
  const { templateId, crmId } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    getData(crmId);
  }, []);
  let getData = async (d) => {
    let dealId = d;
    const response = await httpCall.httpPost(
      `/CRMChartData/getDealTimelineChartGraph?dealId=${dealId}`
    );
    let newD = await response;
    setData(newD ? newD : []);
  };
  let getIcon = (data) => {
    let list = [
      "Developing",
      "Pipeline",
      "Priority",
      "Completion",
      "Kill",
      "Tracking",
    ];
    let newD = "";
    for (let i in list) {
      if (data.includes(list[i])) {
        newD = list[i];
        break;
      }
    }
    switch (newD) {
      case "Developing":
        return (
          <TimelineDot color="primary">
            <DeveloperBoard />
          </TimelineDot>
        );
      case "Pipeline":
        return (
          <TimelineDot color="primary">
            <AccountTreeOutlined />
          </TimelineDot>
        );
      case "Priority":
        return (
          <TimelineDot color="primary">
            <PriorityHigh />
          </TimelineDot>
        );
      case "Completion":
        return (
          <TimelineDot color="primary">
            <Notifications />
          </TimelineDot>
        );
      case "Kill":
        return (
          <TimelineDot color="secondary">
            <Remove />
          </TimelineDot>
        );
      case "Tracking":
        return (
          <TimelineDot>
            <Notifications />
          </TimelineDot>
        );
      default:
        return (
          <TimelineDot>
            <Notifications />
          </TimelineDot>
        );
    }
  };
  return (
    <Box maxHeight={"60vh"} overflow={"auto"}>
      {data ? (
        <Timeline position="alternate">
          <Box
            style={{
              width: "2px",
              height: "20px",
              display: "inline-flex",
              justifyContent: "center",
              alignSelf: "center",
            }}
            bgcolor={"#f0f0f0"}
          ></Box>
          {Array.isArray(data) && data.length > 0
            ? data.map((e, index) => {
                let date = e.date.split(" ")[0];
                if (index % 2 == 0) {
                  return (
                    <>
                      <TimelineItem
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <TimelineOppositeContent
                          style={{ margin: "auto " }}
                          align="right"
                          variant="body"
                          color="text.secondary"
                        >
                          <Typography component="span">{e.status}</Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          {getIcon(e.status)}
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent style={{ paddingY: "12px", paddingX: 2 }}>
                          <Typography>
                            {/* {format(toDate(`${e.date}`),"MM/dd/yyyy")} */}
                            {date}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                      <Box
                        style={{
                          width: "2px",
                          height: "20px",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignSelf: "center",
                        }}
                        bgcolor={"#f0f0f0"}
                      ></Box>
                    </>
                  );
                }
                return (
                  <>
                    <TimelineItem
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <TimelineOppositeContent
                        style={{ margin: "auto " }}
                        align="right"
                        variant="body"
                        color="text.secondary"
                      >
                        <Typography>{date}</Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        {getIcon(e.status)}
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent style={{ paddingY: "12px", paddingX: 2 }}>
                        <Typography component="span">{e.status}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                    <Box
                      style={{
                        width: "2px",
                        height: "20px",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                      bgcolor={"#f0f0f0"}
                    ></Box>
                  </>
                );
              })
            : "No Timeline Found"}
        </Timeline>
      ) : (
        "No Data Found"
      )}
    </Box>
  );
}
