import Moment from "moment";

export const getFormattedDate = (
  anyDate,
  anyFormat,
  fallBackFormat = "MMM-yy-dd"
) => {
  if (anyDate === undefined) return "";

  const newDate = Moment(anyDate).format(
    anyFormat ? anyFormat : fallBackFormat
  );
  return newDate == "Invalid date" ? "" : newDate;
};
