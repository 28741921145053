import React, { useEffect, useState } from 'react'
import { httpGet } from "../../../../../Utils/apis/apis";
import { Box } from '@material-ui/core';
import * as commonFunctions from "../../../../../Utils/Helpers/common";
 function GuideLines(props) {
    const regex = /(<([^>]+)>)/ig;
    const [guideLineData, setGuideLineData] = useState();
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
        getGuideLines()
    }, []);

    let response;
    const getGuideLines = async () => {
        let config = commonFunctions.getHttpConfig();
        response = await httpGet(`${process.env.API_URL}/Common/GetDealWorkFlow?WorkFlowID=irc-guidelines-config`, config);
        setGuideLineData(response);
        setEnabled(true)
    }
    return (
        <Box maxHeight={"60vh"} overflow={"auto"}>

            {enabled && (
                <>
                    {guideLineData && guideLineData.items.map((i) => {
                        {
                            return (
                                <div>
                                    <h4>
                                        {i.title}
                                    </h4>

                                    {i.item.replace(regex, "")}

                                </div>

                            )
                        }

                    })}

                </>

            )}
        </Box>

    )
}
export default GuideLines;