import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import M_TemplatePagesHeader from "../../../Molecules/Common/PageHeaders/TemplatePageHeader/M_TemplatePagesHeader";
import PageContext from "../../../../../contexts/pageContext";
import O_LayoutMenu from "../../../Organisms/Common/LayoutMenu/O_LayoutMenu";
import Layout from "../Layouts/layout";
import ListIcon from "@material-ui/icons/List";
import A_IconButton from "../../../atoms/inputs/buttons/A_IconButton";
import PageNotFound from "../../../../../Routes/PageNotFound";
import ErrorBoundary from "../../../molecules/common/error/ErrorBoundary";
import { useHistory } from "react-router-dom";
import * as manageExcelTemplateActions from "../../../../../store/actions/manageExcelTemplateActions";
import * as addToolbarFilterActions from "../../../../../store/actions/manageToolbarSelectionsActions";
import { SortedLayOuts } from "../../../../Factory/Molecules/Common/Navigations/LayoutMenu/LayoutMenu";
import { handleShowHide, templateToShow } from "./pageLayoutfunctions";
import { AddFilterToChartCard, clearChartKeys } from "./P_PageLayout.Helper";

const useStyles = makeStyles((theme) => ({}));
const P_PageLayout = (props) => {
  const classes = useStyles();
  let { pageUrl, crmId, templateId } = useParams();
  const [currentPage, setCurrentPage] = useState(undefined);
  let [layout, setLayout] = useState([]);
  let [viewOnlyLayouts, setViewOnlyLayouts] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [pageSubtitle, setPageSubtitle] = useState("");
  const [currentPageId, setCurrentPageId] = useState("");
  const [crmDataForShow, setCrmDataForShow] = useState([]);
  const layoutselection = useRef(null);
  const matches = useMediaQuery("(max-width:400px)");
  const [apiError, setApiError] = useState(false);
  const [isExcelTemplateDownload, setIsExcelTemplateDownload] = useState(false);

  const history = useHistory();

  window.onpopstate = function (event) {
    window.location.reload();
  };

  useEffect(() => {
    props.resetToolbarFilters();
    props.setTabs(null);
    props.currentPage.isLoading = true;
    props.getCurrentPage({ pageUrl, crmId });
    //clearChartStorageKeys
    clearChartKeys();
  }, []);

  useEffect(() => {
    //set Active layout
    if (props.currentPage) {
      if (
        props.currentPage.templates &&
        props.currentPage.templates.length > 0
      ) {
        setApiError(false);
        let vwOnlyLayouts = templateToShow(props.currentPage);
        vwOnlyLayouts.reverse();
        vwOnlyLayouts = SortedLayOuts(vwOnlyLayouts);
        var currTemplate =
          templateId == undefined
            ? vwOnlyLayouts[0]
            : vwOnlyLayouts.filter((layOut) => {
                return layOut.id == templateId;
              })[0]
            ? vwOnlyLayouts.filter((layOut) => {
                return layOut.id == templateId;
              })[0]
            : vwOnlyLayouts[0];
        if (currTemplate) {
          let currTemplateJson =
            typeof currTemplate.templateJson === "string" &&
            currTemplate.templateJson.length > 0
              ? JSON.parse(currTemplate.templateJson)
              : currTemplate.templateJson;
          currTemplate.templateJson = currTemplateJson;
          //currTemplate.active = true;
          history.push("/" + pageUrl + "/" + crmId + "/" + currTemplate.id);
          setLayout(AddFilterToChartCard(currTemplate, props.currentPage));
          setPageSubtitle(currTemplate.templateJson.title);
          setCurrentPageId(currTemplate.id);
          setViewOnlyLayouts(vwOnlyLayouts);
        } else {
          setLayout(null);
          setApiError(true);
        }
      }
      //set page title
      if (
        props.currentPage.pageConfig &&
        props.currentPage.pageConfig.type === "CRM" //TODO - Change text comparision with id comparision
      ) {
        let fields = props.currentPage.pageConfig.fields;
        let titleFieldArry = fields.filter((field) => {
          return field.isPageTitle;
        });

        if (titleFieldArry.length > 0) {
          let titleField = titleFieldArry[0];
          let pgTitle =
            props.currentPage && props.currentPage.data
              ? props.currentPage.data[titleField.name]
              : "";
          setPageTitle(pgTitle);
          setCrmDataForShow(props.currentPage.data);
        }
      } else if (
        props.currentPage.pageConfig &&
        props.currentPage.pageConfig.type === "Non-CRM" //Change text comparision with ID comparision
      ) {
        setPageTitle(props.currentPage.pageConfig.name);
      }
    } else {
      setLayout([]);
    }
    setCurrentPage(props.currentPage);

    return () => {
      setCurrentPage(null);
    };
  }, [props.currentPage]);

  useEffect(() => {
    if (
      !props.currentPage.getCurrentPageSucceeded &&
      !props.currentPage.isLoading
    ) {
      setApiError(true);
    }
  }, [props.currentPage.getCurrentPageSucceeded, props.currentPage.isLoading]);

  let newPageConfig = props.currentPage && props.currentPage.pageConfig;
  if (newPageConfig) {
    newPageConfig.subtitle = "subtitle";
  }

  const handleSidebarClick = () => {
    if (matches) {
      if (
        layoutselection.current.style.width == "0px" ||
        layoutselection.current.style.width == ""
      ) {
        layoutselection.current.style.width = "215px";
      } else {
        layoutselection.current.style.width = "0px";
      }
    }
  };

  function handleLayoutMenu(val) {
    if (val !== undefined) {
      let jsonLayout =
        typeof val.templateJson === "string"
          ? JSON.parse(val.templateJson)
          : val.templateJson;
      val.templateJson = jsonLayout;
      if (jsonLayout && jsonLayout.redirectTo && jsonLayout.redirectTo !== "") {
        window.location.href = jsonLayout.redirectTo;
      } else {
        setLayout(null);
        history.push("/" + pageUrl + "/" + crmId + "/" + val.id);
        clearChartKeys();
        setTimeout(() => {
          setLayout(AddFilterToChartCard({ ...val }, props.currentPage));
        }, 100);
        setPageSubtitle(val.templateJson.title);
        setCurrentPageId(val.id);
      }
    }
    handleSidebarClick();
  }

  const downloadExcelTemplatePopUp = (value) => {
    switch (value) {
      case "ExceltemplateDownload":
        setIsExcelTemplateDownload(true);
        break;
      default:
        break;
    }
  };

  const handleClosePopup = (value) => {
    switch (value) {
      case "ExceltemplateDownload":
        setIsExcelTemplateDownload(false);
        break;
      default:
        break;
    }
  };

  return (
    <PageContext.Provider
      value={{
        pageConfig: currentPage && newPageConfig,
        crmData: props.currentPage && props.currentPage.data,
        pageParameters: [],
      }}
    >
      {apiError && (
        <>
          <PageNotFound />
        </>
      )}
      {currentPage && !currentPage.isLoading && !apiError && (
        <main>
          <>
            <article className="page-header">
              <M_TemplatePagesHeader
                title={pageTitle}
                subtitle={pageSubtitle}
                downloadExcelTemplatePopUp={downloadExcelTemplatePopUp}
              />
              <div className="openside-menu">
                <A_IconButton
                  icon={<ListIcon />}
                  color="primary"
                  size="small"
                  onClick={handleSidebarClick}
                ></A_IconButton>
              </div>
            </article>

            {currentPage &&
            currentPage.templates &&
            currentPage.templates.length > 0 ? (
              <article className="page-sidebar" ref={layoutselection}>
                <O_LayoutMenu
                  data={viewOnlyLayouts}
                  currentLayoutTitle={
                    layout && layout.templateJson && layout.name
                  }
                  callbackParent={(val) => handleLayoutMenu(val)}
                  currentPageId={currentPageId}
                />
              </article>
            ) : (
              ""
            )}
            <article className="page-content">
              {!layout && <span>Loading</span>}
              <Layout
                cards={
                  layout &&
                  layout.templateJson &&
                  handleShowHide(layout.templateJson.cards, crmDataForShow)
                }
                data={props.currentPage.data}
                handleClosePopup={handleClosePopup}
                isExcelTemplateDownload={isExcelTemplateDownload}
              />
            </article>
          </>
        </main>
      )}
    </PageContext.Provider>
  );
};

function mapStateToProps(state) {
  return {
    manageExcelTemplates: state.manageExcelTemplates,
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    Object.assign(
      {},
      currentPageActions,
      manageExcelTemplateActions,
      addToolbarFilterActions
    ),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(P_PageLayout);
