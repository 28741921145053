import "date-fns";
import React, {useState} from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import theme from "../../../../Themes/defaultTheme";

 
const A_DatePicker = (props) => {
  const [selectedDate, setSelectedDate] = useState(props.defaultValue ? props.defaultValue : "");
  
  const [error, setError] = useState(false);
  const [helpertext, setHelpertext] = useState(props.helperText ? props.helperText : "");
  const handleDateChange = (date) =>{
    setSelectedDate(date);
    if(date && date.toString() === "Invalid Date"){
      setError(true);
      setHelpertext("Invalid date format");
      props.isDateValidOrNot ? props.isDateValidOrNot(true) : "";
    }
    else{
      props.onChange(date, props.id);
      props.isDateValidOrNot ? props.isDateValidOrNot(false) : "";
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* <FormControl component="fieldset" required={props.required} error={props.error}>     
          
        </FormControl> */}
        <KeyboardDatePicker
            clearable= {props.clearable}
            id={props.id}
            disableToolbar={props.disableToolbar}
            views={props.views}
            variant={props.variant}
            format={props.format && props.format=="default" ? undefined:props.format}
            margin={props.margin}
            label={props.label}
            value={selectedDate}
            defaultValue={props.defaultValue}
            minDate={props.minDate}
            placeholder={props.placeholder}
            helperText = {helpertext}
            error = {error}
            KeyboardButtonProps={{
              "aria-label": props.ariaLabel,
            }}
            onChange={(date) => handleDateChange(date)}
            className={props.className}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
A_DatePicker.defaultProps = {
  id: "date-picker",
  disableToolbar: false,
  //variant: "inline",
  format: "dd/MM/yyyy",
  margin: "normal",
  label: "Select Date",
  value: new Date(),
  ariaLabel: "change date",
  views:['date']
};
A_DatePicker.propTypes = {
  variant: PropTypes.oneOf(["inline"]),
  //color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  //size: PropTypes.oneOf(["small", "medium"]),
  margin: PropTypes.oneOf(["normal", "medium"]),
  onChange: PropTypes.func,
};
export default A_DatePicker;
