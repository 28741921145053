
import { loggedInUserIdActionTypes } from "../actions/actionTypes";

let initialState = {};
const getLoggedInUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case loggedInUserIdActionTypes.GET_LOGGED_IN_USER_TYPE:
        return { getLoggedInUserStarted: true };
    case loggedInUserIdActionTypes.GET_LOGGED_IN_USER_TYPE_SUCCEEDED:
      return action.payload;
    case loggedInUserIdActionTypes.GET_LOGGED_IN_USER_TYPE_FAILED:
      return action.payload;
    default:
      return state;
  }
};
export default getLoggedInUserReducer;