import { take, put, select } from "redux-saga/effects";
import axios from "axios";
import { entityRecordActionType } from "../actions/actionTypes";
import * as entityRecordAction from "../actions/entityRecordAction";
import * as commonFunctions from "../../Utils/Helpers/common";

export function* getEntityRecordSagas() {
  while (true) {
    const action = yield take(entityRecordActionType.GET_ENTITY_RECORD);
    const state = yield select();
    let config = commonFunctions.getHttpConfig();

    if (
      (!config.headers.Authorization ||
        config.headers.Authorization == "Bearer undefined") &&
      action &&
      action.payload &&
      action.payload &&
      action.payload.access_token
    ) {
      config.headers.Authorization = "Bearer " + action.payload.access_token;
    }

    try {
      const res = yield axios.get(
        `${process.env.API_URL}/entities/getRecords`,
        config
      );
      console.log("get entity record response:", res);
      yield put(
        entityRecordAction.getEntityRecordSucceeded({
          data: res.data,
          getEntityRecordSucceeded: true,
        })
      );
    } catch (e) {
      yield put(
        entityRecordAction.getEntityRecordFailed({
          msg: "Some error occured",
          getEntityRecordFailed: true,
        })
      );
    }
  }
}
