import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import debounce from "lodash/debounce";
import theme from "../../../../Themes/defaultTheme";
import * as httpCall from "../../../../../Utils/apis/apis";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
    marginTop: 5,
  },
  selectLabel: {
    marginTop: 5,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const A_Autocomplete = (props) => {
 
  let displayField ="title";
  const classes = useStyles();
  const [value, setValue] = useState(props.defaultValue.properties?props.defaultValue:{edgeLabel:props.edgeLabel,
  fieldType:props.fieldType,
  id:props.id,
  label:props.id,
  properties:{title: props.defaultValue},
  type: "vertex"});
  const [lastInputValue, setLastInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [options, setOptions] = useState([]);
  let edgeLabel=props.edgeLabel
  
  const handleValue = (e, value) => {
    

    setValue({...value ,edgeLabel: props.edgeLabel});
    props.onChange({...value ,edgeLabel: props.edgeLabel}, props.id);
  };
  const debouncedSave = useCallback(
    debounce((nextValue) => getSearchResults(nextValue), props.debounceDelay),
    [] // will be created only once initially
  );
  const getSearchResults = async (newInputValue) => {
    setIsLoading(true);
    let requestPayload = {
      templateId:props.resultTemplate,
      label: props.destinationVertex,
      keyword: newInputValue,
      
    };
   
    const response = await httpCall.httpPost(
      //`/${tenantId}/CRMData/-API-`,
      "/CRMData/getCRMLookupData",
      requestPayload
    );
    if ("message" in response) {
      setIsError(response);
      setIsLoading(false);
    } else {
      setOptions(response.data ? response.data : []);
      setIsLoading(false);
    }
  };
  const handleInputChange = async (e, newInputValue) => {
    if (newInputValue && e && e.type === "change") {
      debouncedSave(newInputValue);
    } else {
      setOptions([]);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <FormControl
        error={props.error || isError}
        variant={props.variant}
        className={classes.formControl}
      >
        <Autocomplete 
          disabled={isLoading || props.disabled}
          required={props.required}
          freeSolo={props.freeSolo}
          id={props.id}
          disableClearable={props.disableClearable}
          options={options}
          getOptionLabel={(option) => {
            return option.properties && option.properties[displayField]
              ? option.properties[displayField]
              : "";
          }}
         renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {props.properties && props.properties["image"] ? (
                <img
                  loading="lazy"
                  width="20"
                  src={option.image}
                  srcSet={option.image}
                  alt=""
                />
              ) : (
                ""
              )}
              {props.properties && props.properties[displayField]
                ? props.properties[displayField]
                : ""}
              <br/>
              <small>
                {props.properties && props.properties["subtitle"]
                  ? props.properties["subtitle"]
                  : ""}
              </small>
            </Box>
          )} 
          //wip for mutiselect autocomplete
         /*  renderTags={(value, getTagProps) =>
            options.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          } */
          size={props.size}
          value={value}
          className={props.className}
          onChange={(event, newValue) => {
            handleValue(event, newValue);
          }}
          onInputChange={(event, newInputValue) => {
            handleInputChange(event, newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.label}
              margin={props.margin}
              variant={props.variant}
              InputProps={{
                ...params.InputProps,
                type: "search",
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </React.Fragment>
                ),
              }}
              required={props.required}
            />
          )}
        />
        <FormHelperText>
          {isError ? "Some error occured. Contact admin." : props.helperText}
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
};
A_Autocomplete.defaultProps = {
  //id: "123",
  label: "Search",
  variant: "outlined",
  color: "primary",
  size: "small",
  required: false,
  disabled: false,
  required: true,
  error: false,
  fullWidth: false,
  margin: "normal",
  helperText: "",
  defaultValue: "",
  focus: false,
  freeSolo: false,
  disableClearable: false,
  debounceDelay: 2000,
};

export default A_Autocomplete;
