import React, { useState, useEffect } from "react";
import M_NavBar from "../../../Molecules/Common/Navigations/M_NavBar";
import M_MainDrawer from "../../../Molecules/Common/Navigations/M_MainDrawer";
import M_SearchResult from "../../../Molecules/Common/Renderers/Views/GlobalSearchRenderers/M_SearchResult";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const O_Navigation = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSearchResult, setOpenSearchResult] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const history = useHistory();

  //handle main-Drawer open/close event.
  const handleDrawer = (event) => {
    setOpenDrawer(!openDrawer);
  };

  const handleSearchDebounce = React.useCallback(
    debounce((e) => {
      setSearchValue(e);
    }, 500),
    []
  );

  const handleSearchBox = (e) => {
    if (e.target.value && e.target.value.length > 1) {
      setOpenSearchResult(true);
      handleSearchDebounce(e.target.value);
    } else if (openSearchResult) {
      setOpenSearchResult(false);
      setSearchValue(null);
    }
  };

  const handleClick = (e) => {
    // if(!link){
    //   return
    // }
    e.stopPropagation();

    let parentNode = e.target.parentElement.parentElement;
    // let link= parentNode.getAttribute('href')
    let pageId = parentNode.getAttribute("pageid");
    let pageUrl = parentNode.getAttribute("pageurl");
    let crmId = parentNode.getAttribute("crmid");
    // let etnRype= parentNode.getAttribute('etnType')
    // let type= etnRype ? etnRype : parentNode.getAttribute('recordtype')
    if (!pageId || !crmId || !pageUrl) {
      return;
    }
    let url = "/" + pageUrl + "/" + crmId;
    setOpenSearchResult(false);
    setSearchValue(null);
    let ele = document.getElementById("globalsearch");
    ele.value = null;
    history.push(url);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <M_NavBar
        defaultValue={searchValue}
        {...props}
        handleDrawer={(e) => handleDrawer(e)}
        handleLogout={(e) => handleLogout(e)}
        onChange={handleSearchBox}
      />

      <M_MainDrawer
        openDrawer={openDrawer}
        user={props.user}
        handleDrawer={(e) => handleDrawer(e)}
      />
      {openSearchResult && searchValue && searchValue.length > 1 ? (
        <M_SearchResult
          user={props.user}
          value={searchValue}
          handleClick={(e) => {
            handleClick(e);
          }}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
function mapStateToProps(state, ownProps) {
  return state;
}

export default connect(mapStateToProps)(O_Navigation);
