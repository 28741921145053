import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import theme from "../../../../Themes/defaultTheme";

const A_Skeleton = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Skeleton variant={props.variant} width={props.width} height={props.height} animation={props.animation} />
    </ThemeProvider>
  );
};
A_Skeleton.defaultProps = {
    variant: "text",
    width: "400px",
    height: "20px",
    animation: "wave"
};
export default A_Skeleton;