import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageContext from "../../../../../../contexts/pageContext";
import M_TemplatePageTitle from "../TemplatePageTitle/M_TemplatePageTitle";
import M_PageToolbar from "../Toolbar/M_PageToolbar";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: "auto",
    width: "auto",
    elevation: 0,
  },
  secondaryMenuButton: {
    marginLeft: "-19px",
  },
  header: {
    background: "#377ca7",
  },
  /*   FundDropdown:{
    color:"white"
  } */
}));

const M_TemplatePagesHeader = (props) => {
  const classes = useStyles();
  const headerLogo = props.logo || "company-default.jpg";
  const [heightData, setHeight] = useState(null);

  const div = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const stageCanvasRef = useRef(null);

  useEffect(() => {
    if (stageCanvasRef.current) {
      let h = +stageCanvasRef.current.offsetHeight;
      setHeight(h);
    }
  }, [stageCanvasRef]);

  return (
    <PageContext.Consumer>
      {({ pageConfig }) => {
        let toolbar =
          pageConfig &&
          pageConfig.toolbar !== "" &&
          JSON.parse(pageConfig.toolbar);

        //let toolbar = mockToolbar;
        const optionSets =
          pageConfig && pageConfig.optionSets ? pageConfig.optionSets : [];

        if (!toolbar && !props.title) {
          return <div className="header-container">Loading...</div>;
        }
        return (
          <>
            <div
              className={classes.header}
              style={{ paddingBottom: `${heightData}px` }}
            >
              <div className="header-container" ref={stageCanvasRef}>
                <div className="grid-item page-title">
                  <M_TemplatePageTitle
                    title={props.title}
                    subtitle={props.subtitle}
                    logo={headerLogo}
                  ></M_TemplatePageTitle>
                </div>

                <div className="grid-item " style={{ margin: "auto 0" }}>
                  {/*Toolbar starts here */}
                  {toolbar && (
                    <M_PageToolbar
                      key="page_toolbar"
                      title={props.title}
                      toolbar={toolbar}
                      optionSets={optionSets}
                      downloadExcelTemplatePopUp={
                        props.downloadExcelTemplatePopUp
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        );
      }}
    </PageContext.Consumer>
  );
};
export default withRouter(M_TemplatePagesHeader);
