import React, { useEffect,useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import { Tooltip } from "@material-ui/core";
import { useParams } from "react-router";
import PersonIcon from "@material-ui/icons/Person";
import Slide from "@material-ui/core/Slide/Slide";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import userManager from "../../../../../utils/oidc/userManager";
import A_IconButton from "../../../atoms/inputs/buttons/A_IconButton";
import M_UserAvatar from "./M_UserAvatar";
import A_Divider from "../../../atoms/dataDisplay/dividers/A_Divider";
import A_Paper from "../../../atoms/surfaces/papers/A_Paper";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { getCurrentUser } from "../../../../../utils/helpers/common";
import TuneIcon from '@material-ui/icons/Tune';
import { withTranslation } from "react-i18next";
import Email from "@material-ui/icons/MailOutline";
import Exit from "@material-ui/icons/ExitToApp";
import Settings from '@material-ui/icons/SettingsOutlined';
import UserPrefrences from "./UserPrefrences";
import { httpPost, httpPut } from "../../../../../Utils/apis/apis";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loggedInUserIdActions from "../../../../../store/actions/loggedInUserIdActions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: "-2px",
    zIndex: 999,
    //marginRight:'-15px'
  },
  person: {
    //marginRight: '-1%'
    "&:hover": {
      color: "#a9a9a9",
    },
  },
  hideProfileSetting: {
    display: "none",
  },
}));
function ListItemLink(props) {
  return props.tooltip ? (
    <Tooltip title={props.tooltip} arrow>
      <ListItem button component="a" {...props} />
    </Tooltip>
  ) : (
    <ListItem button component="a" {...props} />
  );
}

const M_UserArea = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = props;
  const [logoImage, setLogoImage] =React.useState('')
  const handleUserMenu = (event) => {
    setAnchorEl(anchorEl !== null ? null : event.currentTarget);
  };


  const getImage = (userID) => {
    let subdomain =  new URL(window.location.href).hostname.split(".")[0]
    fetch(`${process.env.DOCUMENT_HELPER}`, {
      method: "GET",   
      headers: {
        filename: "profile_"+userID,
        type: "image",
        "file-operation": "download",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    })
      .then((response) => {  
        if (!response.ok) {
          throw Error(response.statusText);
        }     
        return response;
      })
      .then((success) => success.blob())
      .then((data) => {
        const imageAsUrl = URL.createObjectURL(data);
        setLogoImage(imageAsUrl);
      });
  };
React.useEffect(()=>{
 const userID= props.loggedIn && props.loggedIn.data && props.loggedIn.data.length && props.loggedIn.data[0].id ? props.loggedIn.data[0].id : ""
 if(userID && userID!=="" && userID!==null){
  getImage(userID)
 }
 
},[])

  const handleClose = (clickedMenu) => {
    setAnchorEl(null);
    switch (clickedMenu) {
      case "logout":
        handleLogout();
        break;
      case "profile_settings":
        handleServerApp_Redirect("profile_settings");
        break;
      case "change_password":
        handleServerApp_Redirect("change_password");
        break;
      default:
        setAnchorEl(null);
    }
  };

  const handleLogout = () => {
    userManager.signoutRedirect();
    userManager.signoutRedirectCallback().then(() => {
      console.log("signoutRedirectCallback is called");
      localStorage.clear();
      window.location.replace("/");
    });
  };

  const handleServerApp_Redirect = (redirectTo) => {
    if (redirectTo === "change_password") {
      window.location.href = `${
        process.env.OIDC_AUTHORITY
      }/Account/ForgetPassword?username=${btoa(user.profile.name)}`;
    } else {
      window.location.href = `${
        process.env.OIDC_AUTHORITY
      }/Account/Manage?username=${btoa(user.profile.name)}`;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <React.Fragment>     
      <A_IconButton
        color="inherit"
        aria-describedby={id}
        onClick={(e) => handleUserMenu(e)}
        icon={<PersonIcon />}
        className={classes.person}
      ></A_IconButton>
      <Popper
        className={classes.paper}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
      >
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <div>
            <ClickAwayListener onClickAway={(e) => handleUserMenu(e)}>
              <div>
                <A_Paper
                  width={30}
                  // temp fix
                  // height={36}
                  height={"auto"}
                  content={
                    <ProfileMenu
                      handleClose={(e) => handleClose(e)}
                      {...props}
                      logoImage={logoImage}
                    />
                  }
                  size="large"
                />
              </div>
            </ClickAwayListener>
          </div>
        </Slide>
      </Popper>
    </React.Fragment>
  );
};
const ProfileMenu = (props) => {
  //handle profile-menu click event.
  let history = useHistory();
  const classes = useStyles();
  const [radio, setRadio] = React.useState(false);
  const [currentuser, setCurrentuser] = React.useState("");
  const [showUser, setShowUser] = React.useState(false);
  const [value, setValue] = useState("Select");
  let { displayName, userDesignation } = getCurrentUser();
  useEffect(() => {
    props.getLoggedInUser();
    setCurrentuser(props.loggedIn && props.loggedIn.data && props.loggedIn.data.length && props.loggedIn.data[0].id ? props.loggedIn.data[0].id : "")
    fetchMyAPII();
  }, []);

  async function fetchMyAPII() {
    props.getLoggedInUser();
     var data = props.loggedIn && props.loggedIn.data && props.loggedIn.data.length && props.loggedIn.data[0].properties && props.loggedIn.data[0].properties.IsUserPreferenceActivated ? props.loggedIn.data[0].properties.IsUserPreferenceActivated : "";
    // var res = await httpPost(`/CRMData/getViewTemplates?pageUrl=contact&crmId=${data}`);
    // setValue(res.data && res.data.userPreference ? res.data.userPreference :"Do not Tag my Meetings")
     setShowUser(data)
  }
  const { t: translate } = props;
  const handleMenuClick = (clickedMenu) => {
    props.handleClose(clickedMenu);
  };
  const [showProfileSetting, setShowProfileSetting] = React.useState(
    window.location.href.indexOf("quilam") > 0 ||
      window.location.href.indexOf("amitra") > 0 ||
      window.location.href.indexOf("rcapital") > 0
      ? false
      : true
  );

  function mailBox() {
    window.location = `mailto:minervasupport@equipped.ai`;
  }

  function redirectToContactInfo() {
    history.push(`/contact/${currentuser}`);
    handleClose()
    window.location.reload();
  }

  async function handleClick  ()  {
  var data = props.loggedIn && props.loggedIn.data && props.loggedIn.data.length && props.loggedIn.data[0].id ? props.loggedIn.data[0].id : "";
  let res = await httpPost(`/CRMData/getViewTemplates?pageUrl=contact&crmId=${data}`);
  setValue(res.data && res.data.userPreference ? res.data.userPreference :"Select")
  setRadio(true);
  };
  const handleClose = () => {
    setRadio(false);
  };
  return (
    <React.Fragment>
      <List component="nav" aria-label="user area">
        {/* <ListItemLink
          href={`${"/contacts/123"}`}
          onClick={props.handleClose}
          tooltip="View your profile"
        > */}

        <ListItem>
          <ListItemIcon>
            <M_UserAvatar logoImage={props.logoImage} />
          </ListItemIcon>
          <ListItem button onClick={() => redirectToContactInfo()}>
          <ListItemText primary={displayName} secondary={userDesignation} />
        </ListItem>
        </ListItem>
        {/* </ListItemLink> */}
        <div className={showProfileSetting ? "" : classes.hideProfileSetting}>
          <A_Divider />
          <ListItem button onClick={() => handleMenuClick("profile_settings")}>
            <Settings style={{ marginRight: "10px" }} />
            <ListItemText primary={translate("ProfileSettings")} />
          </ListItem>
        </div>
        <div className={showProfileSetting ? "" : classes.hideProfileSetting}>
          <A_Divider />
          {showUser === true ? 
          <ListItem button onClick={() => handleClick()}>
            <TuneIcon style={{ marginRight: "10px" }} />
            <ListItemText primary={translate(" Preferences")} />
          </ListItem>
           : ""}
        </div>
        <A_SimpleDialog
        open={radio}
        title="User preferences"
        height="110"
        fullWidth="1000px"
        onClose={handleClose}
        dialogContent={<UserPrefrences values ={value} LogedUserId={currentuser}/>}
       
        />
        {/*
        <ListItemLink href="/app_settings/123" onClick={props.handleClose}>
          <ListItemText primary={translate("ApplicationSettings")} />

        </ListItemLink>
        <A_Divider />
        <ListItem button onClick={() => handleMenuClick("change_password")}>
          <ListItemText primary={translate("ChangePassword")} />
        </ListItem> */}

        <A_Divider />

        <ListItem
          button
          onClick={() => {
            mailBox("Contact Support");
          }}
        >
          <Email style={{ marginRight: "10px" }} />
          <ListItemText primary={translate("Contact Support")} />
        </ListItem>
        <A_Divider />
        <ListItem button onClick={() => handleMenuClick("logout")}>
          <Exit style={{ marginRight: "10px" }} />
          <ListItemText primary={translate("Logout")} />
        </ListItem>
      </List>
    </React.Fragment>
  );
};
function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(Object.assign({}, loggedInUserIdActions), dispatch);
}

export default  withTranslation() (connect(mapStateToProps, mapDispatchToProps) (M_UserArea));
